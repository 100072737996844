<!--
 * @Author: WGL
 * @Date: 2023-05-30 13:36:31
 * @LastEditors: WGL
 * @LastEditTime: 2024-05-13 16:35:55
 * @Description: 

-->
<template>
  <jwt-table-button
    :config="config"
    :current-path="currentPagePath"
    :menus-obj="userMenusAuthObj"
    :row="detailInfo"
    :show-button-num="showButtonNum"
    :show-icon="showIcon"
    :show-text="showText"
    @table-action="handleHeaderAction"
  />
</template>

<script>
  import { defineComponent, onBeforeMount, computed, useSlots } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter, useRoute } from 'vue-router'
  // import AppForm from '@/components/AppForm'

  export default defineComponent({
    name: 'BaseTableBtn',
    components: {
      // AppForm,
    },
    props: {
      config: { require: true, type: Object, default: () => {} },
      detailInfo: { require: true, type: Object, default: () => {} },
      urlPath: { type: String, default: '' },
      showButtonNum: { type: Number, default: 3 },
      showIcon: { type: Boolean, default: false },
      showText: { type: Boolean, default: true },
    },
    emits: ['table-action'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const slots = useSlots()
      const userMenusAuthObj = computed(() => store.getters['routes/menusObj'])
      const currentPagePath = computed(() => {
        const { path } = route
        return props.urlPath || path || ''
      })

      const handleHeaderAction = (action) => {
        emit('table-action', action.action)
      }

      onBeforeMount(() => {})

      return {
        slots,
        route,
        router,
        userMenusAuthObj,
        currentPagePath,
        handleHeaderAction,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
