/*
 * @Author: WGL
 * @Date: 2022-02-15 18:40:13
 * @LastEditors: WGL
 * @LastEditTime: 2024-05-15 10:53:20
 * @Description: 
 */
import request from '@/utils/request'

export function getRequest(url, params = {}) {
  return request({
    method: 'get',
    url,
    params,
  })
}

export function postRequest(url, data = {}, options = {}) {
  return request({
    url: url,
    method: 'post',
    data,
    ...options
  })
}

export function delRequest(url, row, _this) {
  _this
    .$confirm('确认删除？')
    .then(() => {
      let params = {
        id: row.id,
      }
      postRequest(url, params).then((res) => {
        if (res.code === 0) {
          _this.$notify({
            title: '消息',
            message: res.msg,
            type: 'success',
          })
          _this.dialogFormVisible = false
          _this.getList()
        } else {
          _this.$notify({
            title: '消息',
            message: res.msg,
            type: 'info',
          })
        }
      })
    })
    .catch(() => { })
}
