/*
 * @Author: WGL
 * @Date: 2024-04-26 18:11:00
 * @LastEditors: WGL
 * @LastEditTime: 2024-05-08 13:58:52
 * @Description: 
 */
import Clipboard from 'clipboard'
import { gp } from '@vab'

function clipboardSuccess(text) {
  gp.$baseMessage(`复制${text}成功`, 'success', 'vab-hey-message-success')
}

function clipboardError(text) {
  gp.$baseMessage(`复制${text}失败`, 'error', 'vab-hey-message-success')
}

function windowClipboard(text, showText = true) {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      clipboardSuccess(showText ? text : '')
    })
    .catch(() => {
      clipboardError(showText ? text : '')
    })
}
/**
 * @description 复制数据
 * @param text
 * @param event
 */
export function handleClipboard(text, event, showText = true) {
  if (!event?.target) {
    windowClipboard(text, showText)
    return
  }
  const clipboard = new Clipboard(event.target, {
    text: () => text,
  })
  clipboard.on('success', () => {
    clipboardSuccess(showText ? text : '')
    clipboard.destroy()
  })
  clipboard.on('error', () => {
    windowClipboard(text, showText)
    // clipboardError(showText ? text : '')
    clipboard.destroy()
  })
  clipboard.onClick(event)
}
