<!--
 * @Author: WGL
 * @Date: 2024-07-05 16:38:57
 * @LastEditors: WGL
 * @LastEditTime: 2024-07-05 17:11:55
 * @Description: 
-->
<template>
  <el-popover placement="top-start" title="" trigger="hover" :width="500">
    <template #reference>
      <div>
        <BaseImage empty-img :src="imgList[0]" />
      </div>
    </template>
    <template #default>
      <BaseImages v-if="imgList.length" :src="src" />
    </template>
  </el-popover>
</template>

<!-- 多个图显示一张图预览 -->
<script setup>
  import { defineProps, computed } from 'vue'
  const props = defineProps({
    src: { type: String, default: '' },
  })

  const imgList = computed(() => {
    return props?.src?.split(',') || []
  })
  console.log('imgList', imgList.value)
</script>
<script>
  export default {
    name: 'MultiImgPreview',
  }
</script>
<style scoped></style>
