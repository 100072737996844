/*
 * @Author: WGL
 * @Date: 2022-03-14 11:59:46
 * @LastEditors: WGL
 * @LastEditTime: 2024-04-19 14:52:15
 * @Description: 
 */
import { getRequest, postRequest } from './api'

const module = 'sale-order'
const module_detail = 'sale-order-detail'
export function getOrderList(params = {}) {
  const url = `${module}/index`
  return getRequest(url, params)
}

export function addOrder(params = {}) {
  const url = `${module}/add`
  return postRequest(url, params)
}

export function editOrder(params = {}) {
  const url = `${module}/edit`
  return postRequest(url, params)
}

export function deleteOrder(params = {}) {
  const url = `${module}/delete`
  return postRequest(url, params)
}

// 获取订单标题
export function getOrderTitle(params = {}) {
  const url = `${module}/title`
  return postRequest(url, params)
}
// 订单详情添加
export function orderDetailAdd(params = {}) {
  const url = `${module_detail}/add`
  return postRequest(url, params)
}
// 订单详情编辑
export function orderDetailEdit(params = {}) {
  const url = `${module_detail}/edit`
  return postRequest(url, params)
}
