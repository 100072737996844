import request from '@/utils/request'

export function getList(params = { page: 1, per_page: 100 }) {
  return request({
    url: '/role/roles',
    method: 'get',
    params,
  })
}

export function getRoleOperateTree(params) {
  return request({
    url: '/menu/all',
    method: 'get',
    params,
  })
}

export function addRole(data) {
  return request({
    url: '/role/add',
    method: 'post',
    data,
  })
}

export function oddRole(data) {
  return request({
    url: '/role/odd',
    method: 'post',
    data,
  })
}

export function editRole(data) {
  return request({
    url: '/role/edit',
    method: 'post',
    data,
  })
}

export function oditRole(data) {
  return request({
    url: '/role/odit',
    method: 'post',
    data,
  })
}
