<template>
  <el-dialog
    v-model="dialogCommentVisible"
    center
    destroy-on-close
    fullscreen
    :title="dialogCommentTitle"
    @close="handleDialogClose"
  >
    <div v-if="contentId" class="comment-wrapper fv w">
      <slot></slot>
      <div class="fx ex mh0">
        <div
          class="content-wrapper ex2 mr10"
          :class="[editContent ? '' : 'bor r5px p10 oy']"
        >
          <rich-text-view :content-id="contentId" :edit-content="editContent" />
        </div>
        <base-comment
          :ref-id="contentId"
          :ref-type="refType"
          :title="commentTitle"
        />
      </div>
    </div>
    <base-form
      v-else
      ref="baseForm"
      v-model="formModel"
      :config="baseFormConfig"
      @addSuccess="getAddSuccessData"
    />
  </el-dialog>
</template>

<script>
  import {
    defineComponent,
    onBeforeMount,
    reactive,
    toRefs,
    computed,
    ref,
  } from 'vue'
  // import dayjs from 'dayjs'
  export default defineComponent({
    name: 'DialogComment',
    components: {},
    props: {
      dialogTitle: { type: String, default: '标题' },
      dialogVisible: { type: Boolean, default: false },
      refType: { type: [String, Number], default: 'story' },
      refId: { type: [String, Number], default: '' },
      content: { type: String, default: '' },
      editContent: { type: Boolean, default: false }, // 是否内容可编辑
      commentTitle: { type: String, default: '评论人员' },
      sourceId: { type: [String, Number], default: '' },
    },
    emits: ['close', 'update:modelValue', 'addSuccessStory'],
    setup(props, { emit }) {
      const dialogCommentVisible = computed({
        get: () => {
          return props.dialogVisible
        },
        set: (value) => {
          emit('update:modelValue', value)
        },
      })
      const contentId = computed(() => props.refId)
      console.log('contentId', contentId)
      const dialogCommentTitle = computed({
        get: () => {
          return props.dialogTitle
        },
        set: (value) => {
          emit('update:modelValue', value)
        },
      })
      const baseForm = ref(null)
      const formModel = ref({})

      const state = reactive({
        commentContent: '',
        listData: [],
        dialogVisibleContent: false,
      })
      const baseFormConfig = reactive({
        api: {
          add: `/story/add`,
          update: `/story/edit`,
        },
        formListKey: true, // 参数是formList的key
        formAttrs: {
          labelPosition: 'left',
          labelWidth: '100px',
        },
        formList: [
          {
            type: 'input',
            key: 'title',
            value: '',
            labelAttrs: {
              label: '标题',
              rules: [
                {
                  required: true,
                  message: '请输入标题',
                  trigger: 'blur',
                },
              ],
            },
            formAttrs: {
              type: 'text',
              placeholder: '请输入标题',
              clearable: true,
            },
          },
          {
            type: 'selectServer',
            key: 'story_type_id',
            labelAttrs: {
              label: '类型',
              rules: [
                {
                  required: true,
                  message: '请选择类型',
                  trigger: 'blur',
                },
              ],
            },
            formAttrs: {
              listApi: `/story-type/index`,
              class: 'w',
              placeholder: '请选择类型',
            },
          },
          {
            type: 'richText',
            key: 'content',
            value: '',
            labelAttrs: {
              label: '内容',
              rules: [
                {
                  required: true,
                  message: '请输入内容',
                  trigger: 'blur',
                },
              ],
            },
          },
          {
            type: 'submit',
            submit: {
              title: '确定',
              attrs: {
                type: 'primary',
              },
            },
            reset: {
              title: '重置',
            },
          },
        ],
      })

      const getAddSuccessData = (data) => {
        console.log('getAddSuccessData data', data)
        emit('addSuccessStory', data)
        handleDialogClose()
      }

      const handleDialogClose = () => {
        emit('close', true)
      }
      onBeforeMount(() => {})

      return {
        dialogCommentVisible,
        baseFormConfig,
        baseForm,
        formModel,
        contentId,
        dialogCommentTitle,
        ...toRefs(state),
        handleDialogClose,
        getAddSuccessData,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .comment-wrapper {
    width: 100%;
    height: 90vh;
    overflow-y: scroll;
  }
</style>
