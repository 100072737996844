<template>
  <jwt-icon :icon="icon" :size="size" />
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'VabIcon',
    props: {
      icon: { type: String, default: 'settings-3-line' },
      size: { type: [String, Number], default: 16 },
    },
  })
</script>

<style lang="scss" scoped>
  .tc {
    margin-bottom: 5px;
  }
</style>
