/*
 * @Author: WGL
 * @Date: 2022-02-15 18:40:13
 * @LastEditors: WGL
 * @LastEditTime: 2024-03-14 17:34:51
 * @Description: 
 */
import request from '@/utils/request'

export function getList(params = { page: 1, per_page: 100 }) {
  return request({
    url: '/menu/list',
    method: 'get',
    params,
  })
}

export function getUserMenuList(params = { page: 1, per_page: 100, }) {
  return request({
    url: '/menu/menus',
    method: 'get',
    params,
  })
}

/**
 * 获取用户菜单徽章
 * @returns 返回请求结果
 */
export function getUserMenuBadge() {
  return request({
    url: '/menu/badge',
    method: 'get',
    params: {},
  })
}
/**
 * 清除用户菜单徽章
 *
 * @returns 返回一个Promise对象，用于处理请求结果
 */
export function clearUserMenuBadge(id) {
  return request({
    url: '/menu/badge',
    method: 'post',
    data: {
      id
    },
  })
}