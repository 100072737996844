<template>
  <el-dialog
    v-model="dialogEchartVisible"
    center
    :title="dialogTitle"
    width="80%"
    @close="handleDialogClose"
  >
    <vab-card class="echart-card-wrapper" shadow="hover" skeleton>
      <template #header>
        <div class="fxmj">
          <span>
            <vab-icon icon="line-chart-fill" />
            {{ dialogTitle }}
          </span>
        </div>
      </template>
      <vab-chart
        v-if="dialogEchartVisible"
        class="target-echart"
        :init-options="initOptions"
        :option="option"
        theme="vab-echarts-theme"
      />
    </vab-card>
  </el-dialog>
</template>

<script>
  import {
    defineComponent,
    watch,
    onBeforeMount,
    reactive,
    toRefs,
    nextTick,
    computed,
  } from 'vue'
  import request from '@/utils/request'
  import dayjs from 'dayjs'
  import VabChart from '@/extra/VabChart'
  import { INDEX_TYPE_ECAHART } from '@/config/variable.config'
  import { t } from '@/utils/i18n'

  export default defineComponent({
    name: 'DialogEchart',
    components: {
      VabChart,
    },
    props: {
      dialogTitle: {
        type: String,
        default: '',
      },
      dialogVisible: {
        type: Boolean,
        default: false,
      },
      echartType: {
        type: Number,
        default: 0,
      },
      dataApi: {
        require: true,
        type: String,
        default: '',
      },
    },
    emits: ['close', 'update:modelValue'],
    setup(props, { emit }) {
      watch(props, () => {
        console.log('props', props)
        if (props.dialogVisible) {
          nextTick(() => {
            getDataDetail()
          })
        }
      })
      const colorList = ['#9E87FF', '#73DDFF', '#fe9a8b', '#F56948', '#9E87FF']
      const dialogEchartVisible = computed({
        get: () => {
          return props.dialogVisible
        },
        set: (value) => {
          emit('update:modelValue', value)
        },
      })
      const state = reactive({
        title: t('图表显示'),
        initOptions: {
          renderer: 'svg',
        },
        option: {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'z-index:1',
          },
          grid: {
            top: '4%',
            left: '2%',
            right: '2%',
            bottom: '0%',
            containLabel: true,
          },
          xAxis: [
            {
              type: 'category',
              data: [],
              axisLine: {
                lineStyle: {
                  color: '#DCE2E8',
                },
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                rotate: 65,
                interval: 0,
                color: '#556677',
                fontSize: 12,
                margin: 15,
              },
              axisPointer: {
                label: {
                  padding: [0, 0, 10, 0],
                  margin: 15,
                  fontSize: 12,
                  backgroundColor: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: '#fff',
                      },
                      {
                        offset: 0.86,
                        color: '#fff',
                      },
                      {
                        offset: 0.86,
                        color: '#33c0cd',
                      },
                      {
                        offset: 1,
                        color: '#33c0cd',
                      },
                    ],
                    global: false,
                  },
                },
              },
              boundaryGap: false,
            },
          ],
          yAxis: [
            {
              type: 'value',
              axisTick: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: '#DCE2E8',
                },
              },
              axisLabel: {
                color: '#556677',
              },
              splitLine: {
                show: false,
              },
            },
          ],
          series: [
            {
              name: t('指标值'),
              type: INDEX_TYPE_ECAHART[props.echartType],
              data: [],
              symbolSize: 1,
              symbol: 'circle',
              smooth: true,
              yAxisIndex: 0,
              showSymbol: false,
              lineStyle: {
                width: 2,
                color: new VabChart.graphic.LinearGradient(1, 1, 0, 0, [
                  {
                    offset: 0,
                    color: '#73DD39',
                  },
                  {
                    offset: 1,
                    color: '#73DDFF',
                  },
                ]),
                shadowColor: 'rgba(115,221,255, 0.3)',
                shadowBlur: 10,
                shadowOffsetY: 20,
              },
              itemStyle: {
                color: colorList[1],
                borderColor: colorList[1],
              },
            },
          ],
        },
        radioOptins: {
          day: t('日'),
          month: t('月'),
          year: t('年'),
        },
        radio: 'day',
      })

      // 获取数据详情信息
      const getDataDetail = () => {
        const params = {
          date_time_end: dayjs().format('YYYY-MM-DD HH:mm:ss'),
          per_page: 100000000,
        }
        request({
          url: props.dataApi,
          method: 'get',
          params,
        }).then((res) => {
          console.log('res', res)
          const xAxisData = []
          const seriesData = []
          res.data.map((item) => {
            xAxisData.push(item.x)
            seriesData.push(item.v)
          })
          // console.log('xAxisData', xAxisData)
          // console.log('seriesData', seriesData)
          state.option.xAxis[0].data = xAxisData || []
          // console.log('xAxisData.length', parseInt(xAxisData.length / 50))
          state.option.xAxis[0].axisLabel.interval = parseInt(
            xAxisData.length / 50
          )
          state.option.series[0].data = seriesData || []
        })
      }

      const handleDialogClose = () => {
        emit('close', true)
      }
      onBeforeMount(() => {})

      return {
        dialogEchartVisible,
        ...toRefs(state),
        getDataDetail,
        handleDialogClose,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .echart-card-wrapper {
    width: 100%;
    :deep() {
      .el-card__body {
        position: relative;

        .echarts {
          width: 100%;
        }
      }
    }
  }
</style>
