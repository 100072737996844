<!--
 * @Author: WGL
 * @Date: 2024-04-02 11:19:18
 * @LastEditors: WGL
 * @LastEditTime: 2024-08-09 09:38:14
 * @Description:
-->
<template>
  <div class="fxm mind-node-normal-item" :style="nodeStyleVar">
    <div ref="mindNodeParent" class="mind-node-normal-item-parent-wrapper">
      <el-popover
        effect="dark"
        placement="top-start"
        trigger="hover"
        :width="280"
      >
        <template #reference>
          <div ref="mindNodeText" class="mind-node-normal-item-text cp">
            <div v-if="subTitleText" class="omit">{{ subTitleText }}</div>
            <div class="omit">
              {{ node[titleKey] }} (
              <span class="cd b">{{ currentProgress }}</span>
              )
            </div>
            <!-- <div class="fxm">
              <span>负责人：</span>
              <el-image
                class="wh20 r100px"
                fit="fill"
                :lazy="true"
                :src="node?.mainer?.avatar"
              />
              <span class="ml2">{{ node.mainer.name }}</span>
            </div> -->
          </div>
        </template>
        <div>
          <div class="fxm ptb2">
            <span>{{ t('工单') }}：</span>
            <div>{{ node[titleKey] }}</div>
          </div>
          <div v-if="subTitleText" class="fxm ptb2">
            <span>{{ t('产品') }}：</span>
            <div>{{ subTitleText }}</div>
          </div>
          <div class="fxm ptb2">
            <span>{{ t('数量') }}：</span>
            <div>
              <span>{{ node.plan_num }}</span>
              (
              <span class="cs">{{ node.finish_num }}</span>
              )
            </div>
          </div>
          <div class="fxm ptb2">
            <span>{{ t('状态') }}：</span>
            <div>{{ PRODUCTION_RECEIPT_STATUS[node.status] }}</div>
          </div>
          <div class="fxm ptb2">
            <span>{{ t('进度') }}：</span>
            <div class="cd">{{ currentProgress }}</div>
          </div>
          <div class="fxm ptb2">
            <span>{{ t('负责人') }}：</span>
            <el-image
              class="wh20 r100px"
              fit="fill"
              :src="node?.mainer?.avatar"
            />
            <span class="ml2">{{ node?.mainer?.name }}</span>
          </div>
        </div>
      </el-popover>
    </div>
    <div v-if="hasChild" class="mind-node-normal-item-children-wrapper fxm">
      <div class="mind-node-normal-item-children-line"></div>
      <div ref="childrenItem" class="mind-node-normal-item-children-item">
        <MindMapNodeNormal
          v-for="nodeList in node.children"
          :key="nodeList.id"
          :class="[hasChild === 1 ? 'only-child' : '']"
          :node="nodeList"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, ref, computed } from 'vue'
  import { getDeepVal, percentNum } from '@/utils/index'
  import { t } from '@/utils/i18n'
  import {
    PRODUCT_NATURE,
    PRODUCTION_RECEIPT_STATUS,
  } from '@/config/variable.config'

  // 脑图节点
  export default defineComponent({
    name: 'MindMapNodeNormal',
    props: {
      node: { type: Object, default: () => ({}) },
      titleKey: { type: String, default: 'code' },
      subTitleKey: { type: String, default: 'product_info.title' },
      totalKey: { type: String, default: 'plan_num' },
      finishKey: { type: String, default: 'finish_num' },
    },
    setup(props) {
      const childrenItem = ref(null)
      const mindNodeParent = ref(null)
      const mindNodeText = ref(null)

      // 是否有子节点
      const hasChild = computed(() => {
        return props.node?.children && props.node?.children?.length
      })
      // 产品类型
      const productNature = computed(() => {
        return PRODUCT_NATURE[props.node?.product_info?.nature]
      })
      // 脑图节点文本
      const subTitleText = computed(() => {
        return `${getDeepVal(props.subTitleKey, props.node)} [${
          productNature.value
        }]`
      })

      const currentProgress = computed(() => {
        if (props.node[props.finishKey] === props.node[props.totalKey]) {
          return '100%'
        }
        return percentNum(props.finishKey, props.totalKey, props.node) + '%'
      })

      const nodeStyleVar = computed(() => {
        return {
          '--node-progress': currentProgress.value,
          '--node-progress-color-default':
            props.node.status === 0 ? '#ffb13e' : '#2a99ff',
        }
      })
      return {
        hasChild,
        childrenItem,
        mindNodeText,
        mindNodeParent,
        currentProgress,
        subTitleText,
        productNature,
        t,
        PRODUCT_NATURE,
        PRODUCTION_RECEIPT_STATUS,
        nodeStyleVar,
      }
    },
  })
</script>

<style scoped lang="scss">
  $space-left: 20px;
  $space-right: 100px;
  .mind-node-normal-item {
    position: relative;

    &-text {
      position: relative;
      width: 240px;
      padding: 8px 10px;
      margin: 10px 0 10px $space-left;
      border-radius: 4px;
      color: #fff;
      // background-color: var(--el-color-primary-light-5);
      background: linear-gradient(
        to right,
        var(--el-color-success) var(--node-progress),
        var(--node-progress-color-default) var(--node-progress)
      );

      .mind-node-normal-item-finish {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 50%;
        background-color: var(--el-color-success);
      }
    }
    &-parent {
      &-wrapper {
      }
    }
    &-children {
      &-wrapper {
      }
      &-line {
        width: $space-right;
        height: 1px;
        background-color: var(--el-color-primary);
      }
      &-item {
        .mind-node-normal-item {
          position: relative;
          &::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            height: 100%;
            width: 1px;
            background-color: var(--el-color-primary);
          }
          &:first-child {
            &::before {
              top: 50%;
              height: 50%;
            }
          }
          &:last-child {
            &::before {
              top: 0%;
              height: 50%;
            }
          }
          .mind-node-normal-item-text {
            &::before {
              content: '';
              position: absolute;
              top: 50%;
              left: -$space-left;
              width: $space-left;
              height: 1px;
              // transform: translateY(-50%);
              background-color: var(--el-color-primary);
              // border-top: 0.5px solid var(--el-color-primary);
            }
          }
        }
        .only-child {
          &::before {
            all: initial;
          }
        }
      }
    }
  }
</style>
