import { getRequest, postRequest } from './api'

const module = 'app'
export function getList(params = { page: 1, per_page: 100 }) {
  const url = `${module}/index`
  return getRequest(url, params)
}

export function install(params) {
  const url = `${module}/install`
  return postRequest(url, params)
}

export function uninstall(params) {
  const url = `${module}/uninstall`
  return postRequest(url, params)
}

// 切换产品
export function changeApp(params) {
  const url = `${module}/enter`
  return postRequest(url, params)
}
