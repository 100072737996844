import { EXTEND_ATTR_TYPE } from '@/config/variable.config'
import { t } from '@/utils/i18n'
import dayjs from 'dayjs'
/**
 * @description 格式化时间
 * @param time
 * @param cFormat
 * @returns {string|null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  }
  return format.replace(/{([ymdhisa])+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
}

/**
 * @description 格式化时间
 * @param time
 * @param option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @description 将url请求参数转为json格式
 * @param url
 * @returns {{}|any}
 */
export function paramObj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
    decodeURIComponent(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
      .replace(/\+/g, ' ') +
    '"}'
  )
}

export function objectToUrlParams(obj) {
  let params = [];

  // 遍历对象
  for (let key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj?.hasOwnProperty(key)) {
      // 对键和值进行编码
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(obj[key]);

      // 拼接成 'key=value' 形式的字符串并添加到数组中
      params.push(encodedKey + '=' + encodedValue);
    }
  }

  // 使用 & 符号连接数组中的所有元素
  return params.join('&');
}

// 示例用法
let myObj = {
  name: 'John Doe',
  age: 30,
  city: 'New York'
};

let urlParams = objectToUrlParams(myObj);
console.log(urlParams); // 输出: "name=John%20Doe&age=30&city=New%20York"
/**
 * @description 父子关系的数组转换成树形结构数据
 * @param data
 * @returns {*}
 */
export function translateDataToTree(data) {
  const parent = data.filter(
    (value) => value.parentId === 'undefined' || value.parentId === null
  )
  const children = data.filter(
    (value) => value.parentId !== 'undefined' && value.parentId !== null
  )
  const translator = (parent, children) => {
    parent.forEach((parent) => {
      children.forEach((current, index) => {
        if (current.parentId === parent.id) {
          const temp = JSON.parse(JSON.stringify(children))
          temp.splice(index, 1)
          translator([current], temp)
          typeof parent.children !== 'undefined'
            ? parent.children.push(current)
            : (parent.children = [current])
        }
      })
    })
  }
  translator(parent, children)
  return parent
}

/**
 * @description 树形结构数据转换成父子关系的数组
 * @param data
 * @returns {[]}
 */
export function translateTreeToData(data) {
  const result = []
  data.forEach((item) => {
    const loop = (data) => {
      result.push({
        id: data.id,
        name: data.name,
        parentId: data.parentId,
      })
      const child = data.children
      if (child) {
        for (let i = 0; i < child.length; i++) {
          loop(child[i])
        }
      }
    }
    loop(item)
  })
  return result
}

/**
 * @description 10位时间戳转换
 * @param time
 * @returns {string}
 */
export function tenBitTimestamp(time) {
  const date = new Date(time * 1000)
  const y = date.getFullYear()
  let m = date.getMonth() + 1
  m = m < 10 ? '' + m : m
  let d = date.getDate()
  d = d < 10 ? '' + d : d
  let h = date.getHours()
  h = h < 10 ? '0' + h : h
  let minute = date.getMinutes()
  let second = date.getSeconds()
  minute = minute < 10 ? '0' + minute : minute
  second = second < 10 ? '0' + second : second
  return y + '年' + m + '月' + d + '日 ' + h + ':' + minute + ':' + second //组合
}

/**
 * @description 13位时间戳转换
 * @param time
 * @returns {string}
 */
export function thirteenBitTimestamp(time) {
  const date = new Date(time / 1)
  const y = date.getFullYear()
  let m = date.getMonth() + 1
  m = m < 10 ? '' + m : m
  let d = date.getDate()
  d = d < 10 ? '' + d : d
  let h = date.getHours()
  h = h < 10 ? '0' + h : h
  let minute = date.getMinutes()
  let second = date.getSeconds()
  minute = minute < 10 ? '0' + minute : minute
  second = second < 10 ? '0' + second : second
  return y + '年' + m + '月' + d + '日 ' + h + ':' + minute + ':' + second //组合
}

/**
 * @description 获取随机id
 * @param length
 * @returns {string}
 */
export function uuid(length = 32) {
  const num = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
  let str = ''
  for (let i = 0; i < length; i++) {
    str += num.charAt(Math.floor(Math.random() * num.length))
  }
  return str
}

/**
 * @description m到n的随机数
 * @param m
 * @param n
 * @returns {number}
 */
export function random(m, n) {
  return Math.floor(Math.random() * (m - n) + n)
}

/**
 * @description addEventListener
 * @type {function(...[*]=)}
 */
export const on = (function () {
  return function (element, event, handler, useCapture = false) {
    if (element && event && handler) {
      element.addEventListener(event, handler, useCapture)
    }
  }
})()

/**
 * @description removeEventListener
 * @type {function(...[*]=)}
 */
export const off = (function () {
  return function (element, event, handler, useCapture = false) {
    if (element && event) {
      element.removeEventListener(event, handler, useCapture)
    }
  }
})()

/**
 * @description 数组打乱
 * @param array
 * @returns {*}
 */
export function shuffle(array) {
  let m = array.length,
    t,
    i
  while (m) {
    i = Math.floor(Math.random() * m--)
    t = array[m]
    array[m] = array[i]
    array[i] = t
  }
  return array
}

/**
 * 对象转数组
 * @param {*} object 对象
 * @param {*} parseInt 是否转化为正数
 * @param {*} keyTitle
 * @param {*} valueTitle
 */
export function objToArr(
  object = {},
  parseint = true,
  keyTitle = 'value',
  valueTitle = 'label'
) {
  var arr = []
  for (const [key, value] of Object.entries(object)) {
    const obj = {}
    obj[keyTitle] = parseint ? parseInt(key) : key
    obj[valueTitle] = t(value)
    arr.push(obj)
  }
  return arr
}

/**
 * 对象转数组
 * @param {*} object 对象
 * @param {*} parseInt 是否转化为正数
 * @param {*} keyTitle
 * @param {*} valueTitle
 */
export function objToArrRadio(
  object = {},
  parseint = true,
  keyTitle = 'title',
) {
  var arr = []
  for (const [key, value] of Object.entries(object)) {
    const obj = {}
    obj[keyTitle] = t(value)
    obj.attrs = {
      label: parseint ? parseInt(key) : key
    }
    arr.push(obj)
  }
  return arr.sort((a, b) => {
    return a.attrs.label - b.attrs.label
  })
}

/**
 * 数组转数组
 * @param {*} object 对象
 * @param {*} keyTitle
 * @param {*} valueTitle
 */
export function arrToArr(
  object,
  keyTitle = 'value',
  valueTitle = 'label'
) {
  var arr = []
  for (const [, value] of Object.entries(object)) {
    const obj = {}
    obj[keyTitle] = value
    obj[valueTitle] = value
    arr.push(obj)
  }
  return arr
}

/**
 * 数组转对象
 * @param {*} arr 数组
 * @param {*} targetId
 */
export function arrToObj(arr, targetId = 'id') {
  if (!targetId) {
    return {}
  }
  var obj = {}
  arr.forEach((item) => {
    const key = item[targetId]
    obj[key] = item
  })
  return obj
}

export function keyToValue(target = {}) {
  const obj = {}
  for (const [key, value] of Object.entries(target)) {
    obj[value] = key
  }
  return obj
}

export function deleteKey(target = {}, params = []) {
  params.map((key) => {
    delete target[key]
  })
}

/**
 * 判断对象是否包含指定键名
 *
 * @param key 键名
 * @param obj 对象
 * @returns 是否存在该键名
 */
export function keyInObj(key, obj = {}) {
  // eslint-disable-next-line no-prototype-builtins
  return obj?.hasOwnProperty(key);
}

export function handleProductCategoryList(listData = [], parentTitleArr = []) {
  listData.forEach((item) => {
    const fullNameArr = F.copy(parentTitleArr)
    fullNameArr.push(item.title)
    item.fullNameArr = fullNameArr
    if (item?.children?.length) {
      handleProductCategoryList(item.children, fullNameArr)
    }
  })
}

/**
 * 过滤级联中包含的数据
 * @param {*} list
 * @returns
 */
export function handleFilterCascader(list = []) {
  console.log('list', list)
  debugger
  const newList = []
  for (const item of list) {
    if (newList.indexOf(item) === -1) {
      newList.push(item)
      for (let i = 0; i < newList.length; i++) {
        if (newList[i]?.length > item.length && newList[i].includes(item)) {
          newList.pop()
          break
        } else if (
          newList[i]?.length < item.length &&
          item.includes(newList[i])
        ) {
          newList.splice(i, 1)
          break
        }
      }
    }
  }
  return newList
}

/**
 * 扩展属性数据处理
 * @param {*} dataList // 扩展属性配置
 * @param {*} formModeName // 数据key
 */
export function handleExtraAttrs(dataList = [], formModeName = '') {
  const formList = []
  const formModel = {}
  dataList.forEach((item) => {
    const formItem = {
      type: '',
      key: '',
      value: 0,
      labelAttrs: {
        type: 'text',
        label: '',
      },
      formEvents: {},
      layoutAttrs: {},
      formAttrs: {
        placeholder: '',
        clearable: true,
      },
      options: [],
    }
    formItem.key = formModeName
      ? `${formModeName}[${item.attr_key}]`
      : item.attr_key
    let value = item.attr_value || item.default_value
    if (item.attr_type === 'inputNumber' || item.attr_type === 'switch') {
      value = +value
    }
    if (EXTEND_ATTR_TYPE[item.attr_type].multiple) {
      value = value ? value.split(',') : []
    }
    formItem.value = value
    formModel[item.attr_key] = value
    formItem.labelAttrs.label = item.attr_title
    formItem.formAttrs.placeholder = item.placeholder
    formItem.formAttrs.class = 'w'
    formItem.formAttrs.multiple = EXTEND_ATTR_TYPE[item.attr_type].multiple
    const formItemType = EXTEND_ATTR_TYPE[item.attr_type].type
    formItem.type = formItemType
    formItem.options =
      EXTEND_ATTR_TYPE[item.attr_type].option && JSON.parse(item.options)
    if (item.is_required) {
      formItem.labelAttrs.rules = [
        {
          required: true,
          message: item.placeholder,
          trigger: 'blur',
        },
      ]
    }
    if (item.span) {
      formItem.layoutAttrs = { span: item.span }
    }
    if (formItemType === 'uploadImg') {
      formItem.formAttrs = { ...formItem.formAttrs, copyImgUploade: true, }
    }
    formList.push(formItem)
  })

  return { formList, formModel }
}

export function setSessionStorage(name, data) {
  sessionStorage.setItem(name, JSON.stringify(data))
}

export function getSessionStorage(name) {
  // console.log(`name`, name)
  const result = sessionStorage.getItem(name)
  return JSON.parse(result) || ''
}

export function getSummaries(param) {
  const { columns, data } = param
  const properties = ['num'];
  const sums = []
  columns.forEach((column, index) => {
    if (index === 0) {
      sums[index] = t('总计')
      return
    }
    if (index && properties.indexOf(column.property)) {
      const values = data.map((item) => Number(item[column.property]))
      if (!values.every((value) => Number.isNaN(value))) {
        sums[index] = `${values.reduce((prev, curr) => {
          const value = Number(curr)
          if (!Number.isNaN(value)) {
            const result = prev + curr
            return +result.toFixed(2)
          } else {
            return prev.toFixed(2)
          }
        }, 0)}`
      } else {
        sums[index] = '-'
      }
    }
  })
  return sums
}
/**
 * 返回对象层级中的某一个值
 * @param {*} key 字符串  比如 “a.b.c”
 * @param {*} data 取值的对象
 */
export function getDeepVal(key, data) {
  if (!key) {
    return '-'
  }
  if (key?.includes('.')) {
    return key.split(".").reduce((p, c) => {
      return p && p[c];
    }, data);
  }
  return data[key];
}

/**
 * 过滤值
 * @param val
 * @returns {string|*}
 */
export function filterVal(val, formatType = "", row = {}, item = {}) {
  if (item?.valFun) {
    // 自定义取值函数
    if (F.isFunction(item.valFun)) {
      return item?.valFun(row);
    } else {
      throw new Error("valFun是一个函数");
    }
  } else if (item?.prop?.includes(".")) {
    // 处理多层级取值
    return getDeepVal(item?.prop, row) || "-";
  } else if (item?.options) {
    // 处理对象取值
    return item?.options[val] || "-";
  } else if (formatType) {
    return val ? dayjs(val).format(formatType) : "-";
  } else if (val === null || val === "" || val === undefined) {
    return "-";
  } else {
    return val;
  }
}
/**
 * 获取对象的索引值
 * @param {*} obj 
 * @param {*} val 
 * @param {*} parseint 
 */
export function getObjIndex(obj, val, parseint = true) {
  return Object.keys(obj).findIndex(item => (parseint ? +item : item) === val)
}
/**
 * 数字转化
 * @param {*} num 
 * @returns 
 */
export function formatNumber(data) {
  var num = +data;
  var suffix = '';
  var powerLen = 0
  const obj = {
    3: "K", // 千
    4: "W", // 万
    6: "M", // 百万
    9: "B", // 十亿
  }
  for (const [k, v] of Object.entries(obj)) {
    if (num >= Math.pow(10, +k)) {
      powerLen = +k
      suffix = v
    }
  }
  return num / Math.pow(10, powerLen) + suffix;
}
/**
 * 处理特殊符号
 * @param {*} specialSymbol 
 * @returns 
 */
export function handleEscapedSymbols(specialSymbol = []) {
  const escapedSymbols = specialSymbol.map((symbol) => {
    // 在正则表达式中，这些字符需要转义：\ ^ $ * + ? . ( ) | { } [ ]
    if ('\\^$*+?.()|{}[]'.includes(symbol)) {
      return '\\' + symbol
    }
    return symbol
  })
  const regexPattern = escapedSymbols.join('')
  const regex = new RegExp(`[${regexPattern}]`, 'g') // 'g'标志表示全局匹配
  return regex
}

/**
 * 金额格式化
 * @param {*} amount 
 */
export function formatCurrency(amount = 0) {
  const amountString = (+amount || 0).toString();
  const [integerPart, decimalPart = '00'] = amountString.split('.');
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ','
  );
  const formattedAmount = `${formattedIntegerPart}.${decimalPart}`;
  return formattedAmount;
}

/**
 * 金额格式化
 * @param {*} amount 
 */
export function numberToFixed(amount, len = 0) {
  if (amount) {
    const amountNumber = +amount;
    return amountNumber.toFixed(len);
  }
  return amount;
}

/**
 * 百分比
 * @param {'*'} moleculeKey 
 * @param {*} denominatorKey 
 * @param {*} data 
 */
export function percentNum(moleculeKey, denominatorKey, data) {
  const result = ((getDeepVal(moleculeKey, data) || 0) / (getDeepVal(denominatorKey, data) || 1)) * 100
  return result.toFixed(2)
}
/**
 * 文件大小格式化
 * @param {*} size 
 * @returns 
 */
export function handleFileSize(size) {
  if (!size) return '-'
  const sizeKb = size / 1024
  const sizeM = sizeKb / 1024
  const sizeG = sizeM / 1024
  if (sizeG > 1) {
    return `${sizeG.toFixed(2)}GB`
  } else if (sizeM > 1) {
    return `${sizeM.toFixed(2)}MB`
  } else if (sizeKb > 1) {
    return `${sizeKb.toFixed(2)}KB`
  } else {
    return `${size}B`
  }
}