<template>
  <el-dialog
    v-model="dialogVisible"
    append-to-body
    destroy-on-close
    style="width: 1010px"
    :title="dialogTitle"
    @close="handleClose"
  >
    <div class="search">
      <el-input
        v-model="searchKey"
        class="input"
        :placeholder="t('请输入关键词')"
      />
      <el-button type="primary" @click="search()">{{ t('搜索') }}</el-button>
      <el-button
        :disabled="!selectArr.length"
        type="primary"
        @click="handleSubmit"
      >
        {{ t('使用选择') }}
      </el-button>
    </div>
    <el-card>
      <div
        v-if="imageList.length > 0"
        v-infinite-scroll="load"
        class="box-card"
        infinite-scroll-distance="20"
        :infinite-scroll-immediate="false"
      >
        <div v-for="(i, index) in imageList" :key="i" class="item">
          <div class="item-img cp" @click="click(index)">
            <div v-show="i.click" class="clicked">{{ t('已选择') }}</div>
            <el-image
              :class="i.click ? 'click border' : 'border'"
              fit="cover"
              :src="i.file_path"
              style="width: 100px; height: 100px"
              :zoom-rate="1.2"
            />
            {{ i.name }}
          </div>
        </div>
      </div>
      <el-empty v-else :description="t('暂无内容')" />
    </el-card>
  </el-dialog>
</template>

<script>
  import {
    defineComponent,
    reactive,
    onBeforeMount,
    toRefs,
    computed,
    nextTick,
  } from 'vue'
  import dayjs from 'dayjs'
  import request from '@/utils/request'
  import { ElMessage } from 'element-plus'
  import { t } from '@/utils/i18n'

  export default defineComponent({
    name: 'DialogMaterial',
    props: {
      multiple: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['on-select', 'change'],
    setup(props, { emit }) {
      const selectArr = computed(() => {
        return state.imageList.filter((item) => item.click)
      })
      const dialogTitle = computed(() => {
        return `${t('已选择')}:${selectArr.value.length}`
      })
      const getImageList = () => {
        if (state.total == 0 || state.total > (state.page - 1) * state.per_page)
          request({
            url: `/material-file/index`,
            method: 'get',
            params: {
              page: state.page,
              per_page: state.per_page,
              type: 'img',
              name: state.searchKey,
            },
          }).then((res) => {
            state.imageList = state.imageList.concat(res.data)
            state.total = res.total
          })
      }
      const load = () => {
        state.page = state.page + 1
        getImageList()
      }

      const search = () => {
        state.page = 1
        state.imageList = []
        getImageList()
      }

      const handleSubmit = () => {
        nextTick(() => {
          emit('change', selectArr.value)
        })
      }
      const click = (index) => {
        console.log('index :>> ', index)
        if (!state.imageList[index].click) {
          if (!props.multiple && selectArr.value?.length === 1) {
            return ElMessage({
              showClose: true,
              message: t('只能选择一张'),
              type: 'error',
            })
          }
          emit('on-select', state.imageList[index])
          console.log('state.imageList[index] :>> ', state.imageList[index])
          state.imageList[index].click = true
          ElMessage({
            showClose: true,
            message: t('选择成功'),
            type: 'success',
            duration: 1000,
          })
        } else {
          state.imageList[index].click = false
        }
        // handleSubmit()
      }

      const handleClose = () => {
        state.dialogVisible = false
        state.imageList = state.imageList.map((item) => {
          item.click = false
          return item
        })
      }

      onBeforeMount(async () => {
        console.log('onBeforeMount :>> ')
        getImageList()
      })
      const state = reactive({
        dialogVisible: false,
        searchVal: '',
        imageList: [],
        page: 1,
        per_page: 24,
        searchKey: '',
        total: 0,
      })

      return {
        ...toRefs(state),
        dayjs,
        state,
        selectArr,
        dialogTitle,
        getImageList,
        click,
        load,
        search,
        handleSubmit,
        handleClose,
        t,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .system-user-container {
    height: 100%;
  }
  .search {
    display: flex;
    margin-bottom: 10px;
    .input {
      width: 300px;
      margin-right: 10px;
    }
  }
  .box-card {
    height: 440px;
    display: flex;
    width: 100%;
    overflow: auto;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .item:not(:nth-child(8n)) {
    margin-right: 15px;
  }
  .item {
    display: flex;
    position: relative;
    margin-bottom: 10px;
    height: 140px;
    .item-img {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100px;
    }

    .border:hover {
      border: 1px solid #409eff;
    }
    .click {
      border: 1px solid #409eff;
    }
    .clicked {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 1px;
      left: 1px;
      width: 98px;
      height: 98px;
      color: white;
      background-color: rgba(97, 101, 102);
      opacity: 0.8;
      z-index: 100000;
    }
  }
</style>
