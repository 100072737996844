<!--
 * @Author: WGL
 * @Date: 2023-06-21 09:23:33
 * @LastEditors: WGL
 * @LastEditTime: 2024-02-01 18:19:31
 * @Description: 
-->
<template>
  <div>
    <div>
      {{ info.box_quantity }}{{ info.unit }}/{{
        numberToFixed(info?.box_volume, 4) || '-'
      }}m³
    </div>
    <div>
      {{ numberToFixed(info?.box_weight, 3) || '-' }}/{{
        numberToFixed(info.box_rough_weight, 3) || '-'
      }}kg
    </div>
    <div>{{ info.box_l }}L-{{ info.box_w }}W-{{ info.box_h }}H cm</div>
  </div>
</template>

<script>
  // 基础产品箱规格组件
  import { defineComponent } from 'vue'
  import { numberToFixed } from '@/utils/index'
  export default defineComponent({
    name: 'BaseSpecs',
    components: {},
    props: {
      info: { type: Object, default: () => {} },
    },
    setup() {
      return {
        numberToFixed,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
