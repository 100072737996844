/*
 * @Author: WGL
 * @Date: 2024-06-28 16:54:56
 * @LastEditors: WGL
 * @LastEditTime: 2024-06-28 16:55:21
 * @Description: 
 */

export default {
  vabI18n: {
    首页: '首页',
    看板: '看板',
    工作台: '工作台',
    个人中心: '个人中心',
    Excel: 'Excel',
    错误页: '错误页',
    403: '403',
    404: '404',
    邮件: '邮件',
    更多: '更多',
    退出登录: '退出登录',
    登录: '登录',
    验证码: '验证码',
    注册: '注册',
    国际化: '国际化',
    刷新: '刷新',
    通知: '通知',
    全屏: '全屏',
    页面动画: '页面动画',
    搜索: '搜索',
    重置: '重置',
    开启: '开启',
    关闭: '关闭',
    保存: '保存',
    欢迎来到: '欢迎来到',
    请输入用户名: '请输入用户名',
    请输入密码: '请输入密码',
    用户名不能为空: '用户名不能为空',
    密码不能少于6位: '密码不能少于6位',
    请输入正确的手机号: '请输入正确的手机号',
    请输入手机号: '请输入手机号',
    请输入手机验证码: '请输入手机验证码',
    获取验证码: '获取验证码',
    // ==================================== 常用基本 ==============================
    大: '大',
    中: '中',
    小: '小',
    基本信息: '基本信息',
    分类名称: '分类名称',
    请输入分类名称: '请输入分类名称',
    排序: '排序',
    状态: '状态',
    备注: '备注',
    请输入备注: '请输入备注',
    序号: '序号',
    规格: '规格',
    '重量/长-宽-高/体积': '重量/长-宽-高/体积',
    价格: '价格',
    '价格(元)': '价格(元)',
    销量: '销量',
    封面图: '封面图',
    图片: '图片',
    图标: '图标',
    编码: '编码',
    OEM编码: 'OEM编码',
    '产品编码/OEM编码': '产品编码/OEM编码',
    '编码超级搜索，多个编码换行隔开': '编码超级搜索，多个编码换行隔开',
    库存: '库存',
    调出仓库: '调出仓库',
    调入仓库: '调入仓库',
    '真实库存(锁定库存)': '真实库存(锁定库存)',
    采购价: '采购价',
    毛利率: '毛利率',
    客户报价: '客户报价',
    报价状态: '报价状态',
    来源: '来源',
    创建时间: '创建时间',
    更新时间: '更新时间',
    使用状态: '使用状态',
    总价: '总价',
    操作: '操作',
    名称: '名称',
    加载数据中: '加载数据中...',
    系统: '系统',
    确定: '确定',
    确认: '确认',
    取消: '取消',
    取消操作: '取消操作！',
    '此操作将会永久删除数据，是否继续?': '此操作将会永久删除数据，是否继续?',
    '确定要复制当前数据?': '确定要复制当前数据?',
    请配置打印接口: '请配置打印接口！',
    暂无数据可导出: '暂无数据可导出!',
    正在生成中: '正在生成中...',
    请配置导出接口: '请配置导出接口！',
    '当前页面可能存在未保存内容，直接离开内容将不被保存':
      '当前页面可能存在未保存内容，直接离开内容将不被保存',
    请配置上传的路径地址: '请配置上传的路径地址！',
    编辑: '编辑',
    删除: '删除',
    新建: '新建',
    提示: '提示',
    '请配置导出接口！': '请配置导出接口！',
    '暂无数据可导出！': '暂无数据可导出！',
    正在下载中: '正在下载中。。。',
    文件: '文件',
    '请配置文件上传接口！': '请配置文件上传接口！',
    '请配置编辑接口！': '请配置编辑接口！',
    '导出数据过多，已添加至异步导出队列生成':
      '导出数据过多，已添加至异步导出队列生成',
    请配置文件上传接口: '请配置文件上传接口！',
    请配置编辑接口: '请配置编辑接口！',
    请配置详情接口: '请配置详情接口！',
    头像裁剪: '头像裁剪',
    选择图片: '选择图片',
    上传并保存: '上传并保存',
    返回: '返回',
    产品详情: '产品详情',
    总计: '总计',
    查询: '查询',
    单位: '单位',
    附件: '附件',
    仓库: '仓库',
    手机号: '手机号',
    角色: '角色',
    权限: '权限',
    是否负责人: '是否负责人',
    固定号码: '固定号码',
    上传文件: '上传文件',
    '附件名称(点击预览)': '附件名称(点击预览)',
    附件类型: '附件类型',
    附件大小: '附件大小',
    上传人: '上传人',
    上传图片大小不能超过: '上传图片大小不能超过',
    最多上传: '最多上传',
    您选择了: '您选择了',
    '个文件,请重新选择': '个文件,请重新选择',
    你确定要删除: '你确定要删除',
    上传时间: '上传时间',
    明细: '明细',
    文件名称: '文件名称',
    请输入分类排序: '请输入分类排序',
    展开: '展开',
    合并: '合并',
    紧急程度: '紧急程度',
    添加: '添加',
    请选择: '请选择',
    供应商: '供应商',
    最近报价: '最近报价',
    品牌: '品牌',
    车型: '车型',
    请选择车型: '请选择车型',
    部件: '部件',
    请选择部件: '请选择部件',
    年份: '年份',
    请选择年份: '请选择年份',
    发表评论: '发表评论',
    修改: '修改',
    '暂无评论~': '暂无评论~',
    评论人员: '评论人员',
    '暂无信息~': '暂无信息~',
    姓名: '姓名',
    电话: '电话',
    请输入电话: '请输入电话',
    邮箱: '邮箱',
    部门: '部门',
    职务: '职务',
    图表显示: '图表显示',
    指标值: '指标值',
    日: '日',
    月: '月',
    年: '年',
    已选择: '已选择',
    请输入关键词: '请输入关键词',
    使用选择: '使用选择',
    暂无内容: '暂无内容',
    选择成功: '选择成功',
    跟进: '跟进',
    已添加: '已添加',
    库存不足: '库存不足',
    是: '是',
    否: '否',
    请填写: '请填写',
    币种: '币种',
    创建人: '创建人',
    最近更新: '最近更新',
    从素材库选择: '从素材库选择',
    // TODO: 2023-11-14 start
    从素材库选: '从素材库选',
    在此处粘贴图片即上传: '在此处粘贴图片即上传',
    '上传图片只能是JPG,PNG,GIF,WEBP格式':
      '上传图片只能是 JPG,PNG,GIF,WEBP 格式',
    只能上传一张图片: '只能上传一张图片',
    当前浏览器不支持本地图片粘贴: '当前浏览器不支持本地图片粘贴',
    粘贴内容非图片: '粘贴内容非图片',
    上传文件大小不能超过: '上传文件大小不能超过',
    正在上传中: '正在上传中...',
    上传: '上传',
    上传完成: '上传完成',
    上传失败请重新上传: '上传失败请重新上传',
    正在导入中: '正在导入中',
    导入: '导入',
    导出: '导出',
    全选: '全选',
    使用选中: '使用选中',
    一键抠图: '一键抠图',
    抠图: '抠图',
    不抠图: '不抠图',
    完成: '完成',
    下载: '下载',
    查看: '查看',
    暂无数据: '暂无数据',
    上级分类: '上级分类',
    添加分类: '添加分类',
    编辑分类: '编辑分类',
    删除分类: '删除分类',
    分类名称不能为空: '分类名称不能为空',
    音频: '音频',
    只能选择一张图片: '只能选择一张图片',
    请配置分类接口: '请配置分类接口！',
    全部: '全部',
    请配置素材接口: '请配置素材接口！',
    请添加分类: '请添加分类',
    请配置分类添加接口: '请配置分类添加接口',
    请配置分类编辑接口: '请配置分类编辑接口',
    请配置分类删除接口: '请配置分类删除接口',
    文件类型格式不正确: '文件类型格式不正确!',
    大小不能超过: '大小不能超过',
    个文件请重新选择: '个文件请重新选择',
    请配置素材分类导出接口: '请配置素材分类导出接口！',
    立即新增: '立即新增',
    上传视频大小不能超过: '上传视频大小不能超过',
    图标选择器: '图标选择器',
    最后编辑: '最后编辑',
    步骤: '步骤',
    标题: '标题',
    审核: '审核',
    审核状态: '审核状态',
    请选择类型: '请选择类型',
    请选择审核状态: '请选择审核状态',
    通过: '通过',
    不通过: '不通过',
    付款状态: '付款状态',
    请选择付款状态: '请选择付款状态',
    发货状态: '发货状态',
    请选择发货状态: '请选择发货状态',
    提成金额: '提成金额',
    请输入提成金额: '请输入提成金额',
    回款方式: '回款方式',
    请选择回款方式: '请选择回款方式',
    回款日期: '回款日期',
    请输入回款日期: '请输入回款日期',
    流水单号: '流水单号',
    请输入支付流水单号: '请输入支付流水单号',
    支付凭证: '支付凭证',
    付款方式: '付款方式',
    请选择付款方式: '请选择付款方式',
    付款日期: '付款日期',
    请输入付款日期: '请输入付款日期',
    下单日期: '下单日期',
    请选择下单日期: '请选择下单日期',
    请输入名称: '请输入名称',
    保存并新增: '保存并新增',
    您确定要对当前数据进行提审: '您确定要对当前数据进行提审？',
    您确定要对当前数据进行提审并通过: '您确定要对当前数据进行提审并通过？',
    '您确定要对当前状态进行撤销？撤销后可重新编辑提审':
      '您确定要对当前状态进行撤销？撤销后可重新编辑提审',
    您确定要对当前订单进行续单: '您确定要对当前订单进行续单？',
    您确定要对当前订单生成出库单: '您确定要对当前订单生成出库单？',
    // ==================================== 邮箱模块 ==============================
    表格模版: '表格模版',
    去添加: '去添加',
    邮件模版: '邮件模版',
    生成预览: '生成预览',
    表格: '表格',
    取消附件: '取消附件',
    单选: '单选',
    只看我的: '只看我的',
    先发给自己: '先发给自己',
    主题: '主题',
    请输入主题: '请输入主题',
    请输入标题: '请输入标题',
    收件人: '收件人',
    请选择收件人: '请选择收件人',
    头部: '头部',
    尾部: '尾部',
    发送: '发送',
    // ==================================== f附件模块 ==============================
    下次联系时间: '下次联系时间',
    跟进内容: '跟进内容',
    上传附件: '上传附件',
    '是否删除这条跟进记录?': '是否删除这条跟进记录?',
    确定进入: '确定进入',
    阶段: '阶段',
    // ==================================== f成员管理模块 ==============================
    成员管理: '成员管理',
    成员: '成员',
    请选择成员: '请选择成员',
    只读: '只读',
    读写: '读写',
    负责人: '负责人',
    更换负责人: '更换负责人',
    设置为负责人: '设置为负责人',
    请选择负责人: '请选择负责人',
    // ==================================== 产品模块 ==============================
    产品: '产品',
    数量: '数量',
    产品名称: '产品名称',
    请输入产品名称: '请输入产品名称',
    产品分类: '产品分类',
    请选择产品分类: '请选择产品分类',
    产品类型: '产品类型',
    请选择产品类型: '请选择产品类型',
    请输入排序: '请输入排序',
    产品编码: '产品编码',
    请输入产品编码: '请输入产品编码',
    请输入OEM编码: '请输入OEM编码',
    适用车型: '适用车型',
    请选择适用车型: '请选择适用车型',
    车身部件: '车身部件',
    请选择车身部件: '请选择车身部件',
    产品货币: '产品货币',
    请选择产品货币单位: '请选择产品货币单位',
    预警库存: '预警库存',
    '建议售价/最低售价/成本': '建议售价/最低售价/成本',
    外贸: '外贸',
    零售: '零售',
    成本: '成本',
    '名称/编码/OEM编码': '名称/编码/OEM编码',
    '重量/体积/长-宽-高': '重量/体积/长-宽-高',
    上下架: '上下架',
    上架: '上架',
    下架: '下架',
    请选择单位: '请选择单位',
    建议售价: '建议售价',
    最低售价: '最低售价',
    成本价: '成本价',
    装箱量: '装箱量',
    箱规: '箱规',
    '箱规-长(cm)': '箱规-长(cm)',
    '箱规-宽(cm)': '箱规-宽(cm)',
    '箱规-高(cm)': '箱规-高(cm)',
    '箱规-装箱量': '箱规-装箱量',
    '箱规-净重(kg)': '箱规-净重(kg)',
    '箱规-毛重(kg)': '箱规-毛重(kg)',
    '箱规-体积(m³)': '箱规-体积(m³)',
    '重量(kg)': '重量(kg)',
    '单个-长(cm)': '单个-长(cm)',
    '单个-宽(cm)': '单个-宽(cm)',
    '单个-高(cm)': '单个-高(cm)',
    '单个-体积(m³)': '单个-体积(m³)',
    '单个-净重(kg)': '单个-净重(kg)',
    '单个-毛重(kg)': '单个-毛重(kg)',
    证书: '证书',
    建议尺寸比例: '建议尺寸比例 1*1',
    重量: '重量',
    体积: '体积',
    分类: '分类',
    '体积(m³)': '体积(m³)',
    描述: '描述',
    请输入描述: '请输入描述',
    图集: '图集',
    实施服务: '实施服务',
    请选择实施服务: '请选择实施服务',
    扩展信息: '扩展信息',
    请选择供应商: '请选择供应商',
    图片信息: '图片信息',
    请选择仓库: '请选择仓库',
    请选择产品: '请选择产品',
    入库: '入库',
    产品图片: '产品图片',
    我方编码: '我方编码',
    对方编码: '对方编码',
    历史最低: '历史最低',
    近期价格: '近期价格',
    历史最高: '历史最高',
    联系人: '联系人',
    详细地址: '详细地址',
    请选择车型品牌: '请选择车型品牌',
    请选择配置: '请选择配置',
    货源: '货源',
    产品概览: '产品概览',
    配套产品: '配套产品',
    配套产品详情: '配套产品详情',
    平替产品: '平替产品',
    价格策略: '价格策略',
    策略条件: '策略条件',
    数量大于等于: '数量大于等于',
    单价为: '单价为',
    类型: '类型',
    策略方式: '策略方式',
    策略类型: '策略类型',
    请选择策略类型: '请选择策略类型',
    库存提示: '当前库存量(总库存/预警库存)',
    包含产品: '包含产品',
    配置: '配置',
    导出原图: '导出原图',
    视频信息: '视频信息',
    视频: '视频',
    目标市场: '目标市场',
    请选择目标市场: '请选择目标市场',
    产品货源: '产品货源',
    请选择产品货源: '请选择产品货源',
    上下架状态: '上下架状态',
    请选择产品上下架状态: '请选择产品上下架状态',
    index: {},
    // ==================================== 产品分类模块 ==============================
    需要以字母开头: '需要以字母开头',
    显示名称: '显示名称',
    请输入显示名称: '请输入显示名称',
    用于生成电商网站时显示的分类标题: '用于生成电商网站时显示的分类标题',
    分类url: '分类url',
    请输入字母或数字: '请输入字母或数字',
    '请输入分类url,如lights': '请输入分类url,如lights',
    用于生成电商网站的链接: '用于生成电商网站的链接',
    分类描述: '分类描述',
    用于生成电商网站时SEO: '用于生成电商网站时SEO',
    分类关键词: '分类关键词',
    请输入分类: '请输入分类',
    // ==================================== 销售模块 ==============================
    内容: '内容',
    跟进人: '跟进人',
    跟进时间: '跟进时间',
    添加成员: '添加成员',
    删除成员: '删除成员',
    '请选择成员(可多选)': '请选择成员(可多选)',
    客户: '客户',
    客户名称: '客户名称',
    请输入客户名称: '请输入客户名称',
    客户编码: '客户编码',
    成交单数: '成交单数',
    成交金额: '成交金额',
    回款金额: '回款金额',
    客户来源: '客户来源',
    请选择客户来源: '请选择客户来源',
    客户行业: '客户行业',
    请选择客户行业: '请选择客户行业',
    客户等级: '客户等级',
    请选择客户等级: '请选择客户等级',
    下次跟进时间: '下次跟进时间',
    地区: '地区',
    请选择地区: '请选择地区',
    请输入客户手机号: '请输入客户手机号',
    请输入邮箱地址: '请输入邮箱地址',
    地址: '地址',
    请输入客户地址: '请输入客户地址',
    是否成交: '是否成交',
    请选择是否成交: '请选择是否成交',
    客户详情: '客户详情',
    最后跟进时间: '最后跟进时间',
    微信: '微信',
    请输入微信: '请输入微信',
    商机: '商机',
    范围: '范围',
    请选择数据范围: '请选择数据范围',
    客户地区: '客户地区',
    团队成员: '团队成员',
    发票信息: '发票信息',
    客户分析: '客户分析',
    客户订单分析: '客户订单分析',
    请选择开始日期: '请选择开始日期',
    开始日期: '开始日期',
    结束日期: '结束日期',
    退货产品: '退货产品',
    结束时间: '结束时间',
    开始时间: '开始时间',
    核心数据: '核心数据',
    成交趋势: '成交趋势',
    产品分布: '产品分布',
    产品排行: '产品排行',
    销售前20产品: '销售前20产品',
    历史订单: '历史订单',
    订单金额: '订单金额',
    订单总金额: '订单总金额',
    订单笔数: '订单笔数',
    下单数量: '下单数量',
    实收金额: '实收金额',
    '当前订单收款总和，扣除退款': '当前订单收款总和，扣除退款',
    退款金额: '退款金额',
    当前订单退款金额: '当前订单退款金额',
    未收回款: '未收回款',
    '订单金额-已收金额': '订单金额-已收金额',
    已开票金额: '已开票金额',
    订单已开票的金额: '订单已开票的金额',
    平均单价: '平均单价',
    每笔订单平均单价: '每笔订单平均单价',
    排名: '排名',
    渠道来源: '渠道来源',
    金额占比: '金额占比',
    订单占比: '订单占比',
    公司名称: '公司名称',
    请输入公司名称: '请输入公司名称',
    税号: '税号',
    请输入公司税号: '请输入公司税号',
    开户行: '开户行',
    银行账号: '银行账号',
    账户信息: '账户信息',
    发票抬头: '发票抬头',
    关联客户: '关联客户',
    请选择客户: '请选择客户',
    货币币种: '货币币种',
    开户银行: '开户银行',
    请输入开户银行全称: '请输入开户银行全称',
    银行卡号: '银行卡号',
    请输入银行卡号: '请输入银行卡号',
    请输入地址: '请输入地址',
    退货商品为空表示只退款: '退货商品为空表示只退款',
    请输入退货数量: '请输入退货数量',
    产品明细: '产品明细',
    请至少添加一个产品: '请至少添加一个产品',
    // TODO: 2023-11-14 start
    商机名称: '商机名称',
    请输入商机名称: '请输入商机名称',
    商机阶段: '商机阶段',
    请选择商机阶段: '请选择商机阶段',
    商机属性: '商机属性',
    请选择商机属性: '请选择商机属性',
    商机状态: '商机状态',
    请选择商机状态: '请选择商机状态',
    预计金额: '预计金额',
    请输入预计成交金额: '请输入预计成交金额',
    请选择货币: '请选择货币',
    预计成交: '预计成交',
    请输入预计成交日期: '请输入预计成交日期',
    结束: '结束',
    商机详情: '商机详情',
    预计成交金额: '预计成交金额',
    预计成交时间: '预计成交时间',
    // ==================================== 报价单模块 ==============================
    报价单: '报价单',
    请选择报价单: '请选择报价单',
    报价详情: '报价详情',
    单号: '单号',
    复用价格: '复用价格',
    时间: '时间',
    我的报价: '我的报价',
    '标题/编号': '标题/编号',
    订单Id: '订单Id',
    客户名: '客户名',
    单价: '单价',
    请选择出入库类型: '请选择出入库类型',
    所有客户: '所有客户',
    当前客户: '当前客户',
    时间范围: '时间范围',
    价格范围: '价格范围',
    请选择状态: '请选择状态',
    关联商机: '关联商机',
    请选择商机: '请选择商机',
    请选择币种: '请选择币种',
    生成订单: '生成订单',
    交付日期: '交付日期',
    '订单交付/发货日期': '订单交付/发货日期',
    请选择交付日期: '请选择交付日期',
    报价单名称: '报价单名称',
    报价单编码: '报价单编码',
    产品数量: '产品数量',
    订单货币: '订单货币',
    编号: '编号',
    金额: '金额',
    销售金额: '销售金额',
    销售订单: '销售订单',
    订单数量: '订单数量',
    产品列表: '产品列表',
    订单详情: '订单详情',
    发货计划: '发货计划',
    回款计划: '回款计划',
    回款明细: '回款明细',
    出入库记录: '出入库记录',
    服务明细: '服务明细',
    绩效明细: '绩效明细',
    开票计划: '开票计划',
    '退/换货': '退/换货',
    合同: '合同',
    应收款: '应收款',
    未收款: '未收款',
    已收金额: '已收金额',
    '当前订单收款总和，不扣除退款': '当前订单收款总和，不扣除退款',
    已退金额: '已退金额',
    实收回款: '实收回款',
    退款中金额: '退款中金额',
    订单退款审批中金额: '订单退款审批中金额',
    '金额(应收/实收)': '金额(应收/实收)',
    下单时间: '下单时间',
    发货时间: '发货时间',
    请选择发货时间: '请选择发货时间',
    订单: '订单',
    签单类型: '签单类型',
    生成任务时的开始日期: '生成任务时的开始日期',
    单据日期: '单据日期',
    订单成交时日期: '订单成交时日期',
    '订单退/换货': '订单退/换货',
    请输入退款金额: '请输入退款金额',
    退款金额为0表示只退货: '退款金额为0表示只退货',
    退款方式: '退款方式',
    请选择退款方式: '请选择退款方式',
    退款日期: '退款日期',
    请选择退款日期: '请选择退款日期',
    补差金额: '补差金额',
    请输入补差金额: '请输入补差金额',
    补差金额为0表示只换货: '补差金额为0表示只换货',
    补差方式: '补差方式',
    请选择补差方式: '请选择补差方式',
    补差日期: '补差日期',
    请选择补差计划日期: '请选择补差计划日期',
    换货商品为空表示只补差金额: '换货商品为空表示只补差金额',
    '退/换货备注': '退/换货备注',
    请输入退货备注: '请输入退货备注',
    订单名称: '订单名称',
    订单编码: '订单编码',
    发货日期: '发货日期',
    包装要求: '包装要求',
    规格说明: '规格说明',
    价格条款: '价格条款',
    订单进度: '订单进度',
    // ==================================== 仓库模块 ==============================
    备货中: '备货中',
    备货完成: '备货完成',
    业务员: '业务员',
    计划时间: '计划时间',
    备货状态: '备货状态',
    发货计划详情: '发货计划详情',
    发货进度: '发货进度',
    计划日期: '计划日期',
    采购计划: '采购计划',
    箱单: '箱单',
    发货单: '发货单',
    请选择销售订单: '请选择销售订单',
    交货日期: '交货日期',
    采购状态: '采购状态',
    请选择采购状态: '请选择采购状态',
    添加采购计划: '添加采购计划',
    请输入采购数量: '请输入采购数量',
    添加到采购单: '添加到采购单',
    换货产品: '换货产品',
    换货商品为空表示只补差额: '换货商品为空表示只补差额',
    出入库: '出入库',
    出库: '出库',
    日期: '日期',
    入库类型: '入库类型',
    出库类型: '出库类型',
    出入库类型: '出入库类型',
    供应商名称: '供应商名称',
    '供应商/客户': '供应商/客户',
    盘点状态: '盘点状态',
    '未作废数据删除，库存将不会恢复是否删除':
      '未作废数据删除，库存将不会恢复是否删除？',
    '此操作将会永久删除数据，是否继续': '此操作将会永久删除数据，是否继续？',
    您确定要作废当前数据: '您确定要作废当前数据？',
    // TODO: 2023-11-15 start
    '金额(应付/实付)': '金额(应付/实付)',
    实付金额: '实付金额',
    '当前订单付款总和，扣除退款': '当前订单付款总和，扣除退款',
    未付金额: '未付金额',
    采购: '采购',
    采购单详情: '采购单详情',
    采购进度: '采购进度',
    付款计划: '付款计划',
    付款明细: '付款明细',
    采购数量: '采购数量',
    // ==================================== 供应商模块 ==============================
    供应商详情: '供应商详情',
    '确认删除？': '确认删除？',
    消息: '消息',
    暂无评论: '暂无评论',
    暂无信息: '暂无信息',
    素材选择: '素材选择',
    '文件已存在下载队列中！': '文件已存在下载队列中！',
    新增故事: '新增故事',
    请输入内容: '请输入内容',
    只能选择一张: '只能选择一张',
    请选择邮件模板: '请选择邮件模板',
    '正在生成中...': '正在生成中...',
    '该附件格式不支持预览，请下载后查看': '该附件格式不支持预览，请下载后查看',
    设备: '设备',
    总数: '总数',
    在线: '在线',
    离线: '离线',
    故障: '故障',
    在线率: '在线率',
    故障率: '故障率',
    '确认要离开本页面吗？': '确认要离开本页面吗？',
    历史报价: '历史报价',
    '产品名称/编码': '产品名称/编码',
    '智慧商贸-ERP': '智慧商贸-ERP',
    '正在加载中...': '正在加载中...',
    简体中文: '简体中文',
    繁體中文: '繁體中文',
    已创建: '已创建',
    进行中: '进行中',
    已完成: '已完成',
    折线图: '折线图',
    柱状图: '柱状图',
    周: '周',
    已作废: '已作废',
    已过期: '已过期',
    编辑中: '编辑中',
    待支付: '待支付',
    已付定金: '已付定金',
    已支付: '已支付',
    已发货: '已发货',
    已签收: '已签收',
    '报价单-出口': '报价单-出口',
    '报价单-内销': '报价单-内销',
    '报价单-门市': '报价单-门市',
    '报价单-淘宝': '报价单-淘宝',
    '报价单-云工厂': '报价单-云工厂',
    '报价单-智能工厂': '报价单-智能工厂',
    禁用: '禁用',
    启用: '启用',
    隐藏: '隐藏',
    显示: '显示',
    未知: '未知',
    抖音: '抖音',
    快手: '快手',
    待确定: '待确定',
    有效: '有效',
    无效: '无效',
    推广微信公众号: '推广微信公众号',
    推广抖音账号: '推广抖音账号',
    推广抖音视频: '推广抖音视频',
    推广快手账号: '推广快手账号',
    推广快手视频: '推广快手视频',
    销售收入: '销售收入',
    押金收入: '押金收入',
    租赁收入: '租赁收入',
    购买支出: '购买支出',
    押金退还: '押金退还',
    男: '男',
    女: '女',
    保密: '保密',
    待处理: '待处理',
    处理中: '处理中',
    已处理: '已处理',
    文本框: '文本框',
    多行文本框: '多行文本框',
    数字文本框: '数字文本框',
    日期选择框: '日期选择框',
    状态选择: '状态选择',
    单选框: '单选框',
    多选框: '多选框',
    下拉单选择: '下拉单选择',
    下拉多选择: '下拉多选择',
    单图片: '单图片',
    多图片: '多图片',
    日期选择: '日期选择',
    未读: '未读',
    已读: '已读',
    待审核: '待审核',
    私有: '私有',
    公海: '公海',
    无: '无',
    低: '低',
    高: '高',
    离职: '离职',
    在职: '在职',
    销售: '销售',
    按数量区间设置: '按数量区间设置',
    '按客户/供应商设置': '按客户/供应商设置',
    待接受: '待接受',
    已接受: '已接受',
    不接受: '不接受',
    公司: '公司',
    银行账户: '银行账户',
    单位地址: '单位地址',
    '千美元)': '千美元)',
    宽松默认: '宽松默认',
    严格模式: '严格模式',
    '支持jpg、jpeg、png格式，单次可最多选择':
      '支持jpg、jpeg、png格式，单次可最多选择',
    '张图片，每张不可大于': '张图片，每张不可大于',
    如果大于: '如果大于',
    会自动为您过滤: '会自动为您过滤',
    查看大图: '查看大图',
    '正在上传中...': '正在上传中...',
    当前上传成功数: '当前上传成功数',
    张: '张',
    当前上传失败数: '当前上传失败数',
    开始上传: '开始上传',
    '上传完成!': '上传完成!',
    共上传: '共上传',
    张图片: '张图片',
    '上传失败,文件大小为': '上传失败,文件大小为',
    当前限制选择: '当前限制选择',
    '个文件，本次选择了': '个文件，本次选择了',
    个文件: '个文件',
    密码不能少于: '密码不能少于',
    位: '位',
    销售前: '销售前',
    更新日志: '更新日志',
    用户管理: '用户管理',
    角色管理: '角色管理',
    部门管理: '部门管理',
    菜单管理: '菜单管理',
    系统日志: '系统日志',
    组件: '组件',
    其他: '其他',
    角色权限: '角色权限',
    外链: '外链',
    常规图标: '常规图标',
    小清新图标: '小清新图标',
    综合表格: '综合表格',
    行内编辑表格: '行内编辑表格',
    自定义表格: '自定义表格',
    表单: '表单',
    综合表单: '综合表单',
    分步表单: '分步表单',
    按钮: '按钮',
    文字链接: '文字链接',
    输入框: '输入框',
    计数器: '计数器',
    选择器: '选择器',
    开关: '开关',
    滑块: '滑块',
    时间选择器: '时间选择器',
    日期选择器: '日期选择器',
    日期时间选择器: '日期时间选择器',
    评分: '评分',
    工作流: '工作流',
    图表: '图表',
    打印: '打印',
    手机预览: '手机预览',
    时间线: '时间线',
    数字自增长: '数字自增长',
    多标签: '多标签',
    动态Meta: '动态Meta',
    动态路径参数: '动态路径参数',
    列表: '列表',
    多级路由缓存: '多级路由缓存',
    多级路由: '多级路由',
    拖拽: '拖拽',
    卡片拖拽: '卡片拖拽',
    加载: '加载',
    视频播放器: '视频播放器',
    编辑器: '编辑器',
    富文本编辑器: '富文本编辑器',
    错误日志模拟: '错误日志模拟',
    导出Excel: '导出Excel',
    导出选中行Excel: '导出选中行Excel',
    导出合并Excel: '导出合并Excel',
    动画: '动画',
    第三方登录: '第三方登录',
    物料源: '物料源',
    物料市场: '物料市场',
    清空消息: '清空消息',
    关闭其他: '关闭其他',
    关闭左侧: '关闭左侧',
    关闭右侧: '关闭右侧',
    关闭全部: '关闭全部',
    主题配置: '主题配置',
    常用设置: '常用设置',
    其它设置: '其它设置',
    分栏布局时生效: '分栏布局时生效',
    标签开启时生效: '标签开启时生效',
    '布局配置仅在电脑视窗下生效，手机视窗时将默认锁定为纵向布局':
      '布局配置仅在电脑视窗下生效，手机视窗时将默认锁定为纵向布局',
    默认: '默认',
    海洋之心: '海洋之心',
    海洋之心sss: '海洋之心sss',
    绿荫草场: '绿荫草场',
    碰触纯白: '碰触纯白',
    月上重火: '月上重火',
    蓝黑: '蓝黑',
    蓝白: '蓝白',
    绿黑: '绿黑',
    绿白: '绿白',
    红黑: '红黑',
    红白: '红白',
    渐变: '渐变',
    布局: '布局',
    分栏: '分栏',
    纵向: '纵向',
    横向: '横向',
    综合: '综合',
    常规: '常规',
    浮动: '浮动',
    菜单背景: '菜单背景',
    分栏风格: '分栏风格',
    箭头: '箭头',
    头部固定: '头部固定',
    固定: '固定',
    不固定: '不固定',
    标签: '标签',
    标签风格: '标签风格',
    标签图标: '标签图标',
    卡片: '卡片',
    灵动: '灵动',
    圆滑: '圆滑',
    进度条: '进度条',
    随机换肤: '随机换肤',
    购买源码: '购买源码',
    拷贝源码: '拷贝源码',
    清理缓存: '清理缓存',
    恢复默认: '恢复默认',
    '检测到新版本，正在下载中，请稍后...':
      '检测到新版本，正在下载中，请稍后...',
    温馨提示: '温馨提示',
    更新版本完成: '更新版本完成',
    后刷新项目: '后刷新项目',
    我的待办: '我的待办',
    已办事项: '已办事项',
    经营分析: '经营分析',
    决策辅助: '决策辅助',
    采购订单: '采购订单',
    供应管理: '供应管理',
    客户管理: '客户管理',
    商机管理: '商机管理',
    对外报价: '对外报价',
    财务: '财务',
    仓库管理: '仓库管理',
    收款计划: '收款计划',
    收款明细: '收款明细',
    开票详情: '开票详情',
    库存盘点: '库存盘点',
    库存报表: '库存报表',
    库存余额: '库存余额',
    收发明细: '收发明细',
    收发汇总: '收发汇总',
    单据列表: '单据列表',
    产品入库: '产品入库',
    产品出库: '产品出库',
    产品调拨: '产品调拨',
    产品组装: '产品组装',
    产品拆卸: '产品拆卸',
    绩效: '绩效',
    我的绩效: '我的绩效',
    团队绩效: '团队绩效',
    绩效核定: '绩效核定',
    绩效规则: '绩效规则',
    任务: '任务',
    任务列表: '任务列表',
    电商: '电商',
    首页设置: '首页设置',
    商品: '商品',
    轮播图: '轮播图',
    文章管理: '文章管理',
    设置: '设置',
    公司信息: '公司信息',
    销售配置: '销售配置',
    产品配置: '产品配置',
    产品素材: '产品素材',
    模版管理: '模版管理',
    扩展字段: '扩展字段',
    系统设置: '系统设置',
    操作日志: '操作日志',
    公司列表: '公司列表',
    用户列表: '用户列表',
    权限管理: '权限管理',
    行业管理: '行业管理',
    资源: '资源',
    店铺选址: '店铺选址',
    教程管理: '教程管理',
    超级管理员: '超级管理员',
    '没有菜单权限！': '没有菜单权限！',
    '路由格式返回有误！': '路由格式返回有误！',
    运行中: '运行中',
    已下线: '已下线',
    游客: '游客',
    '未开启登录拦截)': '未开启登录拦截)',
    早上好: '早上好',
    上午好: '上午好',
    中午好: '中午好',
    下午好: '下午好',
    晚上好: '晚上好',
    欢迎登录: '欢迎登录',
    '登录接口异常，未正确返回': '登录接口异常，未正确返回',
    '核心接口异常，请检查返回JSON格式是否正确，是否正确返回':
      '核心接口异常，请检查返回JSON格式是否正确，是否正确返回',
    '核心接口异常，请检查返回JSON格式是否正确':
      '核心接口异常，请检查返回JSON格式是否正确',
    复制: '复制',
    成功: '成功',
    失败: '失败',
    '重量/毛重': '重量/毛重',
    产品证书: '产品证书',
    推荐价格: '推荐价格',
    邮编: '邮编',
    传真: '传真',
    官网: '官网',
    企业状态: '企业状态',
    营收: '营收',
    员工人数: '员工人数',
    公司类型: '公司类型',
    所属国家: '所属国家',
    所属城市: '所属城市',
    简介: '简介',
    产品服务: '产品服务',
    请输入: '请输入',
    产品价格策略: '产品价格策略',
    起始价格: '起始价格',
    分类logo: '分类logo',
    建议尺寸: '建议尺寸',
    分类banner: '分类banner',
    请输入分类描述: '请输入分类描述',
    退款金额为: '退款金额为',
    表示只退货: '表示只退货',
    补差金额为: '补差金额为',
    表示只换货: '表示只换货',
    请输入客户编码: '请输入客户编码',
    请输入联系人: '请输入联系人',
    请输入客户Whats: '请输入客户Whats',
    规则名称: '规则名称',
    请输入规则名称: '请输入规则名称',
    绩效类型: '绩效类型',
    请选择绩效类型: '请选择绩效类型',
    优先级: '优先级',
    请输入优先级: '请输入优先级',
    请输入编码: '请输入编码',
    请选择分类: '请选择分类',
    请输入详细地址: '请输入详细地址',
    固定电话: '固定电话',
    请输入固定电话: '请输入固定电话',
    请输入邮箱: '请输入邮箱',
    请输入QQ: '请输入QQ',
    网址: '网址',
    请输入网址: '请输入网址',
    添加到采购计划: '添加到采购计划',
    请输入数量: '请输入数量',
    选择负责人: '选择负责人',
    服务年限: '服务年限',
    '请输入服务年限(报告年限)': '请输入服务年限(报告年限)',
    任务日期: '任务日期',
    请选择任务日期: '请选择任务日期',
    请输入任务金额: '请输入任务金额',
    请选择关联销售订单: '请选择关联销售订单',
    请输入回款金额: '请输入回款金额',
    请选择回款日期: '请选择回款日期',
    批量生成回款计划: '批量生成回款计划',
    首次回款日期: '首次回款日期',
    请选择首次回款日期: '请选择首次回款日期',
    回款间隔时间: '回款间隔时间',
    请输入回款间隔时间: '请输入回款间隔时间',
    总回款期数: '总回款期数',
    请输入总回款期数: '请输入总回款期数',
    请选择关联回款计划: '请选择关联回款计划',
    渠道: '渠道',
    请选择渠道: '请选择渠道',
    凭证: '凭证',
    请选择采购订单: '请选择采购订单',
    请选择关联采购订单: '请选择关联采购订单',
    付款金额: '付款金额',
    请输入付款金额: '请输入付款金额',
    请选择付款日期: '请选择付款日期',
    批量生成付款计划: '批量生成付款计划',
    首次付款日期: '首次付款日期',
    请选择首次付款日期: '请选择首次付款日期',
    付款间隔时间: '付款间隔时间',
    请输入付款间隔时间: '请输入付款间隔时间',
    总付款期数: '总付款期数',
    请输入总付款期数: '请输入总付款期数',
    请选择关联付款计划: '请选择关联付款计划',
    请选择发票抬头: '请选择发票抬头',
    开票金额: '开票金额',
    请输入开票金额: '请输入开票金额',
    含税金额: '含税金额',
    商品名称: '商品名称',
    请输入商品名称: '请输入商品名称',
    商品编号: '商品编号',
    请输入商品编号: '请输入商品编号',
    规格型号: '规格型号',
    请输入商品规格型号: '请输入商品规格型号',
    商品单位: '商品单位',
    请输入商品单位: '请输入商品单位',
    发票类型: '发票类型',
    请选择发票类型: '请选择发票类型',
    税率: '税率',
    请输入税率: '请输入税率',
    商品数量: '商品数量',
    请输入商品数量: '请输入商品数量',
    开票日期: '开票日期',
    请选择开票日期: '请选择开票日期',
    开票明细: '开票明细',
    员工离职交接: '员工离职交接',
    交接人: '交接人',
    请选择交接人: '请选择交接人',
    单据时间: '单据时间',
    请选择单据时间: '请选择单据时间',
    箱数: '箱数',
    请输入预估箱数: '请输入预估箱数',
    装箱预估数量: '装箱预估数量',
    出库单: '出库单',
    请选择出库单: '请选择出库单',
    正: '正',
    侧: '侧',
    唛信息: '唛信息',
    目的地: '目的地',
    请输入收货目的地: '请输入收货目的地',
    箱号标识: '箱号标识',
    '如M、B': '如M、B',
    产地: '产地',
    请输入产地: '请输入产地',
    订单号: '订单号',
    将自动填充: '将自动填充',
    净重: '净重',
    毛重: '毛重',
    产品盘点: '产品盘点',
    请选择单据日期: '请选择单据日期',
    组装: '组装',
    拆卸: '拆卸',
    请输选择: '请输选择',
    入库仓库: '入库仓库',
    出库仓库: '出库仓库',
    费用: '费用',
    存为模版: '存为模版',
    拆卸产品: '拆卸产品',
    请选择拆卸产品: '请选择拆卸产品',
    图片名称: '图片名称',
    请输入图片: '请输入图片',
    链接地址: '链接地址',
    请输入图片链接地址: '请输入图片链接地址',
    文章: '文章',
    文章标题: '文章标题',
    请输入文章标题: '请输入文章标题',
    文章类型: '文章类型',
    请选择文章类型: '请选择文章类型',
    文章内容: '文章内容',
    请输入文章内容: '请输入文章内容',
    提单详情: '提单详情',
    采购商: '采购商',
    产品信息: '产品信息',
    总金额: '总金额',
    产品描述: '产品描述',
    货运信息: '货运信息',
    原产国: '原产国',
    目的国: '目的国',
    运输方式: '运输方式',
    数据来源: '数据来源',
    请输入姓名: '请输入姓名',
    请输入部门: '请输入部门',
    请输入职务: '请输入职务',
    绩效月份: '绩效月份',
    请选择绩效月份: '请选择绩效月份',
    计提人: '计提人',
    请选计提人: '请选计提人',
    请选择调出仓库: '请选择调出仓库',
    请选择调入仓库: '请选择调入仓库',
    回款类型: '回款类型',
    请选择回款类型: '请选择回款类型',
    退款: '退款',
    回款: '回款',
    付款类型: '付款类型',
    请选择付款类型: '请选择付款类型',
    请选择优先级: '请选择优先级',
    关键词: '关键词',
    请选择上下架状态: '请选择上下架状态',
    只允许输入数字和字母: '只允许输入数字和字母',
    请输入产品关键词: '请输入产品关键词',
    请输入HS编码: '请输入HS编码',
    请输入供应商名称: '请输入供应商名称',
    请输入采购商名称: '请输入采购商名称',
    请选择原产国: '请选择原产国',
    请选择目的国: '请选择目的国',
    起始时间: '起始时间',
    请输入关键词搜索: '请输入关键词搜索',
    请输入公司名称搜索: '请输入公司名称搜索',
    请输入产品名称搜索: '请输入产品名称搜索',
    请选择国家ISO编码: '请选择国家ISO编码',
    请选择公司经营状态: '请选择公司经营状态',
    请选择公司营收范围: '请选择公司营收范围',
    请选择公司人数: '请选择公司人数',
    一: '一',
    二: '二',
    三: '三',
    四: '四',
    五: '五',
    六: '六',
    刚刚: '刚刚',
    分钟前: '分钟前',
    小时前: '小时前',
    天前: '天前',
    时: '时',
    分: '分',
    是一个函数: '是一个函数',
    服务器成功返回请求数据: '服务器成功返回请求数据',
    新建或修改数据成功: '新建或修改数据成功',
    '一个请求已经进入后台排队(异步任务)': '一个请求已经进入后台排队(异步任务)',
    删除数据成功: '删除数据成功',
    发出信息有误: '发出信息有误',
    '用户没有权限(令牌失效、用户名、密码错误、登录过期)':
      '用户没有权限(令牌失效、用户名、密码错误、登录过期)',
    令牌过期: '令牌过期',
    '用户得到授权，但是访问是被禁止的': '用户得到授权，但是访问是被禁止的',
    访问资源不存在: '访问资源不存在',
    请求格式不可得: '请求格式不可得',
    '请求资源被永久删除，且不会被看到': '请求资源被永久删除，且不会被看到',
    服务器发生错误: '服务器发生错误',
    网关错误: '网关错误',
    '服务不可用，服务器暂时过载或维护': '服务不可用，服务器暂时过载或维护',
    网关超时: '网关超时',
    '无网络！,请连接网络！！': '无网络！,请连接网络！！',
    '网络不给力！,请检查网络！！': '网络不给力！,请检查网络！！',
    '服务端正在升级中，请稍后再试': '服务端正在升级中，请稍后再试',
    提审并通过: '提审并通过',
    转: '转',
    切换公司主体: '切换公司主体',
    '确定解绑企业微信?': '确定解绑企业微信?',
    主体未设置企业微信: '主体未设置企业微信',
    扫描二维码联系工作人员: '扫描二维码联系工作人员',
    正在导出的文件: '正在导出的文件',
    '版本)': '版本)',
    '异常捕获(温馨提示：错误必须解决)': '异常捕获(温馨提示：错误必须解决)',
    报错路由: '报错路由',
    错误信息: '错误信息',
    错误详情: '错误详情',
    暂不显示: '暂不显示',
    百度搜索: '百度搜索',
    谷歌搜索: '谷歌搜索',
    最近访问: '最近访问',
    总览: '总览',
    开启全屏失败: '开启全屏失败',
    服务将于: '服务将于',
    天后到期: '天后到期',
    服务永不到期: '服务永不到期',
    站内信: '站内信',
    全部已读: '全部已读',
    消息状态: '消息状态',
    请选择消息类型: '请选择消息类型',
    标记已读: '标记已读',
    没有更多了: '没有更多了',
    清空消息成功: '清空消息成功',
    自有产品: '自有产品',
    '已在当前产品！请换一个试试！': '已在当前产品！请换一个试试！',
    电商网站: '电商网站',
    '支持纵向布局、分栏布局、综合布局、常规布局，不支持横向布局、浮动布局':
      '支持纵向布局、分栏布局、综合布局、常规布局，不支持横向布局、浮动布局',
    '请输入密码(密码在购买时获得，跳转后需登录购买时绑定的github账号)':
      '请输入密码(密码在购买时获得，跳转后需登录购买时绑定的github账号)',
    '秘钥不正确！': '秘钥不正确！',
    错误拦截: '错误拦截',
    '检测到您当前浏览器使用的是IE内核，自':
      '检测到您当前浏览器使用的是IE内核，自',
    '月起，微软已宣布弃用IE，且不再对IE提供任何更新维护，请':
      '月起，微软已宣布弃用IE，且不再对IE提供任何更新维护，请',
    '基于admin-plus构建': '基于admin-plus构建',
    '抱歉!': '抱歉!',
    '您没有操作角色...': '您没有操作角色...',
    '当前帐号没有操作角色,请联系管理员。':
      '当前帐号没有操作角色,请联系管理员。',
    '当前页面不存在...': '当前页面不存在...',
    '请检查您输入的网址是否正确，或点击下面的按钮返回首页。':
      '请检查您输入的网址是否正确，或点击下面的按钮返回首页。',
    返回首页: '返回首页',
    '重量(KG)': '重量(KG)',
    交易次数: '交易次数',
    交易重量: '交易重量',
    交易金额: '交易金额',
    出口: '出口',
    期间采购共计: '期间采购共计',
    次: '次',
    采购次数最高: '采购次数最高',
    采购次数最低: '采购次数最低',
    采购商数量: '采购商数量',
    供应商数量: '供应商数量',
    月度趋势: '月度趋势',
    已添加为客户: '已添加为客户',
    添加客户: '添加客户',
    进口详单: '进口详单',
    出口详单: '出口详单',
    贸易总览: '贸易总览',
    更新数据: '更新数据',
    区域分布: '区域分布',
    编码分布: '编码分布',
    货运历史: '货运历史',
    进口: '进口',
    贸易伙伴: '贸易伙伴',
    搜索时间: '搜索时间',
    页码: '页码',
    查看数据: '查看数据',
    无搜索结果: '无搜索结果',
    国家: '国家',
    经营状态: '经营状态',
    营收范围: '营收范围',
    公司人数: '公司人数',
    搜索历史: '搜索历史',
    历史搜索: '历史搜索',
    公司本地名称: '公司本地名称',
    城市: '城市',
    已解锁: '已解锁',
    未解锁: '未解锁',
    详情: '详情',
    '您确定要解锁当前客户数据?': '您确定要解锁当前客户数据?',
    产品搜索: '产品搜索',
    公司搜索: '公司搜索',
    交易分析: '交易分析',
    分析报告: '分析报告',
    回款详情: '回款详情',
    '您确定要对当前数据进行提审？': '您确定要对当前数据进行提审？',
    '您确定要对当前数据进行提审并通过？': '您确定要对当前数据进行提审并通过？',
    回款时间: '回款时间',
    审核人: '审核人',
    审核时间: '审核时间',
    回款计划详情: '回款计划详情',
    '您确定要对当前数据生成发货单？': '您确定要对当前数据生成发货单？',
    计划回款金额: '计划回款金额',
    付款详情: '付款详情',
    付款时间: '付款时间',
    开票计划详情: '开票计划详情',
    开票公司: '开票公司',
    计划开票金额: '计划开票金额',
    计划开票时间: '计划开票时间',
    开票商品名称: '开票商品名称',
    开票商品编号: '开票商品编号',
    商品规格型号: '商品规格型号',
    开票商品单位: '开票商品单位',
    开票税率: '开票税率',
    开票商品数量: '开票商品数量',
    付款计划详情: '付款计划详情',
    计划付款金额: '计划付款金额',
    需要数量: '需要数量',
    仓库名称: '仓库名称',
    剩余数量: '剩余数量',
    分配库存: '分配库存',
    关联订单: '关联订单',
    开票公司名称: '开票公司名称',
    一键复制: '一键复制',
    计划: '计划',
    计划开票占比: '计划开票占比',
    开票时间: '开票时间',
    付款编号: '付款编号',
    关联采购单: '关联采购单',
    计划编号: '计划编号',
    期数: '期数',
    第: '第',
    期: '期',
    计划付款占比: '计划付款占比',
    采购金额: '采购金额',
    已付款金额占比: '已付款金额占比',
    '当前期数/总期数': '当前期数/总期数',
    '您确定当前数据快速付款？': '您确定当前数据快速付款？',
    计划回款占比: '计划回款占比',
    '已回款金额/占比': '已回款金额/占比',
    '您确定当前数据快速回款？': '您确定当前数据快速回款？',
    回款编号: '回款编号',
    客户排行: '客户排行',
    毛利分析: '毛利分析',
    销量最高: '销量最高',
    毛利最高: '毛利最高',
    采购数量最大: '采购数量最大',
    采购利润最高: '采购利润最高',
    采购额: '采购额',
    销售分析: '销售分析',
    成交最多的销售: '成交最多的销售',
    销量最高的产品: '销量最高的产品',
    毛利最多的销售: '毛利最多的销售',
    毛利最高的产品: '毛利最高的产品',
    最近爆发的销售: '最近爆发的销售',
    上月增长最高的产品: '上月增长最高的产品',
    最近沉默的销售: '最近沉默的销售',
    上月下降最多的产品: '上月下降最多的产品',
    销售额: '销售额',
    产品分析: '产品分析',
    最畅销的产品: '最畅销的产品',
    最赚钱的产品: '最赚钱的产品',
    最近爆发产品: '最近爆发产品',
    最近沉默产品: '最近沉默产品',
    产品销售量: '产品销售量',
    商机漏斗: '商机漏斗',
    漏斗图: '漏斗图',
    销售排行: '销售排行',
    数据简报: '数据简报',
    环比: '环比',
    新增客户: '新增客户',
    新增商机: '新增商机',
    新增订单: '新增订单',
    累计回款: '累计回款',
    累计退款: '累计退款',
    前天: '前天',
    前周: '前周',
    前月: '前月',
    前年: '前年',
    用户趋势: '用户趋势',
    用户数量: '用户数量',
    趋势: '趋势',
    客户跟进: '客户跟进',
    需跟进的客户: '需跟进的客户',
    商机跟进: '商机跟进',
    订单跟进: '订单跟进',
    待办任务: '待办任务',
    任务名称: '任务名称',
    计划完成时间: '计划完成时间',
    占位符: '占位符',
    图形验证码: '图形验证码',
    账号登录: '账号登录',
    快捷登录: '快捷登录',
    扫码登录: '扫码登录',
    '企业微信扫码登录失败，请先确认您的企业微信账号确认属于该企业':
      '企业微信扫码登录失败，请先确认您的企业微信账号确认属于该企业',
    验证码不能空: '验证码不能空',
    事项信息: '事项信息',
    事项标题: '事项标题',
    事项类型: '事项类型',
    所属目标: '所属目标',
    重要程度: '重要程度',
    事项参与人员: '事项参与人员',
    请选择参与人员: '请选择参与人员',
    创建者: '创建者',
    参与人员: '参与人员',
    事项: '事项',
    请输入事项标题: '请输入事项标题',
    请输入重要程度: '请输入重要程度',
    请选择紧急程度: '请选择紧急程度',
    目标开始时间: '目标开始时间',
    目标结束时间: '目标结束时间',
    目标时间: '目标时间',
    请输入所属目标: '请输入所属目标',
    请选择所属目标: '请选择所属目标',
    数据已存在: '数据已存在',
    还有: '还有',
    '项...': '项...',
    系统创建: '系统创建',
    事件已结束: '事件已结束',
    新建日程: '新建日程',
    上一年: '上一年',
    上一月: '上一月',
    今天: '今天',
    下一月: '下一月',
    下一年: '下一年',
    日程主题: '日程主题',
    请输入日程主题: '请输入日程主题',
    日程周期: '日程周期',
    日程描述: '日程描述',
    请输入日程描述: '请输入日程描述',
    产品英文名: '产品英文名',
    请输入产品英文名: '请输入产品英文名',
    产品主图: '产品主图',
    产品轮播图: '产品轮播图',
    请输入产品描述: '请输入产品描述',
    计量单位: '计量单位',
    请输入产品计量单位: '请输入产品计量单位',
    产品库存: '产品库存',
    产品销量: '产品销量',
    产品成本价: '产品成本价',
    产品排序值: '产品排序值',
    产品重量: '产品重量',
    采购建议: '采购建议',
    请输入采购建议: '请输入采购建议',
    于: '于',
    创建者创建时间: '创建者创建时间',
    最近操作时间: '最近操作时间',
    故事: '故事',
    数据周期: '数据周期',
    '请输入属性名：库存': '请输入属性名：库存',
    '属性名：库存': '属性名：库存',
    请选择数据周期: '请选择数据周期',
    数据类型: '数据类型',
    请选择数据类型: '请选择数据类型',
    定时任务: '定时任务',
    请选择定时任务: '请选择定时任务',
    目标组名称: '目标组名称',
    '开始时间-结束时间': '开始时间-结束时间',
    目标标题: '目标标题',
    指标标题: '指标标题',
    图表类型: '图表类型',
    目标组: '目标组',
    目标组标题: '目标组标题',
    请输入目标组标题: '请输入目标组标题',
    目标组开始时间: '目标组开始时间',
    目标组结束时间: '目标组结束时间',
    目标组时间: '目标组时间',
    目标: '目标',
    请输入目标标题: '请输入目标标题',
    所属目标组: '所属目标组',
    请选择所属目标组: '请选择所属目标组',
    指标: '指标',
    请输入指标标题: '请输入指标标题',
    数据源: '数据源',
    请选择数据源: '请选择数据源',
    选择出数据源: '选择出数据源',
    手动: '手动',
    自动: '自动',
    指标数据: '指标数据',
    重要不紧急: '重要不紧急',
    重要紧急: '重要紧急',
    不重要不紧急: '不重要不紧急',
    不重要紧急: '不重要紧急',
    所属指标: '所属指标',
    横坐标: '横坐标',
    数值: '数值',
    显示近一周: '显示近一周',
    显示近一月: '显示近一月',
    显示近一年: '显示近一年',
    显示全部: '显示全部',
    个人: '个人',
    团队: '团队',
    预计提成: '预计提成',
    元: '元',
    '预计提成：团队比例': '预计提成：团队比例',
    '计提人(团队)': '计提人(团队)',
    当期金额: '当期金额',
    实际提成: '实际提成',
    订单编号: '订单编号',
    团队提成: '团队提成',
    服务金额: '服务金额',
    合计: '合计',
    条件: '条件',
    符合以下条件时按本规则计提: '符合以下条件时按本规则计提',
    添加条件: '添加条件',
    '元）': '元）',
    新建规则: '新建规则',
    '规则ID、规则名称': '规则ID、规则名称',
    编辑规则: '编辑规则',
    '负责人/提计人': '负责人/提计人',
    产品分类下的扩展属性: '产品分类下的扩展属性',
    产品属性: '产品属性',
    设置方式: '设置方式',
    销售价格: '销售价格',
    采购价格: '采购价格',
    按客户设置: '按客户设置',
    价格为: '价格为',
    价格币种: '价格币种',
    标准单价: '标准单价',
    按客户类别设置: '按客户类别设置',
    默认调价比例: '默认调价比例',
    实际调价比例: '实际调价比例',
    起始年份: '起始年份',
    截止年份: '截止年份',
    补充年份: '补充年份',
    已上传: '已上传',
    上传图片: '上传图片',
    共: '共',
    综述信息: '综述信息',
    跟进信息: '跟进信息',
    详细跟进: '详细跟进',
    请填写跟进内容: '请填写跟进内容',
    发布: '发布',
    下次跟进: '下次跟进',
    绩效比例: '绩效比例',
    '个人绩效相对团队绩效占比，剩余部分归负责人':
      '个人绩效相对团队绩效占比，剩余部分归负责人',
    最新价格: '最新价格',
    历史近期: '历史近期',
    供应: '供应',
    已付金额: '已付金额',
    '当前订单付款总和，不扣除退款': '当前订单付款总和，不扣除退款',
    '您确定要对当前数据生成入库单？': '您确定要对当前数据生成入库单？',
    批量添加: '批量添加',
    请输入企业名称: '请输入企业名称',
    '您确定要对当前数据进行作废？': '您确定要对当前数据进行作废？',
    请选择组装拆卸产品明细: '请选择组装拆卸产品明细',
    请选择组装拆卸产品: '请选择组装拆卸产品',
    箱单详情: '箱单详情',
    '您确定要生成发货单？': '您确定要生成发货单？',
    装箱数: '装箱数',
    '未作废数据删除，库存将不会恢复是否删除？':
      '未作废数据删除，库存将不会恢复是否删除？',
    '您确定要作废当前数据？': '您确定要作废当前数据？',
    箱单数量: '箱单数量',
    请输入预估所需箱单数: '请输入预估所需箱单数',
    拆分数量: '拆分数量',
    装货箱编号: '装货箱编号',
    码: '码',
    包装数量: '包装数量',
    包装的数量: '包装的数量',
    '单个重量(w/pc)': '单个重量(w/pc)',
    包装的重量: '包装的重量',
    '单个体积(v/pc)': '单个体积(v/pc)',
    包装的体积: '包装的体积',
    总重量: '总重量',
    总体积: '总体积',
    所属箱号: '所属箱号',
    盘点详情: '盘点详情',
    系统库存: '系统库存',
    系统统计当前仓库的数量: '系统统计当前仓库的数量',
    盘点数量: '盘点数量',
    盘亏盘盈: '盘亏盘盈',
    添加后请到出入库单列表审核: '添加后请到出入库单列表审核',
    '剩余数量（冻结库存数量)': '剩余数量（冻结库存数量)',
    操作员: '操作员',
    出入库单详情: '出入库单详情',
    '您确定要对当前数据生成出入库单？': '您确定要对当前数据生成出入库单？',
    出入库进度: '出入库进度',
    备注说明: '备注说明',
    产品调拨详情: '产品调拨详情',
    组合件名称: '组合件名称',
    单位成本: '单位成本',
    组合成本: '组合成本',
    操作时间: '操作时间',
    操作人: '操作人',
    业务类型: '业务类型',
    入库数量: '入库数量',
    出库数量: '出库数量',
    结存数量: '结存数量',
    库存低于预警库存: '库存低于预警库存',
    各仓库数量: '各仓库数量',
    总库存: '总库存',
    盘点时间: '盘点时间',
    盘点产品量: '盘点产品量',
    盘点人: '盘点人',
    中文: '中文',
    父级: '父级',
    请选择父级: '请选择父级',
    记录: '记录',
    租金: '租金',
    人流量: '人流量',
    是否转让: '是否转让',
    转让价格: '转让价格',
    流水: '流水',
    利润: '利润',
    推荐星级: '推荐星级',
    星: '星',
    联系方式: '联系方式',
    商家: '商家',
    是否显示: '是否显示',
    请输入店铺名称: '请输入店铺名称',
    选址类型: '选址类型',
    请选择选址类型: '请选择选址类型',
    请输入店铺地址: '请输入店铺地址',
    经度: '经度',
    请输入经度: '请输入经度',
    纬度: '纬度',
    请输入纬度: '请输入纬度',
    '租金(月)': '租金(月)',
    请输入租金: '请输入租金',
    请输入人流量: '请输入人流量',
    请输入流水: '请输入流水',
    请输入利润: '请输入利润',
    请输入推荐星级: '请输入推荐星级',
    请输入转让价格: '请输入转让价格',
    请输入联系方式: '请输入联系方式',
    竞品情况: '竞品情况',
    请输入竞品情况: '请输入竞品情况',
    请输入教程名称: '请输入教程名称',
    教程类型: '教程类型',
    请选择教程类型: '请选择教程类型',
    教程: '教程',
    教程名称: '教程名称',
    前: '前',
    小时内: '小时内',
    分钟内: '分钟内',
    销售趋势: '销售趋势',
    销售数量: '销售数量',
    客户最新报价: '客户最新报价',
    头像: '头像',
    昵称: '昵称',
    真实姓名: '真实姓名',
    手机号码: '手机号码',
    最后登录时间: '最后登录时间',
    电话号码: '电话号码',
    请选择角色: '请选择角色',
    请选择公司: '请选择公司',
    请选择昵称: '请选择昵称',
    人员: '人员',
    公司简称: '公司简称',
    请输入公司简称: '请输入公司简称',
    统一社会信用代码: '统一社会信用代码',
    请输入统一社会信用代码: '请输入统一社会信用代码',
    企业法人: '企业法人',
    请输入企业法人: '请输入企业法人',
    注册地址: '注册地址',
    请输入注册地址: '请输入注册地址',
    注册日期: '注册日期',
    请输入注册日期: '请输入注册日期',
    请选择注册日期: '请选择注册日期',
    联系电话: '联系电话',
    请输入联系电话: '请输入联系电话',
    联系邮箱: '联系邮箱',
    请输入联系邮箱: '请输入联系邮箱',
    站点域名: '站点域名',
    请输入站点域名: '请输入站点域名',
    请输入ICP: '请输入ICP',
    公司代号: '公司代号',
    请输入公司代号: '请输入公司代号',
    公司简介: '公司简介',
    请输入公司简介: '请输入公司简介',
    公司LOGO: '公司LOGO',
    站点LOGO: '站点LOGO',
    站点ICO: '站点ICO',
    登录页背景图: '登录页背景图',
    部门名称: '部门名称',
    手机: '手机',
    请输入部门名称: '请输入部门名称',
    部门人员: '部门人员',
    员工: '员工',
    请选择员工: '请选择员工',
    职位: '职位',
    '您确定要对当前员工进行禁用？': '您确定要对当前员工进行禁用？',
    '您确定要对当前员工进行解除禁用？': '您确定要对当前员工进行解除禁用？',
    '您确定要对当前员工进行离职处理？': '您确定要对当前员工进行离职处理？',
    员工状态: '员工状态',
    权限字段: '权限字段',
    编辑角色: '编辑角色',
    添加角色: '添加角色',
    角色名称: '角色名称',
    请输入角色名称: '请输入角色名称',
    角色描述: '角色描述',
    数据权限: '数据权限',
    模块权限: '模块权限',
    字段权限: '字段权限',
    请输入角色名: '请输入角色名',
    组织架构: '组织架构',
    员工管理: '员工管理',
    标识: '标识',
    成员限制: '成员限制',
    成员数量: '成员数量',
    到期时间: '到期时间',
    站点: '站点',
    行业: '行业',
    请输入标识: '请输入标识',
    管理员手机号: '管理员手机号',
    请输入管理员手机号: '请输入管理员手机号',
    管理员姓名: '管理员姓名',
    请输入管理员姓名: '请输入管理员姓名',
    公司行业: '公司行业',
    请选择公司行业: '请选择公司行业',
    请选择到期时间: '请选择到期时间',
    键: '键',
    必填: '必填',
    默认值: '默认值',
    占比: '占比',
    请输入正确key: '请输入正确key',
    字段: '字段',
    只能是英文数字下划线的组合且以英文或者下划线开头:
      '只能是英文数字下划线的组合且以英文或者下划线开头',
    请输入键: '请输入键',
    请输入默认值: '请输入默认值',
    输入提示: '输入提示',
    请输入输入提示: '请输入输入提示',
    客户类型: '客户类型',
    请输入客户类型名: '请输入客户类型名',
    请输入客户类型: '请输入客户类型',
    客户配置: '客户配置',
    行业字段: '行业字段',
    行业名称: '行业名称',
    行业权限: '行业权限',
    所属菜单: '所属菜单',
    字段名称: '字段名称',
    字段标识: '字段标识',
    列表宽度: '列表宽度',
    父级菜单: '父级菜单',
    请选择父级菜单: '请选择父级菜单',
    请输入字段名称: '请输入字段名称',
    请输入字段标识: '请输入字段标识',
    请输入列表宽度: '请输入列表宽度',
    字段排序: '字段排序',
    请输入字段排序: '请输入字段排序',
    菜单名称: '菜单名称',
    菜单: '菜单',
    请输入菜单名称: '请输入菜单名称',
    菜单地址: '菜单地址',
    请输入菜单地址: '请输入菜单地址',
    关联菜单: '关联菜单',
    请选择关联菜单: '请选择关联菜单',
    菜单图标: '菜单图标',
    请输入菜单图标: '请输入菜单图标',
    菜单排序: '菜单排序',
    请输入菜单排序: '请输入菜单排序',
    操作名称: '操作名称',
    操作代码: '操作代码',
    操作地址: '操作地址',
    表头操作: '表头操作',
    列表操作: '列表操作',
    选项卡操作: '选项卡操作',
    请输入操作名称: '请输入操作名称',
    不输入则与操作名称相同: '不输入则与操作名称相同',
    请输入操作代码: '请输入操作代码',
    操作图标: '操作图标',
    请输入操作图标: '请输入操作图标',
    请输入操作地址: '请输入操作地址',
    操作排序: '操作排序',
    请输入操作排序: '请输入操作排序',
    显示在表头: '显示在表头',
    显示在列表: '显示在列表',
    显示在选项卡: '显示在选项卡',
    所属企业: '所属企业',
    企业: '企业',
    选择企业: '选择企业',
    操作管理: '操作管理',
    字段管理: '字段管理',
    设备调试: '设备调试',
    门锁状态: '门锁状态',
    物品状态: '物品状态',
    门锁编号: '门锁编号',
    开锁: '开锁',
    检测: '检测',
    通电: '通电',
    断电: '断电',
    门锁操作: '门锁操作',
    门锁打开: '门锁打开',
    门锁已开启: '门锁已开启',
    门锁关闭: '门锁关闭',
    门锁已关闭: '门锁已关闭',
    发信昵称: '发信昵称',
    邮箱服务商: '邮箱服务商',
    默认发信邮箱: '默认发信邮箱',
    请输入发信昵称: '请输入发信昵称',
    请选择邮箱服务商: '请选择邮箱服务商',
    密码: '密码',
    语种: '语种',
    请选择语种: '请选择语种',
    '内容-头部': '内容-头部',
    '内容-尾部': '内容-尾部',
    用户名: '用户名',
    性别: '性别',
    个人简介: '个人简介',
    我的邮箱: '我的邮箱',
    重置密码: '重置密码',
    短信验证码: '短信验证码',
    新密码: '新密码',
    供应商分类: '供应商分类',
    车型配置: '车型配置',
    部件配置: '部件配置',
    行业模板: '行业模板',
    '请选择行业模板(非必填)': '请选择行业模板(非必填)',
    分类图片: '分类图片',
    英文名称: '英文名称',
    顶级分类: '顶级分类',
    请输入搜索内容: '请输入搜索内容',
    父级分类: '父级分类',
    请选择父级分类: '请选择父级分类',
    '分类名称（英文）': '分类名称（英文）',
    '请输入分类名称（英文）': '请输入分类名称（英文）',
    分类排序: '分类排序',
    类型标题: '类型标题',
    类型名称: '类型名称',
    请输入类型名称: '请输入类型名称',
    仓库设置: '仓库设置',
    图片数量: '图片数量',
    宽度: '宽度',
    高度: '高度',
    素材名称: '素材名称',
    分类标题: '分类标题',
    事项分类: '事项分类',
    故事分类: '故事分类',
    汇率设置: '汇率设置',
    人民币: '人民币',
    前缀: '前缀',
    请选择中间规则: '请选择中间规则',
    请选择后缀规则: '请选择后缀规则',
    产品编号规则: '产品编号规则',
    '前缀如CP，类型代码需要在类型信息里面增加':
      '前缀如CP，类型代码需要在类型信息里面增加',
    空: '空',
    类型id: '类型id',
    类型代码: '类型代码',
    自增ID: '自增ID',
    客户编号规则: '客户编号规则',
    前缀如KH: '前缀如KH',
    国家ID: '国家ID',
    国家代码: '国家代码',
    销售单名称规则: '销售单名称规则',
    前缀如XSDD: '前缀如XSDD',
    客户ID: '客户ID',
    年月日: '年月日',
    采购单名称规则: '采购单名称规则',
    前缀如CGD: '前缀如CGD',
    供应商ID: '供应商ID',
    销售报价权限: '销售报价权限',
    '严格模式下，报价低于产品最低售价不可提交，轻松模式下可以提交，会给出明显警告':
      '严格模式下，报价低于产品最低售价不可提交，轻松模式下可以提交，会给出明显警告',
    宽松模式: '宽松模式',
    采购报价权限: '采购报价权限',
    '严格模式下，采购高于供应商报价不可提交，轻松模式下可以提交，会给出明显警告':
      '严格模式下，采购高于供应商报价不可提交，轻松模式下可以提交，会给出明显警告',
    基本配置: '基本配置',
    '用户ID/用户名': '用户ID/用户名',
    路径: '路径',
    请求ID: '请求ID',
    规则: '规则',
    执行类: '执行类',
    执行方法: '执行方法',
    是否启用: '是否启用',
    任务标题: '任务标题',
    请输入任务标题: '请输入任务标题',
    任务规则: '任务规则',
    请输入任务规则: '请输入任务规则',
    如: '如',
    则代表每: '则代表每',
    分钟执行一次: '分钟执行一次',
    请输入执行类: '请输入执行类',
    请输入执行方法: '请输入执行方法',
    任务类型: '任务类型',
    请选择任务类型: '请选择任务类型',
    参数格式: '参数格式',
    '请输入参数格式(必须为json格式),可不填写':
      '请输入参数格式(必须为json格式),可不填写',
    数据组类型: '数据组类型',
    请选择数据组类型: '请选择数据组类型',
    属性: '属性',
    '请输入属性key(唯一)：name': '请输入属性key(唯一)：name',
    '属性key(唯一)：name': '属性key(唯一)：name',
    属性类型: '属性类型',
    排序值: '排序值',
    请填写选项配置值: '请填写选项配置值',
    参数方式例如: '参数方式例如',
    白色: '白色',
    红色: '红色',
    黑色: '黑色',
    添加属性: '添加属性',
    数据组名称: '数据组名称',
    数据组描述: '数据组描述',
    请输入关键字: '请输入关键字',
    扩展属性: '扩展属性',
    请输入数据组名称: '请输入数据组名称',
    请输入数据组描述: '请输入数据组描述',
    请上传: '请上传',
    主体名称: '主体名称',
    域名: '域名',
    站点标题: '站点标题',
    团队名称: '团队名称',
    主体: '主体',
    所属: '所属',
    请输入所属: '请输入所属',
    请输入主体名称: '请输入主体名称',
    请输入税号: '请输入税号',
    法人: '法人',
    请输入法人: '请输入法人',
    请输入站点标题: '请输入站点标题',
    主体简介: '主体简介',
    请输入主体简介: '请输入主体简介',
    主体LOGO: '主体LOGO',
    站点背景: '站点背景',
    数据配置: '数据配置',
    主体管理: '主体管理',
    账号: '账号',
    请输入账号: '请输入账号',
    周期: '周期',
    日志类型: '日志类型',
    执行结果: '执行结果',
    登录成功: '登录成功',
    访问时间: '访问时间',
    登录IP: '登录IP',
    下载模版: '下载模版',
    枚举变量: '枚举变量',
    模版文件: '模版文件',
    仅支持Xlsx: '仅支持Xlsx',
    若为Xls文件请先在Excel中另存为Xlsx: '若为Xls文件请先在Excel中另存为Xlsx',
    '添加完成后，请点击字段集编辑完善模版填充配置信息':
      '添加完成后，请点击字段集编辑完善模版填充配置信息',
    变量: '变量',
    为单一数据占位: '为单一数据占位',
    为列数据占位: '为列数据占位',
    字段集: '字段集',
    '文件名称(点击预览)': '文件名称(点击预览)',
    文件大小: '文件大小',
    数据填充起始行: '数据填充起始行',
    模板选择: '模板选择',
    无模板导出: '无模板导出',
    模板导出: '模板导出',
    无模板打印: '无模板打印',
    模板打印: '模板打印',
    请选择模板: '请选择模板',
    '正在导出中...': '正在导出中...',
    '正在打印中...': '正在打印中...',
    单元格: '单元格',
    多行项: '多行项',
    是否求和: '是否求和',
    是否图片: '是否图片',
    是否合并: '是否合并',
    图片宽度: '图片宽度',
    图片高度: '图片高度',
    关联合并: '关联合并',
    请选择字段: '请选择字段',
    链接: '链接',
    轮播图设置: '轮播图设置',
    首页分类商品设置: '首页分类商品设置',
    '此内容依赖于：产品》产品分类，您可以勾选你需要展示的分类，勾选后分类将出现在首页f分类商品中。':
      '此内容依赖于：产品》产品分类，您可以勾选你需要展示的分类，勾选后分类将出现在首页f分类商品中。',
    首页分类导航设置: '首页分类导航设置',
    '此内容依赖于：产品》产品分类，您可以勾选你需要展示的分类，勾选后分类将出现在首页导航栏中。':
      '此内容依赖于：产品》产品分类，您可以勾选你需要展示的分类，勾选后分类将出现在首页导航栏中。',
    导航设置: '导航设置',
    站点名称: '站点名称',
    请输入站点名称: '请输入站点名称',
    '对SEO很重要,不要堆砌关键字': '对SEO很重要,不要堆砌关键字',
    站点描述: '站点描述',
    请输入站点描述: '请输入站点描述',
    '站点的描述，用于展示在搜素结果': '站点的描述，用于展示在搜素结果',
    站点关键词: '站点关键词',
    请输入站点关键词: '请输入站点关键词',
    '搜索时会匹配对应的关键词，不要重复': '搜索时会匹配对应的关键词，不要重复',
    请输入公司地址: '请输入公司地址',
    社媒设置: '社媒设置',
    联系我们: '联系我们',
    请输入正确的网址信息: '请输入正确的网址信息',
    请输入facebook主页地址: '请输入facebook主页地址',
    请输入twitter主页地址: '请输入twitter主页地址',
    请输入youtube主页地址: '请输入youtube主页地址',
    请输入linkedin主页地址: '请输入linkedin主页地址',
    请输入instagram主页地址: '请输入instagram主页地址',
    页尾设置: '页尾设置',
    '您确定要作废当前数据?': '您确定要作废当前数据?',
    实施明细: '实施明细',
    任务状态: '任务状态',
    参与人: '参与人',
    未开始: '未开始',
    任务详情: '任务详情',
    任务概况: '任务概况',
    完成时间: '完成时间',
    '您确定要作废此任务？': '您确定要作废此任务？',
    '您确定已完成此任务？': '您确定已完成此任务？',
    请选择团队成员: '请选择团队成员',
    请填写比列: '请填写比列',
    请填写分成比列: '请填写分成比列',
    进度: '进度',
    分成比例不能低于: '分成比例不能低于',
    或高于: '或高于',
    钱包金额: '钱包金额',
    用户角色: '用户角色',
    注册时间: '注册时间',
    用户: '用户',
    请输入真实姓名: '请输入真实姓名',
    用户邮箱: '用户邮箱',
    请输入用户邮箱: '请输入用户邮箱',
    请输入正确的用户邮箱地址: '请输入正确的用户邮箱地址',
    性别选择: '性别选择',
    所属团队: '所属团队',
    请选择团队: '请选择团队',
    所属角色: '所属角色',
    用户密码: '用户密码',
    请输入用户密码: '请输入用户密码',
    用户头像: '用户头像',
    用户昵称: '用户昵称',
    回复: '回复',
    反馈: '反馈',
    请输入回复: '请输入回复',
    启用状态: '启用状态',
    用户来源: '用户来源',
    积分: '积分',
    请输入用户昵称: '请输入用户昵称',
    请选择用户角色: '请选择用户角色',
    未知功能: '未知功能',
    成员头像: '成员头像',
    成员名称: '成员名称',
    成员角色: '成员角色',
    管理产品: '管理产品',
    请输入团队名称: '请输入团队名称',
    设备区域: '设备区域',
    请选择设备区域: '请选择设备区域',
    团队简介: '团队简介',
    请输入团队简介: '请输入团队简介',
    团队头像: '团队头像',
    HS编码: 'HS编码',
    // TODO: 2023-11-21
    '产品组成：产品A由B/C/D组成,组合的产品A在销售和库存环节跟其他产品一样。在箱单处理环节可以自动拆分':
      '产品组成：产品A由B/C/D组成,组合的产品A在销售和库存环节跟其他产品一样。在箱单处理环节可以自动拆分',
    M会自动为您过滤: 'M会自动为您过滤',
    S后刷新项目: 'S后刷新项目',
    客户投诉: '客户投诉',
    配置项: '配置项',
    login核心接口异常: 'login核心接口异常',
    '请检查返回JSON格式是否正确，是否正确返回':
      '请检查返回JSON格式是否正确，是否正确返回',
    getUserInfo核心接口异常: 'getUserInfo核心接口异常',
    请检查返回JSON格式是否正确: '请检查返回JSON格式是否正确',
    不含税: '不含税',
    产品质量: '产品质量',
    发货速度: '发货速度',
    售后服务: '售后服务',
    客诉: '客诉',
    客诉标题: '客诉标题',
    请输入客诉标题: '请输入客诉标题',
    客诉类型: '客诉类型',
    关联信息: '关联信息',
    请选择关联信息: '请选择关联信息',
    客诉原因: '客诉原因',
    请输入客诉原因: '请输入客诉原因',
    valFun是一个函数: 'valFun是一个函数',
    产品组成: '产品组成',
    最近销售时间: '最近销售时间',
    EXW价格: 'EXW价格',
    FOB价格: 'FOB价格',
    厂商税率: '厂商税率',
    最近采购时间: '最近采购时间',
    采购次数: '采购次数',
    产品供应商分布: '产品供应商分布',
    OEM码: 'OEM码',
    附件信息: '附件信息',
    原因: '原因',
    URL地址: 'URL地址',
    // TODO: 2023-11-22
    含税: '含税',
    请输入采购税率: '请输入采购税率',
    是否含税: '是否含税',
    运输费用: '运输费用',
    请选择客诉类型: '请选择客诉类型',
    客诉信息: '客诉信息',
    请输入客户WhatsApp: '请输入客户WhatsApp',
    '仅支持Xlsx,若为Xls文件请先在Excel中另存为Xlsx':
      '仅支持Xlsx,若为Xls文件请先在Excel中另存为Xlsx',
    // TODO: 2023-11-24
    设为主图: '设为主图',
    包邮: '包邮',
    不包邮: '不包邮',
    邮费: '邮费',
    减少: '减少',
    增加: '增加',
    产品图文: '产品图文',
    图文列表: '图文列表',
    图文生成: '图文生成',
    金豆记录: '金豆记录',
    是否包邮: '是否包邮',
    抠图图集: '抠图图集',
    金豆变更: '金豆变更',
    选择产品型号: '选择产品型号',
    预览图文: '预览图文',
    生成PDF: '生成PDF',
    '当前只可选择生成三级分类产品，若需生成完整产品图文，请先完善三级分类':
      '当前只可选择生成三级分类产品，若需生成完整产品图文，请先完善三级分类',
    生成请求已提交: '生成请求已提交',
    可前往产品图文列表页查看进度: '可前往产品图文列表页查看进度',
    返回上页: '返回上页',
    开始生成: '开始生成',
    重新生成: '重新生成',
    前往列表页: '前往列表页',
    金豆剩余: '金豆剩余',
    消费类型: '消费类型',
    消费金豆: '消费金豆',
    消费前: '消费前',
    消费后: '消费后',
    消费时间: '消费时间',
    请选择消费类型: '请选择消费类型',
    '您确定要对当前员工进行恢复？': '您确定要对当前员工进行恢复？',
    金豆使用规则: '金豆使用规则',
    // TODO: 2024-05-27
    长: '长',
    厘米: '厘米',
    宽: '宽',
    不能为空: '不能为空',
    购买数量: '购买数量',
    '预览（可根据买家采购的不同数量设置不同价格）':
      '预览（可根据买家采购的不同数量设置不同价格）',
    可售数量: '可售数量',
    单品货号: '单品货号',
    是否上架: '是否上架',
    上架店铺: '上架店铺',
    请选择店铺: '请选择店铺',
    商品类目: '商品类目',
    上一步: '上一步',
    提交: '提交',
    下一步: '下一步',
    选择类目: '选择类目',
    新增产品: '新增产品',
    修改产品: '修改产品',
    使用运费模版: '使用运费模版',
    运费指导模版: '运费指导模版',
    卖家承担运费: '卖家承担运费',
    库存详情: '库存详情',
    国内仓: '国内仓',
    荔枝角: '荔枝角',
    深水埗: '深水埗',
    '产品报价低于最低售价不可提交,请调整!':
      '产品报价低于最低售价不可提交,请调整!',
    '产品报价低于最低售价!': '产品报价低于最低售价!',
    '产品报价高于最低采购价不可提交,请调整!':
      '产品报价高于最低采购价不可提交,请调整!',
    '产品报价高于最低采购价!': '产品报价高于最低采购价!',
    工单: '工单',
    工序名称: '工序名称',
    加工时长: '加工时长',
    默认仓库: '默认仓库',
    '尺寸/规格': '尺寸/规格',
    所述仓库: '所述仓库',
    绑定: '绑定',
    选择: '选择',
    运费: '运费',
    '已更换产品，需重新打印标签贴标': '已更换产品，需重新打印标签贴标',
    规格材质: '规格材质',
    '蓝色字体表示建议售价，绿色字体表示历史成交报价':
      '蓝色字体表示建议售价，绿色字体表示历史成交报价',
    建议采购价: '建议采购价',
    智能选择供应商: '智能选择供应商',
    品规: '品规',
    '体积/净重/长-宽-高': '体积/净重/长-宽-高',
    盒规: '盒规',
    '您确定要对当前数据进行一键采购？': '您确定要对当前数据进行一键采购？',
    '您确定要对当前数据进行一键备货？': '您确定要对当前数据进行一键备货？',
    '当前产品明细数据有未关联的产品请关联产品?':
      '当前产品明细数据有未关联的产品请关联产品?',
    '您当前报价低于最低售价的价格，请修改！':
      '您当前报价低于最低售价的价格，请修改！',
    箱单信息: '箱单信息',
    混装: '混装',
    添加箱子: '添加箱子',
    请选择箱规: '请选择箱规',
    请选择箱号: '请选择箱号',
    '支持产品名称/OEM码/编码搜索': '支持产品名称/OEM码/编码搜索',
    降序: '降序',
    升序: '升序',
    月结: '月结',
    寄付: '寄付',
    到付: '到付',
    明天: '明天',
    后天: '后天',
    深水涉: '深水涉',
    待入库: '待入库',
    在仓: '在仓',
    上车: '上车',
    取消派件: '取消派件',
    发往客户: '发往客户',
    已送达: '已送达',
    元天屯: '元天屯',
    九龍: '九龍',
    新界: '新界',
    港島: '港島',
    '離島/偏遠': '離島/偏遠',
    九東: '九東',
    港東: '港東',
    客户账单: '客户账单',
    供应商账单: '供应商账单',
    运营报表: '运营报表',
    产品面板: '产品面板',
    电商产品: '电商产品',
    报价单表: '报价单表',
    产品图册: '产品图册',
    产品箱规: '产品箱规',
    私有线索: '私有线索',
    公海线索: '公海线索',
    销售面板: '销售面板',
    抖音订单: '抖音订单',
    营销: '营销',
    我方渠道: '我方渠道',
    竞对渠道: '竞对渠道',
    我方店铺: '我方店铺',
    竞对店铺: '竞对店铺',
    渠道类型: '渠道类型',
    对账列表: '对账列表',
    账单明细: '账单明细',
    '发货单(家具)': '发货单(家具)',
    '派货行程(家具)': '派货行程(家具)',
    今日计划: '今日计划',
    收货单: '收货单',
    商品代发: '商品代发',
    商品退货: '商品退货',
    商品索赔: '商品索赔',
    '库存余额(家具)': '库存余额(家具)',
    快递寄件: '快递寄件',
    收费标准: '收费标准',
    工厂: '工厂',
    生产计划: '生产计划',
    生产工单: '生产工单',
    委外管理: '委外管理',
    委外订单: '委外订单',
    整单委外: '整单委外',
    工序委外: '工序委外',
    生产执行: '生产执行',
    生产工序: '生产工序',
    报工单: '报工单',
    物料管理: '物料管理',
    领料单: '领料单',
    退料单: '退料单',
    质量管理: '质量管理',
    工序质检: '工序质检',
    委外质检: '委外质检',
    BOM管理: 'BOM管理',
    产品BOM: '产品BOM',
    BOM清单: 'BOM清单',
    工序工艺: '工序工艺',
    工艺: '工艺',
    工序: '工序',
    设备列表: '设备列表',
    设备类型: '设备类型',
    三方应用: '三方应用',
    硬件配置: '硬件配置',
    费用记录: '费用记录',
    仓储体积: '仓储体积',
    导出记录: '导出记录',
    是否KP: '是否KP',
    起订量: '起订量',
    部件备注: '部件备注',
    原产地: '原产地',
    产品评分: '产品评分',
    供应链评分: '供应链评分',
    账期客户: '账期客户',
    感兴趣产品: '感兴趣产品',
    客户折扣: '客户折扣',
    采购类型: '采购类型',
    线索名称: '线索名称',
    线索来源: '线索来源',
    线索阶段: '线索阶段',
    线索编码: '线索编码',
    客户地址: '客户地址',
    客户区域: '客户区域',
    产品类别: '产品类别',
    订单备注: '订单备注',
    后台备注: '后台备注',
    送货日期: '送货日期',
    选择日期: '选择日期',
    是否账期: '是否账期',
    关联订单号: '关联订单号',
    订单支付状态: '订单支付状态',
    配送方式: '配送方式',
    收件电话: '收件电话',
    收件地址: '收件地址',
    快递单号: '快递单号',
    收货人信息: '收货人信息',
    所在州: '所在州',
    运输保险: '运输保险',
    货品价值: '货品价值',
    签收选项: '签收选项',
    寄件人信息: '寄件人信息',
    商品体积: '商品体积',
    商品重量: '商品重量',
    商品货值: '商品货值',
    快递费用: '快递费用',
    包裹信息: '包裹信息',
    '长(in)': '长(in)',
    '宽(in)': '宽(in)',
    '高(in)': '高(in)',
    '重量(lb)': '重量(lb)',
    计算费用: '计算费用',
    快递公司: '快递公司',
    快递面签: '快递面签',
    预约日期: '预约日期',
    预约时间: '预约时间',
    退货照片: '退货照片',
    外观照片: '外观照片',
    拆开照片: '拆开照片',
    处理方式: '处理方式',
    退货进度: '退货进度',
    索赔结果: '索赔结果',
    订单截图: '订单截图',
    证明图片: '证明图片',
    适用产品: '适用产品',
    版本号: '版本号',
    计划单号: '计划单号',
    生产类型: '生产类型',
    计划生产数量: '计划生产数量',
    计划开工日期: '计划开工日期',
    计划完工日期: '计划完工日期',
    生产产品: '生产产品',
    生产批次号: '生产批次号',
    生产单号: '生产单号',
    生产状态: '生产状态',
    延期情况: '延期情况',
    委外状态: '委外状态',
    入库日期: '入库日期',
    排产人: '排产人',
    生产完成率: '生产完成率',
    已完工数量: '已完工数量',
    待入库数量: '待入库数量',
    已入库数量: '已入库数量',
    工序计划数量: '工序计划数量',
    工序完工数量: '工序完工数量',
    报废数: '报废数',
    返工数: '返工数',
    总生产成本: '总生产成本',
    加工成本: '加工成本',
    物料成本: '物料成本',
    委外编号: '委外编号',
    委外供应商: '委外供应商',
    委外类型: '委外类型',
    委外产品: '委外产品',
    委外数量: '委外数量',
    委外单价: '委外单价',
    委外日期: '委外日期',
    委外总额: '委外总额',
    委外应付总额: '委外应付总额',
    委外已付总额: '委外已付总额',
    已退货数量: '已退货数量',
    质检不合格数量: '质检不合格数量',
    质检合格率: '质检合格率',
    工序编号: '工序编号',
    工序状态: '工序状态',
    执行工序: '执行工序',
    工艺路线: '工艺路线',
    工序执行人: '工序执行人',
    工序计划生产数: '工序计划生产数',
    实际完工日期: '实际完工日期',
    待完工数量: '待完工数量',
    完工良品数: '完工良品数',
    完工良品率: '完工良品率',
    工序完成率: '工序完成率',
    返工数量: '返工数量',
    返工率: '返工率',
    报废数量: '报废数量',
    报废率: '报废率',
    报工单编号: '报工单编号',
    生产数量: '生产数量',
    生产人员: '生产人员',
    报工日期: '报工日期',
    质检状态: '质检状态',
    良品数量: '良品数量',
    生产工资: '生产工资',
    工序单价: '工序单价',
    问题描述: '问题描述',
    问题图片: '问题图片',
    物料申请单号: '物料申请单号',
    审批状态: '审批状态',
    申请人: '申请人',
    申请时间: '申请时间',
    委外工单: '委外工单',
    设备名称: '设备名称',
    设备编码: '设备编码',
    设备型号: '设备型号',
    外观尺寸: '外观尺寸',
    设备用途: '设备用途',
    管理负责人: '管理负责人',
    投产日期: '投产日期',
    采购日期: '采购日期',
    生产日期: '生产日期',
    生产厂家: '生产厂家',
    用途: '用途',
    设备图集: '设备图集',
    请输入OEM码多个: '请输入OEM码多个',
    分割: '分割',
    请选择产品类别: '请选择产品类别',
    是否关键产品: '是否关键产品',
    请选择原产地: '请选择原产地',
    请输入部件备注: '请输入部件备注',
    箱规信息已移至详情页面添加编辑: '箱规信息已移至详情页面添加编辑',
    产品规格: '产品规格',
    复制产品: '复制产品',
    图片设计: '图片设计',
    箱规类型: '箱规类型',
    请选择箱规类型: '请选择箱规类型',
    请输入产品数量: '请输入产品数量',
    可以容纳的产品个数: '可以容纳的产品个数',
    默认箱规: '默认箱规',
    费用箱规: '费用箱规',
    计算货品体积费用时的箱规: '计算货品体积费用时的箱规',
    '长(cm)': '长(cm)',
    请输入箱规长: '请输入箱规长',
    '宽(cm)': '宽(cm)',
    请输入箱规宽: '请输入箱规宽',
    '高(cm)': '高(cm)',
    请输入箱规高: '请输入箱规高',
    请输入箱规重量: '请输入箱规重量',
    请输入箱规体积: '请输入箱规体积',
    所属分类: '所属分类',
    请选择所属分类: '请选择所属分类',
    '用于生成电商网站的链接,可不填写': '用于生成电商网站的链接,可不填写',
    图册: '图册',
    文件请到附件中上传: '文件请到附件中上传',
    产品id: '产品id',
    渠道名称: '渠道名称',
    请选择渠道名称: '请选择渠道名称',
    请输入链接地址: '请输入链接地址',
    请输入销售价格: '请输入销售价格',
    售出产品数: '售出产品数',
    请输入售出产品数量: '请输入售出产品数量',
    拉取三方店铺商品: '拉取三方店铺商品',
    拉取三方店铺订单: '拉取三方店铺订单',
    三方店铺: '三方店铺',
    请选择三方店铺: '请选择三方店铺',
    拉取: '拉取',
    当前快递单号为: '当前快递单号为',
    下单成功: '下单成功',
    保存并打印: '保存并打印',
    请输入订单号: '请输入订单号',
    客户信息: '客户信息',
    智能填写: '智能填写',
    '粘贴收货信息到此处，将自动识别姓名/电话/地址':
      '粘贴收货信息到此处，将自动识别姓名/电话/地址',
    客户电话: '客户电话',
    请输入客户电话: '请输入客户电话',
    已存在客户: '已存在客户',
    '是否使用当前客户信息？': '是否使用当前客户信息？',
    客户姓名: '客户姓名',
    请输入客户姓名: '请输入客户姓名',
    收货地址: '收货地址',
    请输入收货地址: '请输入收货地址',
    销售点: '销售点',
    请选择销售点: '请选择销售点',
    付款信息: '付款信息',
    是否已付款: '是否已付款',
    '您的报价产品中有低于最低售价的价格，请检查！':
      '您的报价产品中有低于最低售价的价格，请检查！',
    线索: '线索',
    国家地区: '国家地区',
    请输入客户感兴趣产品: '请输入客户感兴趣产品',
    是否KA: '是否KA',
    请输入客户网址: '请输入客户网址',
    请输入客户地区: '请输入客户地区',
    请输入客户折扣百分比: '请输入客户折扣百分比',
    请输入比分比: '请输入比分比',
    请选择线索阶段: '请选择线索阶段',
    请输入收件人: '请输入收件人',
    请输入收件电话: '请输入收件电话',
    请输入收件地址: '请输入收件地址',
    请选择渠道类型: '请选择渠道类型',
    渠道分类: '渠道分类',
    请选择渠道分类: '请选择渠道分类',
    快递账号: '快递账号',
    请选择快递账号: '请选择快递账号',
    系统配置: '系统配置',
    快递账号配置: '快递账号配置',
    中添加: '中添加',
    请输入采购单单号: '请输入采购单单号',
    账期: '账期',
    添加付款明细: '添加付款明细',
    请输入昵称: '请输入昵称',
    密码为手机号后六位: '密码为手机号后六位',
    请选择部门: '请选择部门',
    供应商账号: '供应商账号',
    关联供应商: '关联供应商',
    入库预报: '入库预报',
    请选择配送方式: '请选择配送方式',
    交货时间: '交货时间',
    收货人姓名: '收货人姓名',
    请输入收货人姓名: '请输入收货人姓名',
    收货人电话: '收货人电话',
    请输入收货人电话: '请输入收货人电话',
    收货人邮箱: '收货人邮箱',
    请输入收货人邮箱: '请输入收货人邮箱',
    收货人地址: '收货人地址',
    请输入收货人地址: '请输入收货人地址',
    请输入城市: '请输入城市',
    州: '州',
    请输入州: '请输入州',
    请选择国家: '请选择国家',
    请输入邮编: '请输入邮编',
    请输入包装要求: '请输入包装要求',
    寄件人: '寄件人',
    请选择寄件人信息: '请选择寄件人信息',
    请选择签收选项: '请选择签收选项',
    请选择运输保险: '请选择运输保险',
    商品代发单: '商品代发单',
    快递索赔单: '快递索赔单',
    商品退货单: '商品退货单',
    请输入快递单号: '请输入快递单号',
    请选择处理方式: '请选择处理方式',
    将收取一定的费用: '将收取一定的费用',
    请输入备注信息: '请输入备注信息',
    '退货拍照(拆开)': '退货拍照(拆开)',
    '退货拍照(外观)': '退货拍照(外观)',
    提交后将会通知供货商: '提交后将会通知供货商',
    选择处理方式: '选择处理方式',
    处理费用: '处理费用',
    请输入处理费用: '请输入处理费用',
    商品快递索赔: '商品快递索赔',
    请输入货品价值: '请输入货品价值',
    请输入索赔原因: '请输入索赔原因',
    结果: '结果',
    请选择结果: '请选择结果',
    请输入费用: '请输入费用',
    三方发货: '三方发货',
    多个用英文逗号隔开: '多个用英文逗号隔开',
    等: '等',
    件产品: '件产品',
    请选择发货计划: '请选择发货计划',
    收件人信息: '收件人信息',
    收件人姓名: '收件人姓名',
    请输入收件人姓名: '请输入收件人姓名',
    收件人手机号: '收件人手机号',
    请输入收件人手机号: '请输入收件人手机号',
    收件人地址: '收件人地址',
    请输入收件人地址: '请输入收件人地址',
    省市区县街道: '省市区县街道',
    快捷选择寄件人信息: '快捷选择寄件人信息',
    更多快捷寄件人信息请到: '更多快捷寄件人信息请到',
    发货地址: '发货地址',
    寄件人姓名: '寄件人姓名',
    请输入寄件人姓名: '请输入寄件人姓名',
    寄件人手机号: '寄件人手机号',
    请输入寄件人手机号: '请输入寄件人手机号',
    寄件人地址: '寄件人地址',
    请输入寄件人地址: '请输入寄件人地址',
    快递信息: '快递信息',
    请选择快递公司: '请选择快递公司',
    物品名称: '物品名称',
    请输入物品名称: '请输入物品名称',
    支付方式: '支付方式',
    请选择支付方式: '请选择支付方式',
    '物品总重量(Kg)': '物品总重量(Kg)',
    请输入物品总重量: '请输入物品总重量',
    无需单位: '无需单位',
    寄件: '寄件',
    '下单中...': '下单中...',
    立即打印面单: '立即打印面单',
    打印机: '打印机',
    请选择打印机: '请选择打印机',
    快递配置: '快递配置',
    请选择快递配置: '请选择快递配置',
    请选择委外订单: '请选择委外订单',
    请输入产品名: '请输入产品名',
    名片: '名片',
    是否固定: '是否固定',
    默认排序: '默认排序',
    排序方式: '排序方式',
    设置默认排序字段: '设置默认排序字段',
    请输入HSCODE: '请输入HSCODE',
    别名: '别名',
    '请输入别名,多个用英文逗号隔开': '请输入别名,多个用英文逗号隔开',
    销售渠道: '销售渠道',
    推广渠道: '推广渠道',
    关联店铺: '关联店铺',
    请选择关联店铺: '请选择关联店铺',
    请输入渠道名称: '请输入渠道名称',
    快速调价: '快速调价',
    当前修改影响: '当前修改影响',
    '条数据,总价': '条数据,总价',
    '是否继续修改?': '是否继续修改?',
    新购产品按建议价格: '新购产品按建议价格',
    请输入倍率: '请输入倍率',
    倍调价: '倍调价',
    复购产品按历史底价: '复购产品按历史底价',
    所有产品按当前价格: '所有产品按当前价格',
    打印模版: '打印模版',
    硬件设备: '硬件设备',
    购买打印机: '购买打印机',
    飞鹅云标签打印机: '飞鹅云标签打印机',
    佳博云标签打印机: '佳博云标签打印机',
    SN编码: 'SN编码',
    请输入SN编码: '请输入SN编码',
    是否默认: '是否默认',
    '标签纸长(mm)': '标签纸长(mm)',
    请输入标签纸长度: '请输入标签纸长度',
    '标签纸宽(mm)': '标签纸宽(mm)',
    请输入标签纸宽度: '请输入标签纸宽度',
    模本内容: '模本内容',
    请输入模本内容: '请输入模本内容',
    缩略图: '缩略图',
    最大打印宽度: '最大打印宽度',
    购买: '购买',
    打印标签: '打印标签',
    请选择设备: '请选择设备',
    请选择打印模版: '请选择打印模版',
    请输入打印数量: '请输入打印数量',
    请输入寄件人: '请输入寄件人',
    寄件人电话: '寄件人电话',
    请输入寄件人电话: '请输入寄件人电话',
    寄件人邮箱: '寄件人邮箱',
    请输入寄件人邮箱: '请输入寄件人邮箱',
    寄件地址: '寄件地址',
    请输入寄件地址: '请输入寄件地址',
    默认地址: '默认地址',
    费用变更: '费用变更',
    费用方式: '费用方式',
    费用类型: '费用类型',
    请选择费用类型: '请选择费用类型',
    请输入金额: '请输入金额',
    请输入文件名称: '请输入文件名称',
    来源链接: '来源链接',
    请输入网页来源链接: '请输入网页来源链接',
    更换产品: '更换产品',
    更换的产品: '更换的产品',
    名称备注: '名称备注',
    请输入菜单名称备注: '请输入菜单名称备注',
    是否显示PC: '是否显示PC',
    是否显示移动端: '是否显示移动端',
    请输入token: '请输入token',
    征收比例: '征收比例',
    快递费用征收比例: '快递费用征收比例',
    请输入比例: '请输入比例',
    请选择版本号: '请选择版本号',
    用量: '用量',
    请输入用量: '请输入用量',
    损耗率: '损耗率',
    请输入损耗率: '请输入损耗率',
    物料BOM: '物料BOM',
    请选择物料BOM: '请选择物料BOM',
    请输入单位: '请输入单位',
    请输入单价: '请输入单价',
    时长: '时长',
    请输入加工时长: '请输入加工时长',
    是否质检: '是否质检',
    请输入工序描述: '请输入工序描述',
    请选择生产类型: '请选择生产类型',
    请选择订单: '请选择订单',
    请选择产品BOM: '请选择产品BOM',
    请选择生产优先级: '请选择生产优先级',
    请输入交货日期: '请输入交货日期',
    请输入计划开工日期: '请输入计划开工日期',
    请输入计划完工日期: '请输入计划完工日期',
    请输入生产批次号: '请输入生产批次号',
    委外工序: '委外工序',
    请选择生产状态: '请选择生产状态',
    请选择生产计划: '请选择生产计划',
    生产订单: '生产订单',
    请选择生产订单: '请选择生产订单',
    请选择执行工序: '请选择执行工序',
    请选择委外工序: '请选择委外工序',
    生产需求: '生产需求',
    请选择生产需求: '请选择生产需求',
    请选择产品工艺: '请选择产品工艺',
    请输入委外数量: '请输入委外数量',
    请输入计划生产数量: '请输入计划生产数量',
    请输入委外单价: '请输入委外单价',
    请选择计划开工日期: '请选择计划开工日期',
    请选择委外日期: '请选择委外日期',
    请选择计划完工日期: '请选择计划完工日期',
    '请搜索/选择负责人': '请搜索/选择负责人',
    请选择生产工单: '请选择生产工单',
    产品工艺: '产品工艺',
    请选择工序执行人: '请选择工序执行人',
    '请搜索/选择工序执行人': '请搜索/选择工序执行人',
    请输入生产数量: '请输入生产数量',
    请选择生产人员: '请选择生产人员',
    '请搜索/选择生产人员': '请搜索/选择生产人员',
    请选择报工日期: '请选择报工日期',
    质检数量: '质检数量',
    请输入质检数量: '请输入质检数量',
    请输入入库数量: '请输入入库数量',
    质检: '质检',
    请输入报废数量: '请输入报废数量',
    请输入返工数量: '请输入返工数量',
    请输入良品数量: '请输入良品数量',
    请输入问题描述: '请输入问题描述',
    类别: '类别',
    请选择申请人: '请选择申请人',
    请选择关联领料单: '请选择关联领料单',
    服务商: '服务商',
    请选择快递服务商: '请选择快递服务商',
    月结号: '月结号',
    请输入月结号: '请输入月结号',
    添加快递信息: '添加快递信息',
    选择香港仓库: '选择香港仓库',
    选择仓库: '选择仓库',
    选择发货时间及司机: '选择发货时间及司机',
    司机: '司机',
    请选择司机: '请选择司机',
    '请搜索/选择司机': '请搜索/选择司机',
    请选择发货日期: '请选择发货日期',
    上门时间: '上门时间',
    截止时间: '截止时间',
    更改区域: '更改区域',
    区域: '区域',
    请选择区域: '请选择区域',
    shopify店铺: 'shopify店铺',
    店铺名: '店铺名',
    请输入shopify店铺名: '请输入shopify店铺名',
    请输入店铺名: '请输入店铺名',
    请输入access: '请输入access',
    请输入app: '请输入app',
    对账明细: '对账明细',
    请输入日期: '请输入日期',
    生产设备: '生产设备',
    请输入设备名称: '请输入设备名称',
    请选择设备类型: '请选择设备类型',
    请输入设备编码SN: '请输入设备编码SN',
    请输入设备型号: '请输入设备型号',
    请输入长度: '请输入长度',
    请输入宽度: '请输入宽度',
    请输入高度: '请输入高度',
    请选择采购日期: '请选择采购日期',
    请选择投产日期: '请选择投产日期',
    请输入采购价格: '请输入采购价格',
    采购币种: '采购币种',
    请输入生产厂家: '请输入生产厂家',
    请选择生产日期: '请选择生产日期',
    请输入用途: '请输入用途',
    发货: '发货',
    同步发货状态: '同步发货状态',
    行: '行',
    请输入行: '请输入行',
    请输入子仓库行数: '请输入子仓库行数',
    列: '列',
    请输入列: '请输入列',
    请输入子仓库列数: '请输入子仓库列数',
    子仓库数量不能超过: '子仓库数量不能超过',
    个: '个',
    新增仓库: '新增仓库',
    '请搜索/选择产品': '请搜索/选择产品',
    '请搜索/选择创建人': '请搜索/选择创建人',
    编辑人: '编辑人',
    '请搜索/选择最后编辑人': '请搜索/选择最后编辑人',
    '请搜索/选择供应商': '请搜索/选择供应商',
    请输入价格: '请输入价格',
    请选择委外状态: '请选择委外状态',
    请选择工序状态: '请选择工序状态',
    店铺: '店铺',
    关联状态: '关联状态',
    请选择关联状态: '请选择关联状态',
    未关联: '未关联',
    已关联: '已关联',
    面单模板: '面单模板',
    请选择电子面单模板: '请选择电子面单模板',
    打印类型: '打印类型',
    请选择打印类型: '请选择打印类型',
    只下单不打印: '只下单不打印',
    生成图片短链: '生成图片短链',
    快递: '快递',
    云打印机: '云打印机',
    生成html短链: '生成html短链',
    '请搜索/选择客户来源': '请搜索/选择客户来源',
    '请搜索/选择销售点': '请搜索/选择销售点',
    货物状态: '货物状态',
    请选择货物状态: '请选择货物状态',
    '请搜索/选择客户': '请搜索/选择客户',
    请选择账期日期: '请选择账期日期',
    账期类型: '账期类型',
    请选择账期类型: '请选择账期类型',
    '产品编码/名称关键字': '产品编码/名称关键字',
    '輸入產品/單號/手機': '輸入產品/單號/手機',
    请选择数据权限: '请选择数据权限',
    '请搜索/选择渠道名称': '请搜索/选择渠道名称',
    '请搜索/选择产品分类': '请搜索/选择产品分类',
    '请搜索/选择部件': '请搜索/选择部件',
    '请搜索/选择产品货源': '请搜索/选择产品货源',
    '请搜索/选择产品上下架状态': '请搜索/选择产品上下架状态',
    请选择客户类型: '请选择客户类型',
    '请搜索/选择客户等级': '请搜索/选择客户等级',
    '请搜索/选择客户行业': '请搜索/选择客户行业',
    请输入感兴趣的产品: '请输入感兴趣的产品',
    线索状态: '线索状态',
    请选择线索状态: '请选择线索状态',
    '名称/单号/关键词': '名称/单号/关键词',
    请选择计划日期: '请选择计划日期',
    '请搜索/选择业务员': '请搜索/选择业务员',
    退货状态: '退货状态',
    请选择退货状态: '请选择退货状态',
    '请搜索/选择渠道': '请搜索/选择渠道',
    '请搜索/选择渠道类型': '请搜索/选择渠道类型',
    图册名称: '图册名称',
    请选择图册名称: '请选择图册名称',
    '请输入编码/选择产品': '请输入编码/选择产品',
    请选择在仓状态: '请选择在仓状态',
    服务器正在升级中: '服务器正在升级中',
    '响应超时，请稍后再试': '响应超时，请稍后再试',
    线索详情: '线索详情',
    导出明细: '导出明细',
    客户产品: '客户产品',
    线索等级: '线索等级',
    '您确定要将当前线索转化为客户吗？': '您确定要将当前线索转化为客户吗？',
    '您确定要将当前线索释放发到公海吗？': '您确定要将当前线索释放发到公海吗？',
    '您确定要领取当前线索？': '您确定要领取当前线索？',
    分配线索: '分配线索',
    是否为负责人: '是否为负责人',
    天成交单数: '天成交单数',
    天成交金额: '天成交金额',
    客户评分: '客户评分',
    感兴趣的产品: '感兴趣的产品',
    折扣: '折扣',
    最后下单时间: '最后下单时间',
    生成账单: '生成账单',
    设备详情: '设备详情',
    充值记录: '充值记录',
    充值成功: '充值成功',
    充值失败: '充值失败',
    充值金额: '充值金额',
    充值状态: '充值状态',
    充值账户: '充值账户',
    充值时间: '充值时间',
    物料: '物料',
    可用库存: '可用库存',
    所属BOM: '所属BOM',
    BOM详情: 'BOM详情',
    清单明细: '清单明细',
    委外详情: '委外详情',
    生成领料单: '生成领料单',
    退料单详情: '退料单详情',
    领料单详情: '领料单详情',
    物料明细: '物料明细',
    实际库存: '实际库存',
    申请数量: '申请数量',
    入库单: '入库单',
    物料类型: '物料类型',
    分析结果: '分析结果',
    已生成采购计划: '已生成采购计划',
    未生成采购计划: '未生成采购计划',
    所需用量: '所需用量',
    净需求: '净需求',
    待采购数量: '待采购数量',
    已采购入库数量: '已采购入库数量',
    待申请物料数量: '待申请物料数量',
    已领料出库数量: '已领料出库数量',
    已退料数量: '已退料数量',
    待安排生产: '待安排生产',
    已安排生产: '已安排生产',
    待安排委外: '待安排委外',
    已生产数量: '已生产数量',
    '您确定要对当前数据生成采购计划？': '您确定要对当前数据生成采购计划？',
    生产工单详情: '生产工单详情',
    '您确定要对当前数据预生成工序？': '您确定要对当前数据预生成工序？',
    '您确定要对当前数据预生成入库单？': '您确定要对当前数据预生成入库单？',
    需求物料: '需求物料',
    生产计划详情: '生产计划详情',
    '您确定要生成物料需求清单？': '您确定要生成物料需求清单？',
    生产概览: '生产概览',
    生产工序详情: '生产工序详情',
    报工单详情: '报工单详情',
    工序号: '工序号',
    完工数量: '完工数量',
    请先选择工序执行人: '请先选择工序执行人',
    '您确定要开始当前工序？': '您确定要开始当前工序？',
    分配执行人: '分配执行人',
    委外生产: '委外生产',
    质检详情: '质检详情',
    质检明细: '质检明细',
    质检单号: '质检单号',
    质检类型: '质检类型',
    质检申请人: '质检申请人',
    质检人: '质检人',
    质检时间: '质检时间',
    到货数量: '到货数量',
    工序详情: '工序详情',
    工艺详情: '工艺详情',
    工艺明细: '工艺明细',
    工序单位: '工序单位',
    工序负责人: '工序负责人',
    工序编码: '工序编码',
    回款渠道: '回款渠道',
    对账单详情: '对账单详情',
    账期日期: '账期日期',
    当前欠款: '当前欠款',
    账单产品明细: '账单产品明细',
    总计回款: '总计回款',
    当前订单金额: '当前订单金额',
    当前已付金额: '当前已付金额',
    '您确定要对当前数据重新生成对账单吗？':
      '您确定要对当前数据重新生成对账单吗？',
    客户生成账单: '客户生成账单',
    供应商生成账单: '供应商生成账单',
    已付款: '已付款',
    未付款: '未付款',
    已开票: '已开票',
    未开票: '未开票',
    客户数量: '客户数量',
    客户渠道: '客户渠道',
    订单渠道: '订单渠道',
    产品及供应链统计: '产品及供应链统计',
    编辑产品: '编辑产品',
    新增供应链: '新增供应链',
    KP资料不详细: 'KP资料不详细',
    周新增数据: '周新增数据',
    客户数据统计: '客户数据统计',
    产品数据统计: '产品数据统计',
    更新产品: '更新产品',
    KP供应链不足: 'KP供应链不足',
    '请输入手机号/邮箱': '请输入手机号/邮箱',
    关联单号: '关联单号',
    变更类型: '变更类型',
    变更金额: '变更金额',
    变更前: '变更前',
    变更后: '变更后',
    变更时间: '变更时间',
    导出模块: '导出模块',
    导出名称: '导出名称',
    请选择导出模块: '请选择导出模块',
    仓储体积详情: '仓储体积详情',
    客户数: '客户数',
    订单数: '订单数',
    渠道详情: '渠道详情',
    关联产品: '关联产品',
    生成链接: '生成链接',
    发布商品: '发布商品',
    请输入关键词搜索分类: '请输入关键词搜索分类',
    售价: '售价',
    '您确定要对当前产品更新到阿里巴巴？': '您确定要对当前产品更新到阿里巴巴？',
    '净品规格的默认箱规将作为品规在列表展示，外箱规格的默认箱规将作为箱规在列表展示':
      '净品规格的默认箱规将作为品规在列表展示，外箱规格的默认箱规将作为箱规在列表展示',
    请输入箱规名称: '请输入箱规名称',
    请输入体积: '请输入体积',
    非默认箱规: '非默认箱规',
    非费用箱规: '非费用箱规',
    '体积/净重-毛重/长-宽-高': '体积/净重-毛重/长-宽-高',
    图文详情: '图文详情',
    查看价格策略: '查看价格策略',
    查看历史成交: '查看历史成交',
    渠道管理: '渠道管理',
    箱规管理: '箱规管理',
    应用渠道: '应用渠道',
    图片使用: '图片使用',
    覆盖: '覆盖',
    追加: '追加',
    继续: '继续',
    选择应用渠道: '选择应用渠道',
    图册搜索: '图册搜索',
    页: '页',
    图册上传: '图册上传',
    建议价格: '建议价格',
    天销量: '天销量',
    所属仓库: '所属仓库',
    渠道数量: '渠道数量',
    选择客户: '选择客户',
    选择模板: '选择模板',
    若选择客户则会导出该客户关联模版信息:
      '若选择客户则会导出该客户关联模版信息',
    '为保证预览体验，当前仅展示最多': '为保证预览体验，当前仅展示最多',
    '条数据，点击': '条数据，点击',
    即可下载完整Excel数据: '即可下载完整Excel数据',
    电铺类型: '电铺类型',
    电铺名称: '电铺名称',
    '您确定要发布当前产品?': '您确定要发布当前产品?',
    绑定产品: '绑定产品',
    照片: '照片',
    关键字: '关键字',
    网页: '网页',
    页数: '页数',
    EXW币种: 'EXW币种',
    FOB币种: 'FOB币种',
    网站: '网站',
    双击可编辑: '双击可编辑',
    自动上传: '自动上传',
    '部分产品报价高于采购单价，是否确定要审核通过？':
      '部分产品报价高于采购单价，是否确定要审核通过？',
    请选择供货商: '请选择供货商',
    请输入包件编码: '请输入包件编码',
    请输入包件数量: '请输入包件数量',
    请输入物流单号: '请输入物流单号',
    厂商: '厂商',
    产品名: '产品名',
    '规格/材质': '规格/材质',
    备货产品: '备货产品',
    付款情况: '付款情况',
    库存情况: '库存情况',
    '您确定要对当前数据付款？操作后状态将会流转':
      '您确定要对当前数据付款？操作后状态将会流转',
    '您确定要对当前数据所有包裹确认收货？':
      '您确定要对当前数据所有包裹确认收货？',
    '您确定要对当前数据发往香港仓？': '您确定要对当前数据发往香港仓？',
    '确认所有包裹已上车？': '确认所有包裹已上车？',
    包件编码: '包件编码',
    包件数量: '包件数量',
    物流单号: '物流单号',
    包裹状态: '包裹状态',
    最近下单时间: '最近下单时间',
    包含数量: '包含数量',
    请选择地址: '请选择地址',
    适用范围: '适用范围',
    派送费用: '派送费用',
    基础运价: '基础运价',
    体积比: '体积比',
    家庭地址费: '家庭地址费',
    '目的地若为家庭地址，则收取该费用': '目的地若为家庭地址，则收取该费用',
    偏远地区费: '偏远地区费',
    '目的地邮编若在Fedex偏远地区清单中，则收取该费用':
      '目的地邮编若在Fedex偏远地区清单中，则收取该费用',
    特殊处理费: '特殊处理费',
    产品实重: '产品实重',
    磅: '磅',
    包装: '包装',
    '满足以下任一条件，则收取该费用': '满足以下任一条件，则收取该费用',
    产品没有完全由外包装箱包装起来: '产品没有完全由外包装箱包装起来',
    外包装由缠绕膜外包: '外包装由缠绕膜外包',
    未使用纸箱包装: '未使用纸箱包装',
    包装箱外形不是方形的: '包装箱外形不是方形的',
    尺寸: '尺寸',
    最长边: '最长边',
    英寸: '英寸',
    次长边: '次长边',
    '最短边）': '最短边）',
    英寸且: '英寸且',
    超标费: '超标费',
    '产生超标费后，基础运价按实际重量，体积重和':
      '产生超标费后，基础运价按实际重量，体积重和',
    磅三者中最大者计费: '磅三者中最大者计费',
    用Fedex: '用Fedex',
    Ground发货: 'Ground发货',
    '最短边）大于': '最短边）大于',
    英寸且小于等于: '英寸且小于等于',
    最长边大于: '最长边大于',
    Delivery发货: 'Delivery发货',
    未授权发货费: '未授权发货费',
    计价重大于: '计价重大于',
    签收费: '签收费',
    地址更正费: '地址更正费',
    '由于地址不正确，Fedex会设法寻找正确的地址以完成送货':
      '由于地址不正确，Fedex会设法寻找正确的地址以完成送货',
    '应发货方要求，更改送货地址': '应发货方要求，更改送货地址',
    地址更改服务费: '地址更改服务费',
    '应发货方要求，更改地址或者召回货物服务费':
      '应发货方要求，更改地址或者召回货物服务费',
    燃油附加费: '燃油附加费',
    '根据官方燃油附加费率，按百分比收取': '根据官方燃油附加费率，按百分比收取',
    适用于超大或超重件运输: '适用于超大或超重件运输',
    '每次发货前，可免费询价': '每次发货前，可免费询价',
    体积重: '体积重',
    '高/体积比': '高/体积比',
    计价重量为体积重和实际重量中数值较大者:
      '计价重量为体积重和实际重量中数值较大者',
    '特殊处理费，超标费及未授权发货费三项，不重复收费，只收取最贵的一项':
      '特殊处理费，超标费及未授权发货费三项，不重复收费，只收取最贵的一项',
    '所有尺寸，重量均按英制计算': '所有尺寸，重量均按英制计算',
    千克: '千克',
    '所有尺寸，重量均向上取整': '所有尺寸，重量均向上取整',
    所有货币单位均为美元: '所有货币单位均为美元',
    '如Fedex价格变动，报价将进行相应变动，我司将另行通知':
      '如Fedex价格变动，报价将进行相应变动，我司将另行通知',
    '此报价不含旺季附加费，旺季附加费按官网价收取':
      '此报价不含旺季附加费，旺季附加费按官网价收取',
    旺季附加费收费时间段以Fedex官网收费时间段为准:
      '旺季附加费收费时间段以Fedex官网收费时间段为准',
    收费重量尺寸以Fedex实际账单为准: '收费重量尺寸以Fedex实际账单为准',
    计价重量计算方式: '计价重量计算方式',
    见拣货费价格: '见拣货费价格',
    今日计划详情: '今日计划详情',
    快递详情: '快递详情',
    物流信息: '物流信息',
    查看运费: '查看运费',
    快递服务商: '快递服务商',
    个工作日: '个工作日',
    含: '含',
    免费追踪: '免费追踪',
    选择快递: '选择快递',
    请完善包裹信息: '请完善包裹信息',
    '您确定选择当前快递服务商并支付费用?':
      '您确定选择当前快递服务商并支付费用?',
    运费预估: '运费预估',
    关联发货计划: '关联发货计划',
    物品体积: '物品体积',
    物品总重量: '物品总重量',
    您确定要对当前数据进行寄件: '您确定要对当前数据进行寄件',
    您确定要对当前数据进行取消寄件: '您确定要对当前数据进行取消寄件',
    选择面单: '选择面单',
    选择打印机: '选择打印机',
    费用预估为: '费用预估为',
    费用预估: '费用预估',
    '您确定要对当前产品发往香港仓？': '您确定要对当前产品发往香港仓？',
    发货单详情: '发货单详情',
    文本记录: '文本记录',
    '规格/尺寸': '规格/尺寸',
    发货仓: '发货仓',
    包件备注: '包件备注',
    行程安排: '行程安排',
    送货员: '送货员',
    '确认取消包裹派送？': '确认取消包裹派送？',
    '确认包裹客户已接收？': '确认包裹客户已接收？',
    '确认包裹要回仓？': '确认包裹要回仓？',
    未安排: '未安排',
    库存状况: '库存状况',
    采购情况: '采购情况',
    确认收货: '确认收货',
    发往香港仓: '发往香港仓',
    确认上车: '确认上车',
    您确定对所选数据进行全部: '您确定对所选数据进行全部',
    '操作吗？': '操作吗？',
    采购中: '采购中',
    已售待送: '已售待送',
    收货单详情: '收货单详情',
    '您确定要签收入库？': '您确定要签收入库？',
    供货商: '供货商',
    到达时间: '到达时间',
    出入库单号: '出入库单号',
    仓位: '仓位',
    '总体积(m³)': '总体积(m³)',
    添加行: '添加行',
    添加列: '添加列',
    拆分为表格: '拆分为表格',
    当前共有: '当前共有',
    '条数据，请输入要拆分的行数': '条数据，请输入要拆分的行数',
    拆分的行数: '拆分的行数',
    请输入要拆分的行数: '请输入要拆分的行数',
    商品快递索赔详情: '商品快递索赔详情',
    请选择商品代发单: '请选择商品代发单',
    商品退货详情: '商品退货详情',
    商品代发详情: '商品代发详情',
    '您确定要通知仓库发货？': '您确定要通知仓库发货？',
    请完善产品仓库信息: '请完善产品仓库信息',
    入库预报详情: '入库预报详情',
    '您确定要发货？': '您确定要发货？',
    索赔原因: '索赔原因',
    '您确定要对当前数据进行退货处理?': '您确定要对当前数据进行退货处理?',
    发货模版: '发货模版',
    智能识别: '智能识别',
    您确定要对当前订单进行作废: '您确定要对当前订单进行作废',
    '部分产品报价低于最低售价确定要审核通过？':
      '部分产品报价低于最低售价确定要审核通过？',
    预设报价: '预设报价',
    是否发货: '是否发货',
    '您确定要取消当前订单寄件？': '您确定要取消当前订单寄件？',
    '您确定要清除当前订单的关联信息？清除后可以重新添加产品':
      '您确定要清除当前订单的关联信息？清除后可以重新添加产品',
    VIP类型: 'VIP类型',
    商店域名: '商店域名',
    请输入商店域名: '请输入商店域名',
    请选择VIP类型: '请选择VIP类型',
    选项值: '选项值',
    面单标题: '面单标题',
    网点: '网点',
    快递员: '快递员',
    面单编码: '面单编码',
    面单模版: '面单模版',
    绑定打印机: '绑定打印机',
    秘钥: '秘钥',
    校验码: '校验码',
    打印机类型: '打印机类型',
    在线状态: '在线状态',
    模版图: '模版图',
    模版标题: '模版标题',
    '打印纸尺寸(mm)': '打印纸尺寸(mm)',
    快递面单模版: '快递面单模版',
    排序字段: '排序字段',
    '您确定要对当前数据进行同步翻译？': '您确定要对当前数据进行同步翻译？',
    请输入code编码: '请输入code编码',
    请输入汇率: '请输入汇率',
    销售单号规则: '销售单号规则',
    采购单号规则: '采购单号规则',
    使用币种: '使用币种',
    默认汇率: '默认汇率',
    开启供应商仓储报表: '开启供应商仓储报表',
    默认币种: '默认币种',
    费用征收比例: '费用征收比例',
    请输入merchant: '请输入merchant',
    秘钥Key: '秘钥Key',
    请输入秘钥Key: '请输入秘钥Key',
    不允许使用特殊符号: '不允许使用特殊符号',
    公钥Key: '公钥Key',
    请输入公钥Key: '请输入公钥Key',
    字段控制: '字段控制',
    stripe支付配置: 'stripe支付配置',
    乐刷支付配置: '乐刷支付配置',
    使用次数: '使用次数',
    已授权: '已授权',
    未授权: '未授权',
    扫码授权: '扫码授权',
    授权状态: '授权状态',
    token过期时间: 'token过期时间',
    昨日销量: '昨日销量',
    店铺扫码授权: '店铺扫码授权',
    三方渠道: '三方渠道',
    '您确定生成全部任务？': '您确定生成全部任务？',
  },
}
