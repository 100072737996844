<!--
 * @Author: WGL
 * @Date: 2022-09-06 09:57:18
 * @LastEditors: WGL
 * @LastEditTime: 2024-04-26 11:44:22
 * @Description: 
-->
<template>
  <jwt-image
    v-if="src"
    :fill-url="handleFillUrl"
    :fit="fit"
    :multiple="multiple"
    :src="src"
    :type="type"
  />
  <template v-else>
    <div v-if="emptyImg" class="base-image-mask pt5">
      <jwt-icon icon="image-line g9" size="36" />
    </div>
    <div v-else class="g6">{{ t('暂无信息~') }}</div>
  </template>
</template>

<script>
  import { defineComponent } from 'vue'
  import { fillUrl } from '@/common/js/utils'
  import { t } from '@/utils/i18n'

  export default defineComponent({
    name: 'BaseImage',
    props: {
      fit: { type: String, default: 'cover' },
      src: { type: String, default: '' },
      type: { type: String, default: 'search' },
      multiple: { type: Boolean, default: false },
      emptyImg: { type: Boolean, default: false },
    },
    emits: ['table-action'],
    setup() {
      const handleFillUrl = (url, w, h, quality = 50) => {
        return fillUrl(url, w, h, quality)
      }
      return {
        fillUrl,
        handleFillUrl,
        t,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
