<!--
 * @Author: WGL
 * @Date: 2023-05-30 11:04:47
 * @LastEditors: WGL
 * @LastEditTime: 2023-11-16 10:12:23
 * @Description: 
-->
<template>
  <jwt-des-header
    :border="border"
    :tips="t(tips)"
    :title="t(title)"
    :translate-title="t"
  >
    <template #left>
      <slot name="left"></slot>
    </template>
    <slot></slot>
  </jwt-des-header>
</template>

<script>
  import { defineComponent } from 'vue'
  import { t } from '@/utils/i18n'
  export default defineComponent({
    name: 'DesHeader',
    props: {
      title: { type: String, default: '' },
      tips: { type: String, default: '' },
      border: { type: Boolean, default: false },
    },
    setup() {
      return {
        t,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .des-header-title {
    color: var(--el-text-color-primary);
    font-size: 16px;
    font-weight: bold;
    padding-left: 10px;
    border-left: 5px solid var(--el-color-primary);
    margin-bottom: 16px;
  }
</style>
