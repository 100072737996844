<template>
  <el-menu-item class="fxmj" :index="itemOrMenu.path" @click="handleLink">
    <vab-icon
      v-if="itemOrMenu.meta && itemOrMenu.meta.icon"
      :icon="itemOrMenu.meta.icon"
      :is-custom-svg="itemOrMenu.meta.isCustomSvg"
      style="padding-right: 5px"
      :title="menuTitle"
    />
    <span class="ex" :title="menuTitle">
      {{ menuTitle }}
    </span>
    <el-tag
      v-if="itemOrMenu.meta && itemOrMenu.meta.badge"
      effect="dark"
      type="danger"
    >
      {{ itemOrMenu.meta.badge }}
    </el-tag>
    <span
      v-if="itemOrMenu.meta && itemOrMenu.meta.dot"
      class="vab-dot vab-dot-error"
    >
      <span />
    </span>
  </el-menu-item>
</template>

<script>
  import { computed, defineComponent, getCurrentInstance } from 'vue'
  import { mapActions, useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import { isExternal } from '@/utils/validate'
  import { translateTitle } from '@/utils/i18n'
  import { isHashRouterMode } from '@/config'

  export default defineComponent({
    name: 'VabMenuItem',
    props: {
      itemOrMenu: {
        type: Object,
        default() {
          return null
        },
      },
    },
    setup(props) {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()

      const device = computed(() => store.getters['settings/device'])

      const menuTitle = computed(() => {
        const env = process.env.NODE_ENV
        let result =
          env === 'development'
            ? props.itemOrMenu.meta.remark
            : props.itemOrMenu.meta.title
        return translateTitle(result)
      })
      const { proxy } = getCurrentInstance()

      const handleLink = () => {
        if (props.itemOrMenu?.meta?.badge) {
          store.dispatch('sys/handleClearUserMenuBadge', props.itemOrMenu.id)
        }
        const routePath = props.itemOrMenu.path
        const target = props.itemOrMenu.meta.target
        if (target === '_blank') {
          if (isExternal(routePath)) window.open(routePath)
          else if (route.path !== routePath)
            isHashRouterMode
              ? window.open('/#' + routePath)
              : window.open(routePath)
        } else {
          if (isExternal(routePath)) window.location.href = routePath
          else if (route.path !== routePath) {
            if (device.value === 'mobile') proxy.foldSideBar()
            console.log('props.itemOrMenu.path :>> ', props.itemOrMenu.path)
            router.push(props.itemOrMenu.path)
          } else proxy.$pub('reload-router-view')
        }
      }

      return {
        ...mapActions({
          foldSideBar: 'settings/foldSideBar',
        }),
        handleLink,
        menuTitle,
        translateTitle,
      }
    },
  })
</script>

<style lang="scss" scoped>
  :deep() {
    .el-tag {
      float: right;
      height: 16px;
      padding-right: 4px;
      padding-left: 4px;
      // margin-top: #{math.div($base-menu-item-height - 16, 2)};
      line-height: 16px;
      border: 0;
    }
  }

  .vab-dot {
    float: right;
    // margin-top: #{math.div($base-menu-item-height - 6, 2)};
  }
</style>
