<template>
  <el-dialog
    v-model="visible"
    append-to-body
    center
    destroy-on-close
    width="1000px"
  >
    <div class="system-user-container">
      <el-row :gutter="20">
        <el-col :offset="0" :span="12">
          <h3>{{ t('表格模版') }}</h3>
          <base-table :config="tableConfig" table-height="40vh">
            <template #header-button-after="{}">
              <div>
                <el-button
                  class="ml15"
                  type="primary"
                  @click="jump('/setting/templateSetting')"
                >
                  {{ t('去添加') }}
                </el-button>
              </div>
            </template>
            <template #radio="{ scope }">
              <el-radio v-model="selectRadio" :label="scope.id">
                {{ '' }}
              </el-radio>
            </template>
            <template #file="{ scope }">
              <div class="cp cb tdu" @click="showPreview(scope.file_info)">
                {{ scope.title }}
              </div>
            </template>
          </base-table>
        </el-col>
        <el-col :offset="0" :span="12">
          <h3>{{ t('邮件模版') }}</h3>
          <base-table :config="tableConfig1" table-height="40vh">
            <template #header-button-after="{}">
              <div>
                <el-button
                  class="ml15"
                  type="primary"
                  @click="jump('/setting/personalCenter', 'emailTemplate')"
                >
                  {{ t('去添加') }}
                </el-button>
              </div>
            </template>
            <template #radio="{ scope }">
              <el-radio v-model="selectRadio1" :label="scope.id">
                {{ '' }}
              </el-radio>
            </template>
            <template #title="{ scope }">
              <div class="cp cb tdu" @click="showEmailTemplate(scope)">
                {{ scope.title }}
              </div>
            </template>
          </base-table>
        </el-col>
      </el-row>
    </div>
    <Previewer ref="previewerRef" />
    <template #footer>
      <span>
        <el-button @click="visible = false">{{ t('取消') }}</el-button>
        <el-button type="primary" @click="handlePreview(false)">
          {{ t('生成预览') }}
        </el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog v-model="emailTemplateVisible" destroy-on-close width="1000px">
    <RichTextView :content="contentHtml" />
    <template #footer>
      <span>
        <el-button @click="emailTemplateVisible = false">
          {{ t('关闭') }}
        </el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog v-model="emailPreviewVisible" destroy-on-close width="1000px">
    <base-form
      ref="baseForm"
      v-model="formModel"
      :config="baseFormConfig"
      @addSuccess="getAddSuccessData"
    >
      <template #sheet="{ model }">
        <el-form-item :label="t('表格')" prop="sheet">
          <RichTextView :content="model['sheet']" :edit-content="false" />
        </el-form-item>
      </template>
      <template #attachment="{ model }">
        <el-form-item
          v-if="model['attachment']"
          :label="t('附件')"
          prop="attachment"
        >
          <a :href="model['http_path']">{{ model['file_name'] }}</a>
          <!-- <span class="ml15">
            <el-input
              v-model="model['file_name']"
              clearable
              placeholder=""
              
            />
          </span>
          <span>.{{ model['file_extension'] }}</span> -->
          <span class="ml15">
            <el-button @click="model['attachment'] = ''">
              {{ t('取消附件') }}
            </el-button>
          </span>
        </el-form-item>
      </template>
    </base-form>

    <!-- <template #footer>
      <span>
        <el-button @click="emailPreviewVisible = false">关闭</el-button>
        <el-button type="primary">
          {{ t('发送') }}
        </el-button>
      </span>
    </template> -->
  </el-dialog>
</template>

<script>
  import { computed, defineComponent, reactive, ref } from 'vue'
  import { ElLoading, ElMessage } from 'element-plus'
  import request from '@/utils/request'
  // import { download } from '@/common/js/utils'
  import { t } from '@/utils/i18n'
  import { merge } from 'lodash-es'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  const MODULE = 'excel-template' // 模块
  const MODULE1 = 'email-template'

  // 表格数据
  const tableColumns = [
    { prop: 'radio', label: t('单选'), width: 80 },
    {
      label: t('表格模版'),
      prop: 'file',
    },
  ]

  const tableColumns1 = [
    { prop: 'radio', label: t('单选'), width: 80 },
    {
      label: t('邮件模版'),
      prop: 'title',
    },
  ]

  export default defineComponent({
    name: 'EmailBox',
    components: {},
    props: {
      api: {
        type: String,
        default: '',
      },
      params: {
        type: Object,
        default: () => {},
      },
    },
    emits: ['export-success'],
    setup(props, { emit }) {
      const store = useStore()
      const router = useRouter()
      const userInfo = computed(() => store.getters['user/userInfo'])
      const exportParams = computed(() => {
        return props.params
      })
      const selectRadio = ref(null)
      const selectRadio1 = ref(null)
      const visible = ref(false)
      const emailTemplateVisible = ref(false)
      const emailPreviewVisible = ref(false)
      const contentHtml = ref(null)
      const previewerRef = ref(null)
      const formModel = ref(null)
      let tableConfig = reactive({
        headerActions: {
          list: [''],
        },
        tableColumn: tableColumns,
        tableListApi: `/${MODULE}/index`,
        tableListParams: {
          sort: 'id',
          order: 'desc',
        },
        tableAttrs: {
          'highlight-current-row': true,
        },
        tableSearch: {
          formAttrs: {
            inline: true,
          },
          formList: [
            {
              type: 'input',
              key: 'search',
              value: '',
              formAttrs: {
                type: 'text',
                disabled: false,
                placeholder: '搜索',
              },
            },
            {
              type: 'switch',
              key: '__only_me',
              labelAttrs: {
                label: '只看我的',
              },
            },
            {
              type: 'submit',
              submit: {
                title: '搜索',
                attrs: {
                  type: 'primary',
                },
              },
            },
          ],
        },
        tableDeleteParams: {},
        tableButtonNum: 3,
      })

      let tableConfig1 = reactive({
        headerActions: {
          list: [''],
        },
        tableColumn: tableColumns1,
        tableListApi: `/${MODULE1}/index`,
        tableListParams: {
          sort: 'id',
          order: 'desc',
          create_admin_id: userInfo.value.id,
        },
        tableAttrs: {
          'highlight-current-row': true,
        },
        tableSearch: {
          formAttrs: {
            inline: true,
          },
          formList: [
            {
              type: 'input',
              key: 'search',
              value: '',
              formAttrs: {
                type: 'text',
                disabled: false,
                placeholder: '搜索',
              },
            },
            {
              type: 'submit',
              submit: {
                title: '搜索',
                attrs: {
                  type: 'primary',
                },
              },
            },
          ],
        },
        tableDeleteParams: {},
        tableButtonNum: 3,
      })

      let baseFormConfig = reactive({
        api: {
          add: `/email/send`,
          update: `/email/send`,
        },
        formListKey: true, // 参数是formList的key
        formAttrs: {
          labelPosition: 'left',
          labelWidth: '100px',
        },
        formList: [
          {
            type: 'switch',
            key: 'for_self',
            value: 0,
            labelAttrs: {
              label: '先发给自己',
            },
          },
          {
            type: 'input',
            key: 'title',
            value: '',
            labelAttrs: {
              label: '主题',
              rules: [
                {
                  required: true,
                  message: '请输入主题',
                  trigger: 'blur',
                },
              ],
            },
            formAttrs: {
              type: 'text',
              placeholder: '请输入标题',
              clearable: true,
            },
          },
          {
            type: 'selectServer',
            key: 'contact_id',
            labelAttrs: {
              label: '收件人',
            },
            formAttrs: {
              listApi: `contact/index`,
              class: 'w',
              valueType: 'number',
              labelKey: 'email_title',
              exParams: {
                __customer_id: props.params.__customer_id || '',
                __supplier_id: props.params.__supplier_id || '',
              },
              multiple: true,
              placeholder: '请选择收件人',
            },
          },
          {
            type: 'richText',
            key: 'header',
            value: '',
            labelAttrs: {
              label: '头部',
            },
          },
          {
            type: 'slot',
            key: 'sheet',
            name: 'sheet',
          },
          {
            type: 'richText',
            key: 'footer',
            value: '',
            labelAttrs: {
              label: '尾部',
            },
          },
          {
            type: 'slot',
            key: 'attachment',
            name: 'attachment',
          },
          {
            type: 'submit',
            submit: {
              title: '发送',
              attrs: {
                type: 'primary',
              },
            },
          },
        ],
      })

      const showPreview = (row) => {
        previewerRef.value.show(row.file_path, row.extension, row.title)
      }

      const showEmailTemplate = (row) => {
        contentHtml.value = row.header + row.footer
        emailTemplateVisible.value = true
      }

      const handleCloseDrawer = () => {
        store.commit('sys/setOpenDrawer', false)
        store.commit('sys/clearOpenPageDrawerConfig')
        store.commit('sys/setAddProductDrawerSelect', [])
      }
      const jump = (path, tab) => {
        router.push({
          path: path,
          query: {
            tab: tab,
          },
        })
        handleCloseDrawer()
      }

      const handlePreview = () => {
        if (!selectRadio1.value) {
          ElMessage({
            showClose: true,
            message: t('请选择邮件模板'),
            type: 'warning',
          })
          return
        }
        let params = null
        params = merge(exportParams.value, {
          __template_id: selectRadio.value || '',
        })

        params.__email_template_id = selectRadio1.value

        const loading = ElLoading.service({
          lock: true,
          fullscreen: true,
          text: t('正在生成中...'),
        })

        request({
          url: props.api,
          method: 'get',
          params,
          timeout: 120 * 1000,
        })
          .then((data) => {
            // download(data.http_path)
            loading.close()
            formModel.value = data
            // contentHtml.value = data.content
            emailPreviewVisible.value = true
            emit('export-success', data)
          })
          .catch((data) => {
            console.log('catch', data)
            loading.close()
          })
      }
      return {
        visible,
        tableConfig,
        tableConfig1,
        previewerRef,
        showPreview,
        t,
        selectRadio,
        selectRadio1,
        handlePreview,
        emailTemplateVisible,
        contentHtml,
        showEmailTemplate,
        emailPreviewVisible,
        baseFormConfig,
        formModel,
        jump,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
