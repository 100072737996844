<template>
  <div class="comment-list-wrapper wh mh0 fv ex ml15">
    <div class="">
      <slot name="title">
        <h2 class="mt20 dl mr20">{{ title }}</h2>
      </slot>
      <el-button class="ml20" type="primary" @click="handleAddComment">
        {{ t('发表评论') }}
      </el-button>
    </div>
    <div class="comment-list ex oy">
      <template v-if="listData.length">
        <div v-for="(item, index) in listData" :key="index" class="bbor ptb15">
          <div class="fx">
            <el-avatar :size="50" :src="item?.user_info?.avatar" />
            <div class="plr10 ex mw0">
              <div class="c1 fxmj">
                <span>{{ item?.user_info?.name || '-' }}</span>
                <span
                  v-if="userInfo.id === item?.user_id"
                  class="c0 cp"
                  @click="hanldeEditComment(item)"
                >
                  {{ t('修改') }}
                </span>
              </div>
              <div class="c6 pt5">{{ item?.create_time || '-' }}</div>
              <div class="w mw0 rich-text-content" v-html="item.content"></div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <el-empty :description="t('暂无评论~')" />
      </template>
    </div>
  </div>
  <el-dialog
    v-model="dialogVisibleContent"
    center
    :destroy-on-close="true"
    :title="t('发表评论')"
    width="80vw"
  >
    <jwt-editor v-model="commentContent" :upload-url="uploadRichTextUrl" />
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisibleContent = false">
          {{ t('取消') }}
        </el-button>
        <el-button type="primary" @click="handleSubmitContent">
          {{ t('确定') }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
  import {
    defineComponent,
    onBeforeMount,
    reactive,
    toRefs,
    computed,
    watch,
    nextTick,
  } from 'vue'
  // import dayjs from 'dayjs'
  import { getCommentList, addComment, editComment } from '@/api/comment'
  import { uploadRichTextUrl } from '@/config/index'
  import { t } from '@/utils/i18n'

  import { useStore } from 'vuex'
  export default defineComponent({
    name: 'BaseComment',
    components: {},
    props: {
      title: { type: String, default: t('评论人员') },
      refType: { type: [String, Number], default: 'story' },
      refId: { type: [String, Number], default: '' },
    },
    setup(props) {
      const store = useStore()
      const userInfo = computed(() => store.getters['user/userInfo'])
      const state = reactive({
        commentContent: '',
        listData: [],
        dialogVisibleContent: false,
        openData: {},
      })

      watch(
        () => props.refId,
        (newValue) => {
          // console.log('person变化了', newValue)
          // 这里直接执行的话是在onBeforeMount之前所以放在nextTick一个微任务里面
          nextTick(() => {
            // console.log('person变化了 nextTick')
            if (newValue) {
              getDataList()
            }
          })
        },
        { immediate: true }
      )
      // 获取数据详情信息
      const getDataList = () => {
        const params = {
          ref_type: props.refType,
          ref_id: props.refId,
        }
        getCommentList(params).then((res) => {
          console.log('res', res)
          state.listData = res.data || []
        })
      }

      const handleAddComment = () => {
        state.openData = {}
        state.dialogVisibleContent = true
        state.commentContent = ''
      }

      const handleAddCommentSubmit = () => {
        const params = {
          ref_type: props.refType,
          ref_id: props.refId,
          content: state.commentContent,
        }
        console.log('params', params)
        addComment(params).then((res) => {
          console.log('addComment res', res)
          state.dialogVisibleContent = false
          state.commentContent = ''
          getDataList()
        })
      }

      const hanldeEditComment = (item) => {
        state.openData = item
        state.dialogVisibleContent = true
        state.commentContent = item.content
      }
      const hanldeEditCommentSubmit = () => {
        const params = {
          id: state.openData.id,
          content: state.commentContent,
        }
        console.log('params', params)
        editComment(params).then(() => {
          state.dialogVisibleContent = false
          state.commentContent = ''
          getDataList()
        })
      }

      const handleSubmitContent = () => {
        if (state.openData?.id) {
          hanldeEditCommentSubmit()
        } else {
          handleAddCommentSubmit()
        }
      }

      onBeforeMount(() => {})

      return {
        ...toRefs(state),
        userInfo,
        uploadRichTextUrl,
        t,
        handleAddComment,
        hanldeEditComment,
        handleSubmitContent,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .comment-list-wrapper {
    .comment-list {
      .rich-text-content {
        :deep() {
          video {
            width: 100% !important;
          }
          img {
            max-width: 100%;
          }
        }
      }
    }
  }
</style>
