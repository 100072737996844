import { getRequest, postRequest } from './api'

const module = 'msg'
export function getNoticeList(params = {}) {
  const url = `${module}/index`
  return getRequest(url, params)
}

export function addNotice(params = {}) {
  const url = `${module}/add`
  return postRequest(url, params)
}

export function editNotice(params = {}) {
  const url = `${module}/edit`
  return postRequest(url, params)
}

export function detailNotice(params = {}) {
  const url = `${module}/detail`
  return getRequest(url, params)
}

export function deleteNotice(params = {}) {
  const url = `${module}/delete`
  return postRequest(url, params)
}

export function readNotice(params = {}) {
  const url = `${module}/readMsg`
  return postRequest(url, params)
}

export function readAllNotice(params = {}) {
  const url = `${module}/readAllMsg`
  return postRequest(url, params)
}

export function getTypeNotice(params = {}) {
  console.log(params)
  // const url = `${module}/getTypeTree`
  // return getRequest(url, params)
}
export function getUnreadTotal(params = {}) {
  console.log(params)
  const url = `${module}/getUnreadTotal`
  return getRequest(url, params)
}
