/*
 * @Author: WGL
 * @Date: 2023-04-03 15:28:54
 * @LastEditors: WGL
 * @LastEditTime: 2024-05-27 13:54:13
 * @Description:
 */
export default {
  vabI18n: {
    首页: '首頁',
    看板: '看板',
    工作台: '工作台',
    个人中心: '個人中心',
    Excel: 'Excel',
    错误页: '錯誤頁',
    403: '403',
    404: '404',
    邮件: '郵件',
    更多: '更多',
    退出登录: '退出登录',
    登录: '登录',
    验证码: '驗證碼',
    注册: '注册',
    国际化: '國際化',
    刷新: '重新整理',
    通知: '通知',
    全屏: '全屏',
    页面动画: '頁面動畫',
    搜索: '搜尋',
    重置: '重設',
    开启: '啟用',
    关闭: '關閉',
    保存: '儲存',
    欢迎来到: '歡迎來到',
    请输入用户名: '請輸入使用者名稱',
    请输入密码: '請輸入密碼',
    用户名不能为空: '使用者名稱不能為空',
    密码不能少于6位: '密碼不能少於6位',
    请输入正确的手机号: '請輸入正確的手機號',
    请输入手机号: '請輸入手機號',
    请输入手机验证码: '請輸入手機驗證碼',
    获取验证码: '獲取驗證碼',
    // ==================================== 常用基本 ==============================
    大: '大',
    中: '中',
    小: '小',
    基本信息: '基本資訊',
    分类名称: '分類名稱',
    请输入分类名称: '請輸入分類名稱',
    排序: '排序',
    状态: '狀態',
    备注: '備註',
    请输入备注: '請輸入備註',
    序号: '序號',
    规格: '規格',
    '重量/长-宽-高/体积': '重量/長-寬-高/體積',
    价格: '價格',
    '价格(元)': '價格(元)',
    销量: '銷量',
    封面图: '封面圖',
    图片: '圖片',
    图标: '圖示',
    编码: '編碼',
    OEM编码: 'OEM編碼',
    '产品编码/OEM编码': '產品編碼/OEM編碼',
    '编码超级搜索，多个编码换行隔开': '編碼超級搜索，多個編碼換行隔開',
    库存: '庫存',
    调出仓库: '調出倉庫',
    调入仓库: '調入倉庫',
    '真实库存(锁定库存)': '實際庫存(鎖定庫存)',
    采购价: '購買價',
    毛利率: '毛利率',
    客户报价: '客戶報價',
    报价状态: '報價狀態',
    来源: '來源',
    创建时间: '創建時間',
    更新时间: '更新時間',
    使用状态: '使用狀態',
    总价: '總價',
    操作: '操作',
    名称: '名稱',
    加载数据中: '載入資料中...',
    系统: '系統',
    确定: '確定',
    确认: '確認',
    取消: '取消',
    取消操作: '取消操作！',
    '此操作将会永久删除数据，是否继续?': '此操作將永久刪除資料，是否繼續?',
    '确定要复制当前数据?': '確定要複製當前資料?',
    请配置打印接口: '請配置列印介面！',
    暂无数据可导出: '暫無資料可匯出!',
    正在生成中: '正在生成中...',
    请配置导出接口: '請配置匯出接口！',
    '当前页面可能存在未保存内容，直接离开内容将不被保存':
      '當前頁面可能存在未儲存內容，直接離開內容將不被儲存',
    请配置上传的路径地址: '請配置上傳的路徑地址！',
    编辑: '編輯',
    删除: '刪除',
    新建: '新建',
    提示: '提示',
    '请配置导出接口！': '請配置匯出接口！',
    '暂无数据可导出！': '暫無資料可匯出！',
    正在下载中: '正在下載中。。。',
    文件: '文件',
    '请配置文件上传接口！': '請配置檔案上傳接口！',
    '请配置编辑接口！': '請配置編輯介面！',
    '导出数据过多，已添加至异步导出队列生成':
      '匯出資料過多，已新增至非同步匯出佇列生成',
    请配置文件上传接口: '請配置檔案上傳介面！',
    请配置编辑接口: '請配置編輯介面！',
    请配置详情接口: '請配置詳情介面！',
    头像裁剪: '頭像裁剪',
    选择图片: '選擇圖片',
    上传并保存: '上傳並儲存',
    返回: '返回',
    产品详情: '產品詳情',
    总计: '總計',
    查询: '查詢',
    单位: '單位',
    附件: '附件',
    仓库: '倉庫',
    手机号: '手機號',
    角色: '角色',
    权限: '權限',
    是否负责人: '是否負責人',
    固定号码: '固定號碼',
    上传文件: '上傳文件',
    '附件名称(点击预览)': '附件名稱(點擊預覽)',
    附件类型: '附件類型',
    附件大小: '附件大小',
    上传人: '上傳人',
    上传图片大小不能超过: '上傳圖片大小不能超過',
    最多上传: '最多上傳',
    您选择了: '您選擇了',
    '个文件,请重新选择': '個文件,請重新選擇',
    你确定要删除: '你確定要刪除',
    上传时间: '上傳時間',
    明细: '明細',
    文件名称: '文件名稱',
    请输入分类排序: '請輸入分類排序',
    展开: '展開',
    合并: '合併',
    紧急程度: '緊急程度',
    添加: '添加',
    请选择: '請選擇',
    供应商: '供應商',
    最近报价: '最近報價',
    品牌: '品牌',
    车型: '車型',
    请选择车型: '請選擇車型',
    部件: '部件',
    请选择部件: '請選擇部件',
    年份: '年份',
    请选择年份: '請選擇年份',
    发表评论: '發表評論',
    修改: '修改',
    '暂无评论~': '暫無評論~',
    评论人员: '評論人員',
    '暂无信息~': '暫無資訊~',
    姓名: '姓名',
    电话: '電話',
    请输入电话: '請輸入電話',
    邮箱: '郵箱',
    部门: '部門',
    职务: '職務',
    图表显示: '圖表顯示',
    指标值: '指標值',
    日: '日',
    月: '月',
    年: '年',
    已选择: '已選擇',
    请输入关键词: '請輸入關鍵詞',
    使用选择: '使用選擇',
    暂无内容: '暫無內容',
    选择成功: '選擇成功',
    跟进: '跟進',
    已添加: '已添加',
    库存不足: '庫存不足',
    是: '是',
    否: '否',
    请填写: '請填寫',
    币种: '幣種',
    创建人: '創建人',
    最近更新: '最近更新',
    从素材库选择: '從素材庫選擇',
    // TODO: 2023-11-14 start
    从素材库选: '從素材庫選',
    在此处粘贴图片即上传: '在此處黏貼圖片即上傳',
    '上传图片只能是JPG,PNG,GIF,WEBP格式':
      '上傳圖片只能是 JPG,PNG,GIF,WEBP 格式',
    只能上传一张图片: '只能上傳一張圖片',
    当前浏览器不支持本地图片粘贴: '當前瀏覽器不支持本地圖片黏貼',
    粘贴内容非图片: '黏貼內容非圖片',
    上传文件大小不能超过: '上傳檔案大小不能超過',
    正在上传中: '正在上傳中...',
    上传: '上傳',
    上传完成: '上傳完成',
    上传失败请重新上传: '上傳失敗請重新上傳',
    正在导入中: '正在導入中',
    导入: '導入',
    导出: '導出',
    全选: '全選',
    使用选中: '使用選中',
    一键抠图: '一鍵剝圖',
    抠图: '剝圖',
    不抠图: '不剝圖',
    完成: '完成',
    下载: '下載',
    查看: '查看',
    暂无数据: '暫無資料',
    上级分类: '上級分類',
    添加分类: '添加分類',
    编辑分类: '編輯分類',
    删除分类: '刪除分類',
    分类名称不能为空: '分類名稱不能為空',
    音频: '音頻',
    只能选择一张图片: '只能選擇一張圖片',
    请配置分类接口: '請配置分類介面！',
    全部: '全部',
    请配置素材接口: '請配置素材介面！',
    请添加分类: '請添加分類',
    请配置分类添加接口: '請配置分類添加介面',
    请配置分类编辑接口: '請配置分類編輯介面',
    请配置分类删除接口: '請配置分類刪除介面',
    文件类型格式不正确: '檔案類型格式不正確!',
    大小不能超过: '大小不能超過',
    个文件请重新选择: '個文件請重新選擇',
    请配置素材分类导出接口: '請配置素材分類匯出介面！',
    立即新增: '立即新增',
    上传视频大小不能超过: '上傳視頻大小不能超過',
    图标选择器: '圖示選擇器',
    最后编辑: '最後編輯',
    步骤: '步驟',
    标题: '標題',
    审核: '審核',
    审核状态: '審核狀態',
    请选择类型: '請選擇類型',
    请选择审核状态: '請選擇審核狀態',
    通过: '通過',
    不通过: '不通過',
    付款状态: '付款狀態',
    请选择付款状态: '請選擇付款狀態',
    发货状态: '發貨狀態',
    请选择发货状态: '請選擇發貨狀態',
    提成金额: '提成金額',
    请输入提成金额: '請輸入提成金額',
    回款方式: '回款方式',
    请选择回款方式: '請選擇回款方式',
    回款日期: '回款日期',
    请输入回款日期: '請輸入回款日期',
    流水单号: '流水單號',
    请输入支付流水单号: '請輸入支付流水單號',
    支付凭证: '支付憑證',
    付款方式: '付款方式',
    请选择付款方式: '請選擇付款方式',
    付款日期: '付款日期',
    请输入付款日期: '請輸入付款日期',
    下单日期: '下單日期',
    请选择下单日期: '請選擇下單日期',
    请输入名称: '請輸入名稱',
    保存并新增: '保存並新增',
    您确定要对当前数据进行提审: '您確定要對當前數據進行審批？',
    您确定要对当前数据进行提审并通过: '您確定要對當前數據進行審批並通過？',
    '您确定要对当前状态进行撤销？撤销后可重新编辑提审':
      '您確定要對當前狀態進行撤銷？撤銷後可重新編輯審批',
    您确定要对当前订单进行续单: '您確定要對當前訂單進行續單？',
    您确定要对当前订单生成出库单: '您確定要對當前訂單生成出庫單？',
    // ==================================== 邮箱模块 ==============================
    表格模版: '表格模板',
    去添加: '去添加',
    邮件模版: '郵件模板',
    生成预览: '生成預覽',
    表格: '表格',
    取消附件: '取消附件',
    单选: '單選',
    只看我的: '只看我的',
    先发给自己: '先發給自己',
    主题: '主題',
    请输入主题: '請輸入主題',
    请输入标题: '請輸入標題',
    收件人: '收件人',
    请选择收件人: '請選擇收件人',
    头部: '頭部',
    尾部: '尾部',
    发送: '發送',
    // ==================================== f附件模块 ==============================
    下次联系时间: '下次聯繫時間',
    跟进内容: '跟進內容',
    上传附件: '上傳附件',
    '是否删除这条跟进记录?': '是否刪除這條跟進記錄?',
    确定进入: '確定進入',
    阶段: '階段',
    // ==================================== f成员管理模块 ==============================
    成员管理: '成員管理',
    成员: '成員',
    请选择成员: '請選擇成員',
    只读: '只讀',
    读写: '讀寫',
    负责人: '負責人',
    更换负责人: '更換負責人',
    设置为负责人: '設置為負責人',
    请选择负责人: '請選擇負責人',
    // ==================================== 产品模块 ==============================
    产品: '產品',
    数量: '數量',
    产品名称: '產品名稱',
    请输入产品名称: '請輸入產品名稱',
    产品分类: '產品分類',
    请选择产品分类: '請選擇產品分類',
    产品类型: '產品類型',
    请选择产品类型: '請選擇產品類型',
    请输入排序: '請輸入排序',
    产品编码: '產品編碼',
    请输入产品编码: '請輸入產品編碼',
    请输入OEM编码: '請輸入OEM編碼',
    适用车型: '適用車型',
    请选择适用车型: '請選擇適用車型',
    车身部件: '車身部件',
    请选择车身部件: '請選擇車身部件',
    产品货币: '產品貨幣',
    请选择产品货币单位: '請選擇產品貨幣單位',
    预警库存: '預警庫存',
    '建议售价/最低售价/成本': '建議售價/最低售價/成本',
    外贸: '外貿',
    零售: '零售',
    成本: '成本',
    '名称/编码/OEM编码': '名稱/編碼/OEM編碼',
    '重量/体积/长-宽-高': '重量/體積/長-寬-高',
    上下架: '上下架',
    上架: '上架',
    下架: '下架',
    请选择单位: '請選擇單位',
    建议售价: '建議售價',
    最低售价: '最低售價',
    成本价: '成本價',
    装箱量: '裝箱量',
    箱规: '箱規',
    '箱规-长(cm)': '箱規-長(cm)',
    '箱规-宽(cm)': '箱規-寬(cm)',
    '箱规-高(cm)': '箱規-高(cm)',
    '箱规-装箱量': '箱規-裝箱量',
    '箱规-净重(kg)': '箱規-淨重(kg)',
    '箱规-毛重(kg)': '箱規-毛重(kg)',
    '箱规-体积(m³)': '箱規-體積(m³)',
    '重量(kg)': '重量(kg)',
    '单个-长(cm)': '單個-長(cm)',
    '单个-宽(cm)': '單個-寬(cm)',
    '单个-高(cm)': '單個-高(cm)',
    '单个-体积(m³)': '單個-體積(m³)',
    '单个-净重(kg)': '單個-淨重(kg)',
    '单个-毛重(kg)': '單個-毛重(kg)',
    证书: '證書',
    建议尺寸比例: '建議尺寸比例 1*1',
    重量: '重量',
    体积: '體積',
    分类: '分類',
    '体积(m³)': '體積(m³)',
    描述: '描述',
    请输入描述: '請輸入描述',
    图集: '圖集',
    实施服务: '實施服務',
    请选择实施服务: '請選擇實施服務',
    扩展信息: '擴展信息',
    请选择供应商: '請選擇供應商',
    图片信息: '圖片信息',
    请选择仓库: '請選擇倉庫',
    请选择产品: '請選擇產品',
    入库: '入庫',
    产品图片: '產品圖片',
    我方编码: '我方編碼',
    对方编码: '對方編碼',
    历史最低: '歷史最低',
    近期价格: '近期價格',
    历史最高: '歷史最高',
    联系人: '聯繫人',
    详细地址: '詳細地址',
    请选择车型品牌: '請選擇車型品牌',
    请选择配置: '請選擇配置',
    货源: '貨源',
    产品概览: '產品概觀',
    配套产品: '配套產品',
    配套产品详情: '配套產品詳情',
    平替产品: '平替產品',
    价格策略: '價格策略',
    策略条件: '策略條件',
    数量大于等于: '數量大於等於',
    单价为: '單價為',
    类型: '類型',
    策略方式: '策略方式',
    策略类型: '策略類型',
    请选择策略类型: '請選擇策略類型',
    库存提示: '當前庫存量(總庫存/預警庫存)',
    包含产品: '包含產品',
    配置: '配置',
    导出原图: '導出原圖',
    视频信息: '視頻信息',
    视频: '視頻',
    目标市场: '目標市場',
    请选择目标市场: '請選擇目標市場',
    产品货源: '產品貨源',
    请选择产品货源: '請選擇產品貨源',
    上下架状态: '上下架狀態',
    请选择产品上下架状态: '請選擇產品上下架狀態',
    index: {},
    // ==================================== 产品分类模块 ==============================
    需要以字母开头: '需要以字母開頭',
    显示名称: '顯示名稱',
    请输入显示名称: '請輸入顯示名稱',
    用于生成电商网站时显示的分类标题: '用於生成電商網站時顯示的分類標題',
    分类url: '分類url',
    请输入字母或数字: '請輸入字母或數字',
    '请输入分类url,如lights': '請輸入分類url, 如lights',
    用于生成电商网站的链接: '用於生成電商網站的鏈接',
    分类描述: '分類描述',
    用于生成电商网站时SEO: '用於生成電商網站時SEO',
    分类关键词: '分類關鍵詞',
    请输入分类: '請輸入分類',
    // ==================================== 销售模块 ==============================
    内容: '內容',
    跟进人: '跟進人',
    跟进时间: '跟進時間',
    添加成员: '添加成員',
    删除成员: '刪除成員',
    '请选择成员(可多选)': '請選擇成員(可多選)',
    客户: '客戶',
    客户名称: '客戶名稱',
    请输入客户名称: '請輸入客戶名稱',
    客户编码: '客戶編碼',
    成交单数: '成交單數',
    成交金额: '成交金額',
    回款金额: '回款金額',
    客户来源: '客戶來源',
    请选择客户来源: '請選擇客戶來源',
    客户行业: '客戶行業',
    请选择客户行业: '請選擇客戶行業',
    客户等级: '客戶等級',
    请选择客户等级: '請選擇客戶等級',
    下次跟进时间: '下次跟進時間',
    地区: '地區',
    请选择地区: '請選擇地區',
    请输入客户手机号: '請輸入客戶手機號',
    请输入邮箱地址: '請輸入郵箱地址',
    地址: '地址',
    请输入客户地址: '請輸入客戶地址',
    是否成交: '是否成交',
    请选择是否成交: '請選擇是否成交',
    客户详情: '客戶詳情',
    最后跟进时间: '最後跟進時間',
    微信: '微信',
    请输入微信: '請輸入微信',
    商机: '商機',
    范围: '範圍',
    请选择数据范围: '請選擇數據範圍',
    客户地区: '客戶地區',
    团队成员: '團隊成員',
    发票信息: '發票信息',
    客户分析: '客戶分析',
    客户订单分析: '客戶訂單分析',
    请选择开始日期: '請選擇開始日期',
    开始日期: '開始日期',
    结束日期: '結束日期',
    退货产品: '退貨產品',
    结束时间: '結束時間',
    开始时间: '開始時間',
    核心数据: '核心數據',
    成交趋势: '成交趨勢',
    产品分布: '產品分佈',
    产品排行: '產品排行',
    销售前20产品: '銷售前20產品',
    历史订单: '歷史訂單',
    订单金额: '訂單金額',
    订单总金额: '訂單總金額',
    订单笔数: '訂單筆數',
    下单数量: '下單數量',
    实收金额: '實收金額',
    '当前订单收款总和，扣除退款': '當前訂單收款總和，扣除退款',
    退款金额: '退款金額',
    当前订单退款金额: '當前訂單退款金額',
    未收回款: '未收回款',
    '订单金额-已收金额': '訂單金額-已收金額',
    已开票金额: '已開票金額',
    订单已开票的金额: '訂單已開票的金額',
    平均单价: '平均單價',
    每笔订单平均单价: '每筆訂單平均單價',
    排名: '排名',
    渠道来源: '渠道來源',
    金额占比: '金額占比',
    订单占比: '訂單占比',
    公司名称: '公司名稱',
    请输入公司名称: '請輸入公司名稱',
    税号: '稅號',
    请输入公司税号: '請輸入公司稅號',
    开户行: '開戶行',
    银行账号: '銀行賬號',
    账户信息: '賬戶信息',
    发票抬头: '發票抬頭',
    关联客户: '關聯客戶',
    请选择客户: '請選擇客戶',
    货币币种: '貨幣幣種',
    开户银行: '開戶銀行',
    请输入开户银行全称: '請輸入開戶銀行全稱',
    银行卡号: '銀行卡號',
    请输入银行卡号: '請輸入銀行卡號',
    请输入地址: '請輸入地址',
    退货商品为空表示只退款: '退貨商品為空表示只退款',
    请输入退货数量: '請輸入退貨數量',
    产品明细: '產品明細',
    请至少添加一个产品: '請至少添加一個產品',
    // TODO: 2023-11-14 start
    商机名称: '商機名稱',
    请输入商机名称: '請輸入商機名稱',
    商机阶段: '商機階段',
    请选择商机阶段: '請選擇商機階段',
    商机属性: '商機屬性',
    请选择商机属性: '請選擇商機屬性',
    商机状态: '商機狀態',
    请选择商机状态: '請選擇商機狀態',
    预计金额: '預計金額',
    请输入预计成交金额: '請輸入預計成交金額',
    请选择货币: '請選擇貨幣',
    预计成交: '預計成交',
    请输入预计成交日期: '請輸入預計成交日期',
    结束: '結束',
    商机详情: '商機詳情',
    预计成交金额: '預計成交金額',
    预计成交时间: '預計成交時間',
    // ==================================== 报价单模块 ==============================
    报价单: '報價單',
    请选择报价单: '請選擇報價單',
    报价详情: '報價詳情',
    单号: '單號',
    复用价格: '復用價格',
    时间: '時間',
    我的报价: '我的報價',
    '标题/编号': '標題/編號',
    订单Id: '訂單Id',
    客户名: '客戶名',
    单价: '單價',
    请选择出入库类型: '請選擇出入庫類型',
    所有客户: '所有客戶',
    当前客户: '當前客戶',
    时间范围: '時間範圍',
    价格范围: '價格範圍',
    请选择状态: '請選擇狀態',
    关联商机: '關聯商機',
    请选择商机: '請選擇商機',
    请选择币种: '請選擇幣種',
    生成订单: '生成訂單',
    交付日期: '交付日期',
    '订单交付/发货日期': '訂單交付/發貨日期',
    请选择交付日期: '請選擇交付日期',
    报价单名称: '報價單名稱',
    报价单编码: '報價單編碼',
    产品数量: '產品數量',
    订单货币: '訂單貨幣',
    编号: '編號',
    金额: '金額',
    销售金额: '銷售金額',
    销售订单: '銷售訂單',
    订单数量: '訂單數量',
    产品列表: '產品列表',
    订单详情: '訂單詳情',
    发货计划: '發貨計劃',
    回款计划: '回款計劃',
    回款明细: '回款明細',
    出入库记录: '出入庫記錄',
    服务明细: '服務明細',
    绩效明细: '績效明細',
    开票计划: '開票計劃',
    '退/换货': '退/換貨',
    合同: '合同',
    应收款: '應收款',
    未收款: '未收款',
    已收金额: '已收金額',
    '当前订单收款总和，不扣除退款': '當前訂單收款總和，不扣除退款',
    已退金额: '已退金額',
    实收回款: '實收回款',
    退款中金额: '退款中金額',
    订单退款审批中金额: '訂單退款審批中金額',
    '金额(应收/实收)': '金額(應收/實收)',
    下单时间: '下單時間',
    发货时间: '發貨時間',
    请选择发货时间: '請選擇發貨時間',
    订单: '訂單',
    签单类型: '簽單類型',
    生成任务时的开始日期: '生成任務時的開始日期',
    单据日期: '單據日期',
    订单成交时日期: '訂單成交時日期',
    '订单退/换货': '訂單退/換貨',
    请输入退款金额: '請輸入退款金額',
    退款金额为0表示只退货: '退款金額為0表示只退貨',
    退款方式: '退款方式',
    请选择退款方式: '請選擇退款方式',
    退款日期: '退款日期',
    请选择退款日期: '請選擇退款日期',
    补差金额: '補差金額',
    请输入补差金额: '請輸入補差金額',
    补差金额为0表示只换货: '補差金額為0表示只換貨',
    补差方式: '補差方式',
    请选择补差方式: '請選擇補差方式',
    补差日期: '補差日期',
    请选择补差计划日期: '請選擇補差計劃日期',
    换货商品为空表示只补差金额: '換貨商品為空表示只補差金額',
    '退/换货备注': '退/換貨備註',
    请输入退货备注: '請輸入退貨備註',
    订单名称: '訂單名稱',
    订单编码: '訂單編碼',
    发货日期: '發貨日期',
    包装要求: '包裝要求',
    规格说明: '規格說明',
    价格条款: '價格條款',
    订单进度: '訂單進度',
    // ==================================== 倉庫模塊 ==============================
    备货中: '備貨中',
    备货完成: '備貨完成',
    业务员: '業務員',
    计划时间: '計劃時間',
    备货状态: '備貨狀態',
    发货计划详情: '發貨計劃詳情',
    发货进度: '發貨進度',
    计划日期: '計劃日期',
    采购计划: '採購計劃',
    箱单: '箱單',
    发货单: '發貨單',
    请选择销售订单: '請選擇銷售訂單',
    交货日期: '交貨日期',
    采购状态: '採購狀態',
    请选择采购状态: '請選擇採購狀態',
    添加采购计划: '添加採購計劃',
    请输入采购数量: '請輸入採購數量',
    添加到采购单: '添加到採購單',
    换货产品: '換貨產品',
    换货商品为空表示只补差额: '換貨商品為空表示只補差額',
    出入库: '出入庫',
    出库: '出庫',
    日期: '日期',
    入库类型: '入庫類型',
    出库类型: '出庫類型',
    出入库类型: '出入庫類型',
    供应商名称: '供應商名稱',
    '供应商/客户': '供應商/客戶',
    盘点状态: '盤點狀態',
    '未作废数据删除，库存将不会恢复是否删除':
      '未作廢數據刪除，庫存將不會恢復是否刪除？',
    '此操作将会永久删除数据，是否继续': '此操作將會永久刪除數據，是否繼續？',
    您确定要作废当前数据: '您確定要作廢當前數據？',
    // TODO: 2023-11-15 start
    '金额(应付/实付)': '金額(應付/實付)',
    实付金额: '實付金額',
    '当前订单付款总和，扣除退款': '當前訂單付款總和，扣除退款',
    未付金额: '未付金額',
    采购: '採購',
    采购单详情: '採購單詳情',
    采购进度: '採購進度',
    付款计划: '付款計劃',
    付款明细: '付款明細',
    采购数量: '採購數量',
    // ==================================== 供應商模塊 ==============================
    供应商详情: '供應商詳情',
    '确认删除？': '確認刪除？',
    消息: '消息',
    暂无评论: '暫無評論',
    暂无信息: '暫無信息',
    素材选择: '素材選擇',
    '文件已存在下载队列中！': '文件已存在下載隊列中！',
    新增故事: '新增故事',
    请输入内容: '請輸入內容',
    只能选择一张: '只能選擇一張',
    请选择邮件模板: '請選擇郵件模板',
    '正在生成中...': '正在生成中...',
    '该附件格式不支持预览，请下载后查看': '該附件格式不支持預覽，請下載後查看',
    设备: '設備',
    总数: '總數',
    在线: '在線',
    离线: '離線',
    故障: '故障',
    在线率: '在線率',
    故障率: '故障率',
    '确认要离开本页面吗？': '確認要離開本頁面嗎？',
    历史报价: '歷史報價',
    '产品名称/编码': '產品名稱/編碼',
    '智慧商贸-ERP': '智慧商貿-ERP',
    '正在加载中...': '正在加載中...',
    简体中文: '简体中文',
    繁體中文: '繁體中文',
    已创建: '已創建',
    进行中: '進行中',
    已完成: '已完成',
    折线图: '折線圖',
    柱状图: '柱狀圖',
    周: '週',
    已作废: '已作廢',
    已过期: '已過期',
    编辑中: '編輯中',
    待支付: '待支付',
    已付定金: '已付定金',
    已支付: '已支付',
    已发货: '已發貨',
    已签收: '已簽收',
    '报价单-出口': '報價單-出口',
    '报价单-内销': '報價單-內銷',
    '报价单-门市': '報價單-門市',
    '报价单-淘宝': '報價單-淘寶',
    '报价单-云工厂': '報價單-雲工廠',
    '报价单-智能工厂': '報價單-智能工廠',
    禁用: '禁用',
    启用: '啟用',
    隐藏: '隱藏',
    显示: '顯示',
    未知: '未知',
    抖音: '抖音',
    快手: '快手',
    待确定: '待確定',
    有效: '有效',
    无效: '無效',
    推广微信公众号: '推廣微信公眾號',
    推广抖音账号: '推廣抖音帳號',
    推广抖音视频: '推廣抖音視頻',
    推广快手账号: '推廣快手帳號',
    推广快手视频: '推廣快手視頻',
    销售收入: '銷售收入',
    押金收入: '押金收入',
    租赁收入: '租賃收入',
    购买支出: '購買支出',
    押金退还: '押金退還',
    男: '男',
    女: '女',
    保密: '保密',
    待处理: '待處理',
    处理中: '處理中',
    已处理: '已處理',
    文本框: '文本框',
    多行文本框: '多行文本框',
    数字文本框: '數字文本框',
    日期选择框: '日期選擇框',
    状态选择: '狀態選擇',
    单选框: '單選框',
    多选框: '多選框',
    下拉单选择: '下拉單選擇',
    下拉多选择: '下拉多選擇',
    单图片: '單圖片',
    多图片: '多圖片',
    日期选择: '日期選擇',
    未读: '未讀',
    已读: '已讀',
    待审核: '待審核',
    私有: '私有',
    公海: '公海',
    无: '無',
    低: '低',
    高: '高',
    离职: '離職',
    在职: '在職',
    销售: '銷售',
    按数量区间设置: '按數量區間設置',
    '按客户/供应商设置': '按客戶/供應商設置',
    待接受: '待接受',
    已接受: '已接受',
    不接受: '不接受',
    公司: '公司',
    银行账户: '銀行帳戶',
    单位地址: '單位地址',
    '千美元)': '千美元)',
    宽松默认: '寬鬆默認',
    严格模式: '嚴格模式',
    '支持jpg、jpeg、png格式，单次可最多选择':
      '支援jpg、jpeg、png格式，單次可最多選擇',
    '张图片，每张不可大于': '張圖片，每張不可大於',
    如果大于: '如果大於',
    会自动为您过滤: '會自動為您過濾',
    查看大图: '查看大圖',
    '正在上传中...': '正在上傳中...',
    当前上传成功数: '當前上傳成功數',
    张: '張',
    当前上传失败数: '當前上傳失敗數',
    开始上传: '開始上傳',
    '上传完成!': '上傳完成!',
    共上传: '共上傳',
    张图片: '張圖片',
    '上传失败,文件大小为': '上傳失敗,文件大小為',
    当前限制选择: '當前限制選擇',
    '个文件，本次选择了': '個文件，本次選擇了',
    个文件: '個文件',
    密码不能少于: '密碼不能少於',
    位: '位',
    销售前: '銷售前',
    更新日志: '更新日誌',
    用户管理: '用戶管理',
    角色管理: '角色管理',
    部门管理: '部門管理',
    菜单管理: '菜單管理',
    系统日志: '系統日誌',
    组件: '組件',
    其他: '其他',
    角色权限: '角色權限',
    外链: '外鏈',
    常规图标: '常規圖標',
    小清新图标: '小清新圖標',
    综合表格: '綜合表格',
    行内编辑表格: '行內編輯表格',
    自定义表格: '自訂表格',
    表单: '表單',
    综合表单: '綜合表單',
    分步表单: '分步表單',
    按钮: '按鈕',
    文字链接: '文字連結',
    输入框: '輸入框',
    计数器: '計數器',
    选择器: '選擇器',
    开关: '開關',
    滑块: '滑塊',
    时间选择器: '時間選擇器',
    日期选择器: '日期選擇器',
    日期时间选择器: '日期時間選擇器',
    评分: '評分',
    工作流: '工作流',
    图表: '圖表',
    打印: '列印',
    手机预览: '手機預覽',
    时间线: '時間線',
    数字自增长: '數字自增長',
    多标签: '多標籤',
    动态Meta: '動態Meta',
    动态路径参数: '動態路徑參數',
    列表: '清單',
    多级路由缓存: '多級路由緩存',
    多级路由: '多級路由',
    拖拽: '拖曳',
    卡片拖拽: '卡片拖曳',
    加载: '載入',
    视频播放器: '視訊播放器',
    编辑器: '編輯器',
    富文本编辑器: '富文本編輯器',
    错误日志模拟: '錯誤日誌模擬',
    导出Excel: '匯出Excel',
    导出选中行Excel: '匯出選中行Excel',
    导出合并Excel: '匯出合併Excel',
    动画: '動畫',
    第三方登录: '第三方登入',
    物料源: '物料源',
    物料市场: '物料市場',
    清空消息: '清空訊息',
    关闭其他: '關閉其他',
    关闭左侧: '關閉左側',
    关闭右侧: '關閉右側',
    关闭全部: '關閉全部',
    主题配置: '主題配置',
    常用设置: '常用設定',
    其它设置: '其他設定',
    分栏布局时生效: '分欄佈局時生效',
    标签开启时生效: '標籤啟用時生效',
    '布局配置仅在电脑视窗下生效，手机视窗时将默认锁定为纵向布局':
      '佈局配置僅在電腦視窗下生效，手機視窗時將默認鎖定為縱向佈局',
    默认: '默認',
    海洋之心: '海洋之心',
    海洋之心sss: '海洋之心sss',
    绿荫草场: '綠蔭草場',
    碰触纯白: '碰觸純白',
    月上重火: '月上重火',
    蓝黑: '藍黑',
    蓝白: '藍白',
    绿黑: '綠黑',
    绿白: '綠白',
    红黑: '紅黑',
    红白: '紅白',
    渐变: '漸變',
    布局: '佈局',
    分栏: '分欄',
    纵向: '縱向',
    横向: '橫向',
    综合: '綜合',
    常规: '常規',
    浮动: '浮動',
    菜单背景: '選單背景',
    分栏风格: '分欄風格',
    箭头: '箭頭',
    头部固定: '頭部固定',
    固定: '固定',
    不固定: '不固定',
    标签: '標籤',
    标签风格: '標籤風格',
    标签图标: '標籤圖標',
    卡片: '卡片',
    灵动: '靈動',
    圆滑: '圓滑',
    进度条: '進度條',
    随机换肤: '隨機換膚',
    购买源码: '購買源碼',
    拷贝源码: '拷貝源碼',
    清理缓存: '清理快取',
    恢复默认: '恢復預設',
    '检测到新版本，正在下载中，请稍后...':
      '偵測到新版本，正在下載中，請稍後...',
    温馨提示: '溫馨提示',
    更新版本完成: '更新版本完成',
    后刷新项目: '後刷新項目',
    我的待办: '我的待辦',
    已办事项: '已辦事項',
    经营分析: '經營分析',
    决策辅助: '決策輔助',
    采购订单: '採購訂單',
    供应管理: '供應管理',
    客户管理: '客戶管理',
    商机管理: '商機管理',
    对外报价: '對外報價',
    财务: '財務',
    仓库管理: '倉庫管理',
    收款计划: '收款計劃',
    收款明细: '收款明細',
    开票详情: '開票詳情',
    库存盘点: '庫存盤點',
    库存报表: '庫存報表',
    库存余额: '庫存余額',
    收发明细: '收發明細',
    收发汇总: '收發匯總',
    单据列表: '單據列表',
    产品入库: '產品入庫',
    产品出库: '產品出庫',
    产品调拨: '產品調撥',
    产品组装: '產品組裝',
    产品拆卸: '產品拆卸',
    绩效: '績效',
    我的绩效: '我的績效',
    团队绩效: '團隊績效',
    绩效核定: '績效核定',
    绩效规则: '績效規則',
    任务: '任務',
    任务列表: '任務列表',
    电商: '電商',
    首页设置: '首頁設置',
    商品: '商品',
    轮播图: '輪播圖',
    文章管理: '文章管理',
    设置: '設置',
    公司信息: '公司信息',
    销售配置: '銷售配置',
    产品配置: '產品配置',
    产品素材: '產品素材',
    模版管理: '模板管理',
    扩展字段: '擴展字段',
    系统设置: '系統設置',
    操作日志: '操作日誌',
    公司列表: '公司列表',
    用户列表: '用戶列表',
    权限管理: '權限管理',
    行业管理: '行業管理',
    资源: '資源',
    店铺选址: '店鋪選址',
    教程管理: '教程管理',
    超级管理员: '超級管理員',
    '没有菜单权限！': '沒有菜單權限！',
    '路由格式返回有误！': '路由格式返回有誤！',
    运行中: '運行中',
    已下线: '已下線',
    游客: '遊客',
    '未开启登录拦截)': '未啟用登入攔截)',
    早上好: '早上好',
    上午好: '上午好',
    中午好: '中午好',
    下午好: '下午好',
    晚上好: '晚上好',
    欢迎登录: '歡迎登入',
    '登录接口异常，未正确返回': '登入介面異常，未正確返回',
    '核心接口异常，请检查返回JSON格式是否正确，是否正确返回':
      '核心介面異常，請檢查返回JSON格式是否正確，是否正確返回',
    '核心接口异常，请检查返回JSON格式是否正确':
      '核心介面異常，請檢查返回JSON格式是否正確',
    复制: '複製',
    成功: '成功',
    失败: '失敗',
    '重量/毛重': '重量/毛重',
    产品证书: '產品證書',
    推荐价格: '推薦價格',
    邮编: '郵編',
    传真: '傳真',
    官网: '官網',
    企业状态: '企業狀態',
    营收: '營收',
    员工人数: '員工人數',
    公司类型: '公司類型',
    所属国家: '所屬國家',
    所属城市: '所屬城市',
    简介: '簡介',
    产品服务: '產品服務',
    请输入: '請輸入',
    产品价格策略: '產品價格策略',
    起始价格: '起始價格',
    分类logo: '分類logo',
    建议尺寸: '建議尺寸',
    分类banner: '分類banner',
    请输入分类描述: '請輸入分類描述',
    退款金额为: '退款金額為',
    表示只退货: '表示只退貨',
    补差金额为: '補差金額為',
    表示只换货: '表示只換貨',
    请输入客户编码: '請輸入客戶編碼',
    请输入联系人: '請輸入聯繫人',
    请输入客户Whats: '請輸入客戶Whats',
    规则名称: '規則名稱',
    请输入规则名称: '請輸入規則名稱',
    绩效类型: '績效類型',
    请选择绩效类型: '請選擇績效類型',
    优先级: '優先級',
    请输入优先级: '請輸入優先級',
    请输入编码: '請輸入編碼',
    请选择分类: '請選擇分類',
    请输入详细地址: '請輸入詳細地址',
    固定电话: '固定電話',
    请输入固定电话: '請輸入固定電話',
    请输入邮箱: '請輸入郵箱',
    请输入QQ: '請輸入QQ',
    网址: '網址',
    请输入网址: '請輸入網址',
    添加到采购计划: '添加到採購計劃',
    请输入数量: '請輸入數量',
    选择负责人: '選擇負責人',
    服务年限: '服務年限',
    '请输入服务年限(报告年限)': '請輸入服務年限(報告年限)',
    任务日期: '任務日期',
    请选择任务日期: '請選擇任務日期',
    请输入任务金额: '請輸入任務金額',
    请选择关联销售订单: '請選擇關聯銷售訂單',
    请输入回款金额: '請輸入回款金額',
    请选择回款日期: '請選擇回款日期',
    批量生成回款计划: '批量生成回款計劃',
    首次回款日期: '首次回款日期',
    请选择首次回款日期: '請選擇首次回款日期',
    回款间隔时间: '回款間隔時間',
    请输入回款间隔时间: '請輸入回款間隔時間',
    总回款期数: '總回款期數',
    请输入总回款期数: '請輸入總回款期數',
    请选择关联回款计划: '請選擇關聯回款計劃',
    渠道: '渠道',
    请选择渠道: '請選擇渠道',
    凭证: '憑證',
    请选择采购订单: '請選擇採購訂單',
    请选择关联采购订单: '請選擇關聯採購訂單',
    付款金额: '付款金額',
    请输入付款金额: '請輸入付款金額',
    请选择付款日期: '請選擇付款日期',
    批量生成付款计划: '批量生成付款計劃',
    首次付款日期: '首次付款日期',
    请选择首次付款日期: '請選擇首次付款日期',
    付款间隔时间: '付款間隔時間',
    请输入付款间隔时间: '請輸入付款間隔時間',
    总付款期数: '總付款期數',
    请输入总付款期数: '請輸入總付款期數',
    请选择关联付款计划: '請選擇關聯付款計劃',
    请选择发票抬头: '請選擇發票抬頭',
    开票金额: '開票金額',
    请输入开票金额: '請輸入開票金額',
    含税金额: '含稅金額',
    商品名称: '商品名稱',
    请输入商品名称: '請輸入商品名稱',
    商品编号: '商品編號',
    请输入商品编号: '請輸入商品編號',
    规格型号: '規格型號',
    请输入商品规格型号: '請輸入商品規格型號',
    商品单位: '商品單位',
    请输入商品单位: '請輸入商品單位',
    发票类型: '發票類型',
    请选择发票类型: '請選擇發票類型',
    税率: '稅率',
    请输入税率: '請輸入稅率',
    商品数量: '商品數量',
    请输入商品数量: '請輸入商品數量',
    开票日期: '開票日期',
    请选择开票日期: '請選擇開票日期',
    开票明细: '開票明細',
    员工离职交接: '員工離職交接',
    交接人: '交接人',
    请选择交接人: '請選擇交接人',
    单据时间: '單據時間',
    请选择单据时间: '請選擇單據時間',
    箱数: '箱數',
    请输入预估箱数: '請輸入預估箱數',
    装箱预估数量: '裝箱預估數量',
    出库单: '出庫單',
    请选择出库单: '請選擇出庫單',
    正: '正',
    侧: '側',
    唛信息: '唛信息',
    目的地: '目的地',
    请输入收货目的地: '請輸入收貨目的地',
    箱号标识: '箱號標識',
    '如M、B': '如M、B',
    产地: '產地',
    请输入产地: '請輸入產地',
    订单号: '訂單號',
    将自动填充: '將自動填充',
    净重: '凈重',
    毛重: '毛重',
    产品盘点: '產品盤點',
    请选择单据日期: '請選擇單據日期',
    组装: '組裝',
    拆卸: '拆卸',
    请输选择: '請選擇',
    入库仓库: '入庫倉庫',
    出库仓库: '出庫倉庫',
    费用: '費用',
    存为模版: '存為模板',
    拆卸产品: '拆卸產品',
    请选择拆卸产品: '請選擇拆卸產品',
    图片名称: '圖片名稱',
    请输入图片: '請輸入圖片',
    链接地址: '連結地址',
    请输入图片链接地址: '請輸入圖片連結地址',
    文章: '文章',
    文章标题: '文章標題',
    请输入文章标题: '請輸入文章標題',
    文章类型: '文章類型',
    请选择文章类型: '請選擇文章類型',
    文章内容: '文章內容',
    请输入文章内容: '請輸入文章內容',
    提单详情: '提單詳情',
    采购商: '採購商',
    产品信息: '產品信息',
    总金额: '總金額',
    产品描述: '產品描述',
    货运信息: '貨運信息',
    原产国: '原產國',
    目的国: '目的國',
    运输方式: '運輸方式',
    数据来源: '數據來源',
    请输入姓名: '請輸入姓名',
    请输入部门: '請輸入部門',
    请输入职务: '請輸入職務',
    绩效月份: '績效月份',
    请选择绩效月份: '請選擇績效月份',
    计提人: '計提人',
    请选计提人: '請選計提人',
    请选择调出仓库: '請選擇調出倉庫',
    请选择调入仓库: '請選擇調入倉庫',
    回款类型: '回款類型',
    请选择回款类型: '請選擇回款類型',
    退款: '退款',
    回款: '回款',
    付款类型: '付款類型',
    请选择付款类型: '請選擇付款類型',
    请选择优先级: '請選擇優先級',
    关键词: '關鍵詞',
    请选择上下架状态: '請選擇上下架狀態',
    只允许输入数字和字母: '只允許輸入數字和字母',
    请输入产品关键词: '請輸入產品關鍵詞',
    请输入HS编码: '請輸入HS編碼',
    请输入供应商名称: '請輸入供應商名稱',
    请输入采购商名称: '請輸入採購商名稱',
    请选择原产国: '請選擇原產國',
    请选择目的国: '請選擇目的國',
    起始时间: '起始時間',
    请输入关键词搜索: '請輸入關鍵詞搜索',
    请输入公司名称搜索: '請輸入公司名稱搜索',
    请输入产品名称搜索: '請輸入產品名稱搜索',
    请选择国家ISO编码: '請選擇國家ISO編碼',
    请选择公司经营状态: '請選擇公司經營狀態',
    请选择公司营收范围: '請選擇公司營收範圍',
    请选择公司人数: '請選擇公司人數',
    一: '一',
    二: '二',
    三: '三',
    四: '四',
    五: '五',
    六: '六',
    刚刚: '剛剛',
    分钟前: '分鐘前',
    小时前: '小時前',
    天前: '天前',
    时: '時',
    分: '分',
    是一个函数: '是一個函數',
    服务器成功返回请求数据: '伺服器成功返回請求數據',
    新建或修改数据成功: '新建或修改數據成功',
    '一个请求已经进入后台排队(异步任务)': '一個請求已經進入後臺排隊(異步任務)',
    删除数据成功: '刪除數據成功',
    发出信息有误: '發出信息有誤',
    '用户没有权限(令牌失效、用户名、密码错误、登录过期)':
      '用戶沒有權限(令牌失效、用戶名、密碼錯誤、登錄過期)',
    令牌过期: '令牌過期',
    '用户得到授权，但是访问是被禁止的': '用戶得到授權，但是訪問是被禁止的',
    访问资源不存在: '訪問資源不存在',
    请求格式不可得: '請求格式不可得',
    '请求资源被永久删除，且不会被看到': '請求資源被永久刪除，且不會被看到',
    服务器发生错误: '伺服器發生錯誤',
    网关错误: '網關錯誤',
    '服务不可用，服务器暂时过载或维护': '服務不可用，伺服器暫時過載或維護',
    网关超时: '網關超時',
    '无网络！,请连接网络！！': '無網絡！,請連接網絡！！',
    '网络不给力！,请检查网络！！': '網絡不給力！,請檢查網絡！！',
    '服务端正在升级中，请稍后再试': '服務端正在升級中，請稍後再試',
    提审并通过: '提審並通過',
    转: '轉',
    切换公司主体: '切換公司主體',
    '确定解绑企业微信?': '確定解綁企業微信?',
    主体未设置企业微信: '主體未設置企業微信',
    扫描二维码联系工作人员: '掃描二維碼聯繫工作人員',
    正在导出的文件: '正在導出的文件',
    '版本)': '版本)',
    '异常捕获(温馨提示：错误必须解决)': '異常捕獲(溫馨提示：錯誤必須解決)',
    报错路由: '報錯路由',
    错误信息: '錯誤訊息',
    错误详情: '錯誤詳情',
    暂不显示: '暫不顯示',
    百度搜索: '百度搜尋',
    谷歌搜索: '谷歌搜尋',
    最近访问: '最近訪問',
    总览: '總覽',
    开启全屏失败: '開啟全屏失敗',
    服务将于: '服務將於',
    天后到期: '天後到期',
    服务永不到期: '服務永不到期',
    站内信: '站內信',
    全部已读: '全部已讀',
    消息状态: '消息狀態',
    请选择消息类型: '請選擇消息類型',
    标记已读: '標記已讀',
    没有更多了: '沒有更多了',
    清空消息成功: '清空消息成功',
    自有产品: '自有產品',
    '已在当前产品！请换一个试试！': '已在當前產品！請換一個試試！',
    电商网站: '電商網站',
    '支持纵向布局、分栏布局、综合布局、常规布局，不支持横向布局、浮动布局':
      '支持縱向布局、分欄布局、綜合布局、常規布局，不支持橫向布局、浮動布局',
    '请输入密码(密码在购买时获得，跳转后需登录购买时绑定的github账号)':
      '請輸入密碼(密碼在購買時獲得，跳轉後需登錄購買時綁定的github賬號)',
    '秘钥不正确！': '秘鑰不正確！',
    错误拦截: '錯誤攔截',
    '检测到您当前浏览器使用的是IE内核，自':
      '檢測到您當前瀏覽器使用的是IE內核，自',
    '月起，微软已宣布弃用IE，且不再对IE提供任何更新维护，请':
      '月起，微軟已宣布棄用IE，且不再對IE提供任何更新維護，請',
    '基于admin-plus构建': '基於admin-plus構建',
    '抱歉!': '抱歉!',
    '您没有操作角色...': '您沒有操作角色...',
    '当前帐号没有操作角色,请联系管理员。':
      '當前帳號沒有操作角色,請聯繫管理員。',
    '当前页面不存在...': '當前頁面不存在...',
    '请检查您输入的网址是否正确，或点击下面的按钮返回首页。':
      '請檢查您輸入的網址是否正確，或點擊下面的按鈕返回首頁。',
    返回首页: '返回首頁',
    '重量(KG)': '重量(KG)',
    交易次数: '交易次數',
    交易重量: '交易重量',
    交易金额: '交易金額',
    出口: '出口',
    期间采购共计: '期間採購共計',
    次: '次',
    采购次数最高: '採購次數最高',
    采购次数最低: '採購次數最低',
    采购商数量: '採購商數量',
    供应商数量: '供應商數量',
    月度趋势: '月度趨勢',
    已添加为客户: '已添加為客戶',
    添加客户: '添加客戶',
    进口详单: '進口詳單',
    出口详单: '出口詳單',
    贸易总览: '貿易總覽',
    更新数据: '更新數據',
    区域分布: '區域分佈',
    编码分布: '編碼分佈',
    货运历史: '貨運歷史',
    进口: '進口',
    贸易伙伴: '貿易夥伴',
    搜索时间: '搜索時間',
    页码: '頁碼',
    查看数据: '查看數據',
    无搜索结果: '無搜索結果',
    国家: '國家',
    经营状态: '經營狀態',
    营收范围: '營收範圍',
    公司人数: '公司人數',
    搜索历史: '搜索歷史',
    历史搜索: '歷史搜索',
    公司本地名称: '公司本地名稱',
    城市: '城市',
    已解锁: '已解鎖',
    未解锁: '未解鎖',
    详情: '詳情',
    '您确定要解锁当前客户数据?': '您確定要解鎖當前客戶數據?',
    产品搜索: '產品搜索',
    公司搜索: '公司搜索',
    交易分析: '交易分析',
    分析报告: '分析報告',
    回款详情: '回款詳情',
    '您确定要对当前数据进行提审？': '您確定要對當前數據進行提審？',
    '您确定要对当前数据进行提审并通过？': '您確定要對當前數據進行提審並通過？',
    回款时间: '回款時間',
    审核人: '審核人',
    审核时间: '審核時間',
    回款计划详情: '回款計劃詳情',
    '您确定要对当前数据生成发货单？': '您確定要對當前數據生成發貨單？',
    计划回款金额: '計劃回款金額',
    付款详情: '付款詳情',
    付款时间: '付款時間',
    开票计划详情: '開票計劃詳情',
    开票公司: '開票公司',
    计划开票金额: '計劃開票金額',
    计划开票时间: '計劃開票時間',
    开票商品名称: '開票商品名稱',
    开票商品编号: '開票商品編號',
    商品规格型号: '商品規格型號',
    开票商品单位: '開票商品單位',
    开票税率: '開票稅率',
    开票商品数量: '開票商品數量',
    付款计划详情: '付款計劃詳情',
    计划付款金额: '計劃付款金額',
    需要数量: '需要數量',
    仓库名称: '倉庫名稱',
    剩余数量: '剩餘數量',
    分配库存: '分配庫存',
    关联订单: '關聯訂單',
    开票公司名称: '開票公司名稱',
    一键复制: '一鍵複製',
    计划: '計劃',
    计划开票占比: '計劃開票佔比',
    开票时间: '開票時間',
    付款编号: '付款編號',
    关联采购单: '關聯採購單',
    计划编号: '計劃編號',
    期数: '期數',
    第: '第',
    期: '期',
    计划付款占比: '計劃付款佔比',
    采购金额: '採購金額',
    已付款金额占比: '已付款金額佔比',
    '当前期数/总期数': '當前期數/總期數',
    '您确定当前数据快速付款？': '您確定當前數據快速付款？',
    计划回款占比: '計劃回款佔比',
    '已回款金额/占比': '已回款金額/佔比',
    '您确定当前数据快速回款？': '您確定當前數據快速回款？',
    回款编号: '回款編號',
    客户排行: '客戶排行',
    毛利分析: '毛利分析',
    销量最高: '銷量最高',
    毛利最高: '毛利最高',
    采购数量最大: '採購數量最大',
    采购利润最高: '採購利潤最高',
    采购额: '採購額',
    销售分析: '銷售分析',
    成交最多的销售: '成交最多的銷售',
    销量最高的产品: '銷量最高的產品',
    毛利最多的销售: '毛利最多的銷售',
    毛利最高的产品: '毛利最高的產品',
    最近爆发的销售: '最近爆發的銷售',
    上月增长最高的产品: '上月增長最高的產品',
    最近沉默的销售: '最近沉默的銷售',
    上月下降最多的产品: '上月下降最多的產品',
    销售额: '銷售額',
    产品分析: '產品分析',
    最畅销的产品: '最暢銷的產品',
    最赚钱的产品: '最賺錢的產品',
    最近爆发产品: '最近爆發產品',
    最近沉默产品: '最近沉默產品',
    产品销售量: '產品銷售量',
    商机漏斗: '商機漏斗',
    漏斗图: '漏斗圖',
    销售排行: '銷售排行',
    数据简报: '數據簡報',
    环比: '環比',
    新增客户: '新增客戶',
    新增商机: '新增商機',
    新增订单: '新增訂單',
    累计回款: '累計回款',
    累计退款: '累計退款',
    前天: '前天',
    前周: '前周',
    前月: '前月',
    前年: '前年',
    用户趋势: '用戶趨勢',
    用户数量: '用戶數量',
    趋势: '趨勢',
    客户跟进: '客戶跟進',
    需跟进的客户: '需跟進的客戶',
    商机跟进: '商機跟進',
    订单跟进: '訂單跟進',
    待办任务: '待辦任務',
    任务名称: '任務名稱',
    计划完成时间: '計劃完成時間',
    占位符: '占位符',
    图形验证码: '圖形驗證碼',
    账号登录: '賬號登錄',
    快捷登录: '快捷登錄',
    扫码登录: '掃碼登錄',
    '企业微信扫码登录失败，请先确认您的企业微信账号确认属于该企业':
      '企業微信掃碼登錄失敗，請先確認您的企業微信賬號確認屬於該企業',
    验证码不能空: '驗證碼不能為空',
    事项信息: '事項信息',
    事项标题: '事項標題',
    事项类型: '事項類型',
    所属目标: '所屬目標',
    重要程度: '重要程度',
    事项参与人员: '事項參與人員',
    请选择参与人员: '請選擇參與人員',
    创建者: '創建者',
    参与人员: '參與人員',
    事项: '事項',
    请输入事项标题: '請輸入事項標題',
    请输入重要程度: '請輸入重要程度',
    请选择紧急程度: '請選擇緊急程度',
    目标开始时间: '目標開始時間',
    目标结束时间: '目標結束時間',
    目标时间: '目標時間',
    请输入所属目标: '請輸入所屬目標',
    请选择所属目标: '請選擇所屬目標',
    数据已存在: '數據已存在',
    还有: '還有',
    '项...': '項...',
    系统创建: '系統創建',
    事件已结束: '事件已結束',
    新建日程: '新建日程',
    上一年: '上一年',
    上一月: '上一月',
    今天: '今天',
    下一月: '下一月',
    下一年: '下一年',
    日程主题: '日程主題',
    请输入日程主题: '請輸入日程主題',
    日程周期: '日程周期',
    日程描述: '日程描述',
    请输入日程描述: '請輸入日程描述',
    产品英文名: '產品英文名',
    请输入产品英文名: '請輸入產品英文名',
    产品主图: '產品主圖',
    产品轮播图: '產品輪播圖',
    请输入产品描述: '請輸入產品描述',
    计量单位: '計量單位',
    请输入产品计量单位: '請輸入產品計量單位',
    产品库存: '產品庫存',
    产品销量: '產品銷量',
    产品成本价: '產品成本價',
    产品排序值: '產品排序值',
    产品重量: '產品重量',
    采购建议: '採購建議',
    请输入采购建议: '請輸入採購建議',
    于: '於',
    创建者创建时间: '創建者創建時間',
    最近操作时间: '最近操作時間',
    故事: '故事',
    数据周期: '數據周期',
    '请输入属性名：库存': '請輸入屬性名：庫存',
    '属性名：库存': '屬性名：庫存',
    请选择数据周期: '請選擇數據周期',
    数据类型: '數據類型',
    请选择数据类型: '請選擇數據類型',
    定时任务: '定時任務',
    请选择定时任务: '請選擇定時任務',
    目标组名称: '目標組名稱',
    '开始时间-结束时间': '開始時間-結束時間',
    目标标题: '目標標題',
    指标标题: '指標標題',
    图表类型: '圖表類型',
    目标组: '目標組',
    目标组标题: '目標組標題',
    请输入目标组标题: '請輸入目標組標題',
    目标组开始时间: '目標組開始時間',
    目标组结束时间: '目標組結束時間',
    目标组时间: '目標組時間',
    目标: '目標',
    请输入目标标题: '請輸入目標標題',
    所属目标组: '所屬目標組',
    请选择所属目标组: '請選擇所屬目標組',
    指标: '指標',
    请输入指标标题: '請輸入指標標題',
    数据源: '數據源',
    请选择数据源: '請選擇數據源',
    选择出数据源: '選擇出數據源',
    手动: '手動',
    自动: '自動',
    指标数据: '指標數據',
    重要不紧急: '重要不緊急',
    重要紧急: '重要緊急',
    不重要不紧急: '不重要不緊急',
    不重要紧急: '不重要緊急',
    所属指标: '所屬指標',
    横坐标: '橫坐標',
    数值: '數值',
    显示近一周: '顯示近一周',
    显示近一月: '顯示近一月',
    显示近一年: '顯示近一年',
    显示全部: '顯示全部',
    个人: '個人',
    团队: '團隊',
    预计提成: '預計提成',
    元: '元',
    '预计提成：团队比例': '預計提成：團隊比例',
    '计提人(团队)': '計提人(團隊)',
    当期金额: '當期金額',
    实际提成: '實際提成',
    订单编号: '訂單編號',
    团队提成: '團隊提成',
    服务金额: '服務金額',
    合计: '合計',
    条件: '條件',
    符合以下条件时按本规则计提: '符合以下條件時按本規則計提',
    添加条件: '添加條件',
    '元）': '元）',
    新建规则: '新建規則',
    '规则ID、规则名称': '規則ID、規則名稱',
    编辑规则: '編輯規則',
    '负责人/提计人': '負責人/提計人',
    产品分类下的扩展属性: '產品分類下的擴展屬性',
    产品属性: '產品屬性',
    设置方式: '設置方式',
    销售价格: '銷售價格',
    采购价格: '採購價格',
    按客户设置: '按客戶設置',
    价格为: '價格為',
    价格币种: '價格幣種',
    标准单价: '標準單價',
    按客户类别设置: '按客戶類別設置',
    默认调价比例: '默認調價比例',
    实际调价比例: '實際調價比例',
    起始年份: '起始年份',
    截止年份: '截止年份',
    补充年份: '補充年份',
    已上传: '已上傳',
    上传图片: '上傳圖片',
    共: '共',
    综述信息: '綜述信息',
    跟进信息: '跟進信息',
    详细跟进: '詳細跟進',
    请填写跟进内容: '請填寫跟進內容',
    发布: '發布',
    下次跟进: '下次跟進',
    绩效比例: '績效比例',
    '个人绩效相对团队绩效占比，剩余部分归负责人':
      '個人績效相對團隊績效占比，剩余部分歸負責人',
    最新价格: '最新價格',
    历史近期: '歷史近期',
    供应: '供應',
    已付金额: '已付金額',
    '当前订单付款总和，不扣除退款': '當前訂單付款總和，不扣除退款',
    '您确定要对当前数据生成入库单？': '您確定要對當前數據生成入庫單？',
    批量添加: '批量添加',
    请输入企业名称: '請輸入企業名稱',
    '您确定要对当前数据进行作废？': '您確定要對當前數據進行作廢？',
    请选择组装拆卸产品明细: '請選擇組裝拆卸產品明細',
    请选择组装拆卸产品: '請選擇組裝拆卸產品',
    箱单详情: '箱單詳情',
    '您确定要生成发货单？': '您確定要生成發貨單？',
    装箱数: '裝箱數',
    '未作废数据删除，库存将不会恢复是否删除？':
      '未作廢數據刪除，庫存將不會恢復是否刪除？',
    '您确定要作废当前数据？': '您確定要作廢當前數據？',
    箱单数量: '箱單數量',
    请输入预估所需箱单数: '請輸入預估所需箱單數',
    拆分数量: '拆分數量',
    装货箱编号: '裝貨箱編號',
    码: '碼',
    包装数量: '包裝數量',
    包装的数量: '包裝的數量',
    '单个重量(w/pc)': '單個重量(w/pc)',
    包装的重量: '包裝的重量',
    '单个体积(v/pc)': '單個體積(v/pc)',
    包装的体积: '包裝的體積',
    总重量: '總重量',
    总体积: '總體積',
    所属箱号: '所屬箱號',
    盘点详情: '盤點詳情',
    系统库存: '系統庫存',
    系统统计当前仓库的数量: '系統統計當前倉庫的數量',
    盘点数量: '盤點數量',
    盘亏盘盈: '盤虧盤盈',
    添加后请到出入库单列表审核: '添加後請到出入庫單列表審核',
    '剩余数量（冻结库存数量)': '剩余數量（凍結庫存數量)',
    操作员: '操作員',
    出入库单详情: '出入庫單詳情',
    '您确定要对当前数据生成出入库单？': '您確定要對當前數據生成出入庫單？',
    出入库进度: '出入庫進度',
    备注说明: '備註說明',
    产品调拨详情: '產品調撥詳情',
    组合件名称: '組合件名稱',
    单位成本: '單位成本',
    组合成本: '組合成本',
    操作时间: '操作時間',
    操作人: '操作人',
    业务类型: '業務類型',
    入库数量: '入庫數量',
    出库数量: '出庫數量',
    结存数量: '結存數量',
    库存低于预警库存: '庫存低於預警庫存',
    各仓库数量: '各倉庫數量',
    总库存: '總庫存',
    盘点时间: '盤點時間',
    盘点产品量: '盤點產品量',
    盘点人: '盤點人',
    中文: '中文',
    父级: '父級',
    请选择父级: '請選擇父級',
    记录: '記錄',
    租金: '租金',
    人流量: '人流量',
    是否转让: '是否轉讓',
    转让价格: '轉讓價格',
    流水: '流水',
    利润: '利潤',
    推荐星级: '推薦星級',
    星: '星',
    联系方式: '聯繫方式',
    商家: '商家',
    是否显示: '是否顯示',
    请输入店铺名称: '請輸入店鋪名稱',
    选址类型: '選址類型',
    请选择选址类型: '請選擇選址類型',
    请输入店铺地址: '請輸入店鋪地址',
    经度: '經度',
    请输入经度: '請輸入經度',
    纬度: '緯度',
    请输入纬度: '請輸入緯度',
    '租金(月)': '租金(月)',
    请输入租金: '請輸入租金',
    请输入人流量: '請輸入人流量',
    请输入流水: '請輸入流水',
    请输入利润: '請輸入利潤',
    请输入推荐星级: '請輸入推薦星級',
    请输入转让价格: '請輸入轉讓價格',
    请输入联系方式: '請輸入聯繫方式',
    竞品情况: '競品情況',
    请输入竞品情况: '請輸入競品情況',
    请输入教程名称: '請輸入教程名稱',
    教程类型: '教程類型',
    请选择教程类型: '請選擇教程類型',
    教程: '教程',
    教程名称: '教程名稱',
    前: '前',
    小时内: '小時內',
    分钟内: '分鐘內',
    销售趋势: '銷售趨勢',
    销售数量: '銷售數量',
    客户最新报价: '客戶最新報價',
    头像: '頭像',
    昵称: '昵稱',
    真实姓名: '真實姓名',
    手机号码: '手機號碼',
    最后登录时间: '最後登錄時間',
    电话号码: '電話號碼',
    请选择角色: '請選擇角色',
    请选择公司: '請選擇公司',
    请选择昵称: '請選擇昵稱',
    人员: '人員',
    公司简称: '公司簡稱',
    请输入公司简称: '請輸入公司簡稱',
    统一社会信用代码: '統一社會信用代碼',
    请输入统一社会信用代码: '請輸入統一社會信用代碼',
    企业法人: '企業法人',
    请输入企业法人: '請輸入企業法人',
    注册地址: '註冊地址',
    请输入注册地址: '請輸入註冊地址',
    注册日期: '註冊日期',
    请输入注册日期: '請輸入註冊日期',
    请选择注册日期: '請選擇註冊日期',
    联系电话: '聯繫電話',
    请输入联系电话: '請輸入聯繫電話',
    联系邮箱: '聯繫郵箱',
    请输入联系邮箱: '請輸入聯繫郵箱',
    站点域名: '站點域名',
    请输入站点域名: '請輸入站點域名',
    请输入ICP: '請輸入ICP',
    公司代号: '公司代號',
    请输入公司代号: '請輸入公司代號',
    公司简介: '公司簡介',
    请输入公司简介: '請輸入公司簡介',
    公司LOGO: '公司LOGO',
    站点LOGO: '站點LOGO',
    站点ICO: '站點ICO',
    登录页背景图: '登錄頁背景圖',
    部门名称: '部門名稱',
    手机: '手機',
    请输入部门名称: '請輸入部門名稱',
    部门人员: '部門人員',
    员工: '員工',
    请选择员工: '請選擇員工',
    职位: '職位',
    '您确定要对当前员工进行禁用？': '您確定要對當前員工進行禁用？',
    '您确定要对当前员工进行解除禁用？': '您確定要對當前員工進行解除禁用？',
    '您确定要对当前员工进行离职处理？': '您確定要對當前員工進行離職處理？',
    员工状态: '員工狀態',
    权限字段: '權限字段',
    编辑角色: '編輯角色',
    添加角色: '添加角色',
    角色名称: '角色名稱',
    请输入角色名称: '請輸入角色名稱',
    角色描述: '角色描述',
    数据权限: '數據權限',
    模块权限: '模塊權限',
    字段权限: '字段權限',
    请输入角色名: '請輸入角色名',
    组织架构: '組織架構',
    员工管理: '員工管理',
    标识: '標識',
    成员限制: '成員限制',
    成员数量: '成員數量',
    到期时间: '到期時間',
    站点: '站點',
    行业: '行業',
    请输入标识: '請輸入標識',
    管理员手机号: '管理員手機號',
    请输入管理员手机号: '請輸入管理員手機號',
    管理员姓名: '管理員姓名',
    请输入管理员姓名: '請輸入管理員姓名',
    公司行业: '公司行業',
    请选择公司行业: '請選擇公司行業',
    请选择到期時間: '請選擇到期時間',
    键: '鍵',
    必填: '必填',
    默认值: '默認值',
    占比: '佔比',
    请输入正确key: '請輸入正確key',
    字段: '字段',
    只能是英文数字下劃線的組合且以英文或者下劃線開頭:
      '只能是英文數字下劃線的組合且以英文或者下劃線開頭',
    请输入键: '請輸入鍵',
    请输入默认值: '請輸入默認值',
    输入提示: '輸入提示',
    请输入输入提示: '請輸入輸入提示',
    客户类型: '客戶類型',
    请输入客户类型名: '請輸入客戶類型名',
    请输入客户类型: '請輸入客戶類型',
    客户配置: '客戶配置',
    行业字段: '行業字段',
    行业名称: '行業名稱',
    行业权限: '行業權限',
    所属菜单: '所屬菜單',
    字段名称: '字段名稱',
    字段标识: '字段標識',
    列表宽度: '列表寬度',
    父级菜单: '父級菜單',
    请选择父级菜单: '請選擇父級菜單',
    请输入字段名称: '請輸入字段名稱',
    请输入字段标识: '請輸入字段標識',
    请输入列表宽度: '請輸入列表寬度',
    字段排序: '字段排序',
    请输入字段排序: '請輸入字段排序',
    菜单名称: '菜單名稱',
    菜单: '菜單',
    请输入菜单名称: '請輸入菜單名稱',
    菜单地址: '菜單地址',
    请输入菜单地址: '請輸入菜單地址',
    关联菜单: '關聯菜單',
    请选择关联菜单: '請選擇關聯菜單',
    菜单图标: '菜單圖標',
    请输入菜单图标: '請輸入菜單圖標',
    菜单排序: '菜單排序',
    请输入菜单排序: '請輸入菜單排序',
    操作名称: '操作名稱',
    操作代码: '操作代碼',
    操作地址: '操作地址',
    表头操作: '表頭操作',
    列表操作: '列表操作',
    选项卡操作: '選項卡操作',
    请输入操作名称: '請輸入操作名稱',
    不输入则与操作名称相同: '不輸入則與操作名稱相同',
    请输入操作代码: '請輸入操作代碼',
    操作图标: '操作圖標',
    请输入操作图标: '請輸入操作圖標',
    请输入操作地址: '請輸入操作地址',
    操作排序: '操作排序',
    请输入操作排序: '請輸入操作排序',
    显示在表头: '顯示在表頭',
    显示在列表: '顯示在列表',
    显示在选项卡: '顯示在選項卡',
    所属企业: '所屬企業',
    企业: '企業',
    选择企业: '選擇企業',
    操作管理: '操作管理',
    字段管理: '字段管理',
    设备调试: '設備調試',
    门锁状态: '門鎖狀態',
    物品状态: '物品狀態',
    门锁编号: '門鎖編號',
    开锁: '開鎖',
    检测: '檢測',
    通电: '通電',
    断电: '斷電',
    门锁操作: '門鎖操作',
    门锁打开: '門鎖打開',
    门锁已开启: '門鎖已開啟',
    门锁关闭: '門鎖關閉',
    门锁已关闭: '門鎖已關閉',
    发信昵称: '發信昵稱',
    邮箱服务商: '郵箱服務商',
    默认发信邮箱: '默認發信郵箱',
    请输入发信昵称: '請輸入發信昵稱',
    请选择邮箱服务商: '請選擇郵箱服務商',
    密码: '密碼',
    语种: '語種',
    请选择语种: '請選擇語種',
    '内容-头部': '內容-頭部',
    '内容-尾部': '內容-尾部',
    用户名: '用戶名',
    性别: '性別',
    个人简介: '個人簡介',
    我的邮箱: '我的郵箱',
    重置密码: '重置密碼',
    短信验证码: '短信驗證碼',
    新密码: '新密碼',
    供应商分类: '供應商分類',
    车型配置: '車型配置',
    部件配置: '部件配置',
    行业模板: '行業模板',
    '请选择行业模板(非必填)': '請選擇行業模板(非必填)',
    分类图片: '分類圖片',
    英文名称: '英文名稱',
    顶级分类: '頂級分類',
    请输入搜索内容: '請輸入搜索內容',
    父级分类: '父級分類',
    请选择父级分类: '請選擇父級分類',
    '分类名称（英文）': '分類名稱（英文）',
    '请输入分类名称（英文）': '請輸入分類名稱（英文）',
    分类排序: '分類排序',
    类型标题: '類型標題',
    类型名称: '類型名稱',
    请输入类型名称: '請輸入類型名稱',
    仓库设置: '倉庫設置',
    图片数量: '圖片數量',
    宽度: '寬度',
    高度: '高度',
    素材名称: '素材名稱',
    分类标题: '分類標題',
    事项分类: '事項分類',
    故事分类: '故事分類',
    汇率设置: '匯率設置',
    人民币: '人民幣',
    前缀: '前綴',
    请选择中间规则: '請選擇中間規則',
    请选择后缀规则: '請選擇後綴規則',
    产品编号规则: '產品編號規則',
    '前缀如CP，类型代码需要在类型信息里面增加':
      '前綴如CP，類型代碼需要在類型信息裡面增加',
    空: '空',
    类型id: '類型id',
    类型代码: '類型代碼',
    自增ID: '自增ID',
    客户编号规则: '客戶編號規則',
    前缀如KH: '前綴如KH',
    国家ID: '國家ID',
    国家代码: '國家代碼',
    销售单名称规则: '銷售單名稱規則',
    前缀如XSDD: '前綴如XSDD',
    客户ID: '客戶ID',
    年月日: '年月日',
    采购单名称规则: '採購單名稱規則',
    前缀如CGD: '前綴如CGD',
    供应商ID: '供應商ID',
    销售报价权限: '銷售報價權限',
    '严格模式下，报价低于产品最低售价不可提交，轻松模式下可以提交，会给出明显警告':
      '嚴格模式下，報價低於產品最低售價不可提交，輕鬆模式下可以提交，會給出明顯警告',
    宽松模式: '寬松模式',
    采购报价权限: '採購報價權限',
    '严格模式下，采购高于供应商报价不可提交，轻松模式下可以提交，会给出明显警告':
      '嚴格模式下，採購高於供應商報價不可提交，輕鬆模式下可以提交，會給出明顯警告',
    基本配置: '基本配置',
    '用户ID/用户名': '用戶ID/用戶名',
    路径: '路徑',
    请求ID: '請求ID',
    规则: '規則',
    执行类: '執行類',
    执行方法: '執行方法',
    是否启用: '是否啟用',
    任务标题: '任務標題',
    请输入任务标题: '請輸入任務標題',
    任务规则: '任務規則',
    请输入任务规则: '請輸入任務規則',
    如: '如',
    则代表每: '則代表每',
    分钟执行一次: '分鐘執行一次',
    请输入执行类: '請輸入執行類',
    请输入执行方法: '請輸入執行方法',
    任务类型: '任務類型',
    请选择任务类型: '請選擇任務類型',
    参数格式: '參數格式',
    '请输入参数格式(必須為json格式),可不填寫':
      '請輸入參數格式(必須為json格式),可不填寫',
    数据组类型: '數據組類型',
    请选择数据组类型: '請選擇數據組類型',
    属性: '屬性',
    '请输入属性key(唯一)：name': '請輸入屬性key(唯一)：name',
    '属性key(唯一)：name': '屬性key(唯一)：name',
    属性类型: '屬性類型',
    排序值: '排序值',
    请填写选项配置值: '請填寫選項配置值',
    参数方式例如: '參數方式例如',
    白色: '白色',
    红色: '紅色',
    黑色: '黑色',
    添加属性: '添加屬性',
    数据组名称: '數據組名稱',
    数据组描述: '數據組描述',
    请输入关键字: '請輸入關鍵字',
    扩展属性: '擴展屬性',
    请输入数据组名称: '請輸入數據組名稱',
    请输入数据组描述: '請輸入數據組描述',
    请上传: '請上傳',
    主体名称: '主體名稱',
    域名: '域名',
    站点标题: '站點標題',
    团队名称: '團隊名稱',
    主体: '主體',
    所属: '所屬',
    请输入所属: '請輸入所屬',
    请输入主体名称: '請輸入主體名稱',
    请输入税号: '請輸入稅號',
    法人: '法人',
    请输入法人: '請輸入法人',
    请输入站点标题: '請輸入站點標題',
    主体简介: '主體簡介',
    请输入主体简介: '請輸入主體簡介',
    主体LOGO: '主體LOGO',
    站点背景: '站點背景',
    数据配置: '數據配置',
    主体管理: '主體管理',
    账号: '賬號',
    请输入账号: '請輸入賬號',
    周期: '周期',
    日志类型: '日誌類型',
    执行结果: '執行結果',
    登录成功: '登錄成功',
    訪問時間: '訪問時間',
    登录IP: '登錄IP',
    下载模版: '下載模版',
    枚举变量: '枚舉變量',
    模版文件: '模版文件',
    僅支持Xlsx: '僅支持Xlsx',
    若為Xls文件請先在Excel中另存為Xlsx: '若為Xls文件請先在Excel中另存為Xlsx',
    '添加完成后，请点击字段集编辑完善模版填充配置信息':
      '添加完成后，请點擊字段集編輯完善模版填充配置信息',
    變量: '變量',
    為單一數據占位: '為單一數據占位',
    為列數據占位: '為列數據占位',
    字段集: '字段集',
    '文件名称(点击预览)': '文件名稱(點擊預覽)',
    文件大小: '文件大小',
    數據填充起始行: '數據填充起始行',
    模板选择: '模板選擇',
    無模板导出: '無模板導出',
    模板导出: '模板導出',
    無模板打印: '無模板打印',
    模板打印: '模板打印',
    请选择模板: '請選擇模板',
    '正在导出中...': '正在導出中...',
    '正在打印中...': '正在打印中...',
    单元格: '單元格',
    多行项: '多行項',
    是否求和: '是否求和',
    是否图片: '是否圖片',
    是否合并: '是否合並',
    图片宽度: '圖片寬度',
    图片高度: '圖片高度',
    关联合并: '關聯合並',
    请选择字段: '請選擇字段',
    链接: '鏈接',
    轮播图设置: '輪播圖設置',
    首页分类商品设置: '首頁分類商品設置',
    '此内容依赖于：产品》产品分类，您可以勾选你需要展示的分类，勾选后分类将出现在首页f分类商品中。':
      '此內容依賴於：產品》產品分類，您可以勾選你需要展示的分類，勾選后分類將出現在首頁分類商品中。',
    首页分类导航设置: '首頁分類導航設置',
    '此内容依赖于：产品》产品分类，您可以勾选你需要展示的分类，勾选后分类将出现在首页导航栏中。':
      '此內容依賴於：產品》產品分類，您可以勾選你需要展示的分類，勾選后分類將出現在首頁導航欄中。',
    导航设置: '導航設置',
    站点名称: '站點名稱',
    请输入站点名称: '請輸入站點名稱',
    '对SEO很重要,不要堆砌关键字': '對SEO很重要,不要堆砌關鍵字',
    站点描述: '站點描述',
    请输入站点描述: '請輸入站點描述',
    '站点的描述，用于展示在搜素结果': '站點的描述，用於展示在搜素結果',
    站点关键词: '站點關鍵詞',
    请输入站点关键词: '請輸入站點關鍵詞',
    '搜索时会匹配对应的关键词，不要重复': '搜索時會匹配對應的關鍵詞，不要重復',
    请输入公司地址: '請輸入公司地址',
    社媒设置: '社媒設置',
    联系我们: '聯系我們',
    请输入正确的网址信息: '請輸入正確的網址信息',
    请输入facebook主页地址: '請輸入facebook主頁地址',
    请输入twitter主页地址: '請輸入twitter主頁地址',
    请输入youtube主页地址: '請輸入youtube主頁地址',
    请输入linkedin主页地址: '請輸入linkedin主頁地址',
    请输入instagram主页地址: '請輸入instagram主頁地址',
    页尾设置: '頁尾設置',
    '您确定要作废当前数据?': '您確定要作廢當前數據?',
    实施明细: '實施明細',
    任务状态: '任務狀態',
    参与人: '參與人',
    未开始: '未開始',
    任务详情: '任務詳情',
    任务概况: '任務概況',
    完成时间: '完成時間',
    '您确定要作废此任务？': '您確定要作廢此任務？',
    '您确定已完成此任务？': '您確定已完成此任務？',
    请选择团队成员: '請選擇團隊成員',
    请填写比例: '請填寫比例',
    请填写分成比例: '請填寫分成比例',
    进度: '進度',
    分成比例不能低于: '分成比例不能低於',
    或高于: '或高於',
    钱包金额: '錢包金額',
    用户角色: '用戶角色',
    注册时间: '註冊時間',
    用户: '用戶',
    请输入真实姓名: '請輸入真實姓名',
    用户邮箱: '用戶郵箱',
    请输入用户邮箱: '請輸入用戶郵箱',
    请输入正确的用户邮箱地址: '請輸入正確的用戶郵箱地址',
    性别选择: '性別選擇',
    所属团队: '所屬團隊',
    请选择团队: '請選擇團隊',
    所属角色: '所屬角色',
    用户密码: '用戶密碼',
    请输入用户密码: '請輸入用戶密碼',
    用户头像: '用戶頭像',
    用户昵称: '用戶暱稱',
    回复: '回復',
    反馈: '反饋',
    请输入回复: '請輸入回復',
    启用状态: '啟用狀態',
    用户来源: '用戶來源',
    积分: '積分',
    请输入用户昵称: '請輸入用戶暱稱',
    请选择用户角色: '請選擇用戶角色',
    未知功能: '未知功能',
    成员头像: '成員頭像',
    成员名称: '成員名稱',
    成员角色: '成員角色',
    管理产品: '管理產品',
    请输入团队名称: '請輸入團隊名稱',
    设备区域: '設備區域',
    请选择设备区域: '請選擇設備區域',
    团队简介: '團隊簡介',
    请输入团队简介: '請輸入團隊簡介',
    团队头像: '團隊頭像',
    HS编码: 'HS編碼',
    // TODO: 2023-11-21
    "产品组成：产品A由B/C/D组成,组合的产品A在销售和库存环节跟其他产品一样。在箱单处理环节可以自动拆分":
      "產品組成：產品A由B/C/D組成，組合的產品A在銷售和庫存環節跟其他產品一樣。在箱單處理環節可以自動拆分",
    "M会自动为您过滤": "M會自動為您過濾",
    "S后刷新项目": "S後刷新項目",
    "客户投诉": "客戶投訴",
    "配置项": "配置項",
    "login核心接口异常": "login核心接口異常",
    "请检查返回JSON格式是否正确，是否正确返回": "請檢查返回JSON格式是否正確，是否正確返回",
    "getUserInfo核心接口异常": "getUserInfo核心接口異常",
    "请检查返回JSON格式是否正确": "請檢查返回JSON格式是否正確",
    "不含税": "不含稅",
    "产品质量": "產品質量",
    "发货速度": "發貨速度",
    "售后服务": "售後服務",
    "客诉": "客訴",
    "客诉标题": "客訴標題",
    "请输入客诉标题": "請輸入客訴標題",
    "客诉类型": "客訴類型",
    "关联信息": "關聯信息",
    "请选择关联信息": "請選擇關聯信息",
    "客诉原因": "客訴原因",
    "请输入客诉原因": "請輸入客訴原因",
    "valFun是一个函数": "valFun是一個函數",
    "产品组成": "產品組成",
    "最近销售时间": "最近銷售時間",
    "EXW价格": "EXW價格",
    "FOB价格": "FOB價格",
    "厂商税率": "廠商稅率",
    "最近采购时间": "最近采購時間",
    "采购次数": "采購次數",
    "产品供应商分布": "產品供應商分佈",
    "OEM码": "OEM碼",
    "附件信息": "附件信息",
    "原因": "原因",
    "URL地址": "URL地址",
    // TODO: 2023-11-22
    "含税": "含稅",
    "请输入采购税率": "請輸入採購稅率",
    "是否含税": "是否含稅",
    "运输费用": "運輸費用",
    "请选择客诉类型": "請選擇客訴類型",
    "客诉信息": "客訴信息",
    "请输入客户WhatsApp": "請輸入客戶WhatsApp",
    "仅支持Xlsx,若为Xls文件请先在Excel中另存为Xlsx": "僅支持Xlsx，若為Xls檔案請先在Excel中另存為Xlsx",
    // TODO: 2023-11-24
    "设为主图": "設為主圖",
    "包邮": "包郵",
    "不包邮": "不包郵",
    "邮费": "運費",
    "减少": "減少",
    "增加": "增加",
    "产品图文": "產品圖文",
    "图文列表": "圖文列表",
    "图文生成": "圖文生成",
    "金豆记录": "金豆記錄",
    "是否包邮": "是否包郵",
    "抠图图集": "抠圖圖集",
    "金豆变更": "金豆變更",
    "选择产品型号": "選擇產品型號",
    "预览图文": "預覽圖文",
    "生成PDF": "生成PDF",
    "当前只可选择生成三级分类产品，若需生成完整产品图文，请先完善三级分类": "當前只能選擇生成三級分類產品，如果需要生成完整產品圖文，請先完善三級分類。",
    "生成请求已提交": "生成請求已提交",
    "可前往产品图文列表页查看进度": "可前往產品圖文列表頁查看進度",
    "返回上页": "返回上頁",
    "开始生成": "開始生成",
    "重新生成": "重新生成",
    "前往列表页": "前往列表頁",
    "金豆剩余": "金豆剩餘",
    "消费类型": "消費類型",
    "消费金豆": "消費金豆",
    "消费前": "消費前",
    "消费后": "消費後",
    "消费时间": "消費時間",
    "请选择消费类型": "請選擇消費類型",
    "您确定要对当前员工进行恢复？": "您確定要對當前員工進行恢復？",
    金豆使用规则: '金豆使用規則',
    // TODO: 2024-05-27
    "长": "長",
    "厘米": "厘米",
    "宽": "寬",
    "不能为空": "不能為空",
    "购买数量": "購買數量",
    "预览（可根据买家采购的不同数量设置不同价格）": "預覽（可根據買家採購的不同數量設置不同價格）",
    "可售数量": "可售數量",
    "单品货号": "單品貨號",
    "是否上架": "是否上架",
    "上架店铺": "上架店鋪",
    "请选择店铺": "請選擇店鋪",
    "商品类目": "商品類目",
    "上一步": "上一步",
    "提交": "提交",
    "下一步": "下一步",
    "选择类目": "選擇類目",
    "新增产品": "新增產品",
    "修改产品": "修改產品",
    "使用运费模版": "使用運費模板",
    "运费指导模版": "運費指導模板",
    "卖家承担运费": "賣家承擔運費",
    "库存详情": "庫存詳情",
    "国内仓": "國內倉",
    "荔枝角": "荔枝角",
    "深水埗": "深水埗",
    "产品报价低于最低售价不可提交,请调整!": "產品報價低於最低售價不可提交，請調整！",
    "产品报价低于最低售价!": "產品報價低於最低售價！",
    "产品报价高于最低采购价不可提交,请调整!": "產品報價高於最低採購價不可提交，請調整！",
    "产品报价高于最低采购价!": "產品報價高於最低採購價！",
    "工单": "工單",
    "工序名称": "工序名稱",
    "加工时长": "加工時長",
    "默认仓库": "默認倉庫",
    "尺寸/规格": "尺寸/規格",
    "所述仓库": "所述倉庫",
    "绑定": "綁定",
    "选择": "選擇",
    "运费": "運費",
    "已更换产品，需重新打印标签贴标": "已更換產品，需重新打印標籤貼標",
    "规格材质": "規格材質",
    "蓝色字体表示建议售价，绿色字体表示历史成交报价": "藍色字體表示建議售價，綠色字體表示歷史成交報價",
    "建议采购价": "建議採購價",
    "智能选择供应商": "智能選擇供應商",
    "品规": "品規",
    "体积/净重/长-宽-高": "體積/淨重/長-寬-高",
    "盒规": "盒規",
    "您确定要对当前数据进行一键采购？": "您確定要對當前數據進行一鍵採購？",
    "您确定要对当前数据进行一键备货？": "您確定要對當前數據進行一鍵備貨？",
    "当前产品明细数据有未关联的产品请关联产品?": "當前產品明細數據有未關聯的產品請關聯產品？",
    "您当前报价低于最低售价的价格，请修改！": "您當前報價低於最低售價的價格，請修改！",
    "箱单信息": "箱單信息",
    "混装": "混裝",
    "添加箱子": "添加箱子",
    "请选择箱规": "請選擇箱規",
    "请选择箱号": "請選擇箱號",
    "支持产品名称/OEM码/编码搜索": "支持產品名稱/OEM碼/編碼搜索",
    "降序": "降序",
    "升序": "升序",
    "月结": "月結",
    "寄付": "寄付",
    "到付": "到付",
    "明天": "明天",
    "后天": "後天",
    "深水涉": "深水涉",
    "待入库": "待入庫",
    "在仓": "在倉",
    "上车": "上車",
    "取消派件": "取消派件",
    "发往客户": "發往客戶",
    "已送达": "已送達",
    "元天屯": "元天屯",
    "九龍": "九龍",
    "新界": "新界",
    "港島": "港島",
    "離島/偏遠": "離島/偏遠",
    "九東": "九東",
    "港東": "港東",
    "客户账单": "客戶賬單",
    "供应商账单": "供應商賬單",
    "运营报表": "運營報表",
    "产品面板": "產品面板",
    "电商产品": "電商產品",
    "报价单表": "報價單表",
    "产品图册": "產品圖冊",
    "产品箱规": "產品箱規",
    "私有线索": "私有線索",
    "公海线索": "公海線索",
    "销售面板": "銷售面板",
    "抖音订单": "抖音訂單",
    "营销": "營銷",
    "我方渠道": "我方渠道",
    "竞对渠道": "競對渠道",
    "我方店铺": "我方店鋪",
    "竞对店铺": "競對店鋪",
    "渠道类型": "渠道類型",
    "对账列表": "對賬列表",
    "账单明细": "賬單明細",
    "发货单(家具)": "發貨單(家具)",
    "派货行程(家具)": "派貨行程(家具)",
    "今日计划": "今日計劃",
    "收货单": "收貨單",
    "商品代发": "商品代發",
    "商品退货": "商品退貨",
    "商品索赔": "商品索賠",
    "库存余额(家具)": "庫存餘額(家具)",
    "快递寄件": "快遞寄件",
    "收费标准": "收費標準",
    "工厂": "工廠",
    "生产计划": "生產計劃",
    "生产工单": "生產工單",
    "委外管理": "委外管理",
    "委外订单": "委外訂單",
    "整单委外": "整單委外",
    "工序委外": "工序委外",
    "生产执行": "生產執行",
    "生产工序": "生產工序",
    "报工单": "報工單",
    "物料管理": "物料管理",
    "领料单": "領料單",
    "退料单": "退料單",
    "质量管理": "質量管理",
    "工序质检": "工序質檢",
    "委外质检": "委外質檢",
    "BOM管理": "BOM管理",
    "产品BOM": "產品BOM",
    "BOM清单": "BOM清單",
    "工序工艺": "工序工藝",
    "工艺": "工藝",
    "工序": "工序",
    "设备列表": "設備列表",
    "设备类型": "設備類型",
    "三方应用": "三方應用",
    "硬件配置": "硬件配置",
    "费用记录": "費用記錄",
    "仓储体积": "倉儲體積",
    "导出记录": "導出記錄",
    "是否KP": "是否KP",
    "起订量": "起訂量",
    "部件备注": "部件備註",
    "原产地": "原產地",
    "产品评分": "產品評分",
    "供应链评分": "供應鏈評分",
    "账期客户": "賬期客戶",
    "感兴趣产品": "感興趣產品",
    "客户折扣": "客戶折扣",
    "采购类型": "採購類型",
    "线索名称": "線索名稱",
    "线索来源": "線索來源",
    "线索阶段": "線索階段",
    "线索编码": "線索編碼",
    "客户地址": "客戶地址",
    "客户区域": "客戶區域",
    "产品类别": "產品類別",
    "订单备注": "訂單備註",
    "后台备注": "後台備註",
    "送货日期": "送貨日期",
    "选择日期": "選擇日期",
    "是否账期": "是否賬期",
    "关联订单号": "關聯訂單號",
    "订单支付状态": "訂單支付狀態",
    "配送方式": "配送方式",
    "收件电话": "收件電話",
    "收件地址": "收件地址",
    "快递单号": "快遞單號",
    "收货人信息": "收貨人信息",
    "所在州": "所在州",
    "运输保险": "運輸保險",
    "货品价值": "貨品價值",
    "签收选项": "簽收選項",
    "寄件人信息": "寄件人信息",
    "商品体积": "商品體積",
    "商品重量": "商品重量",
    "商品货值": "商品貨值",
    "快递费用": "快遞費用",
    "包裹信息": "包裹信息",
    "长(in)": "長(in)",
    "宽(in)": "寬(in)",
    "高(in)": "高(in)",
    "重量(lb)": "重量(lb)",
    "计算费用": "計算費用",
    "快递公司": "快遞公司",
    "快递面签": "快遞面簽",
    "预约日期": "預約日期",
    "预约时间": "預約時間",
    "退货照片": "退貨照片",
    "外观照片": "外觀照片",
    "拆开照片": "拆開照片",
    "处理方式": "處理方式",
    "退货进度": "退貨進度",
    "索赔结果": "索賠結果",
    "订单截图": "訂單截圖",
    "证明图片": "證明圖片",
    "适用产品": "適用產品",
    "版本号": "版本號",
    "计划单号": "計劃單號",
    "生产类型": "生產類型",
    "计划生产数量": "計劃生產數量",
    "计划开工日期": "計劃開工日期",
    "计划完工日期": "計劃完工日期",
    "生产产品": "生產產品",
    "生产批次号": "生產批次號",
    "生产单号": "生產單號",
    "生产状态": "生產狀態",
    "延期情况": "延期情況",
    "委外状态": "委外狀態",
    "入库日期": "入庫日期",
    "排产人": "排產人",
    "生产完成率": "生產完成率",
    "已完工数量": "已完工數量",
    "待入库数量": "待入庫數量",
    "已入库数量": "已入庫數量",
    "工序计划数量": "工序計劃數量",
    "工序完工数量": "工序完工數量",
    "报废数": "報廢數",
    "返工数": "返工數",
    "总生产成本": "總生產成本",
    "加工成本": "加工成本",
    "物料成本": "物料成本",
    "委外编号": "委外編號",
    "委外供应商": "委外供應商",
    "委外类型": "委外類型",
    "委外产品": "委外產品",
    "委外数量": "委外數量",
    "委外单价": "委外單價",
    "委外日期": "委外日期",
    "委外总额": "委外總額",
    "委外应付总额": "委外應付總額",
    "委外已付总额": "委外已付總額",
    "已退货数量": "已退貨數量",
    "质检不合格数量": "質檢不合格數量",
    "质检合格率": "質檢合格率",
    "工序编号": "工序編號",
    "工序状态": "工序狀態",
    "执行工序": "執行工序",
    "工艺路线": "工藝路線",
    "工序执行人": "工序執行人",
    "工序计划生产数": "工序計劃生產數",
    "实际完工日期": "實際完工日期",
    "待完工数量": "待完工數量",
    "完工良品数": "完工良品數",
    "完工良品率": "完工良品率",
    "工序完成率": "工序完成率",
    "返工数量": "返工數量",
    "返工率": "返工率",
    "报废数量": "報廢數量",
    "报废率": "報廢率",
    "报工单编号": "報工單編號",
    "生产数量": "生產數量",
    "生产人员": "生產人員",
    "报工日期": "報工日期",
    "质检状态": "質檢狀態",
    "良品数量": "良品數量",
    "生产工资": "生產工資",
    "工序单价": "工序單價",
    "问题描述": "問題描述",
    "问题图片": "問題圖片",
    "物料申请单号": "物料申請單號",
    "审批状态": "審批狀態",
    "申请人": "申請人",
    "申请时间": "申請時間",
    "委外工单": "委外工單",
    "设备名称": "設備名稱",
    "设备编码": "設備編碼",
    "设备型号": "設備型號",
    "外观尺寸": "外觀尺寸",
    "设备用途": "設備用途",
    "管理负责人": "管理負責人",
    "投产日期": "投產日期",
    "采购日期": "採購日期",
    "生产日期": "生產日期",
    "生产厂家": "生產廠家",
    "用途": "用途",
    "设备图集": "設備圖集",
    "请输入OEM码多个": "請輸入OEM碼多個",
    "分割": "分割",
    "请选择产品类别": "請選擇產品類別",
    "是否关键产品": "是否關鍵產品",
    "请选择原产地": "請選擇原產地",
    "请输入部件备注": "請輸入部件備註",
    "箱规信息已移至详情页面添加编辑": "箱規信息已移至詳情頁面添加編輯",
    "产品规格": "產品規格",
    "复制产品": "複製產品",
    "图片设计": "圖片設計",
    "箱规类型": "箱規類型",
    "请选择箱规类型": "請選擇箱規類型",
    "请输入产品数量": "請輸入產品數量",
    "可以容纳的产品个数": "可以容納的產品個數",
    "默认箱规": "默認箱規",
    "费用箱规": "費用箱規",
    "计算货品体积费用时的箱规": "計算貨品體積費用時的箱規",
    "长(cm)": "長(cm)",
    "请输入箱规长": "請輸入箱規長",
    "宽(cm)": "寬(cm)",
    "请输入箱规宽": "請輸入箱規寬",
    "高(cm)": "高(cm)",
    "请输入箱规高": "請輸入箱規高",
    "请输入箱规重量": "請輸入箱規重量",
    "请输入箱规体积": "請輸入箱規體積",
    "所属分类": "所屬分類",
    "请选择所属分类": "請選擇所屬分類",
    "用于生成电商网站的链接,可不填写": "用於生成電商網站的鏈接,可不填寫",
    "图册": "圖冊",
    "文件请到附件中上传": "文件請到附件中上傳",
    "产品id": "產品id",
    "渠道名称": "渠道名稱",
    "请选择渠道名称": "請選擇渠道名稱",
    "请输入链接地址": "請輸入鏈接地址",
    "请输入销售价格": "請輸入銷售價格",
    "售出产品数": "售出產品數",
    "请输入售出产品数量": "請輸入售出產品數量",
    "拉取三方店铺商品": "拉取第三方店鋪商品",
    "拉取三方店铺订单": "拉取第三方店鋪訂單",
    "三方店铺": "第三方店鋪",
    "请选择三方店铺": "請選擇第三方店鋪",
    "拉取": "拉取",
    "当前快递单号为": "當前快遞單號為",
    "下单成功": "下單成功",
    "保存并打印": "保存並打印",
    "请输入订单号": "請輸入訂單號",
    "客户信息": "客戶信息",
    "智能填写": "智能填寫",
    "粘贴收货信息到此处，将自动识别姓名/电话/地址": "粘貼收貨信息到此處，將自動識別姓名/電話/地址",
    "客户电话": "客戶電話",
    "请输入客户电话": "請輸入客戶電話",
    "已存在客户": "已存在客戶",
    "是否使用当前客户信息？": "是否使用當前客戶信息？",
    "客户姓名": "客戶姓名",
    "请输入客户姓名": "請輸入客戶姓名",
    "收货地址": "收貨地址",
    "请输入收货地址": "請輸入收貨地址",
    "销售点": "銷售點",
    "请选择销售点": "請選擇銷售點",
    "付款信息": "付款信息",
    "是否已付款": "是否已付款",
    "您的报价产品中有低于最低售价的价格，请检查！": "您的報價產品中有低於最低售價的價格，請檢查！",
    "线索": "線索",
    "国家地区": "國家地區",
    "请输入客户感兴趣产品": "請輸入客戶感興趣產品",
    "是否KA": "是否KA",
    "请输入客户网址": "請輸入客戶網址",
    "请输入客户地区": "請輸入客戶地區",
    "请输入客户折扣百分比": "請輸入客戶折扣百分比",
    "请输入比分比": "請輸入比分比",
    "请选择线索阶段": "請選擇線索階段",
    "请输入收件人": "請輸入收件人",
    "请输入收件电话": "請輸入收件電話",
    "请输入收件地址": "請輸入收件地址",
    "请选择渠道类型": "請選擇渠道類型",
    "渠道分类": "渠道分類",
    "请选择渠道分类": "請選擇渠道分類",
    "快递账号": "快遞賬號",
    "请选择快递账号": "請選擇快遞賬號",
    "系统配置": "系統配置",
    "快递账号配置": "快遞賬號配置",
    "中添加": "中添加",
    "请输入采购单单号": "請輸入採購單單號",
    "账期": "賬期",
    "添加付款明细": "添加付款明細",
    "请输入昵称": "請輸入昵稱",
    "密码为手机号后六位": "密碼為手機號後六位",
    "请选择部门": "請選擇部門",
    "供应商账号": "供應商賬號",
    "关联供应商": "關聯供應商",
    "入库预报": "入庫預報",
    "请选择配送方式": "請選擇配送方式",
    "交货时间": "交貨時間",
    "收货人姓名": "收貨人姓名",
    "请输入收货人姓名": "請輸入收貨人姓名",
    "收货人电话": "收貨人電話",
    "请输入收货人电话": "請輸入收貨人電話",
    "收货人邮箱": "收貨人郵箱",
    "请输入收货人邮箱": "請輸入收貨人郵箱",
    "收货人地址": "收貨人地址",
    "请输入收货人地址": "請輸入收貨人地址",
    "请输入城市": "請輸入城市",
    "州": "州",
    "请输入州": "請輸入州",
    "请选择国家": "請選擇國家",
    "请输入邮编": "請輸入郵編",
    "请输入包装要求": "請輸入包裝要求",
    "寄件人": "寄件人",
    "请选择寄件人信息": "請選擇寄件人信息",
    "请选择签收选项": "請選擇簽收選項",
    "请选择运输保险": "請選擇運輸保險",
    "商品代发单": "商品代發單",
    "快递索赔单": "快遞索賠單",
    "商品退货单": "商品退貨單",
    "请输入快递单号": "請輸入快遞單號",
    "请选择处理方式": "請選擇處理方式",
    "将收取一定的费用": "將收取一定的費用",
    "请输入备注信息": "請輸入備註信息",
    "退货拍照(拆开)": "退貨拍照(拆開)",
    "退货拍照(外观)": "退貨拍照(外觀)",
    "提交后将会通知供货商": "提交後將會通知供貨商",
    "选择处理方式": "選擇處理方式",
    "处理费用": "處理費用",
    "请输入处理费用": "請輸入處理費用",
    "商品快递索赔": "商品快遞索賠",
    "请输入货品价值": "請輸入貨品價值",
    "请输入索赔原因": "請輸入索賠原因",
    "结果": "結果",
    "请选择结果": "請選擇結果",
    "请输入费用": "請輸入費用",
    "三方发货": "三方發貨",
    "多个用英文逗号隔开": "多個用英文逗號隔開",
    "等": "等",
    "件产品": "件產品",
    "请选择发货计划": "請選擇發貨計劃",
    "收件人信息": "收件人信息",
    "收件人姓名": "收件人姓名",
    "请输入收件人姓名": "請輸入收件人姓名",
    "收件人手机号": "收件人手機號",
    "请输入收件人手机号": "請輸入收件人手機號",
    "收件人地址": "收件人地址",
    "请输入收件人地址": "請輸入收件人地址",
    "省市区县街道": "省市區縣街道",
    "快捷选择寄件人信息": "快捷選擇寄件人信息",
    "更多快捷寄件人信息请到": "更多快捷寄件人信息請到",
    "发货地址": "發貨地址",
    "寄件人姓名": "寄件人姓名",
    "请输入寄件人姓名": "請輸入寄件人姓名",
    "寄件人手机号": "寄件人手機號",
    "请输入寄件人手机号": "請輸入寄件人手機號",
    "寄件人地址": "寄件人地址",
    "请输入寄件人地址": "請輸入寄件人地址",
    "快递信息": "快遞信息",
    "请选择快递公司": "請選擇快遞公司",
    "物品名称": "物品名稱",
    "请输入物品名称": "請輸入物品名稱",
    "支付方式": "支付方式",
    "请选择支付方式": "請選擇支付方式",
    "物品总重量(Kg)": "物品總重量(Kg)",
    "请输入物品总重量": "請輸入物品總重量",
    "无需单位": "無需單位",
    "寄件": "寄件",
    "下单中...": "下單中...",
    "立即打印面单": "立即打印面單",
    "打印机": "打印機",
    "请选择打印机": "請選擇打印機",
    "快递配置": "快遞配置",
    "请选择快递配置": "請選擇快遞配置",
    "请选择委外订单": "請選擇委外訂單",
    "请输入产品名": "請輸入產品名",
    "名片": "名片",
    "是否固定": "是否固定",
    "默认排序": "默認排序",
    "排序方式": "排序方式",
    "设置默认排序字段": "設置默認排序字段",
    "请输入HSCODE": "請輸入HSCODE",
    "别名": "別名",
    "请输入别名,多个用英文逗号隔开": "請輸入別名,多個用英文逗號隔開",
    "销售渠道": "銷售渠道",
    "推广渠道": "推廣渠道",
    "关联店铺": "關聯店鋪",
    "请选择关联店铺": "請選擇關聯店鋪",
    "请输入渠道名称": "請輸入渠道名稱",
    "快速调价": "快速調價",
    "当前修改影响": "當前修改影響",
    "条数据,总价": "條數據,總價",
    "是否继续修改?": "是否繼續修改?",
    "新购产品按建议价格": "新購產品按建議價格",
    "请输入倍率": "請輸入倍率",
    "倍调价": "倍調價",
    "复购产品按历史底价": "復購產品按歷史底價",
    "所有产品按当前价格": "所有產品按當前價格",
    "打印模版": "打印模版",
    "硬件设备": "硬件設備",
    "购买打印机": "購買打印機",
    "飞鹅云标签打印机": "飛鵝雲標籤打印機",
    "佳博云标签打印机": "佳博雲標籤打印機",
    "SN编码": "SN編碼",
    "请输入SN编码": "請輸入SN編碼",
    "是否默认": "是否默認",
    "标签纸长(mm)": "標籤紙長(mm)",
    "请输入标签纸长度": "請輸入標籤紙長度",
    "标签纸宽(mm)": "標籤紙寬(mm)",
    "请输入标签纸宽度": "請輸入標籤紙寬度",
    "模本内容": "模本內容",
    "请输入模本内容": "請輸入模本內容",
    "缩略图": "縮略圖",
    "最大打印宽度": "最大打印寬度",
    "购买": "購買",
    "打印标签": "打印標籤",
    "请选择设备": "請選擇設備",
    "请选择打印模版": "請選擇打印模版",
    "请输入打印数量": "請輸入打印數量",
    "请输入寄件人": "請輸入寄件人",
    "寄件人电话": "寄件人電話",
    "请输入寄件人电话": "請輸入寄件人電話",
    "寄件人邮箱": "寄件人郵箱",
    "请输入寄件人邮箱": "請輸入寄件人郵箱",
    "寄件地址": "寄件地址",
    "请输入寄件地址": "請輸入寄件地址",
    "默认地址": "默認地址",
    "费用变更": "費用變更",
    "费用方式": "費用方式",
    "费用类型": "費用類型",
    "请选择费用类型": "請選擇費用類型",
    "请输入金额": "請輸入金額",
    "请输入文件名称": "請輸入文件名稱",
    "来源链接": "來源鏈接",
    "请输入网页来源链接": "請輸入網頁來源鏈接",
    "更换产品": "更換產品",
    "更换的产品": "更換的產品",
    "名称备注": "名稱備註",
    "请输入菜单名称备注": "請輸入菜單名稱備註",
    "是否显示PC": "是否顯示PC",
    "是否显示移动端": "是否顯示移動端",
    "请输入token": "請輸入token",
    "征收比例": "徵收比例",
    "快递费用征收比例": "快遞費用徵收比例",
    "请输入比例": "請輸入比例",
    "请选择版本号": "請選擇版本號",
    "用量": "用量",
    "请输入用量": "請輸入用量",
    "损耗率": "損耗率",
    "请输入损耗率": "請輸入損耗率",
    "物料BOM": "物料BOM",
    "请选择物料BOM": "請選擇物料BOM",
    "请输入单位": "請輸入單位",
    "请输入单价": "請輸入單價",
    "时长": "時長",
    "请输入加工时长": "請輸入加工時長",
    "是否质检": "是否質檢",
    "请输入工序描述": "請輸入工序描述",
    "请选择生产类型": "請選擇生產類型",
    "请选择订单": "請選擇訂單",
    "请选择产品BOM": "請選擇產品BOM",
    "请选择生产优先级": "請選擇生產優先級",
    "请输入交货日期": "請輸入交貨日期",
    "请输入计划开工日期": "請輸入計劃開工日期",
    "请输入计划完工日期": "請輸入計劃完工日期",
    "请输入生产批次号": "請輸入生產批次號",
    "委外工序": "委外工序",
    "请选择生产状态": "請選擇生產狀態",
    "请选择生产计划": "請選擇生產計劃",
    "生产订单": "生產訂單",
    "请选择生产订单": "請選擇生產訂單",
    "请选择执行工序": "請選擇執行工序",
    "请选择委外工序": "請選擇委外工序",
    "生产需求": "生產需求",
    "请选择生产需求": "請選擇生產需求",
    "请选择产品工艺": "請選擇產品工藝",
    "请输入委外数量": "請輸入委外數量",
    "请输入计划生产数量": "請輸入計劃生產數量",
    "请输入委外单价": "請輸入委外單價",
    "请选择计划开工日期": "請選擇計劃開工日期",
    "请选择委外日期": "請選擇委外日期",
    "请选择计划完工日期": "請選擇計劃完工日期",
    "请搜索/选择负责人": "請搜索/選擇負責人",
    "请选择生产工单": "請選擇生產工單",
    "产品工艺": "產品工藝",
    "请选择工序执行人": "請選擇工序執行人",
    "请搜索/选择工序执行人": "請搜索/選擇工序執行人",
    "请输入生产数量": "請輸入生產數量",
    "请选择生产人员": "請選擇生產人員",
    "请搜索/选择生产人员": "請搜索/選擇生產人員",
    "请选择报工日期": "請選擇報工日期",
    "质检数量": "質檢數量",
    "请输入质检数量": "請輸入質檢數量",
    "请输入入库数量": "請輸入入庫數量",
    "质检": "質檢",
    "请输入报废数量": "請輸入報廢數量",
    "请输入返工数量": "請輸入返工數量",
    "请输入良品数量": "請輸入良品數量",
    "请输入问题描述": "請輸入問題描述",
    "类别": "類別",
    "请选择申请人": "請選擇申請人",
    "请选择关联领料单": "請選擇關聯領料單",
    "服务商": "服務商",
    "请选择快递服务商": "請選擇快遞服務商",
    "月结号": "月結號",
    "请输入月结号": "請輸入月結號",
    "添加快递信息": "添加快遞信息",
    "选择香港仓库": "選擇香港倉庫",
    "选择仓库": "選擇倉庫",
    "选择发货时间及司机": "選擇發貨時間及司機",
    "司机": "司機",
    "请选择司机": "請選擇司機",
    "请搜索/选择司机": "請搜索/選擇司機",
    "请选择发货日期": "請選擇發貨日期",
    "上门时间": "上門時間",
    "截止时间": "截止時間",
    "更改区域": "更改區域",
    "区域": "區域",
    "请选择区域": "請選擇區域",
    "shopify店铺": "shopify店鋪",
    "店铺名": "店鋪名",
    "请输入shopify店铺名": "請輸入shopify店鋪名",
    "请输入店铺名": "請輸入店鋪名",
    "请输入access": "請輸入access",
    "请输入app": "請輸入app",
    "对账明细": "對賬明細",
    "请输入日期": "請輸入日期",
    "生产设备": "生產設備",
    "请输入设备名称": "請輸入設備名稱",
    "请选择设备类型": "請選擇設備類型",
    "请输入设备编码SN": "請輸入設備編碼SN",
    "请输入设备型号": "請輸入設備型號",
    "请输入长度": "請輸入長度",
    "请输入宽度": "請輸入寬度",
    "请输入高度": "請輸入高度",
    "请选择采购日期": "請選擇採購日期",
    "请选择投产日期": "請選擇投產日期",
    "请输入采购价格": "請輸入採購價格",
    "采购币种": "採購幣種",
    "请输入生产厂家": "請輸入生產廠家",
    "请选择生产日期": "請選擇生產日期",
    "请输入用途": "請輸入用途",
    "发货": "發貨",
    "同步发货状态": "同步發貨狀態",
    "行": "行",
    "请输入行": "請輸入行",
    "请输入子仓库行数": "請輸入子倉庫行數",
    "列": "列",
    "请输入列": "請輸入列",
    "请输入子仓库列数": "請輸入子倉庫列數",
    "子仓库数量不能超过": "子倉庫數量不能超過",
    "个": "個",
    "新增仓库": "新增倉庫",
    "请搜索/选择产品": "請搜索/選擇產品",
    "请搜索/选择创建人": "請搜索/選擇創建人",
    "编辑人": "編輯人",
    "请搜索/选择最后编辑人": "請搜索/選擇最後編輯人",
    "请搜索/选择供应商": "請搜索/選擇供應商",
    "请输入价格": "請輸入價格",
    "请选择委外状态": "請選擇委外狀態",
    "请选择工序状态": "請選擇工序狀態",
    "店铺": "店鋪",
    "关联状态": "關聯狀態",
    "请选择关联状态": "請選擇關聯狀態",
    "未关联": "未關聯",
    "已关联": "已關聯",
    "面单模板": "面單模板",
    "请选择电子面单模板": "請選擇電子面單模板",
    "打印类型": "打印類型",
    "请选择打印类型": "請選擇打印類型",
    "只下单不打印": "只下單不打印",
    "生成图片短链": "生成圖片短鏈",
    "快递": "快遞",
    "云打印机": "雲打印機",
    "生成html短链": "生成html短鏈",
    "请搜索/选择客户来源": "請搜索/選擇客戶來源",
    "请搜索/选择销售点": "請搜索/選擇銷售點",
    "货物状态": "貨物狀態",
    "请选择货物状态": "請選擇貨物狀態",
    "请搜索/选择客户": "請搜索/選擇客戶",
    "请选择账期日期": "請選擇賬期日期",
    "账期类型": "賬期類型",
    "请选择账期类型": "請選擇賬期類型",
    "产品编码/名称关键字": "產品編碼/名稱關鍵字",
    "輸入產品/單號/手機": "輸入產品/單號/手機",
    "请选择数据权限": "請選擇數據權限",
    "请搜索/选择渠道名称": "請搜索/選擇渠道名稱",
    "请搜索/选择产品分类": "請搜索/選擇產品分類",
    "请搜索/选择部件": "請搜索/選擇部件",
    "请搜索/选择产品货源": "請搜索/選擇產品貨源",
    "请搜索/选择产品上下架状态": "請搜索/選擇產品上下架狀態",
    "请选择客户类型": "請選擇客戶類型",
    "请搜索/选择客户等级": "請搜索/選擇客戶等級",
    "请搜索/选择客户行业": "請搜索/選擇客戶行業",
    "请输入感兴趣的产品": "請輸入感興趣的產品",
    "线索状态": "線索狀態",
    "请选择线索状态": "請選擇線索狀態",
    "名称/单号/关键词": "名稱/單號/關鍵詞",
    "请选择计划日期": "請選擇計劃日期",
    "请搜索/选择业务员": "請搜索/選擇業務員",
    "退货状态": "退貨狀態",
    "请选择退货状态": "請選擇退貨狀態",
    "请搜索/选择渠道": "請搜索/選擇渠道",
    "请搜索/选择渠道类型": "請搜索/選擇渠道類型",
    "图册名称": "圖冊名稱",
    "请选择图册名称": "請選擇圖冊名稱",
    "请输入编码/选择产品": "請輸入編碼/選擇產品",
    "请选择在仓状态": "請選擇在倉狀態",
    "服务器正在升级中": "服務器正在升級中",
    "响应超时，请稍后再试": "響應超時，請稍後再試",
    "线索详情": "線索詳情",
    "导出明细": "導出明細",
    "客户产品": "客戶產品",
    "线索等级": "線索等級",
    "您确定要将当前线索转化为客户吗？": "您確定要將當前線索轉化為客戶嗎？",
    "您确定要将当前线索释放发到公海吗？": "您確定要將當前線索釋放發到公海嗎？",
    "您确定要领取当前线索？": "您確定要領取當前線索？",
    "分配线索": "分配線索",
    "是否为负责人": "是否為負責人",
    "天成交单数": "天成交單數",
    "天成交金额": "天成交金額",
    "客户评分": "客戶評分",
    "感兴趣的产品": "感興趣的產品",
    "折扣": "折扣",
    "最后下单时间": "最後下單時間",
    "生成账单": "生成賬單",
    "设备详情": "設備詳情",
    "充值记录": "充值記錄",
    "充值成功": "充值成功",
    "充值失败": "充值失敗",
    "充值金额": "充值金額",
    "充值状态": "充值狀態",
    "充值账户": "充值賬戶",
    "充值时间": "充值時間",
    "物料": "物料",
    "可用库存": "可用庫存",
    "所属BOM": "所屬BOM",
    "BOM详情": "BOM詳情",
    "清单明细": "清單明細",
    "委外详情": "委外詳情",
    "生成领料单": "生成領料單",
    "退料单详情": "退料單詳情",
    "领料单详情": "領料單詳情",
    "物料明细": "物料明細",
    "实际库存": "實際庫存",
    "申请数量": "申請數量",
    "入库单": "入庫單",
    "物料类型": "物料類型",
    "分析结果": "分析結果",
    "已生成采购计划": "已生成採購計劃",
    "未生成采购计划": "未生成採購計劃",
    "所需用量": "所需用量",
    "净需求": "淨需求",
    "待采购数量": "待採購數量",
    "已采购入库数量": "已採購入庫數量",
    "待申请物料数量": "待申請物料數量",
    "已领料出库数量": "已領料出庫數量",
    "已退料数量": "已退料數量",
    "待安排生产": "待安排生產",
    "已安排生产": "已安排生產",
    "待安排委外": "待安排委外",
    "已生产数量": "已生產數量",
    "您确定要对当前数据生成采购计划？": "您確定要對當前數據生成採購計劃？",
    "生产工单详情": "生產工單詳情",
    "您确定要对当前数据预生成工序？": "您確定要對當前數據預生成工序？",
    "您确定要对当前数据预生成入库单？": "您確定要對當前數據預生成入庫單？",
    "需求物料": "需求物料",
    "生产计划详情": "生產計劃詳情",
    "您确定要生成物料需求清单？": "您確定要生成物料需求清單？",
    "生产概览": "生產概覽",
    "生产工序详情": "生產工序詳情",
    "报工单详情": "報工單詳情",
    "工序号": "工序號",
    "完工数量": "完工數量",
    "请先选择工序执行人": "請先選擇工序執行人",
    "您确定要开始当前工序？": "您確定要開始當前工序？",
    "分配执行人": "分配執行人",
    "委外生产": "委外生產",
    "质检详情": "質檢詳情",
    "质检明细": "質檢明細",
    "质检单号": "質檢單號",
    "质检类型": "質檢類型",
    "质检申请人": "質檢申請人",
    "质检人": "質檢人",
    "质检时间": "質檢時間",
    "到货数量": "到貨數量",
    "工序详情": "工序詳情",
    "工艺详情": "工藝詳情",
    "工艺明细": "工藝明細",
    "工序单位": "工序單位",
    "工序负责人": "工序負責人",
    "工序编码": "工序編碼",
    "回款渠道": "回款渠道",
    "对账单详情": "對賬單詳情",
    "账期日期": "賬期日期",
    "当前欠款": "當前欠款",
    "账单产品明细": "賬單產品明細",
    "总计回款": "總計回款",
    "当前订单金额": "當前訂單金額",
    "当前已付金额": "當前已付金額",
    "您确定要对当前数据重新生成对账单吗？": "您確定要對當前數據重新生成對賬單嗎？",
    "客户生成账单": "客戶生成賬單",
    "供应商生成账单": "供應商生成賬單",
    "已付款": "已付款",
    "未付款": "未付款",
    "已开票": "已開票",
    "未开票": "未開票",
    "客户数量": "客戶數量",
    "客户渠道": "客戶渠道",
    "订单渠道": "訂單渠道",
    "产品及供应链统计": "產品及供應鏈統計",
    '编辑产品': '編輯產品',
    '新增供应链': '新增供應鏈',
    'KP资料不详细': 'KP資料不詳細',
    '周新增数据': '週新增數據',
    '客户数据统计': '客戶數據統計',
    '产品数据统计': '產品數據統計',
    '更新产品': '更新產品',
    'KP供应链不足': 'KP供應鏈不足',
    '请输入手机号/邮箱': '請輸入手機號/郵箱',
    '关联单号': '關聯單號',
    '变更类型': '變更類型',
    '变更金额': '變更金額',
    '变更前': '變更前',
    '变更后': '變更後',
    '变更时间': '變更時間',
    '导出模块': '導出模塊',
    '导出名称': '導出名稱',
    '请选择导出模块': '請選擇導出模塊',
    '仓储体积详情': '倉儲體積詳情',
    '客户数': '客戶數',
    '订单数': '訂單數',
    "渠道详情": "渠道詳情",
    "关联产品": "關聯產品",
    "生成链接": "生成鏈接",
    "发布商品": "發布商品",
    "请输入关键词搜索分类": "請輸入關鍵詞搜索分類",
    "售价": "售價",
    "您确定要对当前产品更新到阿里巴巴？": "您確定要對當前產品更新到阿里巴巴？",
    "净品规格的默认箱规将作为品规在列表展示，外箱规格的默认箱规将作为箱规在列表展示": "淨品規格的默認箱規將作為品規在列表展示，外箱規格的默認箱規將作為箱規在列表展示",
    "请输入箱规名称": "請輸入箱規名稱",
    "请输入体积": "請輸入體積",
    "非默认箱规": "非默認箱規",
    "非费用箱规": "非費用箱規",
    "体积/净重-毛重/长-宽-高": "體積/淨重-毛重/長-寬-高",
    "图文详情": "圖文詳情",
    "查看价格策略": "查看價格策略",
    "查看历史成交": "查看歷史成交",
    "渠道管理": "渠道管理",
    "箱规管理": "箱規管理",
    "应用渠道": "應用渠道",
    "图片使用": "圖片使用",
    "覆盖": "覆蓋",
    "追加": "追加",
    "继续": "繼續",
    "选择应用渠道": "選擇應用渠道",
    "图册搜索": "圖冊搜索",
    "页": "頁",
    "图册上传": "圖冊上傳",
    "建议价格": "建議價格",
    "天销量": "天銷量",
    "所属仓库": "所屬倉庫",
    "渠道数量": "渠道數量",
    "选择客户": "選擇客戶",
    "选择模板": "選擇模板",
    "若选择客户则会导出该客户关联模版信息": "若選擇客戶則會導出該客戶關聯模版信息",
    "为保证预览体验，当前仅展示最多": "為保證預覽體驗，當前僅展示最多",
    "条数据，点击": "條數據，點擊",
    "即可下载完整Excel数据": "即可下載完整Excel數據",
    "电铺类型": "電鋪類型",
    "电铺名称": "電鋪名稱",
    "您确定要发布当前产品?": "您確定要發布當前產品?",
    "绑定产品": "綁定產品",
    "照片": "照片",
    "关键字": "關鍵字",
    "网页": "網頁",
    "页数": "頁數",
    "EXW币种": "EXW幣種",
    "FOB币种": "FOB幣種",
    "网站": "網站",
    "双击可编辑": "雙擊可編輯",
    "自动上传": "自動上傳",
    "部分产品报价高于采购单价，是否确定要审核通过？": "部分產品報價高於採購單價，是否確定要審核通過？",
    "请选择供货商": "請選擇供貨商",
    "请输入包件编码": "請輸入包件編碼",
    "请输入包件数量": "請輸入包件數量",
    "请输入物流单号": "請輸入物流單號",
    "厂商": "廠商",
    "产品名": "產品名",
    "规格/材质": "規格/材質",
    "备货产品": "備貨產品",
    "付款情况": "付款情況",
    "库存情况": "庫存情況",
    "您确定要对当前数据付款？操作后状态将会流转": "您確定要對當前數據付款？操作後狀態將會流轉",
    "您确定要对当前数据所有包裹确认收货？": "您確定要對當前數據所有包裹確認收貨？",
    "您确定要对当前数据发往香港仓？": "您確定要對當前數據發往香港倉？",
    "确认所有包裹已上车？": "確認所有包裹已上車？",
    "包件编码": "包件編碼",
    "包件数量": "包件數量",
    "物流单号": "物流單號",
    "包裹状态": "包裹狀態",
    "最近下单时间": "最近下單時間",
    "包含数量": "包含數量",
    "请选择地址": "請選擇地址",
    "适用范围": "適用範圍",
    "派送费用": "派送費用",
    "基础运价": "基礎運價",
    "体积比": "體積比",
    "家庭地址费": "家庭地址費",
    "目的地若为家庭地址，则收取该费用": "目的地若為家庭地址，則收取該費用",
    "偏远地区费": "偏遠地區費",
    "目的地邮编若在Fedex偏远地区清单中，则收取该费用": "目的地郵編若在Fedex偏遠地區清單中，則收取該費用",
    "特殊处理费": "特殊處理費",
    "产品实重": "產品實重",
    "磅": "磅",
    "包装": "包裝",
    "满足以下任一条件，则收取该费用": "滿足以下任一條件，則收取該費用",
    "产品没有完全由外包装箱包装起来": "產品沒有完全由外包裝箱包裝起來",
    "外包装由缠绕膜外包": "外包裝由纏繞膜外包",
    "未使用纸箱包装": "未使用紙箱包裝",
    "包装箱外形不是方形的": "包裝箱外形不是方形的",
    "尺寸": "尺寸",
    "最长边": "最長邊",
    "英寸": "英寸",
    "次长边": "次長邊",
    "最短边）": "最短邊）",
    "英寸且": "英寸且",
    "超标费": "超標費",
    "产生超标费后，基础运价按实际重量，体积重和": "產生超標費後，基礎運價按實際重量，體積重和",
    "磅三者中最大者计费": "磅三者中最大者計費",
    "用Fedex": "用Fedex",
    "Ground发货": "Ground發貨",
    "最短边）大于": "最短邊）大於",
    "英寸且小于等于": "英寸且小於等於",
    "最长边大于": "最長邊大於",
    "Delivery发货": "Delivery發貨",
    "未授权发货费": "未授權發貨費",
    "计价重大于": "計價重大於",
    "签收费": "簽收費",
    "地址更正费": "地址更正費",
    "由于地址不正确，Fedex会设法寻找正确的地址以完成送货": "由於地址不正確，Fedex會設法尋找正確的地址以完成送貨",
    "应发货方要求，更改送货地址": "應發貨方要求，更改送貨地址",
    "地址更改服务费": "地址更改服務費",
    "应发货方要求，更改地址或者召回货物服务费": "應發貨方要求，更改地址或者召回貨物服務費",
    "燃油附加费": "燃油附加費",
    "根据官方燃油附加费率，按百分比收取": "根據官方燃油附加費率，按百分比收取",
    "适用于超大或超重件运输": "適用於超大或超重件運輸",
    "每次发货前，可免费询价": "每次發貨前，可免費詢價",
    "体积重": "體積重",
    "高/体积比": "高/體積比",
    "计价重量为体积重和实际重量中数值较大者": "計價重量為體積重和實際重量中數值較大者",
    "特殊处理费，超标费及未授权发货费三项，不重复收费，只收取最贵的一项": "特殊處理費，超標費及未授權發貨費三項，不重複收費，只收取最貴的一項",
    "所有尺寸，重量均按英制计算": "所有尺寸，重量均按英制計算",
    "千克": "千克",
    "所有尺寸，重量均向上取整": "所有尺寸，重量均向上取整",
    "所有货币单位均为美元": "所有貨幣單位均為美元",
    "如Fedex价格变动，报价将进行相应变动，我司将另行通知": "如Fedex價格變動，報價將進行相應變動，我司將另行通知",
    "此报价不含旺季附加费，旺季附加费按官网价收取": "此報價不含旺季附加費，旺季附加費按官網價收取",
    "旺季附加费收费时间段以Fedex官网收费时间段为准": "旺季附加費收費時間段以Fedex官網收費時間段為準",
    "收费重量尺寸以Fedex实际账单为准": "收費重量尺寸以Fedex實際賬單為準",
    "计价重量计算方式": "計價重量計算方式",
    "见拣货费价格": "見揀貨費價格",
    "今日计划详情": "今日計劃詳情",
    "快递详情": "快遞詳情",
    "物流信息": "物流信息",
    "查看运费": "查看運費",
    "快递服务商": "快遞服務商",
    "个工作日": "個工作日",
    "含": "含",
    "免费追踪": "免費追蹤",
    "选择快递": "選擇快遞",
    "请完善包裹信息": "請完善包裹信息",
    "您确定选择当前快递服务商并支付费用?": "您確定選擇當前快遞服務商並支付費用?",
    "运费预估": "運費預估",
    "关联发货计划": "關聯發貨計劃",
    "物品体积": "物品體積",
    "物品总重量": "物品總重量",
    "您确定要对当前数据进行寄件": "您確定要對當前數據進行寄件",
    "您确定要对当前数据进行取消寄件": "您確定要對當前數據進行取消寄件",
    "选择面单": "選擇面單",
    "选择打印机": "選擇打印機",
    "费用预估为": "費用預估為",
    "费用预估": "費用預估",
    "您确定要对当前产品发往香港仓？": "您確定要對當前產品發往香港倉？",
    "发货单详情": "發貨單詳情",
    "文本记录": "文本記錄",
    "规格/尺寸": "規格/尺寸",
    "发货仓": "發貨倉",
    "包件备注": "包件備註",
    "行程安排": "行程安排",
    "送货员": "送貨員",
    "确认取消包裹派送？": "確認取消包裹派送？",
    "确认包裹客户已接收？": "確認包裹客戶已接收？",
    "确认包裹要回仓？": "確認包裹要回倉？",
    "未安排": "未安排",
    "库存状况": "庫存狀況",
    "采购情况": "採購情況",
    "确认收货": "確認收貨",
    "发往香港仓": "發往香港倉",
    "确认上车": "確認上車",
    "您确定对所选数据进行全部": "您確定對所選數據進行全部",
    "操作吗？": "操作嗎？",
    "采购中": "採購中",
    "已售待送": "已售待送",
    "收货单详情": "收貨單詳情",
    "您确定要签收入库？": "您確定要簽收入庫？",
    "供货商": "供貨商",
    "到达时间": "到達時間",
    "出入库单号": "出入庫單號",
    "仓位": "倉位",
    "总体积(m³)": "總體積(m³)",
    "添加行": "添加行",
    "添加列": "添加列",
    "拆分为表格": "拆分為表格",
    "当前共有": "當前共有",
    "条数据，请输入要拆分的行数": "條數據，請輸入要拆分的行數",
    "拆分的行数": "拆分的行數",
    "请输入要拆分的行数": "請輸入要拆分的行數",
    "商品快递索赔详情": "商品快遞索賠詳情",
    "请选择商品代发单": "請選擇商品代發單",
    "商品退货详情": "商品退貨詳情",
    "商品代发详情": "商品代發詳情",
    "您确定要通知仓库发货？": "您確定要通知倉庫發貨？",
    "请完善产品仓库信息": "請完善產品倉庫信息",
    "入库预报详情": "入庫預報詳情",
    "您确定要发货？": "您確定要發貨？",
    "索赔原因": "索賠原因",
    "您确定要对当前数据进行退货处理?": "您確定要對當前數據進行退貨處理?",
    "发货模版": "發貨模版",
    "智能识别": "智能識別",
    "您确定要对当前订单进行作废": "您確定要對當前訂單進行作廢",
    "部分产品报价低于最低售价确定要审核通过？": "部分產品報價低於最低售價確定要審核通過？",
    "预设报价": "預設報價",
    "是否发货": "是否發貨",
    "您确定要取消当前订单寄件？": "您確定要取消當前訂單寄件？",
    "您确定要清除当前订单的关联信息？清除后可以重新添加产品": "您確定要清除當前訂單的關聯信息？清除後可以重新添加產品",
    "VIP类型": "VIP類型",
    "商店域名": "商店域名",
    "请输入商店域名": "請輸入商店域名",
    "请选择VIP类型": "請選擇VIP類型",
    "选项值": "選項值",
    "面单标题": "面單標題",
    "网点": "網點",
    "快递员": "快遞員",
    "面单编码": "面單編碼",
    "面单模版": "面單模版",
    "绑定打印机": "綁定打印機",
    "秘钥": "秘鑰",
    "校验码": "校驗碼",
    "打印机类型": "打印機類型",
    "在线状态": "在線狀態",
    "模版图": "模版圖",
    "模版标题": "模版標題",
    "打印纸尺寸(mm)": "打印紙尺寸(mm)",
    "快递面单模版": "快遞面單模版",
    "排序字段": "排序字段",
    "您确定要对当前数据进行同步翻译？": "您確定要對當前數據進行同步翻譯？",
    "请输入code编码": "請輸入code編碼",
    "请输入汇率": "請輸入匯率",
    "销售单号规则": "銷售單號規則",
    "采购单号规则": "採購單號規則",
    "使用币种": "使用幣種",
    "默认汇率": "默認匯率",
    "开启供应商仓储报表": "開啟供應商倉儲報表",
    "默认币种": "默認幣種",
    "费用征收比例": "費用徵收比例",
    "请输入merchant": "請輸入merchant",
    "秘钥Key": "秘鑰Key",
    "请输入秘钥Key": "請輸入秘鑰Key",
    "不允许使用特殊符号": "不允許使用特殊符號",
    "公钥Key": "公鑰Key",
    "请输入公钥Key": "請輸入公鑰Key",
    "字段控制": "字段控制",
    "stripe支付配置": "stripe支付配置",
    "乐刷支付配置": "樂刷支付配置",
    "使用次数": "使用次數",
    "已授权": "已授權",
    "未授权": "未授權",
    "扫码授权": "掃碼授權",
    "授权状态": "授權狀態",
    "token过期时间": "token過期時間",
    "昨日销量": "昨日銷量",
    "店铺扫码授权": "店鋪掃碼授權",
    "三方渠道": "三方渠道",
    "您确定生成全部任务？": "您確定生成全部任務？"
  },
}
