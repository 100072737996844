import { getRequest, postRequest } from './api'

const module = 'quote'
const module_detail = 'quote-detail'
export function getQuoteList(params = {}) {
  const url = `${module}/index`
  return getRequest(url, params)
}

export function addQuote(params = {}) {
  const url = `${module}/add`
  return postRequest(url, params)
}

export function editQuote(params = {}) {
  const url = `${module}/edit`
  return postRequest(url, params)
}

export function deleteQuote(params = {}) {
  const url = `${module}/delete`
  return postRequest(url, params)
}

export function quoteCreateOrder(params = {}) {
  const url = `${module}/order`
  return postRequest(url, params)
}

export function quotePriceCover(params = {}) {
  const url = `${module_detail}/cover`
  return postRequest(url, params)
}

export function getQuoteDetailList(params = {}) {
  const url = `${module_detail}/index`
  return getRequest(url, params)
}
// 报价单详情添加
export function quoteDetailAdd(params = {}) {
  const url = `${module_detail}/add`
  return postRequest(url, params)
}
// 报价单详情编辑
export function quoteDetailEdit(params = {}) {
  const url = `${module_detail}/edit`
  return postRequest(url, params)
}
// 修改报价单顺序
export function quoteDetailUpdateRank(params = {}) {
  const url = `${module_detail}/updateRank`
  return postRequest(url, params)
}
// 报价单货币变换
export function quoteCurrencyChange(params = {}) {
  const url = `${module}/currencyChange`
  return postRequest(url, params)
}
