<!--
 * @Author: WGL
 * @Date: 2023-06-21 09:23:33
 * @LastEditors: WGL
 * @LastEditTime: 2024-08-05 16:52:19
 * @Description: 
-->
<template>
  <div>
    <div>
      <span>{{ numberToFixed(info?.volume, 4) || '-' }}m³</span>
      <span class="plr2">/</span>
      <span>{{ numberToFixed(+info?.volume * 35.3147248, 5) || '-' }}pt³</span>
    </div>
    <div>{{ numberToFixed(info?.weight, 3) || '-' }} kg</div>
    <!-- <div>
      {{ numberToFixed(info?.weight, 3) || '-' }} kg/{{
        numberToFixed(info?.rough_weight, 3) || '-'
      }}
      kg
    </div> -->
    <div>{{ info?.l }}L-{{ info?.w }}W-{{ info?.h }}H cm</div>
  </div>
</template>

<script>
  // 基础产品规格组件
  import { defineComponent } from 'vue'
  import { numberToFixed } from '@/utils/index'
  export default defineComponent({
    name: 'BaseSingleSpecs',
    components: {},
    props: {
      info: { type: Object, default: () => {} },
    },
    setup() {
      return {
        numberToFixed,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
