import { resolve } from 'path'
import { hasPermission } from '@/utils/permission'
import { isExternal } from '@/utils/validate'
import { recordRoute } from '@/config'
import qs from 'qs'
import { t } from '@/utils/i18n'
const isDevelopmentEnv = process.env.NODE_ENV === 'development'
// const isDevelopmentEnv = false

/**
 * @description all模式渲染后端返回路由,支持包含views路径的所有页面
 * @param asyncRoutes
 * @returns {*}
 */
export function convertRouter(asyncRoutes) {
  return asyncRoutes.map((route) => {
    if (route.component) {
      if (route.component === 'Layout') {
        route.component = () => import('@/vab/layouts')
      } else {
        console.log('route', route)
        const index = route.component.indexOf('views')
        const path =
          index > 0 ? route.component.slice(index) : `views/${route.component}`
        route.component = () => import(`@/${path}`)
      }
    }
    if (route.children && route.children.length)
      route.children = convertRouter(route.children)
    if (route.children && route.children.length === 0) delete route.children
    return route
  })
}

/**
 * @description 根据roles数组拦截路由
 * @param routes 路由
 * @param rolesControl 是否进行权限控制
 * @param baseUrl 基础路由
 * @returns {[]}
 */
export function filterRoutes(routes, rolesControl, baseUrl = '/') {
  return routes
    .filter((route) =>
      rolesControl && route.meta && route.meta.guard
        ? hasPermission(route.meta.guard)
        : true
    )
    .map((route) => {
      route = { ...route }
      route.path =
        route.path !== '*' && !isExternal(route.path)
          ? resolve(baseUrl, route.path)
          : route.path
      if (route.children && route.children.length > 0) {
        route.children = filterRoutes(route.children, rolesControl, route.path)
        if (route.children.length > 0) {
          route.childrenNameList = route.children.flatMap(
            (_) => _.childrenNameList
          )
          if (!route.redirect)
            route.redirect = route.children[0].redirect
              ? route.children[0].redirect
              : route.children[0].path
        }
      } else route.childrenNameList = [route.name]
      return route
    })
}

/**
 * 根据path路径获取matched
 * @param routes 菜单routes
 * @param name 路由名
 * @returns {*} matched
 */
export function handleMatched(routes, name) {
  // console.log('routes :>> ', routes)
  // console.log('name :>> ', name)
  return routes
    .filter((route) => route.childrenNameList.indexOf(name) + 1)
    .flatMap((route) =>
      route.children ? [route, ...handleMatched(route.children, name)] : [route]
    )
}
/**
 * 生成单个多标签元素，可用于同步/异步添加多标签
 * @param tag route页信息
 * @param init 是否是从router获取路由
 */
export function handleTabs(tag, init = false) {
  console.log('tag1 :>> ', tag)
  let parentIcon = null
  if (tag.matched)
    for (let i = tag.matched.length - 2; i >= 0; i--)
      if (!parentIcon && tag.matched[i].meta.icon)
        parentIcon = tag.matched[i].meta.icon
  if (!parentIcon) parentIcon = 'menu-line'
  const path = handleActivePath(tag, true)
  if (tag.name && tag.meta && tag.meta.tabHidden !== true) {
    return {
      path: path,
      query: tag.query,
      params: tag.params,
      name: tag.name,
      matched: init
        ? [tag.name]
        : tag.matched.map((route) => route.components.default.name),
      parentIcon,
      meta: { ...tag.meta },
    }
  }
}

/**
 * 根据当前route获取激活菜单
 * @param route 当前路由
 * @param isTab 是否是标签
 * @returns {string|*}
 */
export function handleActivePath(route, isTab = false) {
  // console.log('routesss :>> ', route)
  const { meta, path } = route
  const rawPath = route.matched
    ? route.matched[route.matched.length - 1].path
    : path
  // rawPath:/ceshiIndex
  const fullPath =
    route.query && Object.keys(route.query).length
      ? `${route.path}&${qs.stringify(route.query)}`
      : route.path
  // console.log('fullPath :>> ', fullPath)
  if (isTab) return meta.dynamicNewTab ? fullPath : rawPath
  if (meta.activeMenu) return meta.activeMenu
  return fullPath
}

/**
 * 获取当前跳转登录页的Route
 * @param currentPath 当前页面地址
 */
export function toLoginRoute(currentPath) {
  if (recordRoute && currentPath !== '/')
    return {
      path: '/login',
      query: { redirect: currentPath },
      replace: true,
    }
  else return { path: '/login', replace: true }
}

/**
 * 获取路由中所有的Name
 * @param routes 路由数组
 * @returns {*} Name数组
 */
export function getNames(routes) {
  return routes.flatMap((route) => {
    let names = []
    if (route.name) names.push(route.name)
    if (route.children) names.push(...getNames(route.children))
    return names
  })
}

// 菜单
export function getTreeData(data) {
  return data.map((item) => {
    if (item.children && item.children.length) {
      item.children = getTreeData(item.children)
    } else {
      item.children = undefined
    }
    return item
  })
}

// 操作
export function getTreeOperateData(data) {
  return data.map((item) => {
    if (item.children && item.children.length) {
      item.children = getTreeOperateData(item.children)
    } else {
      item.children = undefined
    }
    if (item.operations && item.operations.length) {
      item.children = item.operations.map((v) => {
        v.oid = v.id
        v.id = `o-${v.id}`
        return v
      })
    }
    return item
  })
}

// 获取菜单中没有操作的菜单
let unOperateMenuIds = []
export function getTreeUnOperateData(data) {
  // console.log(`getTreeUnOperateData`, data)
  data.map((item) => {
    if (item.children && item.children.length) {
      getTreeUnOperateData(item.children)
    } else {
      if (item.operations && item.operations.length === 0) {
        unOperateMenuIds.push(item.id)
      }
    }
    return item
  })
  return unOperateMenuIds
}

// 生成静态路由对象
export function generateAsyncRoutes(asyncRoutes, result = {}, parentPath = '') {
  // console.log('asyncRoutes :>> ', asyncRoutes)
  // console.log('`${parentPath}` :>> ', `${parentPath}`)
  asyncRoutes.forEach((item) => {
    // console.log('`${parentPath}${item?.path}` :>> ', `${item?.path}`)
    if (item.path) {
      item.fullPath = `${parentPath}${item?.path.indexOf('/') > -1 ? '' : '/'}${item.path
        }`
      // console.log('item.fullPath :>> ', item.fullPath)
      item.fullPath = item.fullPath.replace('//', '/')

      // console.log('item.fullPath', item.fullPath)
      result[item.fullPath] = item
    }
    if (item.children && item.children.length) {
      generateAsyncRoutes(item.children, result, item.fullPath)
    }
  })
  return result
}

// 菜单
let userMenuObj = {}
export function generateUserMenuObj(data, reset = true) {
  if (reset) {
    userMenuObj = {}
  }
  const result = F.copy(data)
  // console.log(`generateUserMenuObj2 data`, result)
  result.forEach((item) => {
    if (item.url) {
      userMenuObj[item.url] = item
    }
    if (item.children && item.children.length) {
      generateUserMenuObj(item.children, false)
    }
  })
  return userMenuObj
}

export function generateUserMenuObj2(data, reset = true) {
  const result = generateUserMenuObj(data, reset)
  const userMenuIdObj = {}
  for (const val of Object.values(result)) {
    userMenuIdObj[val.id] = val
  }
  // console.log('userMenuIdObj :>> generateUserMenuObj2', userMenuIdObj);
  // 关联菜单权限
  for (const val of Object.values(result)) {
    // console.log('val :>> generateUserMenuObj2', val);
    let commitOperateIndex = 0
    const hasCommitAudit = val.operations.filter((item, index) => {
      if (item?.code?.includes('commit')) {
        commitOperateIndex = index
      }
      return (item.code?.includes('commit') || item.code?.includes('editAudit'))
    })
    if (hasCommitAudit?.length > 1) {
      // console.log('hasCommitAudit :>> ', hasCommitAudit, val);

      val.operations.splice(commitOperateIndex + 1, 0, {
        ...val.operations[commitOperateIndex],
        show_title: t('提审并通过'),
        title: t('提审并通过'),
        code: 'commitAudit'
      })
    }
    if (val.rid) {
      val.operations = userMenuIdObj[val.rid]?.operations || []
    }
    if (isDevelopmentEnv) {
      val.operations = val?.operations?.map(item => {
        return {
          ...item,
          show_title: item.title
        }
      })
    }
  }
  console.log('generateUserMenuObj2 :>> ', result);
  return result
}

// 当前用户菜单路由
export function generateUserMenu(
  allRoutes,
  userMenusObj,
  asyncRoutesObj,
  parentPath = ''
) {
  // console.log(`allRoutes`, allRoutes)
  // console.log(`userMenusObj`, userMenusObj)
  const result = allRoutes.filter((item) => {
    let path = `${parentPath}/${item.path}`
    path = path.replace('//', '/')
    if (userMenusObj[path]) {
      item.meta.icon = userMenusObj[path].icon || 'settings-3-line'
      item.meta.title = userMenusObj[path].title
      item.meta.remark = userMenusObj[path].remark
      item.meta.hidden = !userMenusObj[path].show
      item.meta.badge = 0
      item.meta.dot = !!userMenusObj[path].dot
      item.rank = userMenusObj[path].rank
      item.id = userMenusObj[path].id
      item.pid = userMenusObj[path].pid
      item.component = asyncRoutesObj[path].component
      if (item.children && item.children.length) {
        item.children = generateUserMenu(
          item.children,
          userMenusObj,
          asyncRoutesObj,
          path
        )
      }
      return true
    }
  })
  return result
}

export function sortUserMenu(data) {
  // console.log(`allRoutes`, allRoutes)
  // console.log(`userMenusObj`, userMenusObj)
  const result = data.sort((a, b) => {
    if (a.children && a.children.length) {
      a.children = sortUserMenu(a.children)
    }
    if (b.children && b.children.length) {
      b.children = sortUserMenu(b.children)
    }
    return a.rank - b.rank
  })

  return result
}
