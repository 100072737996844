<!--
 * @Author: WGL
 * @Date: 2023-06-21 09:23:33
 * @LastEditors: WGL
 * @LastEditTime: 2024-01-26 17:12:18
 * @Description: 
-->
<template>
  <div class="w">
    <el-tooltip
      v-if="info?.code"
      :content="`${t('编码')}：${info?.code}`"
      effect="dark"
      placement="top"
    >
      <div class="omit">{{ info?.code }}</div>
    </el-tooltip>
    <el-tooltip
      v-if="info?.oem_code"
      :content="`${t('OEM编码')}：${info?.oem_code}`"
      effect="dark"
      placement="bottom"
    >
      <div>
        <div v-for="code in oemCode" :key="code" class="omit">
          {{ code }}
        </div>
      </div>
    </el-tooltip>
  </div>
</template>

<script>
  // 基础产品编码组件
  import { defineComponent, reactive, toRefs, computed } from 'vue'
  import { t } from '@/utils/i18n'

  export default defineComponent({
    name: 'BaseCode',
    components: {},
    props: {
      info: { type: Object, default: () => {} },
    },
    setup(props) {
      const state = reactive({})
      const oemCode = computed(() => {
        return props?.info?.oem_code?.split(',')
      })
      return {
        oemCode,
        ...toRefs(state),
        t,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
