import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'

// 引入其中的相对时间 例一天前，五秒前
import rTime from 'dayjs/plugin/relativeTime'
// 全局使用中文
dayjs.locale('zh-cn')

// 配置相对时间 例5秒前 1天前
dayjs.extend(rTime)

// // 对时间进行格式化
// export function formatTime(data = new Date(), type = 'YYYY-MM-DD') {
//   return dayjs(data).format(type)
// }

// //设置相对时间
// export const relativeTime = (value) => {
//   return dayjs().to(dayjs(value))
// }

// // 返出两个设置
// export default {
//   formatTime,
//   relativeTime,
// }
