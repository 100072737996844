<template>
  <el-form-item
    v-if="tableColumns.length"
    :label="item.label"
    v-bind="item.formAttrs"
  >
    <div v-if="item?.promote?.top" class="g9" v-html="item.promote.top"></div>
    <el-table border :data="appModel[item.labelKey]" stripe>
      <el-table-column
        v-for="(column, index) in tableColumns"
        :key="index"
        align="center"
        :min-width="50"
        v-bind="column"
      >
        <template #default="{ row }" v-if="column.uiType">
          <template v-if="column.uiType === 'input'">
            <el-input-number
              v-model="row[column.prop]"
              :controls="false"
              controls-position="both"
              :max="999999"
              :min="0"
              placeholder="请输入"
              :step="1"
            />
          </template>
          <!-- 预览 -->
          <template v-else-if="column.uiType === 'switch'">
            <el-switch
              v-model="row[column.prop]"
              active-text="上架"
              :active-value="1"
              inactive-text="下架"
              :inactive-value="0"
              inline-prompt
            />
          </template>
          <template v-else-if="column.uiType === 'text'">
            <div>
              {{ handleGetSkuAttrVal(row, column) }}
            </div>
          </template>
        </template>
      </el-table-column>
    </el-table>
  </el-form-item>
</template>

<script>
  // 产品规格sku
  import { defineComponent, computed, watchEffect } from 'vue'

  export default defineComponent({
    name: 'ProductPriceSku',
    props: {
      item: { type: Object, default: () => {} },
      formModel: { type: Object, default: () => {} },
      salePropInfo: { type: Object, default: () => {} }, // 产品规格属性
      isSpecsSku: { type: Boolean, default: false }, // 是否是按规格sku报价
      isEdit: { type: Boolean, default: false }, // 是否是编辑
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const appModel = computed({
        get: () => {
          return props.formModel || {}
        },
        set: (value) => {
          emit('update:modelValue', value)
        },
      })

      /**
       * 生成sku
       * @param {*} arrays
       * @param {*} index
       * @param {*} currentCombination
       * @param {*} result
       */
      const handleGenerateSku = (arrays, index, currentCombination, result) => {
        if (index === arrays.length) {
          // 当前组合完成
          result.push({
            sku_props: [...currentCombination],
            sku_price: null,
            sku_amountOnSale: null,
            sku_cargoNumber: null,
            sku_status: 1,
          })
          return
        }
        const currentAttribute = arrays[index]

        for (const value of currentAttribute.values) {
          // console.log('value :>> allSKUCombinations', value)
          // 递归生成下一个属性的组合
          handleGenerateSku(
            arrays,
            index + 1,
            [
              ...currentCombination,
              { ...currentAttribute.attrs, text: value.text },
            ],
            result
          )
        }
      }

      /**
       * 获取sku属性值
       * @param {*} row
       * @param {*} item
       */
      const handleGetSkuAttrVal = (row, item) => {
        const { prop } = item
        return row.sku_props.find((v) => v.name === prop).text
      }

      // 价格sku
      const handleGetPriceSkuTableData = (dataSource) => {
        const result = []
        const salePropAttributes = []
        dataSource.map((item) => {
          const { name, label, propertyId } = item
          if (props.formModel[name] && props.formModel[name].length) {
            salePropAttributes.push({
              attrs: {
                label,
                name,
                id: propertyId,
              },
              values: props.formModel[name] || [],
            })
          }
        })
        // console.log('salePropAttributes :>> ', salePropAttributes)
        handleGenerateSku(salePropAttributes, 0, [], result)
        return result
      }
      const tableColumns = computed(() => {
        const defaultColumns = [
          {
            label: '序号',
            width: 100,
            type: 'index',
            align: 'center',
          },

          {
            label: '可售数量',
            width: 200,
            prop: 'sku_amountOnSale',
            align: 'center',
            uiType: 'input',
          },
          {
            label: '单品货号',
            width: 200,
            prop: 'sku_cargoNumber',
            align: 'center',
            uiType: 'input',
          },
          {
            label: '是否上架',
            width: 100,
            prop: 'sku_status',
            align: 'center',
            uiType: 'switch',
          },
        ]
        if (props.isSpecsSku) {
          defaultColumns.splice(1, 0, {
            label: '单价',
            width: 200,
            prop: 'sku_price',
            align: 'center',
            uiType: 'input',
          })
        }
        let hasSalePropValue = false
        props.salePropInfo.dataSource.forEach((item, i) => {
          const { name, label } = item
          if (props.formModel[name] && props.formModel[name].length) {
            hasSalePropValue = true
            defaultColumns.splice(1 + i, 0, {
              label: label,
              width: 200,
              prop: name,
              align: 'center',
              uiType: 'text',
            })
          }
        })
        // if(props.formModel.){

        // }
        // console.log('defaultColumns :>> ', defaultColumns)
        return hasSalePropValue ? defaultColumns : []
      })

      watchEffect(() => {
        const labelKey = props.item.labelKey
        console.log('watchEffect', labelKey)
        appModel.value[labelKey] = handleGetPriceSkuTableData(
          props.salePropInfo.dataSource
        )
      })

      return {
        appModel,
        tableColumns,
        handleGetSkuAttrVal,
      }
    },
  })
</script>

<style scoped></style>
