<template>
  <el-dropdown @command="handleCommand" @visible-change="handleVisibleChange">
    <span class="avatar-dropdown ml15">
      <el-avatar class="user-avatar" :src="avatar" />
      <div class="user-name">
        <span v-if="currentTeamIfo?.title">{{ currentTeamIfo.title }}-</span>
        <div>{{ username }}</div>
        <vab-icon
          class="vab-dropdown"
          :class="{ 'vab-dropdown-active': active }"
          icon="arrow-down-s-line"
        />
      </div>
    </span>
    <template #dropdown>
      <el-dropdown-menu>
        <!-- <el-dropdown-item command="personalCenter">
          <vab-icon icon="user-line" />
          <span>{{ translateTitle('个人中心') }}</span>
        </el-dropdown-item> -->
        <!-- <el-dropdown-item
          v-if="!userInfo.enterprise_wx_user_id"
          command="bindEnterpriseWx"
        >
          <vab-icon icon="chat-smile-2-fill" />
          <span>{{ translateTitle('绑定企业微信') }}</span>
        </el-dropdown-item> -->
        <!-- <el-dropdown-item v-else command="unbindEnterpriseWx">
          <vab-icon icon="chat-smile-2-fill" />
          <span>{{ translateTitle('解绑企业微信') }}</span>
        </el-dropdown-item> -->
        <el-dropdown-item command="logout">
          <vab-icon icon="logout-circle-r-line" />
          <span>{{ translateTitle('退出登录') }}</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
  <el-dialog
    v-model="dialogVisible"
    v-bind="dialogVisible"
    center
    title="切换公司主体"
    width="35%"
  >
    <!-- <ul v-loading="listLoading"> -->
    <ul class="list-container">
      <li
        v-for="(item, index) in companyList"
        :key="index"
        class="list-item fx bor r4px mb15 g3"
      >
        <div class="list-item-meta-avatar">
          <el-image class="r100px" :src="fillUrl(item.site_logo)" />
        </div>
        <div class="list-item-meta-content fxj fv ex ptb5">
          <div class="list-item-meta-title">{{ item.title }}</div>
          <div class="list-item-meta-description g9">
            {{ item.role_name }}
          </div>
        </div>
        <div class="fxmc">
          <span class="g6">{{ item.create_time }}</span>
        </div>
        <div class="fxmc w80 ml30" @click="handleChangeCompany(item.id)">
          <vab-icon
            v-if="item.id === currentCompanyId"
            icon="checkbox-circle-fill"
            style="color: #1890ff; font-size: 30px"
          />
          <vab-icon
            v-else
            icon="checkbox-blank-circle-line"
            style="color: #aaa; font-size: 30px"
          />
        </div>
      </li>
    </ul>
  </el-dialog>
  <el-dialog
    v-model="unBindDialogVisible"
    title="确定解绑企业微信?"
    width="35%"
  >
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="unBindDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="unBind">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
  import {
    computed,
    defineComponent,
    reactive,
    ref,
    toRefs,
    getCurrentInstance,
    onBeforeMount,
  } from 'vue'
  import { useStore } from 'vuex'
  // import { useRoute, useRouter } from 'vue-router'
  import { useRouter } from 'vue-router'
  import { toLoginRoute } from '@/utils/routes'
  import { translateTitle } from '@/utils/i18n'
  import { clearStorage } from '@/utils/token'
  import { fillUrl } from 'common/js/utils'
  import { windowReload } from '@/utils/business'
  import { switchCompany } from '@/api/user'
  import request from '@/utils/request'
  import { gp } from '@vab'
  export default defineComponent({
    name: 'VabAvatar',
    setup() {
      const store = useStore()
      // const route = useRoute()
      const router = useRouter()
      const { proxy } = getCurrentInstance()
      const state = reactive({
        dialogVisible: false,
        unBindDialogVisible: false,
      })
      const companyList = computed(() => store.getters['user/companyList'])
      const currentCompanyId = computed(
        () => store.getters['user/currentCompanyId']
      )
      const currentTeamIfo = computed(() => {
        let result = null
        if (currentCompanyId.value) {
          result = companyList.value.find(
            (item) => item.id === currentCompanyId.value
          )
        }
        return result
      })
      const logout = () => store.dispatch('user/logout')
      const active = ref(false)

      const handleVisibleChange = (val) => {
        active.value = val
      }
      const unBind = () => {
        request({
          url: '/enterprise-wx/unBindAuthorize',
          method: 'get',
        })
        router.go(0)
      }
      const handleCommand = async (command) => {
        switch (command) {
          case 'logout':
            await logout()
            clearStorage()
            await router.push(toLoginRoute())
            // await router.push(toLoginRoute(route.fullPath))
            break
          case 'personalCenter':
            await router.push('/setting/personalCenter')
            break
          case 'bindEnterpriseWx':
            await request({
              url: '/enterprise-wx/getAuthorizeUrl',
              method: 'get',
            }).then((res) => {
              if (!res.url) {
                gp.$baseMessage(
                  '主体未设置企业微信',
                  'error',
                  'vab-hey-message-error'
                )
                return
              }
              window.open(
                res.url,
                '_blank',
                'top=300,left=300,width=800,height=600,menubar=no,toolbar=no,titlebar=no,status=no,scrollbars=yes,location=no'
              )
            })
            break
          case 'unbindEnterpriseWx':
            state.unBindDialogVisible = true
            break
          case 'changeCompany':
            state.dialogVisible = true
            break
        }
      }

      // 切换公司主体
      const handleChangeCompany = async (val) => {
        switchCompany({ principal_id: val })
          .then(async () => {
            await store.dispatch('routes/changeProductTeam')
            state.dialogVisible = false
            proxy.$pub('reload-router-view')
          })
          .catch(() => {
            console.log('window.location.reload   >>>>>>>>>')
            windowReload()
          })
      }

      const delAllVisitedRoutes = (activePath) =>
        store.dispatch('tabs/delAllVisitedRoutes', activePath)

      onBeforeMount(() => {})
      return {
        ...toRefs(state),
        currentCompanyId,
        active,
        companyList,
        fillUrl,
        currentTeamIfo,
        handleCommand,
        translateTitle,
        handleVisibleChange,
        handleChangeCompany,
        delAllVisitedRoutes,
        avatar: computed(() => store.getters['user/avatar']),
        username: computed(() => store.getters['user/username']),
        userInfo: computed(() => store.getters['user/userInfo']),
        unBind,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .avatar-dropdown {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;

    .user-avatar {
      width: 30px;
      height: 30px;
      margin-left: 15px;
      cursor: pointer;
      border-radius: 50%;
    }

    .user-name {
      position: relative;
      display: flex;
      align-content: center;
      align-items: center;
      height: 30px;
      margin-left: 6px;
      line-height: 30px;
      color: #fff7e9;
      cursor: pointer;

      [class*='ri-'] {
        margin-left: 0 !important;
      }
    }
  }

  :deep() {
    .el-radio {
      &__label {
        display: none;
      }
    }
  }
  ul.list-container {
    padding: 0;
    margin: 0;
    list-style: none;
    outline: none;
    .list-item {
      padding: $base-padding;
      font-size: 14px;

      &-meta {
        display: flex;
        flex: 1 1;
        align-items: flex-start;

        &-avatar {
          margin-right: 16px;

          :deep() {
            .el-image {
              width: 61px;
              height: 61px;
            }
          }
        }

        &-item {
          display: inline-block;
          height: 61px;
          margin-left: 40px;
          color: rgba(0, 0, 0, 0.45);
          vertical-align: middle;

          > span {
            line-height: 30px;
          }

          > p {
            margin-top: 4px;
            margin-bottom: 0;
          }
        }

        :deep() {
          .el-progress {
            margin-top: 21px;
          }
        }
      }
    }
  }
</style>
