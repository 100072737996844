<!--
 * @Author: WGL
 * @Date: 2024-01-05 10:41:28
 * @LastEditors: WGL
 * @LastEditTime: 2024-05-09 11:05:15
 * @Description: 
-->
<template>
  <el-popover placement="top" trigger="click" :width="458">
    <template #reference>
      <div
        class="vab-side-bar-footer-wrapper tbor fxmc cp bgh"
        :class="{
          'is-collapse': collapse,
        }"
      >
        <div class="fxmc wh30 r100px bcf">
          <jwt-icon class="ch" icon="tools-fill" size="18" />
        </div>
        <span v-show="!collapse" class="ml5 cf">{{ t('实用工具') }}</span>
      </div>
    </template>
    <div class="vab-side-bar-footer-wrapper-tools">
      <p class="tc b pb10">{{ t('实用工具下载') }}</p>
      <div class="fxm fxwp">
        <template v-for="(item, index) in toolsList" :key="index">
          <el-popover
            placement="right"
            :trigger="item?.children?.length ? 'hover' : 'hovers'"
            :width="200"
          >
            <template #reference>
              <div
                class="w100 fxmc fv mb10 bor r5px p5 cp tools-item"
                :class="[index % 4 == 3 && index ? '' : 'mr10']"
                @click="handleLink(item)"
              >
                <div v-if="item.icon" class="wh60 fxmc">
                  <jwt-icon
                    :class="item.cls"
                    :icon="item.icon"
                    :is-custom="item.isCustom"
                    :size="item.size"
                  />
                </div>
                <el-image
                  v-else
                  alt=""
                  :class="item.cls || 'wh60'"
                  :preview-src-list="item.previewSrc"
                  :src="item.logo"
                />
                <div class="omit w tc fs12">{{ item.title }}</div>
              </div>
            </template>
            <div v-if="item?.children?.length">
              <template v-for="(menu, i) in item.children" :key="i">
                <div
                  v-if="menu.show"
                  class="fxmj p4 cp hcpt r2px"
                  @click="handleLinkPath(menu)"
                >
                  <jwt-icon :icon="menu.icon || 'settings-4-line'" size="16" />
                  <div class="ex ml4">{{ menu.title }}</div>
                </div>
              </template>
            </div>
          </el-popover>
        </template>
      </div>
    </div>
  </el-popover>
  <PosterDesign ref="PosterDesignRef" />
</template>

<script>
  import { computed, defineComponent, ref } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import variables from '@/vab/styles/variables/variables.module.scss'
  import { t } from '@/utils/i18n'
  import logo from '@/assets/logo2.jpeg'
  import miniApp from '@/assets/miniApp.jpg'
  import imgDesign from '@/assets/imgDesign.png'
  import dayjs from 'dayjs'
  import {
    MONITOR_PATH,
    DECISIONAIDS_PATH,
    SHOP_PATH,
    PRODUCT_ATLAS_PATH,
    PRODUCT_GRAPHICS_PATH,
  } from '@/config/business.config'

  export default defineComponent({
    name: 'VabSideBarFooter',
    setup() {
      const PosterDesignRef = ref(null)
      const store = useStore()
      const router = useRouter()
      const collapse = computed(() => store.getters['settings/collapse'])
      const userMenusAuthObj = computed(() => store.getters['routes/menusObj'])
      console.log('userMenusAuthObj', userMenusAuthObj)
      const toolsList = computed(() => {
        return [
          {
            show: true,
            title: '鲸苇助手',
            logo: logo,
            // url: 'https://crx.jinweinet.com/?post=1',
            url: 'http://doc.erp.jinweinet.com/?post=1',
            previewSrc: null,
          },
          {
            show: true,
            title: 'ERP小程序',
            logo: miniApp,
            type: 'scan',
            cls: 'wh60 p5',
            previewSrc: [miniApp],
          },
          {
            show: true,
            title: 'AI助手',
            url: `https://jwkj.123499.xyz/?code=${window.btoa(
              dayjs().format('YYYYMMDD')
            )}`,
            icon: 'openai-fill',
            cls: 'fs50 opai',
            type: 'newWindow',
            previewSrc: null,
          },
          {
            show: !!userMenusAuthObj.value[DECISIONAIDS_PATH],
            title: '客户智搜',
            path: DECISIONAIDS_PATH,
            icon: 'group-fill',
            cls: 'fs50 c0',
            previewSrc: null,
          },
          {
            show: !!userMenusAuthObj.value[MONITOR_PATH],
            title: '海关数据',
            path: MONITOR_PATH,
            icon: 'focus-3-line',
            cls: 'fs50 c0',
            previewSrc: null,
          },
          {
            show: !!userMenusAuthObj.value[SHOP_PATH],
            title: '独立站',
            parentPath: SHOP_PATH,
            children: userMenusAuthObj.value[SHOP_PATH]?.children || [],
            icon: 'shopping-bag-3-line',
            cls: 'fs50 cd',
            previewSrc: null,
          },
          {
            show: !!userMenusAuthObj.value[PRODUCT_ATLAS_PATH],
            title: '产品图册',
            path: PRODUCT_ATLAS_PATH,
            icon: 'stack-fill',
            cls: 'fs50',
            previewSrc: null,
          },
          {
            show: !!userMenusAuthObj.value[PRODUCT_GRAPHICS_PATH],
            title: '产品图文',
            parentPath: PRODUCT_GRAPHICS_PATH,
            children:
              userMenusAuthObj.value[PRODUCT_GRAPHICS_PATH]?.children || [],
            icon: 'gallery-fill',
            cls: 'fs50',
            previewSrc: null,
          },
          {
            show: true,
            title: '图片编辑器',
            // icon: 'image-edit-fill',
            logo: imgDesign,
            cls: 'wh60 p5',
            // cls: 'fs50 c0',
            type: 'imgEidt',
            previewSrc: null,
          },
          {
            show: true,
            title: '远程连接工具',
            url: 'https://sunlogin.oray.com/download?categ=personal',
            icon: 'icon-yuanchenglianjie-wuzhuangtai',
            isCustom: true,
            size: 50,
            cls: 'c0',
            previewSrc: null,
          },
          {
            show: true,
            title: '推荐浏览器',
            url: 'https://www.google.cn/intl/zh-CN/chrome/',
            icon: 'chrome-fill',
            cls: 'fs50',
            previewSrc: null,
          },
        ]
      })
      const handleLink = (item) => {
        if (item.path) {
          router.push(item.path)
        }
        if (item.url) {
          // if (item.type === 'newWindow') {
          //   window.open(
          //     item.url,
          //     item.title,
          //     'width=600,height=700,left=300,top=100'
          //   )
          //   return
          // }
          window.open(item.url)
        }
        if (item.type === 'imgEidt') {
          PosterDesignRef.value.showPosterDesign({
            slider_image: '',
          })
        }
      }

      const handleLinkPath = (item) => {
        if (item.url) {
          router.push(item.url)
        }
      }
      return {
        PosterDesignRef,
        collapse,
        variables,
        toolsList,
        handleLink,
        t,
        handleLinkPath,
      }
    },
  })
</script>

<style lang="scss" scoped>
  @import '../../../styles/variable.scss';
  .vab-side-bar-footer-wrapper {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;
    width: $base-left-menu-width;
    padding: 10px 5px;
    &.is-collapse {
      width: $base-left-menu-width-min;
    }
    &-tools {
      min-height: 300px;
      .tools-item {
        &:hover {
          // background-color: $primary-opt;
          border-color: $primary;
        }
      }
    }
  }
</style>
