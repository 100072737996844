/*
 * @Author: WGL
 * @Date: 2022-05-07 11:19:17
 * @LastEditors: WGL
 * @LastEditTime: 2024-07-23 16:45:09
 * @Description: 
 */
import { getRequest, postRequest } from './api'

const module = 'product'
const module_category = 'category'
const module_customer = 'customer-type'
const module_attr_template = 'product-attr-template'
const module_computed_template = 'product-compound-template' // 产品组装拆卸模版
const module_product_grant = 'product-grant' // 产品授权

export function getProductList(params = {}) {
  const url = `${module}/index`
  return getRequest(url, params)
}


export function addProduct(params = {}) {
  const url = `${module}/add`
  return postRequest(url, params)
}

export function editProduct(params = {}) {
  const url = `${module}/edit`
  return postRequest(url, params)
}

export function getProductDetail(params = {}) {
  const url = `${module}/detail`
  return getRequest(url, params)
}

export function getProductCode(params = {}) {
  const url = `${module}/code`
  return postRequest(url, params)
}

export function deleteProduct(params = {}) {
  const url = `${module}/delete`
  return postRequest(url, params)
}


// 生成产品sku
export function createProductSku(params = {}) {
  const url = `${module}/sku`
  return postRequest(url, params)
}

// 产品分类
export async function productCategoryList(params = {}) {
  const url = `${module_category}/list`
  return getRequest(url, params)
}
// 产品具体分类
export function getProductCategory(params = {}) {
  const url = `${module_category}/index`
  return getRequest(url, params)
}

// 用户分类
export async function productCustomerList(params = {}) {
  const url = `${module_customer}/index`
  return getRequest(url, params)
}

// 产品规格模板

export function getProductAttrTemplateList(params = {}) {
  const url = `${module_attr_template}/index`
  return getRequest(url, params)
}

export function addProductAttrTemplate(params = {}) {
  const url = `${module_attr_template}/add`
  return postRequest(url, params)
}

export function editProductAttrTemplate(params = {}) {
  const url = `${module_attr_template}/edit`
  return postRequest(url, params)
}

export function getProductAttrTemplateDetail(params = {}) {
  const url = `${module_attr_template}/detail`
  return getRequest(url, params)
}

export function deleteProductAttrTemplate(params = {}) {
  const url = `${module_attr_template}/delete`
  return postRequest(url, params)
}

/**
 * 产品组装拆卸模版列表
 * @param {*} params 
 * @returns 
 */
export function productComputedTemplate(params = {}) {
  const url = `${module_computed_template}/index`
  return getRequest(url, params)
}

/**
 * 产品组装拆卸模版添加
 * @param {*} params 
 * @returns 
 */
export function addProductComputedTemplate(params = {}) {
  const url = `${module_computed_template}/add`
  return postRequest(url, params)
}

export function editProductGrant(params = {}) {
  const url = `${module_product_grant}/edit`
  return postRequest(url, params)
}