import { getRequest, postRequest } from './api'
/**
 * 获取菜单列表
 * @param {*} params
 */
export function getMenuList(params = {}) {
  const url = 'menu/index'
  return getRequest(url, params)
}
/**
 * 编辑菜单
 * @param {*} params
 */
export function editMenu(params = {}) {
  const url = 'menu/edit'
  return postRequest(url, params)
}
/**
 * 添加菜单
 * @param {*} params
 */
export function addMenu(params = {}) {
  const url = 'menu/add'
  return postRequest(url, params)
}

/**
 * 获取角色列表
 * @param {*} params
 * page 当前页
 * name 角色名
 */
export function getRoleList(params = {}) {
  const url = 'role/roles'
  return getRequest(url, params)
}
/**
 * 角色添加
 * @param {*} params
 */
export function addRole(params = {}) {
  const url = 'role/add'
  return postRequest(url, params)
}
/**
 * 角色编辑
 * @param {*} params
 */
export function editRole(params = {}) {
  const url = 'role/edit'
  return postRequest(url, params)
}
/**
 * 删除角色
 * @param {*} id 角色id
 */
export function deleteRole(id) {
  const url = 'role/delete'
  const params = { id }
  return postRequest(url, params)
}
/**
 * 获取网站配置信息
 * @param {*} domain 站点domain
 */
export function getWebsiteConfing() {
  const { host: domain } = window.location
  // const domain = 'localhost'
  const url = 'company/domain'
  const params = { domain }
  return getRequest(url, params)
}

/**
 * 获取通用配置字典信息
 * @param {*} domain 站点domain
 */
export function getDictConfing() {
  // const domain = 'localhost'
  const url = 'config/variable'
  return getRequest(url, {})
}

/**
 * 获取系统配置信息
 */
export function getSysConfigSetting(params = {}) {
  const url = 'company/config'
  return getRequest(url, params)
} 