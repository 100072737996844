<template>
  <div class="system-user-container">
    <base-table
      ref="tableRef"
      :config="tableConfig"
      @header-action="handleHeaderAction"
    >
      <template #title="{ scope }">
        <div class="cp cb tdu" @click="showPreview(scope)">
          {{ scope.title || scope.file_path }}
        </div>
      </template>
      <template #size="{ scope }">
        {{ (scope.size / 1024).toFixed(2) + 'KB' }}
      </template>
      <template #type="{ scope }">
        {{ scope.type == 'image' ? t('图片') : t('文件') }}
      </template>
    </base-table>
    <el-dialog
      v-model="showUpload"
      append-to-body
      center
      destroy-on-close
      :title="t('上传文件')"
      width="45%"
    >
      <el-form
        label-position="left"
        label-width="120px"
        style="margin-top: 20px"
      >
        <FileUpload
          :id="id"
          v-model:file-list="followcondition"
          limit="1"
          :module="fileModule"
          @on-success="handleUploadSuccess"
        />
      </el-form>
    </el-dialog>
    <Previewer ref="previewerRef" />
  </div>
</template>

<script>
  import {
    defineComponent,
    reactive,
    onBeforeMount,
    ref,
    toRefs,
    computed,
  } from 'vue'
  import { fillUrl } from '@/common/js/utils'
  import { t } from '@/utils/i18n'

  // 表格数据

  const MODULE = 'file' // 模块

  export default defineComponent({
    name: 'FileList',
    components: {},
    props: {
      info: {
        require: true,
        type: Object,
        default: () => {},
      },
      module: {
        type: String,
        default: '/',
      },
      params: {
        type: Object,
        default: () => {},
      },
    },
    setup(props) {
      const fileModule = computed(() => {
        return props.info?.module || props.params?.module || props.module
      })
      const state = reactive({
        showUpload: false,
        followcondition: [],
      })
      const handleHeaderAction = (action) => {
        if (action.code === 'upload') {
          state.showUpload = true
        }
      }
      const previewerRef = ref(null)
      const showPreview = (row) => {
        previewerRef.value.show(row.file_path, row.extension, row.title)
      }
      const tableRef = ref(null)
      const handleUploadSuccess = (data) => {
        if (data.status == 'success') {
          state.showUpload = false
          tableRef.value.initSearch()
        }
      }
      const id = computed(() => {
        return props?.info?.id || 0
      })
      const tableColumns = [
        { label: 'ID', prop: 'id', width: 80 },
        { label: '附件名称(点击预览)', prop: 'title' },
        { label: '附件类型', prop: 'type', width: '120px' },

        { label: '附件大小', prop: 'size', width: '120px' },
        { label: '上传人', prop: 'create_admin_info.name', width: '160px' },
        { label: '上传时间', prop: 'create_time', width: '180px' },
        {
          width: 150,
           label: '操作',
            isOperate: true,
          fixed: 'right',
          action: {
            rename: {
              type: 'editBox',
            },
            list: ['rename', 'deleteFile'],
          },
        },
      ]
      const tableConfig = reactive({
        headerActions: {
          upload: {
            type: '',
          },
          list: ['upload'],
        },
        tableColumn: tableColumns,
        tableListApi: `/${MODULE}/index`,
        tableListParams: {
          sort: 'id',
          order: 'desc',
          ref_id: id,
          per_page: 10,
          module: fileModule,
        },
        tableDeleteApi: `/${MODULE}/delete`,
        tableUpdateApi: `/${MODULE}/edit`,
        tableDeleteParams: {},
        editBox: {
          title: '明细',
          dialog: {
            width: '450px',
            appendToBody: true,
            destroyOnClose: true,
          },
          form: {
            api: {
              detail: `/${MODULE}/detail`,
              add: `/${MODULE}/add`,
              update: `/${MODULE}/edit`,
            },
            formAttrs: {
              labelPosition: 'left',
              labelWidth: '150px',
            },
            formList: [
              {
                type: 'input',
                key: 'title',
                labelAttrs: {
                  label: '文件名称',
                },
                formAttrs: {
                  type: 'text',
                  placeholder: '文件名称',
                  clearable: true,
                },
              },
            ],
          },
          footer: [
            {
              action: 'cancel',
              title: '取消',
              attrs: {},
            },
            {
              action: 'confirm',
              title: '确定',
              attrs: {
                type: 'primary',
              },
            },
          ],
        },
      })
      onBeforeMount(async () => {})
      return {
        tableRef,
        previewerRef,
        tableConfig,
        id,
        fileModule,
        ...toRefs(state),
        fillUrl,
        handleHeaderAction,
        handleUploadSuccess,
        showPreview,
        t,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .system-user-container {
    height: 100%;
  }
</style>
