<!--
 * @Author: WGL
 * @Date: 2023-04-18 09:16:26
 * @LastEditors: WGL
 * @LastEditTime: 2024-09-11 15:15:24
 * @Description: 
-->
<template>
  <div v-show="showForm" class="system-user-container extra-form-wrapper">
    <el-divider>
      {{ t('扩展字段') }}
      <jwt-icon class="cp g6" :icon="unfoldIcon" @click="handleUnfold" />
    </el-divider>
    <base-form
      v-show="unfoldForm"
      ref="extraFormRef"
      v-model="formModel"
      :config="formConfig"
      @get-detail-success="handleGetDetailSuccess"
    />
  </div>
</template>

<script>
  import { defineComponent, reactive, ref, computed } from 'vue'
  import { handleExtraAttrs } from '@/utils/index'
  import { EXTEND_ATTR_TYPE } from '@/config/variable.config'
  import { t } from '@/utils/i18n'
  // form 表单中编辑额外的扩展字段
  export default defineComponent({
    name: 'ExtraForm',
    components: {},
    props: {
      info: { type: Object, default: () => {} },
      params: { type: Object, default: () => {} },
      unfold: { type: Boolean, default: false },
    },
    setup(props) {
      console.log('props.info :>> ', props.info)
      const unfoldForm = ref(props.unfold) // 展开form
      const extraFormRef = ref(null)
      const formModel = ref({ id: props?.info?.id })
      const showForm = ref(false)
      const unfoldIcon = computed(() => {
        return unfoldForm.value
          ? 'arrow-down-double-line'
          : 'arrow-up-double-line'
      })
      let formConfig = reactive({
        api: {
          add: `/${props.params.module}/extra/edit`,
          detail: `/${props.params.module}/extra/detail`,
          update: `/${props.params.module}/extra/edit`,
          detailParams: {
            id: props?.info?.id || 'null',
          },
          detailCallBack: (data, results) => {
            if (F.isArray(results)) {
              const { formModel } = handleExtraAttrs(results, '')
              return { ...formModel, id: props?.info?.id }
            } else if (F.isObject(data)) {
              return data
              // let result = {
              //   id: props?.info?.id,
              // }
              // for (const val of Object.values(data)) {
              //   const { attr_key, attr_value, attr_type } = val || {}
              //   if (attr_key) {
              //     result[attr_key] = EXTEND_ATTR_TYPE[attr_type].multiple
              //       ? attr_value
              //         ? attr_value?.split(',')
              //         : []
              //       : attr_value
              //   }
              // }
              // // data.forEach((item) => {
              // //   result[attr_key] = attr_value
              // // })
              // console.log('detailCallBack data', result)
              // return result
            }
            return data
          },
        },
        // beforeReq: (data, model) => {
        //   return model
        // },
        formEditParams: {
          id: props?.info?.id,
        },
        formListKey: true,
        formAttrs: {
          labelPosition: 'right',
          labelWidth: '100px',
        },
        formList: [],
      })

      const handleGetDetailSuccess = (data) => {
        console.log('data handleGetDetailSuccess', data)
        showForm.value = !!data.length
        const { formList } = handleExtraAttrs(data, '')
        console.log('formList :>> ', formList)
        formConfig.formList = formList.map((item) => {
          if (props?.params?.layoutAttrs) {
            item.layoutAttrs = props.params.layoutAttrs
          }
          return item
        })
      }

      const handleSubmit = (res) => {
        if (!showForm.value) return
        extraFormRef.value?.formSubmit('', { id: res.id })
      }

      const handleUnfold = () => {
        unfoldForm.value = !unfoldForm.value
      }
      const handleGetDetailInfo = () => {
        extraFormRef.value?.formRef.getDetail()
      }

      return {
        showForm,
        formConfig,
        formModel,
        extraFormRef,
        unfoldForm,
        unfoldIcon,
        t,
        handleGetDetailSuccess,
        handleSubmit,
        handleUnfold,
        handleGetDetailInfo,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
