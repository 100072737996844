/*
 * @Author: WGL
 * @Date: 2022-02-15 18:40:13
 * @LastEditors: WGL
 * @LastEditTime: 2024-01-22 15:04:04
 * @Description:
 */
/**
 * @description 导入所有 vuex 模块，自动加入namespaced:true，用于解决vuex命名冲突，请勿修改。
 */
import { createStore } from 'vuex'
import ErpPosterDesign from 'erp-poster-design'
// import ErpPosterDesign from '../../../erp-poster-design/dist/erp-poster-design.es'
// import '../../../erp-poster-design/dist/style.css'

const modules = {}
const files = require.context('./modules', false, /\.js$/)
files.keys().forEach((key) => {
  modules[key.replace(/(modules|\/|\.|js)/g, '')] = {
    ...files(key).default,
    namespaced: true,
  }
})

const store = createStore({ modules })

export function setupStore(app) {
  app.use(store)
  app.use(ErpPosterDesign, { store })
}

export default store
