<!--
 * @Author: WGL
 * @Date: 2023-11-27 14:45:42
 * @LastEditors: WGL
 * @LastEditTime: 2024-07-05 17:10:56
 * @Description: 
-->
<template>
  <div>
    <div class="waterfall">
      <div v-for="(item, index) in imgList" :key="index" class="item">
        <base-image
          class="img"
          :fill-url="fillUrl"
          :fit="fit"
          :src="item"
          :type="type"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { computed, defineComponent } from 'vue'
  import { fillUrl } from '@/common/js/utils'

  export default defineComponent({
    name: 'BaseImages',
    props: {
      fit: {
        type: String,
        default: 'cover',
      },
      src: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: '',
      },
      border: { type: Boolean, default: true },
    },
    emits: ['table-action'],
    setup(props) {
      const imgList = computed(() => {
        return props.src.split(',')
      })

      return {
        fillUrl,
        imgList,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .waterfall {
    column-count: 4; //想要排成的列数
    column-gap: 10px;
  }
  .item img {
    width: 80%;
  }
</style>
