import { getList } from '@/api/roleManagement'

const state = () => ({
  admin: false,
  roles: [],
  permission: [],
})
const getters = {
  admin: (state) => state.admin,
  roles: (state) => state.roles,
  rolesObj: (state) => {
    const obj = {}
    state.roles.map((item) => {
      obj[item.value] = item.label
    })
    return obj
  },
  permission: (state) => state.permission,
}
const mutations = {
  setFull(state, admin) {
    state.admin = admin
  },
  setRole(state, roles) {
    state.roles = roles
  },
  setPermission(state, permission) {
    state.permission = permission
  },
}
const actions = {
  setFull({ commit }, admin) {
    commit('setFull', admin)
  },

  async getRoleList({ commit }) {
    const result = await getList()
    const roles = result.data.map((item) => {
      item.value = item.id
      item.label = item.title
      return item
    })
    roles.unshift({ label: '超级管理员', value: -1 })
    commit('setRole', roles)
  },
  setPermission({ commit }, permission) {
    commit('setPermission', permission)
  },
}
export default { state, getters, mutations, actions }
