<!--
 * @Author: WGL
 * @Date: 2023-09-19 16:07:25
 * @LastEditors: WGL
 * @LastEditTime: 2023-12-27 18:41:34
 * @Description: 
-->
<template>
  <jwt-des-info
    v-if="config?.desType === 'info'"
    class="base-des-table-wrapper"
    :config="desTableConfig"
    :current-page-path="currentPagePath"
    :fill-url="fillUrl"
    :info="detailInfo"
    :request="request"
    :translate-title="translateTitle"
    :upload-url="uploadUrl"
    :user-menus-auth="userMenusAuthObj"
    @change="handleEditRowProp"
    @click-cell="handleClickRow"
    @des-edit-success="handleDesEditSuccess"
    @header-action="handleHeaderAction"
  >
    <template v-for="k in Object.keys(slots)" #[k]="{ scope }" :key="k">
      <slot :name="k" :scope="scope"></slot>
    </template>
  </jwt-des-info>
  <jwt-des-table
    v-else
    class="base-des-table-wrapper"
    :config="desTableConfig"
    :current-page-path="currentPagePath"
    :detail-info="detailInfo"
    :fill-url="fillUrl"
    :request="request"
    :translate-title="translateTitle"
    :upload-url="uploadUrl"
    :user-menus-auth="userMenusAuthObj"
    @change="handleEditRowProp"
    @click-cell="handleClickRow"
    @des-edit-success="handleDesEditSuccess"
    @header-action="handleHeaderAction"
  >
    <template v-for="k in Object.keys(slots)" #[k]="{ scope }" :key="k">
      <slot :name="k" :scope="scope"></slot>
    </template>
  </jwt-des-table>
</template>

<script>
  import { defineComponent, onBeforeMount, computed, useSlots } from 'vue'
  import request from '@/utils/request'
  import { fillUrl } from '@/common/js/utils'
  import { useStore } from 'vuex'
  import { uploadUrl, uploadRichTextUrl } from '@/config/index'
  import { useRouter, useRoute } from 'vue-router'
  import { translateTitle } from '@/utils/i18n'

  export default defineComponent({
    name: 'BaseDesTable',
    components: {},
    props: {
      config: { require: true, type: Object, default: () => {} },
      detailInfo: { require: true, type: Object, default: () => {} },
      params: { type: Object, default: () => {} }, // 参数
    },
    emits: ['change', 'click-cell', 'header-action', 'des-edit-success'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const slots = useSlots()
      const userMenusAuthObj = computed(() => store.getters['routes/menusObj'])
      const currentPagePath = computed(() => {
        const { path } = route
        return path || ''
      })

      // 用户的列表字段
      const userFieldList = computed(() => store.getters['sys/userFieldList'])
      // 过滤隐私字段信息
      const desTableConfig = computed(() => {
        const newConfig = props.config
        const controlFieldList =
          userFieldList.value[props?.params?.refMenuId || '']
        newConfig.desColumns = newConfig.desColumns?.filter((item) => {
          if (item.privacyId && controlFieldList) {
            return !!controlFieldList[item.privacyId]
          }
          return true
        })
        if (newConfig?.attrs?.title) {
          newConfig.attrs.title = translateTitle(newConfig.attrs.title)
        }
        return newConfig
      })
      const handleEditRowProp = (row, item) => {
        emit('change', row, item, 'drawDes')
      }

      const handleClickRow = (row, item) => {
        console.log('row', row)
        console.log('item', item)
        emit('click-cell', row, item)
      }

      const handleHeaderAction = (action) => {
        emit('header-action', action)
      }

      const handleDesEditSuccess = (item) => {
        emit('des-edit-success', item)
      }
      onBeforeMount(() => {})

      return {
        desTableConfig,
        request,
        uploadUrl,
        uploadRichTextUrl,
        slots,
        route,
        router,
        userMenusAuthObj,
        currentPagePath,
        fillUrl,
        handleHeaderAction,
        translateTitle,
        handleEditRowProp,
        handleClickRow,
        handleDesEditSuccess,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .base-des-table-wrapper {
    img {
      width: 100%;
    }
  }
</style>
