/*
 * @Author: WGL
 * @Date: 2024-02-19 10:46:39
 * @LastEditors: WGL
 * @LastEditTime: 2024-02-19 11:03:35
 * @Description: 
 */
import { postRequest } from './api'

const module = 'tool'


/**
 * 获取图片原图
 * @param {*} params 
 * @returns 
 */
export function getImageOrg(params = {}) {
  const url = `${module}/image/org`
  return postRequest(url, params)
}
