<!--
 * @Author: WGL
 * @Date: 2023-06-21 09:23:33
 * @LastEditors: WGL
 * @LastEditTime: 2024-09-10 17:23:36
 * @Description: 
-->
<template>
  <div class="w p5 abs_lt fx" style="z-index: 1000;">
    <div v-for="(item, index) in tagList" :key="index">
      <template v-if="item.show">
        <el-tooltip :content="item?.tips" effect="dark" placement="top">
          <div class="wh20 fxmc cp r100px cf mr2 b" :class="item.class">
            {{ item?.title }}
          </div>
        </el-tooltip>
      </template>
    </div>
  </div>
</template>

<script>
  // 基础产品编码组件
  import { defineComponent, reactive, toRefs, computed } from 'vue'
  import { t } from '@/utils/i18n'

  export default defineComponent({
    name: 'BaseImageTag',
    components: {},
    props: {
      info: { type: Object, default: () => {} },
    },
    setup(props) {
      const state = reactive({})
      const tagList = computed(() => {
        return [
          {
            title: 'S',
            tips: '自产产品',
            show: +props?.info?.source === 1,
            class: 'bgt',
          },
          {
            title: 'K',
            tips: 'KP产品',
            show: props?.info?.is_pivotal,
            class: 'bgd',
          },
          {
            title: 'C',
            tips: '有产品凭证',
            show: !!props?.info?.certificate,
            class: 'bgy',
          },
        ]
      })
      const oemCode = computed(() => {
        return props?.info?.oem_code?.split(',')
      })
      return {
        oemCode,
        tagList,
        ...toRefs(state),
        t,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
