<template>
  <vab-app />
</template>

<script>
  import { defineComponent, onMounted } from 'vue'

  export default defineComponent({
    name: 'App',
    setup() {
      onMounted(() => {
        window.addEventListener('error', (e) => console.error(e.message))
      })
    },
  })
</script>
