<template>
  <el-card
    class="vab-colorful-card"
    :shadow="shadow"
    :style="{
      background: `linear-gradient(50deg, ${colorFrom}, ${colorTo})`,
    }"
  >
    <template #header>{{ title }}</template>
    <vab-icon v-if="icon" :icon="icon" />
    <slot />
  </el-card>
</template>

<script>
  export default {
    name: 'VabColorfulCard',
    props: {
      shadow: {
        type: String,
        default: '',
      },
      colorFrom: {
        type: String,
        default: '',
      },
      colorTo: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      icon: {
        type: String,
        default: '',
      },
    },
  }
</script>
<style lang="scss" scoped>
  .vab-colorful-card {
    position: relative;
    min-height: 120px;
    cursor: pointer;

    * {
      color: var(--el-color-white);
    }

    :deep() {
      .el-card__header {
        color: var(--el-color-white);
        border-bottom: 0;
      }
      .el-card__body {
        padding-top: 0;
      }
    }

    i {
      position: absolute;
      top: -30px;
      right: 20px;
      font-size: 60px;
      transform: rotate(15deg);
    }
  }
</style>
