<!--
 * @Author: WGL
 * @Date: 2023-10-31 10:15:08
 * @LastEditors: WGL
 * @LastEditTime: 2024-05-31 16:55:51
 * @Description: 
-->
<template>
  <el-popover
    content="this is content, this is content, this is content"
    placement="top-start"
    :title="goldenComponentConfig.popoverTitle"
    trigger="hover"
    :width="400"
  >
    <template #reference>
      <div v-if="isAutoParts" class="fxm cf cp ml10" @click="handleToGolden">
        <vab-icon :icon="goldenComponentConfig.icon" is-custom size="18" />
        <span class="b">{{ goldenComponentConfig.value }}</span>
      </div>
    </template>
    <div v-if="isSupplier">
      <div>{{ t('当前余额') }}：{{ goldenComponentConfig.value }}</div>
      <div class="fxm mt10">
        <span>{{ t('充值方式') }}：</span>
        <el-radio-group v-model="rechargeType">
          <el-radio
            v-for="(val, key) in CHARGE_PAY_TYPE"
            :key="val"
            :label="+key"
          >
            {{ val }}
          </el-radio>
        </el-radio-group>
      </div>
      <div class="fx mt10">
        <el-input-number
          v-model="rechargeVal"
          class="ex w mr10"
          :controls="true"
          controls-position="both"
          :max="999999"
          :min="0.01"
          :placeholder="t('请输入充值金额')"
          :step="1"
        />
        <el-button
          :disabled="ajaxLoading"
          :loading="ajaxLoading"
          type="primary"
          @click="handleRecharge"
        >
          {{ t('充值') }}
        </el-button>
      </div>
    </div>
    <div v-else>
      <div v-for="(item, index) in useRule" :key="index" class="fxm ptb2 fs14">
        <span class="">{{ t(item.title) }}:</span>
        <div class="ex ml4 g9">
          <span class="cd b fs16">{{ item.num }}</span>
          <span>{{ t(item.unit) }}</span>
          <span>{{ t(item.tips) }}</span>
        </div>
      </div>
    </div>
  </el-popover>
  <el-dialog
    v-model="showDialog"
    append-to-body
    center
    :title="t('请用微信或支付宝扫描二维码')"
    width="500px"
  >
    <div class="fxmc w fv">
      <qrcode-vue level="H" :size="size" :value="authUrl" />
    </div>
  </el-dialog>
</template>

<script>
  import {
    defineComponent,
    onMounted,
    ref,
    onUnmounted,
    computed,
    reactive,
    toRefs,
  } from 'vue'
  import { CHARGE_PAY_TYPE } from '@/config/variable.config'
  import { getCompanyInfo } from '@/api/user'
  import { postRequest, getRequest } from '@/api/api'
  import { t } from '@/utils/i18n'
  import { useRouter, useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import { ElMessage } from 'element-plus'
  import QrcodeVue from 'qrcode.vue'
  import { compareVersion } from '@/utils/business'

  export default defineComponent({
    name: 'VabGolden',
    components: { QrcodeVue },
    setup() {
      const route = useRoute()
      const router = useRouter()
      const store = useStore()
      const isAutoParts = computed(() => store.getters['sys/isAutoParts'])
      const isSupplier = computed(() => store.getters['user/isSupplier'])
      const companyInfo = ref({})
      const state = reactive({
        rechargeVal: null,
        rechargeType: 10,
        ajaxLoading: false,
        showDialog: false,
        authUrl: '',
        size: 300,
      })
      console.log('isSupplier', isSupplier)
      const goldenComponentConfig = computed(() => {
        return {
          icon: isSupplier.value
            ? 'icon-zhanghuyue-meiyuan cy'
            : 'icon-jindouyindou01 cy',
          value: isSupplier.value
            ? `$${companyInfo.value?.supplier_info?.balance || 0}`
            : companyInfo.value?.golden_bean || 0,
          popoverTitle: isSupplier.value ? t('费用余额') : t('金豆使用规则'),
        }
      })
      const useRule = [
        { title: '海关数据查询', unit: '金豆/次', num: '7' },
        { title: '海关数据进出口列表查询', unit: '金豆/次', num: '10' },
        { title: '海关数据分析', unit: '金豆/次', num: '20' },
        { title: '智能搜客详情', unit: '金豆/次', num: '300' },
        { title: '自动抠图功能', unit: '金豆/一张图', num: '8' },
        // {
        //   title: '短信发送',
        //   unit: '金豆/一条短信',
        //   num: '3',
        //   tips: '（国内短信）',
        // },
        // {
        //   title: '全量异步导出',
        //   unit: '金豆/页',
        //   num: '2',
        //   tips: '(一次可以导出超过1000条数据，导出数据包含图片)',
        // },
        { title: '电子图册生成', unit: '金豆/页', num: '2' },
        { title: '快递寄件', unit: '金豆/次', num: '5' },
      ]

      let timer = null
      const handleGetCompanyInfo = () => {
        getCompanyInfo().then((res) => {
          companyInfo.value = res
          if (res?.front_pc_admin_version) {
            compareVersion(res.front_pc_admin_version)
          }
        })
      }

      const handleInit = () => {
        handleGetCompanyInfo()
        timer = setInterval(() => {
          handleGetCompanyInfo()
        }, 1000 * 60)
      }

      const handleToGolden = () => {
        const url = isSupplier.value ? '/logs/expenseLog' : '/logs/goldenLog'
        router.push({ path: url })
      }

      const handleRecharge = () => {
        const { path } = route
        if (!state.rechargeVal) {
          return ElMessage.error(t('请输入充值金额'))
        }
        if (state.rechargeVal) {
          state.ajaxLoading = true
          postRequest('/supplier/charge', {
            amount: state.rechargeVal,
            pay_type: state.rechargeType,
            redirect_path: path,
          })
            .then((res) => {
              if (+state.rechargeType === 11) {
                state.authUrl = res.qr_url
                state.showDialog = true
                handleGetRechargeResult(res.order_id)
              } else {
                window.open(res.redirect_url)
              }
              state.ajaxLoading = false
              console.log('res :>> ', res)
            })
            .catch(() => {
              state.ajaxLoading = false
            })
        }
      }

      const handleGetRechargeResult = (order_id) => {
        if (!state.showDialog) return
        getRequest('/order/info', { order_id }).then((res) => {
          if (res.status === 10) {
            setTimeout(() => {
              handleGetRechargeResult(order_id)
            }, 1000)
          } else {
            state.showDialog = false
            router.push({
              path: '/stripePayResult',
              query: {
                order_id: order_id,
              },
            })
          }
          console.log('handleGetOrderDetail', res)
        })
      }
      onMounted(() => {
        handleInit()
      })

      onUnmounted(() => {
        clearInterval(timer)
      })
      return {
        ...toRefs(state),
        companyInfo,
        isAutoParts,
        useRule,
        goldenComponentConfig,
        isSupplier,
        CHARGE_PAY_TYPE,
        t,
        handleToGolden,
        handleRecharge,
      }
    },
  })
</script>
