<template>
  <div class="vab-product-wrapper pr10">
    <el-dropdown @command="handleCommand">
      <div class="fxm">
        <img class="product-logo" :src="currentAppInfo?.logo" />
        <div
          class="product-name ml5"
          :class="{ hover: userAppList.length > 1 }"
        >
          <span v-if="currentAppInfo?.title" class="plr5 fs16">
            {{ currentAppInfo?.title }}
          </span>
          <vab-icon v-if="userAppList.length > 1" icon="arrow-down-s-fill" />
        </div>
      </div>
      <template #dropdown v-if="userAppList.length > 1">
        <el-dropdown-menu>
          <el-dropdown-item disabled>
            <div class="w g3 fs15 ptb2">自有产品</div>
          </el-dropdown-item>
          <el-dropdown-item
            v-for="(item, index) in userAppList"
            :key="index"
            :command="item.id"
            :divided="index === 0"
          >
            <div class="fxm ptb8">
              <div class="rel">
                <img alt="" class="product-logo" :src="item.logo" />
                <span
                  v-if="item.id === currentAppInfo?.id"
                  class="vab-dot vab-dot-error"
                >
                  <span></span>
                </span>
              </div>
              <span class="pl10">{{ item.title }}</span>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script>
  import { computed, defineComponent, getCurrentInstance } from 'vue'
  import { useStore } from 'vuex'
  import { fillUrl } from 'common/js/utils'
  import { changeApp } from '@/api/app'
  import { switchTeam } from '@/api/user'
  import { windowReload } from '@/utils/business'

  export default defineComponent({
    name: 'VabProductTeam',
    props: {},
    setup() {
      const { proxy } = getCurrentInstance()
      const store = useStore()
      const user = computed(() => store.getters['user/userInfo'])
      const currentAppInfo = computed(
        () => store.getters['user/currentAppInfo']
      )
      const userAppList = computed(() => store.getters['user/userTeamAppList'])

      // 切换产品
      const handleCommand = async (command) => {
        if (command === currentAppInfo.value?.id) {
          return proxy.$baseMessage(
            '已在当前产品！请换一个试试！',
            'error',
            'vab-hey-message-error'
          )
        }
        const selectAppInfo = userAppList.value.find(
          (item) => item.id === command
        )
        console.log('selectAppInfo', selectAppInfo)
        const { isOwn } = selectAppInfo
        if (isOwn) {
          changeApp({ appid: command })
            .then(async () => {
              await store.dispatch('routes/changeProductTeam', command)
              proxy.$pub('reload-router-view')
            })
            .catch(() => {
              // windowReload()
            })
        } else {
          switchTeam({ team_id: selectAppInfo.team_id })
            .then(async () => {
              await store.dispatch('routes/changeProductTeam')
              proxy.$pub('reload-router-view')
            })
            .catch(() => {
              windowReload()
            })
        }
      }

      return {
        user,
        currentAppInfo,
        fillUrl,
        userAppList,
        handleCommand,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .vab-product-wrapper {
    .product-name {
      cursor: pointer;
      padding: 10px 5px;
      border-radius: 3px;
      &.hover {
        &:hover {
          background-color: #f2f4f6;
          // font-weight: bold;
          color: #222 !important;
        }
      }
    }
  }
  .vab-dot {
    position: absolute !important;
    top: -3px;
    right: -6px;
  }
  .product-logo {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 5px;
  }
</style>
