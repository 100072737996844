/*
 * @Author: WGL
 * @Date: 2023-03-29 14:35:36
 * @LastEditors: WGL
 * @LastEditTime: 2023-11-13 11:13:15
 * @Description: 
 */
import i18n from '@/i18n'

export function translateTitleNormal(title) {
  const { t, te } = i18n.global
  if (te(`vabI18n.${title}`)) return t(`vabI18n.${title}`)
  return title
}

export function translateTitle(title, module = '') {
  return t(title, module)
}

export function t(title, module = '') {
  if (module) {
    return translateTitleNormal(`${title}.${title}`)
  }
  return translateTitleNormal(title)
}