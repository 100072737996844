/*
 * @Author: WGL
 * @Date: 2024-04-26 18:10:59
 * @LastEditors: WGL
 * @LastEditTime: 2024-06-28 16:57:13
 * @Description: 
 */
import zh_CN from './zh_CN'
import en_US from './en_US'
import tr_TR from './tr_TR'
import zh_TW from './zh_TW'
import es_ES from './es_ES'
import de_DE from './de_DE'
import ru_RU from './ru_RU'
import { createI18n } from 'vue-i18n'
import enLocale from 'element-plus/dist/locale/en.mjs'
import zhLocale from 'element-plus/dist/locale/zh-cn.mjs'
import zhTwLocale from 'element-plus/dist/locale/zh-tw.mjs'
import trLocale from 'element-plus/dist/locale/tr.mjs'
import esLocale from 'element-plus/dist/locale/es.mjs'
import deLocale from 'element-plus/dist/locale/de.mjs'
import ruLocale from 'element-plus/dist/locale/ru.mjs'

const messages = {
  en_US: {
    ...enLocale,
    ...en_US,
  },
  zh_CN: {
    ...zh_CN,
    ...zhLocale,
  },
  tr_TR: {
    ...trLocale,
    ...tr_TR,
  },
  zh_TW: {
    ...zhTwLocale,
    ...zh_TW,
  },
  es_ES: {
    ...esLocale,
    ...es_ES,
  },
  de_DE: {
    ...deLocale,
    ...de_DE,
  },
  ru_RU: {
    ...ruLocale,
    ...ru_RU
  }
}

function getLanguage() {
  console.log(localStorage.getItem('language'))
  return localStorage.getItem('language') || 'zh_CN'
}

const i18n = createI18n({
  locale: getLanguage(),
  messages,
})

export default i18n
