/*
 * @Author: WGL
 * @Date: 2023-04-03 15:28:54
 * @LastEditors: WGL
 * @LastEditTime: 2024-05-27 14:35:27
 * @Description:
 */
export default {
  vabI18n: {
    首页: 'Home',
    看板: 'Dashboard',
    工作台: 'Workbench',
    个人中心: 'User center',
    Excel: 'Excel',
    错误页: 'Error',
    403: '403',
    404: '404',
    邮件: 'Email',
    更多: 'More',
    退出登录: 'Log out',
    登录: 'Log in',
    验证码: 'Verification code',
    注册: 'Register',
    国际化: 'Internationlization',
    刷新: 'Refresh',
    通知: 'Inform',
    全屏: 'Full screen',
    页面动画: 'Page animation',
    搜索: 'Search',
    重置: 'Reset',
    开启: 'Open',
    关闭: 'Close',
    保存: 'Save',
    欢迎来到: 'Welcome to',
    请输入用户名: 'Please enter your username',
    请输入密码: 'Password',
    用户名不能为空: 'The user name cannot be empty',
    密码不能少于6位: 'The password cannot be less than 6 characters',
    请输入正确的手机号: 'Please enter the correct phone number',
    请输入手机号: 'Please enter your phone number',
    请输入手机验证码: 'Please enter the mobile verification code',
    获取验证码: 'Get code',
    // ==================================== 常用基本 ==============================
    大: 'Big',
    中: 'Middle',
    小: 'Small',
    基本信息: 'Basic info',
    分类名称: 'Category name',
    请输入分类名称: 'Please enter category name',
    排序: 'Sort:',
    状态: 'Status',
    备注: 'Note',
    请输入备注: 'Please enter remarks',
    序号: 'No.',
    规格: 'Specs',
    '重量/长-宽-高/体积': 'WT/L-W-H/CBM',
    价格: 'Price',
    '价格(元)': 'Price($)',
    销量: 'Sales',
    封面图: 'Cover image',
    图片: 'Image',
    图标: 'Icon',
    编码: 'Code',
    OEM编码: 'OEM Code',
    '产品编码/OEM编码': 'Product Code /OEM Code ',
    '编码超级搜索，多个编码换行隔开':
      'Coded super search, multiple coded feed lines separated ',
    库存: 'Storage',
    调出仓库: 'Take out warehouse',
    调入仓库: 'Put in warehouse',
    '真实库存(锁定库存)': 'Real storage (locked storage)',
    采购价: 'Purchase Price',
    毛利率: 'Gross profit margin',
    客户报价: 'Customer quote',
    报价状态: 'Quotation status',
    来源: 'From',
    创建时间: 'Created',
    更新时间: 'Updated',
    使用状态: 'Status',
    总价: 'Total price',
    操作: 'Operation',
    名称: 'Name',
    加载数据中: 'Loading data...',
    系统: 'System',
    确定: 'Confirm',
    确认: 'Confirm',
    取消: 'Cancel',
    取消操作: 'Cancel operation！',
    '此操作将会永久删除数据，是否继续?':
      'This operation will delete the data completely, continue?',
    '确定要复制当前数据?':
      'Confirm to copy the current data?',
    请配置打印接口: 'Please configure the print interface！',
    暂无数据可导出: 'No data to export!',
    正在生成中: 'Generating...',
    请配置导出接口: 'Please configure export interface！',
    '当前页面可能存在未保存内容，直接离开内容将不被保存':
      'The current page may have unsaved content,the content will not be saved',
    请配置上传的路径地址: 'Please configure the upload path address！',
    编辑: 'Edit ',
    删除: 'Del',
    新建: 'New',
    提示: 'Notes',
    '请配置导出接口！': 'Please configure the export interface！',
    '暂无数据可导出！': 'No data to export!',
    正在下载中: ' Downloading...',
    文件: 'Files',
    '请配置文件上传接口！': 'Please configure the file upload interface!',
    '请配置编辑接口！': 'Please configure the editing interface!',
    '导出数据过多，已添加至异步导出队列生成':
      'Datas are too large to export,A async queue has been created',
    请配置文件上传接口: 'Please configure file upload interface！',
    请配置编辑接口: 'Please configure the editing interface！',
    请配置详情接口: 'Please configure details interface！',
    头像裁剪: 'Profile photo edit',
    选择图片: 'Select photo',
    上传并保存: 'Upload and save',
    返回: 'Return',
    产品详情: 'Product specs',
    总计: 'Total',
    查询: 'Query',
    单位: 'Unit',
    附件: 'Attachment',
    仓库: 'Warehouse',
    手机号: 'Cellphone',
    角色: 'Role',
    权限: 'Authority',
    是否负责人: 'In charge',
    固定号码: 'Telephone',
    上传文件: 'Upload file',
    '附件名称(点击预览)': 'Attachment Name (click to preview)',
    附件类型: 'Attachment Type',
    附件大小: 'Attachment size',
    上传人: 'Uploader:',
    上传图片大小不能超过: 'Upload picture size can not exceed',
    最多上传: 'Maximum upload',
    您选择了: 'Selected',
    '个文件,请重新选择': '-pieces of file, please re-select',
    你确定要删除: 'Confirm to delete?',
    上传时间: 'Upload time',
    明细: 'Detail',
    文件名称: 'File name',
    请输入分类排序: 'Please enter category sort',
    展开: 'Unfold',
    合并: 'Fold',
    紧急程度: 'Emergency level',
    添加: 'Add',
    请选择: 'Please select',
    供应商: 'Vendor',
    最近报价: 'Updated quote',
    品牌: 'Make',
    车型: 'Model',
    请选择车型: 'Please select model',
    部件: 'Part',
    请选择部件: 'Please select part',
    年份: 'Year',
    请选择年份: 'Please select the year',
    发表评论: 'Post a comment',
    修改: 'Modify',
    '暂无评论~': 'No comments~',
    评论人员: 'Reviewer',
    '暂无信息~': 'No information~',
    姓名: 'Name',
    电话: 'Phone number',
    请输入电话: 'Email or phone number',
    邮箱: 'Email',
    部门: 'Department',
    职务: 'Position',
    图表显示: 'Chart display',
    指标值: 'Index value',
    日: 'D',
    月: 'M',
    年: 'Y',
    已选择: 'Selected',
    请输入关键词: 'Please enter keyword',
    使用选择: 'Use selection',
    暂无内容: 'No content yet',
    选择成功: 'Select successfully',
    跟进: 'Follow up',
    已添加: 'Added',
    库存不足: 'Out of stock',
    是: 'Yes',
    否: 'No',
    请填写: 'Please fill in',
    币种: 'Currency',
    创建人: 'Creator',
    最近更新: 'Recent updated',
    从素材库选择: 'Select from the album',
    // TODO: 2023-11-14 start
    从素材库选: 'Select from the album',
    在此处粘贴图片即上传: 'Paste picture here and upload',
    '上传图片只能是JPG,PNG,GIF,WEBP格式':
      'Upload images in JPG,PNG,GIF,WEBP format only',
    只能上传一张图片: 'Only one image can be uploaded',
    当前浏览器不支持本地图片粘贴:
      'Current browser does not support local image paste',
    粘贴内容非图片: 'Paste content non-picture',
    上传文件大小不能超过: 'Upload file size cannot exceed',
    正在上传中: 'Uploading...',
    上传: 'Upload',
    上传完成: 'Upload finished',
    上传失败请重新上传: 'Upload failed please re-upload',
    正在导入中: 'Importing',
    导入: 'Import',
    导出: 'Export',
    全选: 'Select all',
    使用选中: 'Use check',
    一键抠图: 'One-click matting',
    抠图: 'Matting',
    不抠图: 'No matting',
    完成: 'Done',
    下载: 'Download',
    查看: 'View',
    暂无数据: 'No data yet',
    上级分类: 'Superior classification',
    添加分类: 'Add category',
    编辑分类: 'Edit category',
    删除分类: 'Delete category',
    分类名称不能为空: 'Category name cannot be empty',
    音频: 'Audio',
    只能选择一张图片: 'Only one picture can be selected',
    请配置分类接口: 'Please configure the classification interface',
    全部: 'All',
    请配置素材接口: 'Please configure the material interface',
    请添加分类: 'Please add category',
    请配置分类添加接口: 'Please configure category add interface',
    请配置分类编辑接口: 'Please configure the category editing interface',
    请配置分类删除接口: 'Please configure category delete interface',
    文件类型格式不正确: 'File type formatted incorrectly!',
    大小不能超过: 'Size cannot exceed',
    个文件请重新选择: 'Please re-select file',
    请配置素材分类导出接口:
      'Please configure the material category export interface！',
    立即新增: 'Add now',
    上传视频大小不能超过: 'Upload video size can not exceed',
    图标选择器: 'Icon selector',
    最后编辑: 'Last edit',
    步骤: 'Step',
    标题: 'Title',
    审核: 'Audit',
    审核状态: 'Audit status',
    请选择类型: 'Please select type',
    请选择审核状态: 'Please select audit status',
    通过: 'Through',
    不通过: 'Unthrough',
    付款状态: 'Payment status',
    请选择付款状态: 'Please select payment status',
    发货状态: 'Delivery status',
    请选择发货状态: 'Please select delivery status',
    提成金额: 'Royalty amount',
    请输入提成金额: 'Please enter the amount of commission',
    回款方式: 'Collection method',
    请选择回款方式: 'Please select collection method',
    回款日期: 'Received date',
    请输入回款日期: 'Please enter received date',
    流水单号: 'Number of bank slip',
    请输入支付流水单号: 'Please enter the payment slip number',
    支付凭证: 'Payment voucher',
    付款方式: 'Payment method',
    请选择付款方式: 'Please select payment method',
    付款日期: 'Payment Date',
    请输入付款日期: 'Please enter payment date',
    下单日期: 'Order Date',
    请选择下单日期: 'Please select order date',
    请输入名称: 'Please enter name',
    保存并新增: 'Save and add',
    您确定要对当前数据进行提审:
      'Are you sure you want to arraign the current data？',
    您确定要对当前数据进行提审并通过:
      'Are you sure you want to arraign the current data and pass？',
    '您确定要对当前状态进行撤销？撤销后可重新编辑提审':
      'Are you sure you want to undo the current state? After cancellation, the arraignment can be edited again',
    您确定要对当前订单进行续单:
      'Are you sure you want to renew your current order？',
    您确定要对当前订单生成出库单:
      'Are you sure you want to generate an exit sheet for your current order？',
    // ==================================== 邮箱模块 ==============================
    表格模版: 'Form template',
    去添加: 'To add',
    邮件模版: 'Mail template',
    生成预览: 'Build preview',
    表格: 'Form',
    取消附件: 'Cancel attachment',
    单选: '单选',
    只看我的: 'Only look mine',
    先发给自己: 'First to send to myself',
    主题: 'Subject',
    请输入主题: 'Please enter a theme',
    请输入标题: 'Please enter a title',
    收件人: 'Recipient',
    请选择收件人: 'Please select recipient',
    头部: 'Head',
    尾部: 'Tail',
    发送: 'Send',
    // ==================================== f附件模块 ==============================
    下次联系时间: 'Next contact time',
    跟进内容: 'Follow-up content',
    上传附件: 'Upload attachment',
    '是否删除这条跟进记录?': 'Do you want to delete this follow-up?',
    确定进入: 'Confirm entry:',
    阶段: 'Stage',
    // ==================================== f成员管理模块 ==============================
    成员管理: 'Member Management:',
    成员: 'Member',
    请选择成员: 'Please select members',
    只读: 'Read only',
    读写: 'Read and write',
    负责人: 'Responsible person',
    更换负责人: 'Change in charge',
    设置为负责人: 'Set as Responsible',
    请选择负责人: 'Select Responsible',
    // ==================================== 产品模块 ==============================
    产品: 'Product',
    数量: 'QTY',
    产品名称: 'Product Name',
    请输入产品名称: 'Please enter product name',
    产品分类: 'Product Category',
    请选择产品分类: 'Please select product category',
    产品类型: 'Product Type',
    请选择产品类型: 'Please select product type',
    请输入排序: 'Please enter sort',
    产品编码: 'Product Code',
    请输入产品编码: 'Please enter product code',
    请输入OEM编码: 'Please enter OEM code',
    适用车型: 'Vehicle model',
    请选择适用车型: 'Please select vehicle model',
    车身部件: 'Body parts',
    请选择车身部件: 'Please select body parts',
    产品货币: 'Currency',
    请选择产品货币单位: 'Please select product currency unit',
    预警库存: 'Early warning inventory',
    '建议售价/最低售价/成本': 'Suggested/lowest/cost',
    外贸: 'Foreign',
    零售: 'Retail',
    成本: 'Cost',
    '名称/编码/OEM编码': 'Name/code/OEM',
    '重量/体积/长-宽-高': 'Weight/volume/L-W-H',
    上下架: 'On/Off line',
    上架: 'On line',
    下架: 'Off line',
    请选择单位: 'Please select a unit',
    建议售价: 'List price',
    最低售价: 'Lowest price',
    成本价: 'Cost price',
    装箱量: 'Packing quantity',
    箱规: 'Box gauge',
    '箱规-长(cm)': 'L/CTN(cm)',
    '箱规-宽(cm)': 'W/CTN(cm)',
    '箱规-高(cm)': 'H/CTN(cm)',
    '箱规-装箱量': 'Pcs/CTN',
    '箱规-净重(kg)': 'N.W./CTN(kg)',
    '箱规-毛重(kg)': 'G.W./CTN(kg)',
    '箱规-体积(m³)': 'CBM/CNT(m³)',
    '重量(kg)': 'Weight(kg)',
    '单个-长(cm)': 'L/Item(cm)',
    '单个-宽(cm)': 'WT/Item(cm)',
    '单个-高(cm)': 'H/Item(cm)',
    '单个-体积(m³)': 'CBM/Item(m³)',
    '单个-净重(kg)': 'N.W./Item(kg)',
    '单个-毛重(kg)': 'G.W./Item(kg)',
    证书: 'Certificate',
    建议尺寸比例: 'Recommended size ratio',
    重量: 'Weight',
    体积: 'Volume',
    分类: 'Category',
    '体积(m³)': 'Volume (m³)',
    描述: 'Description',
    请输入描述: 'Please enter a description: ',
    图集: 'Album',
    实施服务: 'Implementation Services',
    请选择实施服务: 'Please select implementation servic',
    扩展信息: 'Extended information',
    请选择供应商: 'Please select a vendor',
    图片信息: 'Image info',
    请选择仓库: 'Please select a warehouse ',
    请选择产品: 'Please select product ',
    入库: 'In storage',
    产品图片: 'Product image',
    我方编码: 'company code',
    对方编码: 'customer code',
    历史最低: 'Lowest',
    近期价格: 'Recent',
    历史最高: 'Highest',
    联系人: 'Contacts',
    详细地址: 'Full address',
    请选择车型品牌: 'Select make',
    请选择配置: 'Select configuration',
    货源: 'Source',
    产品概览: 'Product overview',
    配套产品: 'Full set products',
    配套产品详情: 'Set Product details',
    平替产品: 'Replaceable products',
    价格策略: 'Price Level',
    策略条件: 'Criterion',
    数量大于等于: 'Qty ≥',
    单价为: 'Unit price is',
    类型: 'Type',
    策略方式: 'Strategy mode',
    策略类型: 'Policy type',
    请选择策略类型: 'Please select a policy type',
    库存提示: 'Inventory alert',
    包含产品: 'Include product',
    配置: 'Configuration',
    导出原图: 'Export artwork',
    视频信息: 'Video message',
    视频: 'Video',
    目标市场: 'Target market',
    请选择目标市场: 'Please select target market',
    产品货源: 'Product source',
    请选择产品货源: 'Please select product source',
    上下架状态: 'Loading and unloading state',
    请选择产品上下架状态:
      'Please select product status of loading and unloading state',
    index: {},
    // ==================================== 产品分类模块 ==============================
    需要以字母开头: 'Need to start with a letter',
    显示名称: 'Display name ',
    请输入显示名称: 'Please enter a display name',
    用于生成电商网站时显示的分类标题:
      'Category title displayed when generating e-commerce website',
    分类url: 'Category url',
    请输入字母或数字: 'Please enter letters or numbers',
    '请输入分类url,如lights': 'Please enter the category url, such as lights',
    用于生成电商网站的链接: 'For generating links to e-commerce sites',
    分类描述: 'Category description',
    用于生成电商网站时SEO: 'SEO when generating e-commerce websites',
    分类关键词: 'Category keywords',
    请输入分类: 'Please enter category',
    // ==================================== 销售模块 ==============================
    内容: 'Content',
    跟进人: 'Follow-up person',
    跟进时间: 'Follow-up time',
    添加成员: 'Add member',
    删除成员: 'Delete member',
    '请选择成员(可多选)': 'Please select members (multiple options)',
    客户: 'Customer',
    客户名称: 'Customer Name',
    请输入客户名称: 'Please enter customer name: ',
    客户编码: 'Customer Code',
    成交单数: 'Number of deal',
    成交金额: 'Transaction amount',
    回款金额: 'Collection amount',
    客户来源: 'Customer Source',
    请选择客户来源: 'Please select customer source',
    客户行业: 'Customer Industry',
    请选择客户行业: 'Please select customer industry',
    客户等级: 'Customer Level',
    请选择客户等级: 'Please select Customer level',
    下次跟进时间: 'Next follow-up time',
    地区: 'Region',
    请选择地区: 'Please select region',
    请输入客户手机号: 'Please enter the customers mobile number',
    请输入邮箱地址: 'Please enter email address',
    地址: 'Address',
    请输入客户地址: 'Please enter customer address',
    是否成交: 'Deal or not',
    请选择是否成交: 'Please select deal or not',
    客户详情: 'Customer Details',
    最后跟进时间: 'Last follow-up time',
    微信: 'Wechat',
    请输入微信: 'Please enter wechat',
    商机: 'Business opportunity',
    范围: 'Range',
    请选择数据范围: 'Please select a data range',
    客户地区: 'Customer region',
    团队成员: 'Team member',
    发票信息: 'Invoice information',
    客户分析: 'Customer Analytics',
    客户订单分析: 'Customer order Analysis',
    请选择开始日期: 'Please select a start date',
    开始日期: 'Start date',
    结束日期: 'End date',
    退货产品: 'Returned Products',
    结束时间: 'End time',
    开始时间: 'Start time',
    核心数据: 'Core data',
    成交趋势: 'Transaction trend',
    产品分布: 'Product distribution',
    产品排行: 'Product Ranking',
    销售前20产品: 'Top 20 Products sold',
    历史订单: 'History Order',
    订单金额: 'Order Amount',
    订单总金额: 'Total order amount',
    订单笔数: 'Order number',
    下单数量: 'Order quantity',
    实收金额: 'Paid amount',
    '当前订单收款总和，扣除退款':
      'Total current order collections, net of refunds',
    退款金额: 'Refund amount',
    当前订单退款金额: 'Current order refund amount',
    未收回款: 'Uncollected',
    '订单金额-已收金额': 'Order amount - amount received',
    已开票金额: 'Invoiced amount',
    订单已开票的金额: 'Order invoiced amount',
    平均单价: 'Average unit price',
    每笔订单平均单价: 'Average price per order',
    排名: 'Ranking',
    渠道来源: 'Channel source',
    金额占比: 'Amount proportion',
    订单占比: 'Order share',
    公司名称: 'Company Name',
    请输入公司名称: 'Please enter company name',
    税号: 'Tax number',
    请输入公司税号: 'Please enter the company tax ID',
    开户行: 'Opening bank',
    银行账号: 'Bank account number',
    账户信息: 'Account information',
    发票抬头: 'Invoice title',
    关联客户: 'Associated customers',
    请选择客户: 'Please select customer',
    货币币种: 'Currency currency',
    开户银行: 'Account bank',
    请输入开户银行全称: 'Please enter your full bank name',
    银行卡号: 'Bank card number',
    请输入银行卡号: 'Please enter bank card number',
    请输入地址: 'Please enter address',
    退货商品为空表示只退款: 'Return item empty means refund only',
    请输入退货数量: 'Please enter return quantity',
    产品明细: 'Product Details',
    请至少添加一个产品: 'Please add at least one product',
    // TODO: 2023-11-14 start
    商机名称: 'Business Name',
    请输入商机名称: 'Please enter the business name',
    商机阶段: 'Business opportunity stage',
    请选择商机阶段: 'Please select business opportunity stage',
    商机属性: 'Business attributes',
    请选择商机属性: 'Please select business attributes',
    商机状态: 'Business status',
    请选择商机状态: 'Please select business status',
    预计金额: 'Estimated amount',
    请输入预计成交金额: 'Please enter the estimated transaction amount',
    请选择货币: 'Please select currency',
    预计成交: 'Estimated deal',
    请输入预计成交日期: 'Please enter the estimated deal date ',
    结束: 'End',
    商机详情: 'Business details',
    预计成交金额: 'Estimated deal amount',
    预计成交时间: 'Estimated deal time',
    // ==================================== 报价单模块 ==============================
    报价单: 'Quotation sheet',
    请选择报价单: 'Please select quotation sheet',
    报价详情: 'Quotation details',
    单号: 'Order No.',
    复用价格: 'Copy',
    时间: 'Time',
    我的报价: 'My quotations',
    '标题/编号': 'Title/No.',
    订单Id: 'Order ID',
    客户名: 'Customer name',
    单价: 'price',
    请选择出入库类型: 'Please select the in/out storage type',
    所有客户: 'All customers',
    当前客户: 'Current customer',
    时间范围: 'Time range',
    价格范围: 'Price range',
    请选择状态: 'Please select status',
    关联商机: 'Opportunity',
    请选择商机: 'Please select relative opportunities',
    请选择币种: 'Please select currency',
    生成订单: 'Generate order:',
    交付日期: 'Delivery date',
    '订单交付/发货日期': 'Order delivery date',
    请选择交付日期: 'Please select delivery date',
    报价单名称: 'Quotation name',
    报价单编码: 'Quotation No.',
    产品数量: 'Product quantity',
    订单货币: 'Order currency',
    编号: 'Code',
    金额: 'Amount',
    销售金额: 'Sales amount',
    销售订单: 'Sales order:',
    订单数量: 'Order quantity:',
    产品列表: 'Product list',
    订单详情: 'Order details',
    发货计划: 'Delivery plan',
    回款计划: 'Collection plan',
    回款明细: 'Collection details',
    出入库记录: 'Inbound and outbound records',
    服务明细: 'Service details',
    绩效明细: 'Performance breakdown',
    开票计划: 'Billing plan',
    '退/换货': 'Return/Exchange',
    合同: 'Contract',
    应收款: 'Receivable:',
    未收款: 'Unpaid',
    已收金额: 'Amount received',
    '当前订单收款总和，不扣除退款':
      'Current order collection total, not deducting refunds',
    已退金额: 'Amount refunded',
    实收回款: 'Received amount',
    退款中金额: 'Amount in refund',
    订单退款审批中金额: 'Amount in order refund approval',
    '金额(应收/实收)': 'Amount (Receivable/Collected)',
    下单时间: 'Order time',
    发货时间: 'Delivery time',
    请选择发货时间: 'Please select delivery time',
    订单: 'Order',
    签单类型: 'Signing order type',
    生成任务时的开始日期: 'Start date when the task is generated',
    单据日期: 'Document date',
    订单成交时日期: 'Order deal date',
    '订单退/换货': 'Order return/exchange',
    请输入退款金额: 'Please enter the refund amount',
    退款金额为0表示只退货: 'Refund amount 0 means return goods only',
    退款方式: 'Refund method',
    请选择退款方式: 'Please select refund method',
    退款日期: 'Refund date',
    请选择退款日期: 'Please select refund date',
    补差金额: 'Compensation amount',
    请输入补差金额: 'Please enter the compensation amount',
    补差金额为0表示只换货: 'A compensation amount of 0 means exchange only',
    补差方式: 'Compensation method',
    请选择补差方式: 'Please select the compensation method',
    补差日期: 'Compensation date',
    请选择补差计划日期: 'Please select the compensation plan date',
    换货商品为空表示只补差金额:
      'If the exchange product is empty, it means only the compensation amount will be paid.',
    '退/换货备注': 'Return/Exchange Remarks',
    请输入退货备注: 'Please enter return note',
    订单名称: 'Order name',
    订单编码: 'Order code',
    发货日期: 'Delivery date',
    包装要求: 'Packing requirements',
    规格说明: 'Specification',
    价格条款: 'Price terms',
    订单进度: 'Order progress',
    // ==================================== 仓库模块 ==============================
    备货中: 'Preparing',
    备货完成: 'Ready to delivery',
    业务员: 'Sales',
    计划时间: 'Schedule time',
    备货状态: 'Stock status',
    发货计划详情: 'Delivery plan details',
    发货进度: 'Delivery schedule',
    计划日期: 'Planned date',
    采购计划: 'Purchasing Plan',
    箱单: 'Packing list',
    发货单: 'Invoice',
    请选择销售订单: 'Please select sales order',
    交货日期: 'Delivery date',
    采购状态: 'Purchase status',
    请选择采购状态: 'Please select purchase status',
    添加采购计划: 'Add purchasing plan',
    请输入采购数量: 'Please enter purchase quantity',
    添加到采购单: 'Add to purchase order',
    换货产品: 'Exchange products',
    换货商品为空表示只补差额:
      'Replacement goods empty means only the difference',
    出入库: 'In/Out storage',
    出库: 'Out storage',
    日期: 'Date',
    入库类型: 'In storage type',
    出库类型: 'Out storage type',
    出入库类型: 'Storage/Outbound type',
    供应商名称: 'Vendor name',
    '供应商/客户': 'Vendor/customer',
    盘点状态: 'Storage audit status',
    '未作废数据删除，库存将不会恢复是否删除':
      'Uncancelled data deleted, inventory will not be restored. do you deceide to delete？',
    '此操作将会永久删除数据，是否继续':
      'This operation will permanently delete data, do you want to continue？',
    您确定要作废当前数据: 'Are you sure you want to invalidate current data？',
    // TODO: 2023-11-15 start
    '金额(应付/实付)': 'Amount (Payable/Paid)',
    实付金额: 'Paid Amount',
    '当前订单付款总和，扣除退款':
      'Total Payment of Current Order, Deducting Refunds',
    未付金额: 'Unpaid Amount',
    采购: 'Purchase',
    采购单详情: 'Purchase Order Details',
    采购进度: 'Purchasing Progress',
    付款计划: 'Payment Plan',
    付款明细: 'Payment Details',
    采购数量: 'Purchase Quantity',
    供应商详情: 'Vendor details',
    '确认删除？': 'Confirm Deletion?',
    消息: 'Message',
    暂无评论: 'No Comments Yet',
    暂无信息: 'No Information',
    素材选择: 'Material Selection',
    '文件已存在下载队列中！': 'File already in download queue!',
    新增故事: 'Add New Story',
    请输入内容: 'Please Enter Content',
    只能选择一张: 'Can Only Select One',
    请选择邮件模板: 'Please Select an Email Template',
    '正在生成中...': 'Generating...',
    '该附件格式不支持预览，请下载后查看':
      'This attachment format does not support preview, please download to view',
    设备: 'Device',
    总数: 'Total Number',
    在线: 'Online',
    离线: 'Offline',
    故障: 'Fault',
    在线率: 'Online Rate',
    故障率: 'Fault Rate',
    '确认要离开本页面吗？': 'Are you sure you want to leave this page?',
    // ==================================== 供应商模块 ==============================
    历史报价: 'Historical Quotation',
    '产品名称/编码': 'Product Name/Code',
    '智慧商贸-ERP': 'Smart Trade-ERP',
    '正在加载中...': 'Loading...',
    简体中文: '简体中文',
    繁體中文: '繁體中文',
    已创建: 'Created',
    进行中: 'In Progress',
    已完成: 'Completed',
    折线图: 'Line Chart',
    柱状图: 'Bar Chart',
    周: 'Week',
    已作废: 'Voided',
    已过期: 'Expired',
    编辑中: 'Editing',
    待支付: 'Pending Payment',
    已付定金: 'Deposit Paid',
    已支付: 'Paid',
    已发货: 'Shipped',
    已签收: 'Delivered',
    '报价单-出口': 'Quotation-Export',
    '报价单-内销': 'Quotation-Domestic Sale',
    '报价单-门市': 'Quotation-Retail',
    '报价单-淘宝': 'Quotation-Taobao',
    '报价单-云工厂': 'Quotation-Cloud Factory',
    '报价单-智能工厂': 'Quotation-Smart Factory',
    禁用: 'Disable',
    启用: 'Enable',
    隐藏: 'Hide',
    显示: 'Show',
    未知: 'Unknown',
    抖音: 'TikTok',
    快手: 'Kuaishou',
    待确定: 'To Be Determined',
    有效: 'Valid',
    无效: 'Invalid',
    推广微信公众号: 'Promote WeChat Public Account',
    推广抖音账号: 'Promote TikTok Account',
    推广抖音视频: 'Promote TikTok Video',
    推广快手账号: 'Promote Kuaishou Account',
    推广快手视频: 'Promote Kuaishou Video',
    销售收入: 'Sales Revenue',
    押金收入: 'Deposit Income',
    租赁收入: 'Lease Income',
    购买支出: 'Purchase Expenses',
    押金退还: 'Deposit Refund',
    男: 'Male',
    女: 'Female',
    保密: 'Confidential',
    待处理: 'Pending',
    处理中: 'Processing',
    已处理: 'Processed',
    文本框: 'Text Box',
    多行文本框: 'Multiline Text Box',
    数字文本框: 'Numeric Text Box',
    日期选择框: 'Date Picker',
    状态选择: 'Status Selection',
    单选框: 'Radio Button',
    多选框: 'Checkbox',
    下拉单选择: 'Dropdown Single Select',
    下拉多选择: 'Dropdown Multi-Select',
    单图片: 'Single Image',
    多图片: 'Multiple Images',
    日期选择: 'Date Selection',
    未读: 'Unread',
    已读: 'Read',
    待审核: 'Pending Review',
    私有: 'Private',
    公海: 'Public Pool',
    无: 'None',
    低: 'Low',
    高: 'High',
    离职: 'Resigned',
    在职: 'Employed',
    销售: 'Sales',
    按数量区间设置: 'Set by Quantity Range',
    '按客户/供应商设置': 'Set by Customer/Vendor',
    待接受: 'Awaiting Acceptance',
    已接受: 'Accepted',
    不接受: 'Not Accepted',
    公司: 'Company',
    银行账户: 'Bank Account',
    单位地址: 'Unit Address',
    '千美元)': 'Thousand USD)',
    宽松默认: 'Loose by Default',
    严格模式: 'Strict Mode',
    '支持jpg、jpeg、png格式，单次可最多选择':
      'Support jpg, jpeg, png format, can select up to',
    '张图片，每张不可大于': 'photos, each no larger than',
    如果大于: 'If larger than',
    会自动为您过滤: 'will be automatically filtered for you',
    查看大图: 'View larger image',
    '正在上传中...': 'Uploading...',
    当前上传成功数: 'Current number of successful uploads',
    张: 'photos',
    当前上传失败数: 'Current number of failed uploads',
    开始上传: 'Start uploading',
    '上传完成!': 'Upload complete!',
    共上传: 'Total uploaded',
    张图片: 'photos',
    '上传失败,文件大小为': 'Upload failed, file size is',
    当前限制选择: 'Current selection limit',
    '个文件，本次选择了': 'files, this time selected',
    个文件: 'files',
    密码不能少于: 'Password cannot be less than',
    位: 'digits',
    销售前: 'Before sale',
    更新日志: 'Update log',
    用户管理: 'User management',
    角色管理: 'Role management',
    部门管理: 'Department management',
    菜单管理: 'Menu management',
    系统日志: 'System log',
    组件: 'Component',
    其他: 'Other',
    角色权限: 'Role permissions',
    外链: 'External link',
    常规图标: 'Regular icons',
    小清新图标: 'Fresh and simple icons',
    综合表格: 'Comprehensive table',
    行内编辑表格: 'Inline editing table',
    自定义表格: 'Custom table',
    表单: 'Form',
    综合表单: 'Comprehensive form',
    分步表单: 'Step form',
    按钮: 'Button',
    文字链接: 'Text link',
    输入框: 'Input box',
    计数器: 'Counter',
    选择器: 'Selector',
    开关: 'Switch',
    滑块: 'Slider',
    时间选择器: 'Time picker',
    日期选择器: 'Date picker',
    日期时间选择器: 'Date time picker',
    评分: 'Rating',
    工作流: 'Workflow',
    图表: 'Chart',
    打印: 'Print',
    手机预览: 'Mobile preview',
    时间线: 'Timeline',
    数字自增长: 'Auto-increment number',
    多标签: 'Multiple tabs',
    动态Meta: 'Dynamic Meta',
    动态路径参数: 'Dynamic path parameters',
    列表: 'List',
    多级路由缓存: 'Multi-level route caching',
    多级路由: 'Multi-level routing',
    拖拽: 'Drag and drop',
    卡片拖拽: 'Card drag',
    加载: 'Load',
    视频播放器: 'Video player',
    编辑器: 'Editor',
    富文本编辑器: 'Rich text editor',
    错误日志模拟: 'Error log simulation',
    导出Excel: 'Export Excel',
    导出选中行Excel: 'Export selected rows to Excel',
    导出合并Excel: 'Export merged Excel',
    动画: 'Animation',
    第三方登录: 'Third-party login',
    物料源: 'Material source',
    物料市场: 'Material market',
    清空消息: 'Clear messages',
    关闭其他: 'Close others',
    关闭左侧: 'Close left',
    关闭右侧: 'Close right',
    关闭全部: 'Close all',
    主题配置: 'Theme configuration',
    常用设置: 'Common settings',
    其它设置: 'Other settings',
    分栏布局时生效: 'Effective in column layout',
    标签开启时生效: 'Effective when tabs are enabled',
    '布局配置仅在电脑视窗下生效，手机视窗时将默认锁定为纵向布局':
      'Layout configuration is only effective on computer screens, and will default to vertical layout on mobile screens',
    默认: 'Default',
    海洋之心: 'Heart of the Ocean',
    海洋之心sss: 'Heart of the Ocean sss',
    绿荫草场: 'Green Grass Field',
    碰触纯白: 'Touch of Pure White',
    月上重火: 'Moon over Heavy Fire',
    蓝黑: 'Blue Black',
    蓝白: 'Blue White',
    绿黑: 'Green Black',
    绿白: 'Green White',
    红黑: 'Red Black',
    红白: 'Red White',
    渐变: 'Gradient',
    布局: 'Layout',
    分栏: 'Column',
    纵向: 'Vertical',
    横向: 'Horizontal',
    综合: 'Comprehensive',
    常规: 'Regular',
    浮动: 'Floating',
    菜单背景: 'Menu Background',
    分栏风格: 'Column Style',
    箭头: 'Arrow',
    头部固定: 'Header Fixed',
    固定: 'Fixed',
    不固定: 'Not Fixed',
    标签: 'Tab',
    标签风格: 'Tab Style',
    标签图标: 'Tab Icon',
    卡片: 'Card',
    灵动: 'Agile',
    圆滑: 'Smooth',
    进度条: 'Progress Bar',
    随机换肤: 'Random Skin Change',
    购买源码: 'Purchase Source Code',
    拷贝源码: 'Copy Source Code',
    清理缓存: 'Clear Cache',
    恢复默认: 'Restore Defaults',
    '检测到新版本，正在下载中，请稍后...':
      'New version detected, downloading, please wait...',
    温馨提示: 'Kind Reminder',
    更新版本完成: 'Update Version Completed',
    后刷新项目: 'Refresh Project Afterward',
    我的待办: 'My To-Do List',
    已办事项: 'Completed Items',
    经营分析: 'Business Analysis',
    决策辅助: 'Decision Support',
    采购订单: 'Purchase Orders',
    供应管理: 'Supply Management',
    客户管理: 'Customer Management',
    商机管理: 'Opportunity Management',
    对外报价: 'External Quoting',
    财务: 'Finance',
    仓库管理: 'Warehouse Management',
    收款计划: 'Collection Plan',
    收款明细: 'Collection Details',
    开票详情: 'Invoice Details',
    库存盘点: 'Inventory Stocktaking',
    库存报表: 'Inventory Reports',
    库存余额: 'Inventory Balance',
    收发明细: 'Receiving and Dispatching Details',
    收发汇总: 'Receiving and Dispatching Summary',
    单据列表: 'Document List',
    产品入库: 'Product in storage',
    产品出库: 'Product out storage',
    产品调拨: 'Product allocation',
    产品组装: 'Product assembly',
    产品拆卸: 'Product disassembly',
    绩效: 'Performance',
    我的绩效: 'My Performance',
    团队绩效: 'Team Performance',
    绩效核定: 'Performance Verification',
    绩效规则: 'Performance Rules',
    任务: 'Task',
    任务列表: 'Task List',
    电商: 'E-Mall',
    首页设置: 'Homepage Settings',
    商品: 'Products',
    轮播图: 'Carousel',
    文章管理: 'Article Management',
    设置: 'Settings',
    公司信息: 'Company Information',
    销售配置: 'Sales Configuration',
    产品配置: 'Product Configuration',
    产品素材: 'Product Materials',
    模版管理: 'Template Management',
    扩展字段: 'Extended Fields',
    系统设置: 'System Settings',
    操作日志: 'Operation Log',
    公司列表: 'Company List',
    用户列表: 'User List',
    权限管理: 'Permission Management',
    行业管理: 'Industry Management',
    资源: 'Resources',
    店铺选址: 'Shop Location',
    教程管理: 'Tutorial Management',
    超级管理员: 'Super Administrator',
    '没有菜单权限！': 'No Menu Permissions!',
    '路由格式返回有误！': 'Incorrect Route Format Returned!',
    运行中: 'Running',
    已下线: 'Offline',
    游客: 'Visitor',
    '未开启登录拦截)': 'Login Interception Not Enabled)',
    早上好: 'Good Morning',
    上午好: 'Good Morning',
    中午好: 'Good Noon',
    下午好: 'Good Afternoon',
    晚上好: 'Good Evening',
    欢迎登录: 'Welcome to Log In',
    '登录接口异常，未正确返回': 'Login Interface Exception, Incorrect Return',
    '核心接口异常，请检查返回JSON格式是否正确，是否正确返回':
      'Core Interface Exception, Please Check if the Returned JSON Format is Correct',
    '核心接口异常，请检查返回JSON格式是否正确':
      'Core Interface Exception, Please Check if the Returned JSON Format is Correct',
    复制: 'Copy',
    成功: 'Success',
    失败: 'Failure',
    '重量/毛重': 'WT/G.W.',
    产品证书: 'Certificate',
    推荐价格: 'Suggested price',
    邮编: 'Postal code',
    传真: 'Fax',
    官网: 'Official website',
    企业状态: 'Enterprise Status',
    营收: 'Revenue',
    员工人数: 'Number of Employees',
    公司类型: 'Company Type',
    所属国家: 'Country',
    所属城市: 'City',
    简介: 'Introduction',
    产品服务: 'Product Services',
    请输入: 'Please Enter',
    产品价格策略: 'Pricing Strategy',
    起始价格: 'Starting Price',
    分类logo: 'Category Logo',
    建议尺寸: 'Suggested Dimensions',
    分类banner: 'Category Banner',
    请输入分类描述: 'Please Enter Category Description',
    退款金额为: 'Refund Amount is',
    表示只退货: 'Indicates Only Return of Goods',
    补差金额为: 'Price Difference is',
    表示只换货: 'Indicates Only Exchange of Goods',
    请输入客户编码: 'Please Enter Customer Code',
    请输入联系人: 'Please Enter Contact Person',
    请输入客户Whats: "Please Enter Customer's WhatsApp",
    规则名称: 'Rule Name',
    请输入规则名称: 'Please Enter Rule Name',
    绩效类型: 'Performance Type',
    请选择绩效类型: 'Please Select Performance Type',
    优先级: 'Priority',
    请输入优先级: 'Please Enter Priority',
    请输入编码: 'Please Enter Code',
    请选择分类: 'Please Select Category',
    请输入详细地址: 'Please Enter Detailed Address',
    固定电话: 'Landline Telephone',
    请输入固定电话: 'Please Enter Landline Telephone',
    请输入邮箱: 'Please Enter Email',
    请输入QQ: 'Please Enter QQ',
    网址: 'Website',
    请输入网址: 'Please Enter Website',
    添加到采购计划: 'Add to Purchase Plan',
    请输入数量: 'Please Enter Quantity',
    选择负责人: 'Select Person in Charge',
    服务年限: 'Years of Service',
    '请输入服务年限(报告年限)':
      'Please Enter Service Duration (Report Duration)',
    任务日期: 'Task Date',
    请选择任务日期: 'Please Select Task Date',
    请输入任务金额: 'Please Enter Task Amount',
    请选择关联销售订单: 'Please Select Related Sales Order',
    请输入回款金额: 'Please enter collection amount',
    请选择回款日期: 'Please select received date',
    批量生成回款计划: 'Batch Generate collection plan',
    首次回款日期: 'First received date',
    请选择首次回款日期: 'Please select first received date',
    回款间隔时间: 'Collection interval date',
    请输入回款间隔时间: 'Please enter collection interval date',
    总回款期数: 'Total collection times',
    请输入总回款期数: 'Please Enter total collection times',
    请选择关联回款计划: 'Please select the associated collection plan',
    渠道: 'Channel',
    请选择渠道: 'Please Select a Channel',
    凭证: 'Voucher',
    请选择采购订单: 'Please Select a Purchase Order',
    请选择关联采购订单: 'Please Select the Related Purchase Order',
    付款金额: 'Payment Amount',
    请输入付款金额: 'Please Enter the Payment Amount',
    请选择付款日期: 'Please Select a Payment Date',
    批量生成付款计划: 'Generate Payment Plans in Bulk',
    首次付款日期: 'First Payment Date',
    请选择首次付款日期: 'Please Select the First Payment Date',
    付款间隔时间: 'Payment Interval Time',
    请输入付款间隔时间: 'Please Enter the Payment Interval Time',
    总付款期数: 'Total Number of Payment Periods',
    请输入总付款期数: 'Please Enter the Total Number of Payment Periods',
    请选择关联付款计划: 'Please Select the Related Payment Plan',
    请选择发票抬头: 'Please Select an Invoice Title',
    开票金额: 'Invoice Amount',
    请输入开票金额: 'Please Enter the Invoice Amount',
    含税金额: 'Tax-Included Amount',
    商品名称: 'Product Name',
    请输入商品名称: 'Please Enter the Product Name',
    商品编号: 'Product Code',
    请输入商品编号: 'Please Enter the Product Code',
    规格型号: 'Specs/Model',
    请输入商品规格型号: 'Please Enter the Product Specification/Model',
    商品单位: 'Product Unit',
    请输入商品单位: 'Please Enter the Product Unit',
    发票类型: 'Invoice Type',
    请选择发票类型: 'Please Select an Invoice Type',
    税率: 'Tax Rate',
    请输入税率: 'Please Enter the Tax Rate',
    商品数量: 'Product Quantity',
    请输入商品数量: 'Please Enter the Product Quantity',
    开票日期: 'Invoice Date',
    请选择开票日期: 'Please Select an Invoice Date',
    开票明细: 'Invoice Details',
    员工离职交接: 'Employee Resignation Handover',
    交接人: 'Handover Person',
    请选择交接人: 'Please Select a Handover Person',
    单据时间: 'Document Time',
    请选择单据时间: 'Please Select Document Time',
    箱数: 'Number of Boxes',
    请输入预估箱数: 'Please Enter the Estimated Number of Boxes',
    装箱预估数量: 'Estimated Packing Quantity',
    出库单: 'Outbound Order',
    请选择出库单: 'Please Select an Outbound Order',
    正: 'Positive',
    侧: 'Side',
    唛信息: 'Mark Information',
    目的地: 'Destination',
    请输入收货目的地: 'Please Enter the Destination of the Goods',
    箱号标识: 'Box Number Identification',
    '如M、B': 'e.g., M, B',
    产地: 'Origin',
    请输入产地: 'Please Enter the Origin',
    订单号: 'Order Number',
    将自动填充: 'Will Be Automatically Populated',
    净重: 'Net Weight',
    毛重: 'Gross Weight',
    产品盘点: 'Product Inventory',
    请选择单据日期: 'Please Select the Document Date',
    组装: 'Assembly',
    拆卸: 'Dismantling',
    请输选择: 'Please Choose',
    入库仓库: 'Warehousing Warehouse',
    出库仓库: 'Outbound Warehouse',
    费用: 'Cost',
    存为模版: 'Save as Template',
    拆卸产品: 'Disassemble Product',
    请选择拆卸产品: 'Please Select a Product to Disassemble',
    图片名称: 'Image Name',
    请输入图片: 'Please Enter the Image',
    链接地址: 'Link Address',
    请输入图片链接地址: 'Please Enter the Image Link Address',
    文章: 'Article',
    文章标题: 'Article Title',
    请输入文章标题: 'Please Enter the Article Title',
    文章类型: 'Article Type',
    请选择文章类型: 'Please Select an Article Type',
    文章内容: 'Article Content',
    请输入文章内容: 'Please Enter Article Content',
    提单详情: 'Bill of Lading Details',
    采购商: 'Purchaser',
    产品信息: 'Product Info',
    总金额: 'Total Amount',
    产品描述: 'Product Des',
    货运信息: 'Freight Info',
    原产国: 'Country of Origin',
    目的国: 'Destination Country',
    运输方式: 'Mode of Transport',
    数据来源: 'Data Source',
    请输入姓名: 'Please enter the name',
    请输入部门: 'Please enter the department',
    请输入职务: 'Please enter the position',
    绩效月份: 'Performance month',
    请选择绩效月份: 'Please select the performance month',
    计提人: 'Contributor',
    请选计提人: 'Please select the contributor',
    请选择调出仓库: 'Please select the warehouse to transfer from',
    请选择调入仓库: 'Please select the warehouse to transfer to',
    回款类型: 'Collection type',
    请选择回款类型: 'Please select the collection type',
    退款: 'Refund',
    回款: 'Collection',
    付款类型: 'Payment type',
    请选择付款类型: 'Please select the payment type',
    请选择优先级: 'Please select a priority',
    关键词: 'Keyword',
    请选择上下架状态: 'Please select the shelving status',
    只允许输入数字和字母: 'Only numbers and letters are allowed',
    请输入产品关键词: 'Please enter product keywords',
    请输入HS编码: 'Please enter the HS code',
    请输入供应商名称: "Please enter the vendor's name",
    请输入采购商名称: "Please enter the purchaser's name",
    请选择原产国: 'Please select the country of origin',
    请选择目的国: 'Please select the destination country',
    起始时间: 'Start time',
    请输入关键词搜索: 'Please enter keywords to search',
    请输入公司名称搜索: 'Please enter the company name to search',
    请输入产品名称搜索: 'Please enter the product name to search',
    请选择国家ISO编码: 'Please select the country ISO code',
    请选择公司经营状态: "Please select the company's operational status",
    请选择公司营收范围: "Please select the company's revenue range",
    请选择公司人数: 'Please select the number of company employees',
    一: 'One',
    二: 'Two',
    三: 'Three',
    四: 'Four',
    五: 'Five',
    六: 'Six',
    刚刚: 'Just now',
    分钟前: 'minutes ago',
    小时前: 'hours ago',
    天前: 'days ago',
    时: 'hour',
    分: 'minute',
    是一个函数: 'is a function',
    服务器成功返回请求数据: 'Server successfully returned request data',
    新建或修改数据成功: 'Successfully created or modified data',
    '一个请求已经进入后台排队(异步任务)':
      'A request has entered the background queue (asynchronous task)',
    删除数据成功: 'Data deletion successful',
    发出信息有误: 'Incorrect information sent',
    '用户没有权限(令牌失效、用户名、密码错误、登录过期)':
      'User does not have permission (token invalid, username, password error, login expired)',
    令牌过期: 'Token expired',
    '用户得到授权，但是访问是被禁止的':
      'User is authorized, but access is forbidden',
    访问资源不存在: 'Accessed resource does not exist',
    请求格式不可得: 'Request format is not available',
    '请求资源被永久删除，且不会被看到':
      'Requested resource has been permanently deleted and will not be seen',
    服务器发生错误: 'Server error occurred',
    网关错误: 'Gateway error',
    '服务不可用，服务器暂时过载或维护':
      'Service unavailable, server temporarily overloaded or under maintenance',
    网关超时: 'Gateway timeout',
    '无网络！,请连接网络！！': 'No network! Please connect to the network!!',
    '网络不给力！,请检查网络！！':
      'Network is not strong! Please check the network!!',
    '服务端正在升级中，请稍后再试':
      'The server is being upgraded, please try again later',
    提审并通过: 'Submit for approval and pass',
    转: 'Transfer',
    切换公司主体: 'Switch company entity',
    '确定解绑企业微信?': 'Are you sure you want to unbind WeChat for Work?',
    主体未设置企业微信: 'Entity has not set up WeChat for Work',
    扫描二维码联系工作人员: 'Scan QR code to contact staff',
    正在导出的文件: 'File being exported',
    '版本)': 'Version)',
    '异常捕获(温馨提示：错误必须解决)':
      'Exception capture (Kind reminder: Errors must be resolved)',
    报错路由: 'Error route',
    错误信息: 'Error message',
    错误详情: 'Error details',
    暂不显示: 'Do not display for now',
    百度搜索: 'Baidu search',
    谷歌搜索: 'Google search',
    最近访问: 'Recently visited',
    总览: 'Overview',
    开启全屏失败: 'Failed to enter fullscreen',
    服务将于: 'Service will',
    天后到期: 'expire in days',
    服务永不到期: 'Service never expires',
    站内信: 'Internal message',
    全部已读: 'All read',
    消息状态: 'Message status',
    请选择消息类型: 'Please select message type',
    标记已读: 'Mark as read',
    没有更多了: 'No more',
    清空消息成功: 'Messages cleared successfully',
    自有产品: 'Own products',
    '已在当前产品！请换一个试试！':
      'Already in the current product! Please try another one!',
    电商网站: 'E-commerce website',
    '支持纵向布局、分栏布局、综合布局、常规布局，不支持横向布局、浮动布局':
      'Supports vertical layout, column layout, comprehensive layout, regular layout, does not support horizontal layout, floating layout',
    '请输入密码(密码在购买时获得，跳转后需登录购买时绑定的github账号)':
      'Please enter the password (obtained at the time of purchase, you need to log in to the github account bound at the time of purchase after redirecting)',
    '秘钥不正确！': 'The key is incorrect!',
    错误拦截: 'Error interception',
    '检测到您当前浏览器使用的是IE内核，自':
      'Detected that your current browser is using the IE kernel, from',
    '月起，微软已宣布弃用IE，且不再对IE提供任何更新维护，请':
      'Starting from the month, Microsoft has announced the abandonment of IE and will no longer provide any updates or maintenance for IE, please',
    '基于admin-plus构建': 'Built on admin-plus',
    '抱歉!': 'Sorry!',
    '您没有操作角色...': 'You do not have an operating role...',
    '当前帐号没有操作角色,请联系管理员。':
      'The current account does not have an operating role, please contact the administrator.',
    '当前页面不存在...': 'The current page does not exist...',
    '请检查您输入的网址是否正确，或点击下面的按钮返回首页。':
      'Please check if the URL you entered is correct, or click the button below to return to the homepage.',
    返回首页: 'Return to homepage',
    '重量(KG)': 'Weight (KG)',
    交易次数: 'Number of transactions',
    交易重量: 'Transaction weight',
    交易金额: 'Transaction amount',
    出口: 'Export',
    期间采购共计: 'Total purchases during the period',
    次: 'times',
    采购次数最高: 'Highest number of purchases',
    采购次数最低: 'Lowest number of purchases',
    采购商数量: 'Number of purchasers',
    供应商数量: 'Number of vendors',
    月度趋势: 'Monthly trend',
    已添加为客户: 'Added as customer',
    添加客户: 'Add customer',
    进口详单: 'Import details',
    出口详单: 'Export details',
    贸易总览: 'Trade overview',
    更新数据: 'Update data',
    区域分布: 'Regional distribution',
    编码分布: 'Code distribution',
    货运历史: 'Delivery records',
    进口: 'Import',
    贸易伙伴: 'Trade Partner',
    搜索时间: 'Search Time',
    页码: 'Page Number',
    查看数据: 'View Data',
    无搜索结果: 'No Search Results',
    国家: 'Country',
    经营状态: 'Operating Status',
    营收范围: 'Revenue Range',
    公司人数: 'Company Size',
    搜索历史: 'Search History',
    历史搜索: 'Historical Search',
    公司本地名称: 'Local Company Name',
    城市: 'City',
    已解锁: 'Unlocked',
    未解锁: 'Locked',
    详情: 'Details',
    '您确定要解锁当前客户数据?':
      'Are you sure you want to unlock the current customer data?',
    产品搜索: 'Product Search',
    公司搜索: 'Company Search',
    交易分析: 'Transaction Analysis',
    分析报告: 'Analysis Report',
    回款详情: 'Collection details',
    '您确定要对当前数据进行提审？':
      'Are you sure you want to submit the current data for review?',
    '您确定要对当前数据进行提审并通过？':
      'Are you sure you want to review and pass the current data?',
    回款时间: 'Collection time',
    审核人: 'Reviewer',
    审核时间: 'Review Time',
    回款计划详情: 'Collection plan details',
    '您确定要对当前数据生成发货单？':
      'Are you sure you want to generate a delivery order for the current data?',
    计划回款金额: 'Planned collection amount',
    付款详情: 'Payment Details',
    付款时间: 'Payment Time',
    开票计划详情: 'Invoicing Plan Details',
    开票公司: 'Invoicing Company',
    计划开票金额: 'Planned Invoicing Amount',
    计划开票时间: 'Planned Invoicing Time',
    开票商品名称: 'Invoiced Product Name',
    开票商品编号: 'Invoiced Product Code',
    商品规格型号: 'Product Specifications',
    开票商品单位: 'Invoiced Product Unit',
    开票税率: 'Invoicing Tax Rate',
    开票商品数量: 'Invoiced Product Quantity',
    付款计划详情: 'Payment Plan Details',
    计划付款金额: 'Planned Payment Amount',
    需要数量: 'Required Quantity',
    仓库名称: 'Warehouse Name',
    剩余数量: 'Remaining Quantity',
    分配库存: 'Allocate Inventory',
    关联订单: 'Related Orders',
    开票公司名称: 'Invoicing Company Name',
    一键复制: 'One-click Copy',
    计划: 'Plan',
    计划开票占比: 'Planned Invoicing Proportion',
    开票时间: 'Invoicing Time',
    付款编号: 'Payment Code',
    关联采购单: 'Related Purchase Order',
    计划编号: 'Plan Code',
    期数: 'Periods',
    第: 'Number',
    期: 'Period',
    计划付款占比: 'Planned Payment Proportion',
    采购金额: 'Purchase Amount',
    已付款金额占比: 'Proportion of Amount Paid',
    '当前期数/总期数': 'Current Period/Total Periods',
    '您确定当前数据快速付款？':
      'Are you sure to make a quick payment for the current data?',
    计划回款占比: 'Planned collection ratio',
    '已回款金额/占比': 'Received amount ratio',
    '您确定当前数据快速回款？':
      'Confirm to make a quick collection?',
    回款编号: 'Collection Code',
    客户排行: 'Customer Ranking',
    毛利分析: 'Gross Profit Analysis',
    销量最高: 'Highest Sales Volume',
    毛利最高: 'Highest Gross Profit',
    采购数量最大: 'Largest Purchase Quantity',
    采购利润最高: 'Highest Purchase Profit',
    采购额: 'Purchase Amount',
    销售分析: 'Sales Analysis',
    成交最多的销售: 'Top-selling Salesperson',
    销量最高的产品: 'Best-selling Product',
    毛利最多的销售: 'Salesperson with Highest Gross Profit',
    毛利最高的产品: 'Product with Highest Gross Profit',
    最近爆发的销售: 'Recently Surged Salesperson',
    上月增长最高的产品: 'Product with Highest Growth Last Month',
    最近沉默的销售: 'Recently Quiet Salesperson',
    上月下降最多的产品: 'Product with Largest Decline Last Month',
    销售额: 'Sales Volume',
    产品分析: 'Product Analysis',
    最畅销的产品: 'Best-selling Product',
    最赚钱的产品: 'Most Profitable Product',
    最近爆发产品: 'Recently Surged Product',
    最近沉默产品: 'Recently Quiet Product',
    产品销售量: 'Product Sales Volume',
    商机漏斗: 'Opportunity Funnel',
    漏斗图: 'Funnel Chart',
    销售排行: 'Sales Ranking',
    数据简报: 'Data Briefing',
    环比: 'Sequential Comparison',
    新增客户: 'New Customers',
    新增商机: 'New Opportunities',
    新增订单: 'New Orders',
    累计回款: 'Total received',
    累计退款: 'Total refunds',
    前天: 'Before Yesterday',
    前周: 'Last Week',
    前月: 'Last Month',
    前年: 'Last Year',
    用户趋势: 'User Trend',
    用户数量: 'Number of Users',
    趋势: 'Trend',
    客户跟进: 'Customer Follow-up',
    需跟进的客户: 'Customers Needing Follow-up',
    商机跟进: 'Opportunity Follow-up',
    订单跟进: 'Order Follow-up',
    待办任务: 'Pending Tasks',
    任务名称: 'Task Name',
    计划完成时间: 'Planned Completion Time',
    占位符: 'Placeholder',
    图形验证码: 'Verification',
    账号登录: 'Account Login',
    快捷登录: 'Quick Login',
    扫码登录: 'Scan to Login',
    '企业微信扫码登录失败，请先确认您的企业微信账号确认属于该企业':
      'Enterprise WeChat scan code login failed, please confirm your Enterprise WeChat account belongs to the company',
    验证码不能空: 'Verification code cannot be empty',
    事项信息: 'Matter Information',
    事项标题: 'Matter Title',
    事项类型: 'Matter Type',
    所属目标: 'Affiliated Objective',
    重要程度: 'Importance Level',
    事项参与人员: 'Participants in the Matter',
    请选择参与人员: 'Please Select Participants',
    创建者: 'Creator',
    参与人员: 'Participants',
    事项: 'Matter',
    请输入事项标题: 'Please Enter the Matter Title',
    请输入重要程度: 'Please Enter the Importance Level',
    请选择紧急程度: 'Please Select the Urgency Level',
    目标开始时间: 'Objective Start Time',
    目标结束时间: 'Objective End Time',
    目标时间: 'Objective Time',
    请输入所属目标: 'Please Enter the Affiliated Objective',
    请选择所属目标: 'Please Select the Affiliated Objective',
    数据已存在: 'Data Already Exists',
    还有: 'There are still',
    '项...': 'items...',
    系统创建: 'System Created',
    事件已结束: 'Event Has Ended',
    新建日程: 'Create a New Schedule',
    上一年: 'Previous Year',
    上一月: 'Previous Month',
    今天: 'Today',
    下一月: 'Next Month',
    下一年: 'Next Year',
    日程主题: 'Schedule Topic',
    请输入日程主题: 'Please Enter the Schedule Topic',
    日程周期: 'Schedule Period',
    日程描述: 'Schedule Description',
    请输入日程描述: 'Please Enter the Schedule Description',
    产品英文名: 'Product English Name',
    请输入产品英文名: 'Please Enter the Product English Name',
    产品主图: 'Main Product Image',
    产品轮播图: 'Product Carousel Images',
    请输入产品描述: 'Please Enter the Product Description',
    计量单位: 'Unit of Measurement',
    请输入产品计量单位: "Please Enter the Product's Unit of Measurement",
    产品库存: 'Product Inventory',
    产品销量: 'Product Sales Volume',
    产品成本价: 'Product Cost Price',
    产品排序值: 'Product Sort Value',
    产品重量: 'Product Weight',
    采购建议: 'Purchasing Advice',
    请输入采购建议: 'Please Enter Purchasing Advice',
    于: 'at',
    创建者创建时间: 'Creator Creation Time',
    最近操作时间: 'Recent Operation Time',
    故事: 'Story',
    数据周期: 'Data Cycle',
    '请输入属性名：库存': 'Please Enter the Attribute Name: Inventory',
    '属性名：库存': 'Attribute Name: Inventory',
    请选择数据周期: 'Please Select the Data Cycle',
    数据类型: 'Data Type',
    请选择数据类型: 'Please Select the Data Type',
    定时任务: 'Scheduled Task',
    请选择定时任务: 'Please Select a Scheduled Task',
    目标组名称: 'Objective Group Name',
    '开始时间-结束时间': 'Start Time-End Time',
    目标标题: 'Objective Title',
    指标标题: 'Indicator Title',
    图表类型: 'Chart Type',
    目标组: 'Objective Group',
    目标组标题: 'Objective Group Title',
    请输入目标组标题: 'Please Enter the Objective Group Title',
    目标组开始时间: 'Objective Group Start Time',
    目标组结束时间: 'Objective Group End Time',
    目标组时间: 'Objective Group Time',
    目标: 'Objective',
    请输入目标标题: 'Please Enter the Objective Title',
    所属目标组: 'Affiliated Objective Group',
    请选择所属目标组: 'Please Select the Affiliated Objective Group',
    指标: 'Indicator',
    请输入指标标题: 'Please Enter the Indicator Title',
    数据源: 'Data Source',
    请选择数据源: 'Please Select the Data Source',
    选择出数据源: 'Choose a Data Source',
    手动: 'Manual',
    自动: 'Automatic',
    指标数据: 'Indicator Data',
    重要不紧急: 'Important but Not Urgent',
    重要紧急: 'Important and Urgent',
    不重要不紧急: 'Not Important and Not Urgent',
    不重要紧急: 'Not Important but Urgent',
    所属指标: 'Associated Indicator',
    横坐标: 'Horizontal Axis',
    数值: 'Value',
    显示近一周: 'Display the Past Week',
    显示近一月: 'Display the Past Month',
    显示近一年: 'Display the Past Year',
    显示全部: 'Display All',
    个人: 'Individual',
    团队: 'Team',
    预计提成: 'Expected Commission',
    元: 'Yuan',
    '预计提成：团队比例': 'Expected Commission: Team Ratio',
    '计提人(团队)': 'Commissioner (Team)',
    当期金额: 'Current Amount',
    实际提成: 'Actual Commission',
    订单编号: 'Order Number',
    团队提成: 'Team Commission',
    服务金额: 'Service Amount',
    合计: 'Total',
    条件: 'Condition',
    符合以下条件时按本规则计提:
      'Commission according to this rule when the following conditions are met',
    添加条件: 'Add Condition',
    '元）': 'Yuan)',
    新建规则: 'Create New Rule',
    '规则ID、规则名称': 'Rule ID, Rule Name',
    编辑规则: 'Edit Rule',
    '负责人/提计人': 'Person in Charge/Commissioner',
    产品分类下的扩展属性: 'Extended Attributes under Product Category',
    产品属性: 'Product Attributes',
    设置方式: 'Setting Method',
    销售价格: 'Selling Price',
    采购价格: 'Purchase Price',
    按客户设置: 'Set by Customer',
    价格为: 'Price is',
    价格币种: 'Price Currency',
    标准单价: 'Standard Unit Price',
    按客户类别设置: 'Set by Customer Category',
    默认调价比例: 'Default Price Adjustment Ratio',
    实际调价比例: 'Actual Price Adjustment Ratio',
    起始年份: 'Starting Year',
    截止年份: 'Ending Year',
    补充年份: 'Additional Year',
    已上传: 'Uploaded',
    上传图片: 'Upload Image',
    共: 'Total',
    综述信息: 'Summary Information',
    跟进信息: 'Follow-up Information',
    详细跟进: 'Detailed Follow-up',
    请填写跟进内容: 'Please Fill in the Follow-up Content',
    发布: 'Publish',
    下次跟进: 'Next Follow-up',
    绩效比例: 'Performance Ratio',
    '个人绩效相对团队绩效占比，剩余部分归负责人':
      'The proportion of individual performance relative to team performance, the remaining part goes to the person in charge',
    最新价格: 'Latest Price',
    历史近期: 'Recent History',
    供应: 'Supply',
    已付金额: 'Amount Paid',
    '当前订单付款总和，不扣除退款':
      'Total payment of current order, without deducting refunds',
    '您确定要对当前数据生成入库单？':
      'Are you sure you want to generate a warehousing order for the current data?',
    批量添加: 'Batch Add',
    请输入企业名称: 'Please Enter Company Name',
    '您确定要对当前数据进行作废？':
      'Are you sure you want to void the current data?',
    请选择组装拆卸产品明细:
      'Please select assembly/disassembly product details',
    请选择组装拆卸产品: 'Please select a product to assemble/disassemble',
    箱单详情: 'Packing List Details',
    '您确定要生成发货单？':
      'Are you sure you want to generate a delivery note?',
    装箱数: 'Number of Boxes',
    '未作废数据删除，库存将不会恢复是否删除？':
      'If the data is not voided and deleted, the inventory will not be restored. Do you want to delete it?',
    '您确定要作废当前数据？': 'Are you sure you want to void the current data?',
    箱单数量: 'Number of Packing Lists',
    请输入预估所需箱单数:
      'Please enter the estimated number of packing lists required',
    拆分数量: 'Split Quantity',
    装货箱编号: 'Loading Box Number',
    码: 'Code',
    包装数量: 'Packaging Quantity',
    包装的数量: 'Number of Packages',
    '单个重量(w/pc)': 'Single Weight (w/pc)',
    包装的重量: 'Weight of Packaging',
    '单个体积(v/pc)': 'Single Volume (v/pc)',
    包装的体积: 'Volume of Packaging',
    总重量: 'Total Weight',
    总体积: 'Total Volume',
    所属箱号: 'Associated Box Number',
    盘点详情: 'Inventory Details',
    系统库存: 'System Inventory',
    系统统计当前仓库的数量:
      'System counts the current inventory of the warehouse',
    盘点数量: 'Inventory Count',
    盘亏盘盈: 'Inventory Shortage/Overage',
    添加后请到出入库单列表审核:
      'After adding, please go to the warehousing list for review',
    '剩余数量（冻结库存数量)': 'Remaining Quantity (Frozen Inventory Quantity)',
    操作员: 'Operator',
    出入库单详情: 'Inbound and Outbound Order Details',
    '您确定要对当前数据生成出入库单？':
      'Are you sure you want to generate an inbound and outbound order for the current data?',
    出入库进度: 'Inbound and Outbound Progress',
    备注说明: 'Remarks',
    产品调拨详情: 'Product Transfer Details',
    组合件名称: 'Composite Part Name',
    单位成本: 'Unit Cost',
    组合成本: 'Composite Cost',
    操作时间: 'Operation Time',
    操作人: 'Operator',
    业务类型: 'Business Type',
    入库数量: 'Quantity In Stock',
    出库数量: 'Quantity Out of Stock',
    结存数量: 'Ending Inventory',
    库存低于预警库存: 'Inventory Below Warning Level',
    各仓库数量: 'Quantities in Each Warehouse',
    总库存: 'Total Inventory',
    盘点时间: 'Inventory Time',
    盘点产品量: 'Inventory Product Quantity',
    盘点人: 'Inventory Person',
    中文: 'Chinese',
    父级: 'Parent',
    请选择父级: 'Please Select Parent',
    记录: 'Record',
    租金: 'Rent',
    人流量: 'Foot Traffic',
    是否转让: 'Transferable',
    转让价格: 'Transfer Price',
    流水: 'Cash Flow',
    利润: 'Profit',
    推荐星级: 'Recommended Star Rating',
    星: 'Star',
    联系方式: 'Contact Information',
    商家: 'Merchant',
    是否显示: 'Display or Not',
    请输入店铺名称: 'Please Enter Shop Name',
    选址类型: 'Location Type',
    请选择选址类型: 'Please Select Location Type',
    请输入店铺地址: 'Please Enter Shop Address',
    经度: 'Longitude',
    请输入经度: 'Please Enter Longitude',
    纬度: 'Latitude',
    请输入纬度: 'Please Enter Latitude',
    '租金(月)': 'Monthly Rent',
    请输入租金: 'Please Enter Rent',
    请输入人流量: 'Please Enter Foot Traffic',
    请输入流水: 'Please Enter Cash Flow',
    请输入利润: 'Please Enter Profit',
    请输入推荐星级: 'Please Enter Recommended Star Rating',
    请输入转让价格: 'Please Enter Transfer Price',
    请输入联系方式: 'Please Enter Contact Information',
    竞品情况: 'Competitive Products Situation',
    请输入竞品情况: 'Please Enter Competitive Products Situation',
    请输入教程名称: 'Please Enter Tutorial Name',
    教程类型: 'Tutorial Type',
    请选择教程类型: 'Please Select the Tutorial Type',
    教程: 'Tutorial',
    教程名称: 'Tutorial Name',
    前: 'before',
    小时内: 'within hours',
    分钟内: 'within minutes',
    销售趋势: 'sales trend',
    销售数量: 'sales volume',
    客户最新报价: 'latest customer quote',
    头像: 'avatar',
    昵称: 'nickname',
    真实姓名: 'real name',
    手机号码: 'mobile number',
    最后登录时间: 'last login time',
    电话号码: 'telephone number',
    请选择角色: 'please select a role',
    请选择公司: 'please select a company',
    请选择昵称: 'please select a nickname',
    人员: 'personnel',
    公司简称: 'company abbreviation',
    请输入公司简称: 'please enter company abbreviation',
    统一社会信用代码: 'unified social credit code',
    请输入统一社会信用代码: 'please enter unified social credit code',
    企业法人: 'corporate legal person',
    请输入企业法人: 'please enter corporate legal person',
    注册地址: 'registered address',
    请输入注册地址: 'please enter registered address',
    注册日期: 'registration date',
    请输入注册日期: 'please enter registration date',
    请选择注册日期: 'please select registration date',
    联系电话: 'contact number',
    请输入联系电话: 'please enter contact number',
    联系邮箱: 'contact email',
    请输入联系邮箱: 'please enter contact email',
    站点域名: 'site domain',
    请输入站点域名: 'please enter site domain',
    请输入ICP: 'please enter ICP',
    公司代号: 'company code',
    请输入公司代号: 'please enter company code',
    公司简介: 'company profile',
    请输入公司简介: 'please enter company profile',
    公司LOGO: 'company LOGO',
    站点LOGO: 'site LOGO',
    站点ICO: 'site ICO',
    登录页背景图: 'login page background image',
    部门名称: 'department name',
    手机: 'mobile',
    请输入部门名称: 'please enter department name',
    部门人员: 'department personnel',
    员工: 'employee',
    请选择员工: 'please select an employee',
    职位: 'position',
    '您确定要对当前员工进行禁用？':
      'Are you sure you want to disable the current employee?',
    '您确定要对当前员工进行解除禁用？':
      'Are you sure you want to enable the current employee?',
    '您确定要对当前员工进行离职处理？':
      "Are you sure you want to process the current employee's resignation?",
    员工状态: 'employee status',
    权限字段: 'permission field',
    编辑角色: 'edit role',
    添加角色: 'add role',
    角色名称: 'role name',
    请输入角色名称: 'please enter role name',
    角色描述: 'role description',
    数据权限: 'data permissions',
    模块权限: 'module permissions',
    字段权限: 'field permissions',
    请输入角色名: 'please enter role name',
    组织架构: 'organizational structure',
    员工管理: 'employee management',
    标识: 'identifier',
    成员限制: 'member restriction',
    成员数量: 'number of members',
    到期时间: 'expiration time',
    站点: 'site',
    行业: 'industry',
    请输入标识: 'please enter identifier',
    管理员手机号: "administrator's mobile number",
    请输入管理员手机号: "please enter administrator's mobile number",
    管理员姓名: "administrator's name",
    请输入管理员姓名: "please enter administrator's name",
    公司行业: 'company industry',
    请选择公司行业: 'please select company industry',
    请选择到期时间: 'please select expiration time',
    键: 'key',
    必填: 'required',
    默认值: 'default value',
    占比: 'proportion',
    请输入正确key: 'please enter the correct key',
    字段: 'field',
    只能是英文数字下划线的组合且以英文或者下划线开头:
      'can only be a combination of letters, numbers, and underscores, and must start with a letter or an underscore',
    请输入键: 'please enter key',
    请输入默认值: 'please enter default value',
    输入提示: 'input prompt',
    请输入输入提示: 'please enter input prompt',
    客户类型: 'customer type',
    请输入客户类型名: 'please enter customer type name',
    请输入客户类型: 'please enter customer type',
    客户配置: 'customer configuration',
    行业字段: 'industry field',
    行业名称: 'industry name',
    行业权限: 'industry permissions',
    所属菜单: 'associated menu',
    字段名称: 'field name',
    字段标识: 'field identifier',
    列表宽度: 'list width',
    父级菜单: 'parent menu',
    请选择父级菜单: 'please select parent menu',
    请输入字段名称: 'please enter field name',
    请输入字段标识: 'please enter field identifier',
    请输入列表宽度: 'please enter list width',
    字段排序: 'field order',
    请输入字段排序: 'please enter field order',
    菜单名称: 'menu name',
    菜单: 'menu',
    请输入菜单名称: 'please enter menu name',
    菜单地址: 'menu address',
    请输入菜单地址: 'please enter menu address',
    关联菜单: 'related menu',
    请选择关联菜单: 'please select related menu',
    菜单图标: 'menu icon',
    请输入菜单图标: 'please enter menu icon',
    菜单排序: 'menu order',
    请输入菜单排序: 'please enter menu order',
    操作名称: 'operation name',
    操作代码: 'operation code',
    操作地址: 'operation address',
    表头操作: 'table header operation',
    列表操作: 'list operation',
    选项卡操作: 'tab operation',
    请输入操作名称: 'please enter operation name',
    不输入则与操作名称相同:
      'if not entered, it will be the same as operation name',
    请输入操作代码: 'please enter operation code',
    操作图标: 'operation icon',
    请输入操作图标: 'please enter operation icon',
    请输入操作地址: 'please enter operation address',
    操作排序: 'operation order',
    请输入操作排序: 'please enter operation order',
    显示在表头: 'display in the table header',
    显示在列表: 'display in the list',
    显示在选项卡: 'display on the tab',
    所属企业: 'affiliated enterprise',
    企业: 'enterprise',
    选择企业: 'select enterprise',
    操作管理: 'operation management',
    字段管理: 'field management',
    设备调试: 'equipment debugging',
    门锁状态: 'door lock status',
    物品状态: 'item status',
    门锁编号: 'door lock number',
    开锁: 'unlock',
    检测: 'detect',
    通电: 'power on',
    断电: 'power off',
    门锁操作: 'door lock operation',
    门锁打开: 'door lock opened',
    门锁已开启: 'door lock is open',
    门锁关闭: 'door lock closed',
    门锁已关闭: 'door lock is closed',
    发信昵称: 'sending nickname',
    邮箱服务商: 'email service provider',
    默认发信邮箱: 'default sending email',
    请输入发信昵称: 'please enter sending nickname',
    请选择邮箱服务商: 'please select email service provider',
    密码: 'password',
    语种: 'language',
    请选择语种: 'please select language',
    '内容-头部': 'content - header',
    '内容-尾部': 'content - footer',
    用户名: 'username',
    性别: 'gender',
    个人简介: 'personal profile',
    我的邮箱: 'my email',
    重置密码: 'reset password',
    短信验证码: 'SMS verification code',
    新密码: 'new password',
    供应商分类: 'vendor classification',
    车型配置: 'vehicle model configuration',
    部件配置: 'component configuration',
    行业模板: 'industry template',
    '请选择行业模板(非必填)': 'please select industry template (not required)',
    分类图片: 'category image',
    英文名称: 'English name',
    顶级分类: 'top-level category',
    请输入搜索内容: 'please enter search content',
    父级分类: 'parent category',
    请选择父级分类: 'please select parent category',
    '分类名称（英文）': 'category name (English)',
    '请输入分类名称（英文）': 'please enter category name (English)',
    分类排序: 'category order',
    类型标题: 'type title',
    类型名称: 'type name',
    请输入类型名称: 'please enter type name',
    仓库设置: 'warehouse setting',
    图片数量: 'number of images',
    宽度: 'width',
    高度: 'height',
    素材名称: 'material name',
    分类标题: 'classification title',
    事项分类: 'matter classification',
    故事分类: 'story classification',
    汇率设置: 'exchange rate setting',
    人民币: 'RMB',
    前缀: 'prefix',
    请选择中间规则: 'please select the middle rule',
    请选择后缀规则: 'please select the suffix rule',
    产品编号规则: 'product number rule',
    '前缀如CP，类型代码需要在类型信息里面增加':
      'prefix such as CP, type code needs to be added in the type information',
    空: 'empty',
    类型id: 'type id',
    类型代码: 'type code',
    自增ID: 'auto-increment ID',
    客户编号规则: 'customer number rule',
    前缀如KH: 'prefix such as KH',
    国家ID: 'country ID',
    国家代码: 'country code',
    销售单名称规则: 'sales order name rule',
    前缀如XSDD: 'prefix such as XSDD',
    客户ID: 'customer ID',
    年月日: 'year-month-day',
    采购单名称规则: 'purchase order name rule',
    前缀如CGD: 'prefix such as CGD',
    供应商ID: 'Vendor ID',
    销售报价权限: 'sales quotation permission',
    '严格模式下，报价低于产品最低售价不可提交，轻松模式下可以提交，会给出明显警告':
      'In strict mode, quotations lower than the minimum selling price of the product cannot be submitted, in easy mode, they can be submitted, but will give a clear warning',
    宽松模式: 'relaxed mode',
    采购报价权限: 'purchase quotation permission',
    '严格模式下，采购高于供应商报价不可提交，轻松模式下可以提交，会给出明显警告':
      "In strict mode, purchases higher than the vendor's quotation cannot be submitted, in easy mode, they can be submitted, but will give a clear warning",
    基本配置: 'basic configuration',
    '用户ID/用户名': 'user ID/username',
    路径: 'path',
    请求ID: 'request ID',
    规则: 'rule',
    执行类: 'execution class',
    执行方法: 'execution method',
    是否启用: 'whether to enable',
    任务标题: 'task title',
    请输入任务标题: 'please enter task title',
    任务规则: 'task rule',
    请输入任务规则: 'please enter task rule',
    如: 'such as',
    则代表每: 'then represents every',
    分钟执行一次: 'execute once every minute',
    请输入执行类: 'please enter execution class',
    请输入执行方法: 'please enter execution method',
    任务类型: 'task type',
    请选择任务类型: 'please select task type',
    参数格式: 'parameter format',
    '请输入参数格式(必须为json格式),可不填写':
      'please enter the parameter format (must be in json format), optional',
    数据组类型: 'data group type',
    请选择数据组类型: 'please select data group type',
    属性: 'attribute',
    '请输入属性key(唯一)：name': 'please enter attribute key (unique): name',
    '属性key(唯一)：name': 'attribute key (unique): name',
    属性类型: 'attribute type',
    排序值: 'sort value',
    请填写选项配置值: 'please fill in the option configuration value',
    参数方式例如: 'parameter method such as',
    白色: 'white',
    红色: 'red',
    黑色: 'black',
    添加属性: 'add attribute',
    数据组名称: 'data group name',
    数据组描述: 'data group description',
    请输入关键字: 'please enter keyword',
    扩展属性: 'extended attribute',
    请输入数据组名称: 'please enter data group name',
    请输入数据组描述: 'please enter data group description',
    请上传: 'please upload',
    主体名称: 'main body name',
    域名: 'domain name',
    站点标题: 'site title',
    团队名称: 'team name',
    主体: 'main body',
    所属: 'belongs to',
    请输入所属: 'please enter belonging',
    请输入主体名称: 'please enter main body name',
    请输入税号: 'please enter tax number',
    法人: 'legal person',
    请输入法人: 'please enter legal person',
    请输入站点标题: 'please enter site title',
    主体简介: 'main body introduction',
    请输入主体简介: 'please enter main body introduction',
    主体LOGO: 'main body LOGO',
    站点背景: 'site background',
    数据配置: 'data configuration',
    主体管理: 'main body management',
    账号: 'account',
    请输入账号: 'please enter account',
    周期: 'cycle',
    日志类型: 'log type',
    执行结果: 'execution result',
    登录成功: 'login successful',
    访问时间: 'visit time',
    登录IP: 'login IP',
    下载模版: 'download template',
    枚举变量: 'enumeration variable',
    模版文件: 'template file',
    仅支持Xlsx: 'only supports Xlsx',
    若为Xls文件请先在Excel中另存为Xlsx:
      'if it is an Xls file, please save it as Xlsx in Excel first',
    '添加完成后，请点击字段集编辑完善模版填充配置信息':
      'after adding, please click on the field set to edit and perfect the template filling configuration information',
    变量: 'variable',
    为单一数据占位: 'placeholder for single data',
    为列数据占位: 'placeholder for column data',
    字段集: 'field set',
    '文件名称(点击预览)': 'file name (click to preview)',
    文件大小: 'file size',
    数据填充起始行: 'data fill start line',
    模板选择: 'template selection',
    无模板导出: 'export without template',
    模板导出: 'template export',
    无模板打印: 'print without template',
    模板打印: 'template print',
    请选择模板: 'please select a template',
    '正在导出中...': 'exporting...',
    '正在打印中...': 'printing...',
    单元格: 'cell',
    多行项: 'multi-row item',
    是否求和: 'whether to sum',
    是否图片: 'whether image',
    是否合并: 'whether to merge',
    图片宽度: 'image width',
    图片高度: 'image height',
    关联合并: 'related merge',
    请选择字段: 'please select a field',
    链接: 'link',
    轮播图设置: 'carousel settings',
    首页分类商品设置: 'home category product settings',
    '此内容依赖于：产品》产品分类，您可以勾选你需要展示的分类，勾选后分类将出现在首页f分类商品中。':
      'this content depends on: Product》Product category, you can check the category you need to display, after checking, the category will appear in the homepage f category products.',
    首页分类导航设置: 'home category navigation settings',
    '此内容依赖于：产品》产品分类，您可以勾选你需要展示的分类，勾选后分类将出现在首页导航栏中。':
      'this content depends on: Product》Product category, you can check the category you need to display, after checking, the category will appear in the homepage navigation bar.',
    导航设置: 'navigation settings',
    站点名称: 'site name',
    请输入站点名称: 'please enter site name',
    '对SEO很重要,不要堆砌关键字': 'important for SEO, do not keyword stuffing',
    站点描述: 'site description',
    请输入站点描述: 'please enter site description',
    '站点的描述，用于展示在搜素结果':
      'description of the site, used to display in search results',
    站点关键词: 'site keywords',
    请输入站点关键词: 'please enter site keywords',
    '搜索时会匹配对应的关键词，不要重复':
      'keywords will be matched during search, do not repeat',
    请输入公司地址: 'please enter company address',
    社媒设置: 'social media settings',
    联系我们: 'contact us',
    请输入正确的网址信息: 'please enter correct website information',
    请输入facebook主页地址: 'please enter Facebook homepage address',
    请输入twitter主页地址: 'please enter Twitter homepage address',
    请输入youtube主页地址: 'please enter YouTube homepage address',
    请输入linkedin主页地址: 'please enter LinkedIn homepage address',
    请输入instagram主页地址: 'please enter Instagram homepage address',
    页尾设置: 'footer settings',
    '您确定要作废当前数据?':
      'Are you sure you want to invalidate the current data?',
    实施明细: 'implementation details',
    任务状态: 'task status',
    参与人: 'participants',
    未开始: 'not started',
    任务详情: 'task details',
    任务概况: 'task overview',
    完成时间: 'completion time',
    '您确定要作废此任务？': 'Are you sure you want to invalidate this task?',
    '您确定已完成此任务？': 'Are you sure you have completed this task?',
    请选择团队成员: 'Please select team members',
    请填写比列: 'Please fill in the ratio',
    请填写分成比列: 'Please fill in the profit-sharing ratio',
    进度: 'progress',
    分成比例不能低于: 'Profit-sharing ratio cannot be lower than',
    或高于: 'or higher than',
    钱包金额: 'wallet amount',
    用户角色: 'user role',
    注册时间: 'registration time',
    用户: 'user',
    请输入真实姓名: 'please enter real name',
    用户邮箱: 'user email',
    请输入用户邮箱: 'please enter user email',
    请输入正确的用户邮箱地址: 'please enter a valid user email address',
    性别选择: 'gender selection',
    所属团队: 'belonging to team',
    请选择团队: 'please select a team',
    所属角色: 'belonging to role',
    用户密码: 'user password',
    请输入用户密码: 'please enter user password',
    用户头像: 'user avatar',
    用户昵称: 'user nickname',
    回复: 'reply',
    反馈: 'feedback',
    请输入回复: 'please enter a reply',
    启用状态: 'enable status',
    用户来源: 'user source',
    积分: 'points',
    请输入用户昵称: 'please enter user nickname',
    请选择用户角色: 'please select user role',
    未知功能: 'unknown function',
    成员头像: 'member avatar',
    成员名称: 'member name',
    成员角色: 'member role',
    管理产品: 'manage products',
    请输入团队名称: 'Please enter team name',
    设备区域: 'Device area',
    请选择设备区域: 'Please select device area',
    团队简介: 'Team introduction',
    请输入团队简介: 'Please enter team introduction',
    团队头像: 'Team avatar',
    HS编码: 'HS Code',
    // TODO: 2023-11-21
    "产品组成：产品A由B/C/D组成,组合的产品A在销售和库存环节跟其他产品一样。在箱单处理环节可以自动拆分": "Product Composition: Product A is composed of B/C/D. The combined Product A is treated the same as other products in sales and inventory processes. It can be automatically separated during shipment processing.",
    "M会自动为您过滤": "M will automatically filter for you",
    "S后刷新项目": "Refresh items after S seconds",
    "客户投诉": "Customer Complaints",
    "配置项": "Configuration Items",
    "login核心接口异常": "Login Core Interface Exception",
    "请检查返回JSON格式是否正确，是否正确返回": "Please check if the returned JSON format is correct and returns correctly",
    "getUserInfo核心接口异常": "getUserInfo Core Interface Exception",
    "请检查返回JSON格式是否正确": "Please check if the returned JSON format is correct",
    "不含税": "Tax Excluded",
    "产品质量": "Product Quality",
    "发货速度": "Delivery Speed",
    "售后服务": "After-Sales Service",
    "客诉": "Customer Complaint",
    "客诉标题": "Customer Complaint Title",
    "请输入客诉标题": "Please enter the customer complaint title",
    "客诉类型": "Customer Complaint Type",
    "关联信息": "Associated Information",
    "请选择关联信息": "Please select associated information",
    "客诉原因": "Customer Complaint Reason",
    "请输入客诉原因": "Please enter the customer complaint reason",
    "valFun是一个函数": "valFun is a function",
    "最近销售时间": "Recent Sales Time",
    "EXW价格": "EXW Price",
    "FOB价格": "FOB Price",
    "厂商税率": "Manufacturer Tax Rate",
    "最近采购时间": "Recent Purchase Time",
    "采购次数": "Purchase Frequency",
    "产品供应商分布": "Product Supplier Distribution",
    "OEM码": "OEM Code",
    "附件信息": "Attachment Information",
    "原因": "Reason",
    "URL地址": "URL Address",
    // TODO: 2023-11-22
    "含税": "Tax included",
    "请输入采购税率": "Please enter the purchase tax rate",
    "是否含税": "Is it taxable?",
    "运输费用": "Shipping cost",
    "请选择客诉类型": "Please select the complaint type",
    "客诉信息": "Complaint information",
    "请输入客户WhatsApp": "Please enter customer WhatsApp",
    "仅支持Xlsx,若为Xls文件请先在Excel中另存为Xlsx": "Only Xlsx is supported. If it is an Xls file, please save it as Xlsx in Excel first",
    // TODO: 2023-11-24
    "设为主图": "Set as Main Image",
    "包邮": "Free Shipping",
    "不包邮": "Not Free Shipping",
    "邮费": "Shipping Fee",
    "减少": "Decrease",
    "增加": "Increase",
    "产品图文": "Product Graphics and Text",
    "图文列表": "Graphics and Text List",
    "图文生成": "Graphics and Text Generation",
    "金豆记录": "Gold Bean Record",
    "是否包邮": "Whether Free Shipping",
    "抠图图集": "Clipping Image Collection",
    "金豆变更": "Gold Bean Change",
    "选择产品型号": "Select Product Model",
    "预览图文": "Preview Graphics and Text",
    "生成PDF": "Generate PDF",
    "当前只可选择生成三级分类产品，若需生成完整产品图文，请先完善三级分类": "Currently, only third-level category products can be selected for generation. To generate complete product graphics and text, please first complete the third-level category",
    "生成请求已提交": "Generation Request Submitted",
    "可前往产品图文列表页查看进度": "You can go to the product graphics and text list page to check the progress",
    "返回上页": "Go Back to the Previous Page",
    "开始生成": "Start Generation",
    "重新生成": "Regenerate",
    "前往列表页": "Go to List Page",
    "金豆剩余": "Gold Beans Remaining",
    "消费类型": "Consumption Type",
    "消费金豆": "Consume Gold Beans",
    "消费前": "Before Consumption",
    "消费后": "After Consumption",
    "消费时间": "Consumption Time",
    "请选择消费类型": "Please Select Consumption Type",
    "您确定要对当前员工进行恢复？": "Are you sure you want to restore the current employee?",
    金豆使用规则: 'Golden Bean Usage Rules',
    // TODO: 2024-05-27
    长: "length",
    "厘米": "centimeter",
    "宽": "width",
    "不能为空": "can not be empty",
    "购买数量": "purchase quantity",
    "预览（可根据买家采购的不同数量设置不同价格）": "preview (can set different prices according to the different quantities purchased by the buyer)",
    "可售数量": "available quantity",
    "单品货号": "single item number",
    "是否上架": "whether to put on the shelf",
    "上架店铺": "put on the shelf shop",
    "请选择店铺": "please choose a shop",
    "商品类目": "product category",
    "上一步": "previous step",
    "提交": "submit",
    "下一步": "next step",
    "选择类目": "select category",
    "新增产品": "add new product",
    "修改产品": "modify product",
    "使用运费模版": "use freight template",
    "运费指导模版": "freight guide template",
    "卖家承担运费": "seller bears the freight",
    "库存详情": "inventory details",
    "国内仓": "domestic warehouse",
    "荔枝角": "Lai Chi Kok",
    "深水埗": "Sham Shui Po",
    "产品报价低于最低售价不可提交,请调整!": "The product quotation is lower than the minimum selling price and cannot be submitted, please adjust!",
    "产品报价低于最低售价!": "Product quotes are lower than the lowest selling price!",
    "产品报价高于最低采购价不可提交,请调整!": "The product quotation is higher than the minimum purchase price and cannot be submitted, please adjust!",
    "产品报价高于最低采购价!": "Product quotes are higher than the lowest purchase price!",
    "工单": "work order",
    "工序名称": "process name",
    "加工时长": "processing time",
    "默认仓库": "default warehouse",
    "尺寸/规格": "size / specification",
    "所述仓库": "the warehouse mentioned",
    "绑定": "bind",
    "选择": "select",
    "运费": "freight",
    "已更换产品，需重新打印标签贴标": "The product has been replaced and needs to be reprinted and labeled",
    "规格材质": "specification material",
    "蓝色字体表示建议售价，绿色字体表示历史成交报价": "Blue font indicates recommended selling price, green font indicates historical transaction quotation",
    "建议采购价": "suggested purchase price",
    "智能选择供应商": "smart choice of suppliers",
    "品规": "product specification",
    "体积/净重/长-宽-高": "volume / net weight / length-width-height",
    "盒规": "box rule",
    "您确定要对当前数据进行一键采购？": "Are you sure you want to purchase the current data with one click?",
    "您确定要对当前数据进行一键备货？": "Are you sure you want to stock up the current data with one click?",
    "当前产品明细数据有未关联的产品请关联产品?": "The current product detail data has unassociated products, please associate products?",
    "您当前报价低于最低售价的价格，请修改！": "Your current quote is lower than the lowest selling price, please modify!",
    "箱单信息": "packing list information",
    "混装": "mixed loading",
    "添加箱子": "add box",
    "请选择箱规": "please choose the box rule",
    "请选择箱号": "please choose the box number",
    "支持产品名称/OEM码/编码搜索": "support product name / OEM code / coding search",
    "降序": "descending",
    "升序": "ascending",
    "月结": "monthly settlement",
    "寄付": "donation",
    "到付": "pay on delivery",
    "明天": "tomorrow",
    "后天": "the day after tomorrow",
    "深水涉": "deep water",
    "待入库": "to be warehoused",
    "在仓": "in the warehouse",
    "上车": "get on the car",
    "取消派件": "cancel delivery",
    "发往客户": "sent to the customer",
    "已送达": "has been delivered",
    "元天屯": "Yuan Tiantun",
    "九龍": "Kowloon",
    "新界": "New Territories",
    "港島": "Hong Kong Island",
    "離島/偏遠": "outlying islands / remote",
    "九東": "Kowloon East",
    "港東": "Hong Kong East",
    "客户账单": "customer bill",
    "供应商账单": "supplier bill",
    "运营报表": "operation report",
    "产品面板": "product panel",
    "电商产品": "e-commerce products",
    "报价单表": "quotation sheet",
    "产品图册": "product atlas",
    "产品箱规": "product box rule",
    "私有线索": "private clues",
    "公海线索": "public sea clues",
    "销售面板": "sales panel",
    "抖音订单": "TikTok order",
    "营销": "marketing",
    "我方渠道": "our channel",
    "竞对渠道": "competitive channel",
    "我方店铺": "our shop",
    "竞对店铺": "competitive shop",
    "渠道类型": "channel type",
    "对账列表": "reconciliation list",
    "账单明细": "bill details",
    "发货单(家具)": "delivery note (furniture)",
    "派货行程(家具)": "delivery itinerary (furniture)",
    "今日计划": "today's plan",
    "收货单": "receipt",
    "商品代发": "product consignment",
    "商品退货": "product returns",
    "商品索赔": "product claims",
    "库存余额(家具)": "inventory balance (furniture)",
    "快递寄件": "express delivery",
    "收费标准": "charging standard",
    "工厂": "factory",
    "生产计划": "production plan",
    "生产工单": "production work order",
    "委外管理": "outsourcing management",
    "委外订单": "outsourcing order",
    "整单委外": "whole order outsourcing",
    "工序委外": "process outsourcing",
    "生产执行": "production execution",
    "生产工序": "production process",
    "报工单": "report work order",
    "物料管理": "material management",
    "领料单": "material requisition",
    "退料单": "material return form",
    "质量管理": "quality management",
    "工序质检": "process quality inspection",
    "委外质检": "outsourcing quality inspection",
    "BOM管理": "BOM management",
    "产品BOM": "product BOM",
    "BOM清单": "BOM list",
    "工序工艺": "process technology",
    "工艺": "technology",
    "工序": "process",
    "设备列表": "equipment list",
    "设备类型": "equipment type",
    "三方应用": "third-party application",
    "硬件配置": "hardware configuration",
    "费用记录": "cost record",
    "仓储体积": "storage volume",
    "导出记录": "export record",
    "是否KP": "whether KP",
    "起订量": "minimum order quantity",
    "部件备注": "component remarks",
    "原产地": "place of origin",
    "产品评分": "product rating",
    "供应链评分": "supply chain rating",
    "账期客户": "account period customer",
    "感兴趣产品": "interested products",
    "客户折扣": "customer discount",
    "采购类型": "purchase type",
    "线索名称": "clue name",
    "线索来源": "clue source",
    "线索阶段": "clue stage",
    "线索编码": "clue code",
    "客户地址": "customer address",
    "客户区域": "customer area",
    "产品类别": "product category",
    "订单备注": "order remarks",
    "后台备注": "backend remarks",
    "送货日期": "delivery date",
    "选择日期": "select date",
    "是否账期": "whether it is a credit period",
    "关联订单号": "associated order number",
    "订单支付状态": "order payment status",
    "配送方式": "delivery method",
    "收件电话": "recipient's phone number",
    "收件地址": "recipient's address",
    "快递单号": "express delivery number",
    "收货人信息": "recipient's information",
    "所在州": "state",
    "运输保险": "transport insurance",
    "货品价值": "goods value",
    "签收选项": "signing options",
    "寄件人信息": "sender's information",
    "商品体积": "product volume",
    "商品重量": "product weight",
    "商品货值": "product value",
    "快递费用": "express delivery cost",
    "包裹信息": "parcel information",
    "长(in)": "length (in)",
    "宽(in)": "width (in)",
    "高(in)": "height (in)",
    "重量(lb)": "weight (lb)",
    "计算费用": "calculate cost",
    "快递公司": "express company",
    "快递面签": "express face sign",
    "预约日期": "appointment date",
    "预约时间": "appointment time",
    "退货照片": "return photos",
    "外观照片": "appearance photos",
    "拆开照片": "disassembly photos",
    "处理方式": "processing method",
    "退货进度": "return progress",
    "索赔结果": "claim result",
    "订单截图": "order screenshot",
    "证明图片": "proof picture",
    "适用产品": "applicable products",
    "版本号": "version number",
    "计划单号": "plan number",
    "生产类型": "production type",
    "计划生产数量": "planned production quantity",
    "计划开工日期": "planned start date",
    "计划完工日期": "planned completion date",
    "生产产品": "Manufactured Product",
    "生产批次号": "Production Batch Number",
    "生产单号": "Production Order Number",
    "生产状态": "Production Status",
    "延期情况": "Delay Situation",
    "委外状态": "Outsourcing Status",
    "入库日期": "Entry Date",
    "排产人": "Production Planner",
    "生产完成率": "Production Completion Rate",
    "已完工数量": "Completed Quantity",
    "待入库数量": "Pending Entry Quantity",
    "已入库数量": "Entered Quantity",
    "工序计划数量": "Scheduled Work Process Quantity",
    "工序完工数量": "Completed Work Process Quantity",
    "报废数": "Scrap Quantity",
    "返工数": "Rework Quantity",
    "总生产成本": "Total Production Cost",
    "加工成本": "Processing Cost",
    "物料成本": "Material Cost",
    "委外编号": "Outsourcing Number",
    "委外供应商": "Outsourcing Supplier",
    "委外类型": "Outsourcing Type",
    "委外产品": "Outsourced Product",
    "委外数量": "Outsourcing Quantity",
    "委外单价": "Outsourcing Unit Price",
    "委外日期": "Outsourcing Date",
    "委外总额": "Total Outsourcing Amount",
    "委外应付总额": "Outstanding Outsourcing Amount",
    "委外已付总额": "Paid Outsourcing Amount",
    "已退货数量": "Returned Quantity",
    "质检不合格数量": "Non-conforming Quality Check Quantity",
    "质检合格率": "Quality Inspection Pass Rate",
    "工序编号": "Work Process Number",
    "工序状态": "Work Process Status",
    "执行工序": "Executing Work Process",
    "工艺路线": "Process Route",
    "工序执行人": "Work Process Executor",
    "工序计划生产数": "Scheduled Work Process Production Quantity",
    "实际完工日期": "Actual Completion Date",
    "待完工数量": "Pending Completion Quantity",
    "完工良品数": "Finished Good Quantity",
    "完工良品率": "Completion Good Rate",
    "工序完成率": "Work Process Completion Rate",
    "返工数量": "Reprocessing Quantity",
    "返工率": "Reprocessing Rate",
    "报废数量": "Scrapped Quantity",
    "报废率": "Scrap Rate",
    "报工单编号": "Work Report Number",
    "生产数量": "Production Quantity",
    "生产人员": "Production Staff",
    "报工日期": "Work Reporting Date",
    "质检状态": "Quality Inspection Status",
    "良品数量": "Good Quantity",
    "生产工资": "Production Wages",
    "工序单价": "Work Process Unit Price",
    "问题描述": "Problem Description",
    "问题图片": "Issue Image",
    "物料申请单号": "Material Application Number",
    "审批状态": "Approval Status",
    "申请人": "Applicant",
    "申请时间": "Application Time",
    "委外工单": "Outsourcing Work Order",
    "设备名称": "Equipment Name",
    "设备编码": "Equipment Code",
    "设备型号": "Equipment Model",
    "外观尺寸": "External Dimensions",
    "设备用途": "Equipment Usage",
    "管理负责人": "Management Responsible Person",
    "投产日期": "Start of Production Date",
    "采购日期": "Purchase Date",
    "生产日期": "Production Date",
    "生产厂家": "Manufacturer",
    "用途": "Purpose",
    "设备图集": "Equipment Portfolio",
    "请输入OEM码多个": "Enter Multiple OEM Codes",
    "分割": "Separate",
    "请选择产品类别": "Select Product Category",
    "是否关键产品": "Is Critical Product?",
    "请选择原产地": "Select Country of Origin",
    "请输入部件备注": "Enter Component Notes",
    "箱规信息已移至详情页面添加编辑": "Box Specification Information Has Been Moved to the Detail Page for Addition and Editing",
    "产品规格": "Product Specifications",
    "复制产品": "Copy Product",
    "图片设计": "Image Design",
    "箱规类型": "Box Specification Type",
    "请选择箱规类型": "Select Box Specification Type",
    "请输入产品数量": "Enter Product Quantity",
    "可以容纳的产品个数": "Number of Products It Can Hold",
    "默认箱规": "Default Box Specification",
    "费用箱规": "Cost-related Box Specification",
    "计算货品体积费用时的箱规": "Box Specification for Calculating Volume-based Shipping Costs",
    "长(cm)": "Length (cm)",
    "请输入箱规长": "Enter Box Length",
    "宽(cm)": "Width (cm)",
    "请输入箱规宽": "Enter Box Width",
    "高(cm)": "Height (cm)",
    "请输入箱规高": "Enter Box Height",
    "请输入箱规重量": "Enter Box Weight",
    "请输入箱规体积": "Enter Box Volume",
    "所属分类": "Belongs to Category",
    "请选择所属分类": "Select Belonging Category",
    "用于生成电商网站的链接,可不填写": "For Generating E-commerce Website Links, Optional",
    "图册": "Photo Album",
    "文件请到附件中上传": "Please Upload Files in the Attachment",
    "产品id": "Product ID",
    "渠道名称": "Channel Name",
    "请选择渠道名称": "Select Channel Name",
    "请输入链接地址": "Enter Link Address",
    "请输入销售价格": "Enter Selling Price",
    "售出产品数": "Sold Product Quantity",
    "请输入售出产品数量": "Enter Sold Product Quantity",
    "拉取三方店铺商品": "Pull Third-party Store Products",
    "拉取三方店铺订单": "Pull Third-party Store Orders",
    "三方店铺": "Third-party Store",
    "请选择三方店铺": "Select Third-party Store",
    "拉取": "Pull",
    "当前快递单号为": "Current Courier Number is",
    "下单成功": "Order Successful",
    "保存并打印": "Save and Print",
    "请输入订单号": "Enter Order Number",
    "客户信息": "Customer Information",
    "智能填写": "Smart Fill",
    "粘贴收货信息到此处，将自动识别姓名/电话/地址": "Paste Delivery Information Here, It Will Automatically Recognize Name/Phone/Address",
    "客户电话": "Customer Phone",
    "请输入客户电话": "Enter Customer Phone",
    "已存在客户": "Existing Customer",
    "是否使用当前客户信息？": "Use Current Customer Information?",
    "客户姓名": "Customer Name",
    "请输入客户姓名": "Enter Customer Name",
    "收货地址": "Delivery Address",
    "请输入收货地址": "Enter Delivery Address",
    "销售点": "Sales Point",
    "请选择销售点": "Select Sales Point",
    "付款信息": "Payment Information",
    "是否已付款": "Has Payment Been Made?",
    "您的报价产品中有低于最低售价的价格，请检查！": "Your Quoted Products Have Prices Below the Minimum Selling Price, Please Check!",
    "线索": "Lead",
    "国家地区": "Country/Region",
    "请输入客户感兴趣产品": "Enter Products Customer Is Interested In",
    "是否KA": "Is KA?",
    "请输入客户网址": "Enter Customer Website",
    "请输入客户地区": "Enter Customer Region",
    "请输入客户折扣百分比": "Enter Customer Discount Percentage",
    "请输入比分比": "Enter Score Ratio",
    "请选择线索阶段": "Select Lead Stage",
    "请输入收件人": "Enter Recipient",
    "请输入收件电话": "Enter Recipient Phone",
    "请输入收件地址": "Enter Recipient Address",
    "请选择渠道类型": "Select Channel Type",
    "渠道分类": "Channel Category",
    "请选择渠道分类": "Select Channel Category",
    "快递账号": "Courier Account",
    "请选择快递账号": "Select Courier Account",
    "系统配置": "System Configuration",
    "快递账号配置": "Courier Account Configuration",
    "中添加": "Add in",
    "请输入采购单单号": "Enter Purchase Order Number",
    "账期": "Credit Period",
    "添加付款明细": "Add Payment Details",
    "请输入昵称": "Enter Nickname",
    "密码为手机号后六位": "Password is the last six digits of the phone number",
    "请选择部门": "Select Department",
    "供应商账号": "Supplier Account",
    "关联供应商": "Associate Supplier",
    "入库预报": "Inventory Forecast",
    "请选择配送方式": "Select Delivery Method",
    "交货时间": "Delivery Time",
    "收货人姓名": "Consignee Name",
    "请输入收货人姓名": "Enter Consignee Name",
    "收货人电话": "Consignee Phone",
    "请输入收货人电话": "Enter Consignee Phone",
    "收货人邮箱": "Consignee Email",
    "请输入收货人邮箱": "Enter Consignee Email",
    "收货人地址": "Consignee Address",
    "请输入收货人地址": "Enter Consignee Address",
    "请输入城市": "Enter City",
    "州": "State",
    "请输入州": "Enter State",
    "请选择国家": "Select Country",
    "请输入邮编": "Enter Postal Code",
    "请输入包装要求": "Enter Packaging Requirements",
    "寄件人": "Sender",
    "请选择寄件人信息": "Select Sender Information",
    "请选择签收选项": "Select Receipt Option",
    "请选择运输保险": "Select Shipping Insurance",
    "商品代发单": "Product Forwarding Order",
    "快递索赔单": "Courier Claim Form",
    "商品退货单": "Product Return Form",
    "请输入快递单号": "Enter Courier Number",
    "请选择处理方式": "Select Handling Method",
    "将收取一定的费用": "A certain fee will be charged",
    "请输入备注信息": "Please enter remarks",
    "退货拍照(拆开)": "Return photo (opened package)",
    "退货拍照(外观)": "Return photo (external appearance)",
    "提交后将会通知供货商": "Supplier will be notified upon submission",
    "选择处理方式": "Select handling method",
    "处理费用": "Handling fee",
    "请输入处理费用": "Please enter handling fee",
    "商品快递索赔": "Parcel claim for damaged goods",
    "请输入货品价值": "Please input the value of the item",
    "请输入索赔原因": "Enter claim reason",
    "结果": "Result",
    "请选择结果": "Select result",
    "请输入费用": "Enter cost",
    "三方发货": "Third-party shipping",
    "多个用英文逗号隔开": "Separate with commas",
    "等": "and so on",
    "件产品": "pieces of product",
    "请选择发货计划": "Select shipping plan",
    "收件人信息": "Recipient information",
    "收件人姓名": "Recipient's name",
    "请输入收件人姓名": "Enter recipient's name",
    "收件人手机号": "Recipient's phone number",
    "请输入收件人手机号": "Enter recipient's phone number",
    "收件人地址": "Recipient's address",
    "请输入收件人地址": "Enter recipient's address",
    "省市区县街道": "Province, city, district, street",
    "快捷选择寄件人信息": "Quickly select sender's information",
    "更多快捷寄件人信息请到": "More quick sender information is available at",
    "发货地址": "Shipping address",
    "寄件人姓名": "Sender's name",
    "请输入寄件人姓名": "Enter sender's name",
    "寄件人手机号": "Sender's phone number",
    "请输入寄件人手机号": "Enter sender's phone number",
    "寄件人地址": "Sender's address",
    "请输入寄件人地址": "Enter sender's address",
    "快递信息": "Courier information",
    "请选择快递公司": "Select courier company",
    "物品名称": "Item name",
    "请输入物品名称": "Enter item name",
    "支付方式": "Payment method",
    "请选择支付方式": "Select payment method",
    "物品总重量(Kg)": "Total weight (Kg)",
    "请输入物品总重量": "Enter total weight",
    "无需单位": "No unit needed",
    "寄件": "Send package",
    "下单中...": "Placing order...",
    "立即打印面单": "Print shipping label now",
    "打印机": "Printer",
    "请选择打印机": "Select printer",
    "快递配置": "Courier configuration",
    "请选择快递配置": "Select courier configuration",
    "请选择委外订单": "Select outsourcing order",
    "请输入产品名": "Enter product name",
    "名片": "Business card",
    "是否固定": "Is it fixed?",
    "默认排序": "Default sorting",
    "排序方式": "Sorting method",
    "设置默认排序字段": "Set default sorting field",
    "请输入HSCODE": "Enter HSCODE",
    "别名": "Alias",
    "请输入别名,多个用英文逗号隔开": "Enter aliases, separated by commas",
    "销售渠道": "Sales channel",
    "推广渠道": "Promotion channel",
    "关联店铺": "Associated store",
    "请选择关联店铺": "Select associated store",
    "请输入渠道名称": "Enter channel name",
    "快速调价": "Quick price adjustment",
    "当前修改影响": "Current modification affects",
    "条数据,总价": "Number of items, total price",
    "是否继续修改?": "Continue modifying?",
    "新购产品按建议价格": "New purchases at suggested prices",
    "请输入倍率": "Enter multiplier",
    "倍调价": "Multiply price adjustment",
    "复购产品按历史底价": "Repeat purchases at historical lowest price",
    "所有产品按当前价格": "All products at current prices",
    "打印模版": "Printing template",
    "硬件设备": "Hardware device",
    "购买打印机": "Purchase printer",
    "飞鹅云标签打印机": "Fei'e Cloud Label Printer",
    "佳博云标签打印机": "GaoBo Cloud Label Printer",
    "SN编码": "SN code",
    "请输入SN编码": "Enter SN code",
    "是否默认": "Is it default?",
    "标签纸长(mm)": "Label paper length (mm)",
    "请输入标签纸长度": "Enter label paper length",
    "标签纸宽(mm)": "Label paper width (mm)",
    "请输入标签纸宽度": "Enter label paper width",
    "模本内容": "Template content",
    "请输入模本内容": "Enter template content",
    "缩略图": "Thumbnail",
    "最大打印宽度": "Maximum print width",
    "购买": "Purchase",
    "打印标签": "Print label",
    "请选择设备": "Select device",
    "请选择打印模版": "Select printing template",
    "请输入打印数量": "Enter quantity to print",
    "请输入寄件人": "Enter sender",
    "寄件人电话": "Sender's phone number",
    "请输入寄件人电话": "Enter sender's phone number",
    "寄件人邮箱": "Sender's email",
    "请输入寄件人邮箱": "Enter sender's email",
    "寄件地址": "Sender's address",
    "请输入寄件地址": "Enter sender's address",
    "默认地址": "Default address",
    "费用变更": "Cost change",
    "费用方式": "Cost type",
    "费用类型": "Expense type",
    "请选择费用类型": "Select expense type",
    "请输入金额": "Enter amount",
    "请输入文件名称": "Enter file name",
    "来源链接": "Source link",
    "请输入网页来源链接": "Enter web page source link",
    "更换产品": "Replace product",
    "更换的产品": "Replaced product",
    "名称备注": "Name note",
    "请输入菜单名称备注": "Enter menu name note",
    "是否显示PC": "Show on PC?",
    "是否显示移动端": "Show on mobile?",
    "请输入token": "Enter token",
    "征收比例": "Collection rate",
    "快递费用征收比例": "Courier fee collection rate",
    "请输入比例": "Enter ratio",
    "请选择版本号": "Select version number",
    "用量": "Usage",
    "请输入用量": "Enter usage",
    "损耗率": "Loss rate",
    "请输入损耗率": "Enter loss rate",
    "物料BOM": "Bill of Materials (BOM)",
    "请选择物料BOM": "Select material BOM",
    "请输入单位": "Enter unit",
    "请输入单价": "Enter unit price",
    "时长": "Duration",
    "请输入加工时长": "Enter processing time",
    "是否质检": "Is quality inspection required?",
    "请输入工序描述": "Enter process description",
    "请选择生产类型": "Select production type",
    "请选择订单": "Select order",
    "请选择产品BOM": "Select product BOM",
    "请选择生产优先级": "Select production priority",
    "请输入交货日期": "Enter delivery date",
    "请输入计划开工日期": "Enter planned start date",
    "请输入计划完工日期": "Enter planned completion date",
    "请输入生产批次号": "Enter production batch number",
    "委外工序": "Outsourced process",
    "请选择生产状态": "Select production status",
    "请选择生产计划": "Select production plan",
    "生产订单": "Production order",
    "请选择生产订单": "Select production order",
    "请选择执行工序": "Select process to execute",
    "请选择委外工序": "Select outsourced process",
    "生产需求": "Production requirement",
    "请选择生产需求": "Select production requirement",
    "请选择产品工艺": "Select product process",
    "请输入委外数量": "Enter outsourcing quantity",
    "请输入计划生产数量": "Enter planned production quantity",
    "请输入委外单价": "Enter outsourcing unit price",
    "请选择计划开工日期": "Select planned start date",
    "请选择委外日期": "Select outsourcing date",
    "请选择计划完工日期": "Select planned completion date",
    "请搜索/选择负责人": "Please search/select responsible person",
    "请选择生产工单": "Please select production work order",
    "产品工艺": "Product process",
    "请选择工序执行人": "Please select process executor",
    "请搜索/选择工序执行人": "Please search/select process executor",
    "请输入生产数量": "Please enter production quantity",
    "请选择生产人员": "Please select production staff",
    "请搜索/选择生产人员": "Please search/select production staff",
    "请选择报工日期": "Please select work report date",
    "质检数量": "Quality inspection quantity",
    "请输入质检数量": "Please enter quality inspection quantity",
    "请输入入库数量": "Please enter storage quantity",
    "质检": "Quality inspection",
    "请输入报废数量": "Please enter scrap quantity",
    "请输入返工数量": "Please enter rework quantity",
    "请输入良品数量": "Please enter good product quantity",
    "请输入问题描述": "Please enter problem description",
    "类别": "Category",
    "请选择申请人": "Please select applicant",
    "请选择关联领料单": "Please select associated material requisition",
    "服务商": "Service provider",
    "请选择快递服务商": "Please select express service provider",
    "月结号": "Monthly settlement number",
    "请输入月结号": "Please enter monthly settlement number",
    "添加快递信息": "Add express information",
    "选择香港仓库": "Select Hong Kong warehouse",
    "选择仓库": "Select warehouse",
    "选择发货时间及司机": "Select delivery time and driver",
    "司机": "Driver",
    "请选择司机": "Please select driver",
    "请搜索/选择司机": "Please search/select driver",
    "请选择发货日期": "Please select delivery date",
    "上门时间": "Visit time",
    "截止时间": "Deadline",
    "更改区域": "Change area",
    "区域": "Area",
    "请选择区域": "Please select area",
    "shopify店铺": "Shopify store",
    "店铺名": "Store name",
    "请输入shopify店铺名": "Please enter Shopify store name",
    "请输入店铺名": "Please enter store name",
    "请输入access": "Please enter access",
    "请输入app": "Please enter app",
    "对账明细": "Reconciliation details",
    "请输入日期": "Please enter date",
    "生产设备": "Production equipment",
    "请输入设备名称": "Please enter equipment name",
    "请选择设备类型": "Please select equipment type",
    "请输入设备编码SN": "Please enter equipment code SN",
    "请输入设备型号": "Please enter equipment model",
    "请输入长度": "Please enter length",
    "请输入宽度": "Please enter width",
    "请输入高度": "Please enter height",
    "请选择采购日期": "Please select purchase date",
    "请选择投产日期": "Please select production date",
    "请输入采购价格": "Please enter purchase price",
    "采购币种": "Purchase currency",
    "请输入生产厂家": "Please enter manufacturer",
    "请选择生产日期": "Please select production date",
    "请输入用途": "Please enter purpose",
    "发货": "Shipment",
    "同步发货状态": "Synchronize shipment status",
    "行": "Row",
    "请输入行": "Please enter row",
    "请输入子仓库行数": "Please enter the number of sub-warehouse rows",
    "列": "Column",
    "请输入列": "Please enter column",
    "请输入子仓库列数": "Please enter the number of sub-warehouse columns",
    "子仓库数量不能超过": "Sub-warehouse quantity cannot exceed",
    "个": "pcs",
    "新增仓库": "Add warehouse",
    "请搜索/选择产品": "Please search/select product",
    "请搜索/选择创建人": "Please search/select creator",
    "编辑人": "Editor",
    "请搜索/选择最后编辑人": "Please search/select last editor",
    "请搜索/选择供应商": "Please search/select supplier",
    "请输入价格": "Please enter price",
    "请选择委外状态": "Please select outsourcing status",
    "请选择工序状态": "Please select process status",
    "店铺": "Store",
    "关联状态": "Association status",
    "请选择关联状态": "Please select association status",
    "未关联": "Not associated",
    "已关联": "Associated",
    "面单模板": "Waybill template",
    "请选择电子面单模板": "Please select electronic waybill template",
    "打印类型": "Print type",
    "请选择打印类型": "Please select print type",
    "只下单不打印": "Only order without printing",
    "生成图片短链": "Generate image short link",
    "快递": "Express delivery",
    "云打印机": "Cloud printer",
    "生成html短链": "Generate HTML short link",
    "请搜索/选择客户来源": "Please search/select customer source",
    "请搜索/选择销售点": "Please search/select sales point",
    "货物状态": "Cargo status",
    "请选择货物状态": "Please select cargo status",
    "请搜索/选择客户": "Please search/select customer",
    "请选择账期日期": "Please select account period date",
    "账期类型": "Account period type",
    "请选择账期类型": "Please select account period type",
    "产品编码/名称关键字": "Product code/name keyword",
    "輸入產品/單號/手機": "Enter product/order number/phone",
    "请选择数据权限": "Please select data permission",
    "请搜索/选择渠道名称": "Please search/select channel name",
    "请搜索/选择产品分类": "Please search/select product category",
    "请搜索/选择部件": "Please search/select component",
    "请搜索/选择产品货源": "Please search/select product source",
    "请搜索/选择产品上下架状态": "Please search/select product on/off status",
    "请选择客户类型": "Please select customer type",
    "请搜索/选择客户等级": "Please search/select customer level",
    "请搜索/选择客户行业": "Please search/select customer industry",
    "请输入感兴趣的产品": "Please enter products of interest",
    "线索状态": "Lead status",
    "请选择线索状态": "Please select lead status",
    "名称/单号/关键词": "Name/order number/keyword",
    "请选择计划日期": "Please select planned date",
    "请搜索/选择业务员": "Please search/select salesperson",
    "退货状态": "Return status",
    "请选择退货状态": "Please select return status",
    "请搜索/选择渠道": "Please search/select channel",
    "请搜索/选择渠道类型": "Please search/select channel type",
    "图册名称": "Atlas name",
    "请选择图册名称": "Please select atlas name",
    "请输入编码/选择产品": "Please enter code/select product",
    "请选择在仓状态": "Please select inventory status",
    "服务器正在升级中": "The server is being upgraded",
    "响应超时，请稍后再试": "Response timeout, please try again later",
    "线索详情": "Lead details",
    "导出明细": "Export details",
    "客户产品": "Customer products",
    "线索等级": "Lead level",
    "您确定要将当前线索转化为客户吗？": "Are you sure you want to convert the current lead into a customer?",
    "您确定要将当前线索释放发到公海吗？": "Are you sure you want to release the current lead to the public sea?",
    "您确定要领取当前线索？": "Are you sure you want to claim the current lead?",
    "分配线索": "Assign leads",
    "是否为负责人": "Whether responsible person",
    "天成交单数": "Daily transaction count",
    "天成交金额": "Daily transaction amount",
    "客户评分": "Customer rating",
    "感兴趣的产品": "Products of interest",
    "折扣": "Discount",
    "最后下单时间": "Last order time",
    "生成账单": "Generate bill",
    "设备详情": "Device details",
    "充值记录": "Recharge records",
    "充值成功": "Recharge successful",
    "充值失败": "Recharge failed",
    "充值金额": "Recharge amount",
    "充值状态": "Recharge status",
    "充值账户": "Recharge Account",
    "充值时间": "Recharge Time",
    "物料": "Material",
    "可用库存": "Available Inventory",
    "所属BOM": "Belongs to BOM",
    "BOM详情": "BOM Details",
    "清单明细": "List Details",
    "委外详情": "Outsourcing Details",
    "生成领料单": "Generate Material Requisition",
    "退料单详情": "Return Material Order Details",
    "领料单详情": "Material Requisition Details",
    "物料明细": "Material Details",
    "实际库存": "Actual Inventory",
    "申请数量": "Application Quantity",
    "入库单": "Warehouse Receipt",
    "物料类型": "Material Type",
    "分析结果": "Analysis Result",
    "已生成采购计划": "Purchase Plan Generated",
    "未生成采购计划": "Purchase Plan Not Generated",
    "所需用量": "Required Quantity",
    "净需求": "Net Demand",
    "待采购数量": "Quantity to be Purchased",
    "已采购入库数量": "Purchased and Warehoused Quantity",
    "待申请物料数量": "Material Quantity to be Applied",
    "已领料出库数量": "Material Outbound Quantity",
    "已退料数量": "Returned Material Quantity",
    "待安排生产": "Production to be Arranged",
    "已安排生产": "Production Arranged",
    "待安排委外": "Outsourcing to be Arranged",
    "已生产数量": "Produced Quantity",
    "您确定要对当前数据生成采购计划？": "Are you sure you want to generate a purchase plan for the current data?",
    "生产工单详情": "Production Work Order Details",
    "您确定要对当前数据预生成工序？": "Are you sure you want to pre-generate the process for the current data?",
    "您确定要对当前数据预生成入库单？": "Are you sure you want to pre-generate a warehouse receipt for the current data?",
    "需求物料": "Required Material",
    "生产计划详情": "Production Plan Details",
    "您确定要生成物料需求清单？": "Are you sure you want to generate a material demand list?",
    "生产概览": "Production Overview",
    "生产工序详情": "Production Process Details",
    "报工单详情": "Work Reporting Order Details",
    "工序号": "Process Number",
    "完工数量": "Completion Quantity",
    "请先选择工序执行人": "Please select the process executor first",
    "您确定要开始当前工序？": "Are you sure you want to start the current process?",
    "分配执行人": "Assign Executor",
    "委外生产": "Outsourced Production",
    "质检详情": "Quality Inspection Details",
    "质检明细": "Quality Inspection Details",
    "质检单号": "Quality Inspection Order Number",
    "质检类型": "Quality Inspection Type",
    "质检申请人": "Quality Inspection Applicant",
    "质检人": "Quality Inspector",
    "质检时间": "Quality Inspection Time",
    "到货数量": "Arrival Quantity",
    "工序详情": "Process Details",
    "工艺详情": "Craft Details",
    "工艺明细": "Craft Details",
    "工序单位": "Process Unit",
    "工序负责人": "Process Leader",
    "工序编码": "Process Code",
    "回款渠道": "Payment Channel",
    "对账单详情": "Statement Details",
    "账期日期": "Accounting Period Date",
    "当前欠款": "Current Debt",
    "账单产品明细": "Bill Product Details",
    "总计回款": "Total Payment",
    "当前订单金额": "Current Order Amount",
    "当前已付金额": "Current Paid Amount",
    "您确定要对当前数据重新生成对账单吗？": "Are you sure you want to regenerate the statement for the current data?",
    "客户生成账单": "Customer Generated Bill",
    "供应商生成账单": "Supplier Generated Bill",
    "已付款": "Paid",
    "未付款": "Unpaid",
    "已开票": "Invoiced",
    "未开票": "Uninvoiced",
    "客户数量": "Customer Quantity",
    "客户渠道": "Customer Channel",
    "订单渠道": "Order Channel",
    "产品及供应链统计": "Product and Supply Chain Statistics",
    "编辑产品": "Edit Product",
    "新增供应链": "Add Supply Chain",
    "KP资料不详细": "KP Information is not Detailed",
    "周新增数据": "Weekly New Data",
    "客户数据统计": "Customer Data Statistics",
    "产品数据统计": "Product Data Statistics",
    "更新产品": "Update Product",
    "KP供应链不足": "KP Supply Chain is Insufficient",
    "请输入手机号/邮箱": "Please Enter Mobile Number/Email",
    "关联单号": "Associated Order Number",
    "变更类型": "Change Type",
    "变更金额": "Change Amount",
    "变更前": "Before Change",
    "变更后": "After Change",
    "变更时间": "Change Time",
    "导出模块": "Export Module",
    "导出名称": "Export Name",
    "请选择导出模块": "Please Select Export Module",
    "仓储体积详情": "Warehouse Volume Details",
    "客户数": "Number of Customers",
    "订单数": "Number of Orders",
    "渠道详情": "Channel Details",
    "关联产品": "Associated Product",
    "生成链接": "Generate Link",
    "发布商品": "Publish Product",
    "请输入关键词搜索分类": "Please Enter Keywords to Search Categories",
    "售价": "Selling Price",
    "您确定要对当前产品更新到阿里巴巴？": "Are you sure you want to update the current product to Alibaba?",
    "绑定产品": "Bind Product",
    "照片": "Photo",
    "关键字": "Keyword",
    "网页": "Web Page",
    "页数": "Page Number",
    "EXW币种": "EXW Currency",
    "FOB币种": "FOB Currency",
    "网站": "Website",
    "双击可编辑": "Double Click to Edit",
    "自动上传": "Automatic Upload",
    "部分产品报价高于采购单价，是否确定要审核通过？": "Some product prices are higher than the purchase price, are you sure you want to approve?",
    "请选择供货商": "Please select a supplier",
    "请输入包件编码": "Please enter the package code",
    "请输入包件数量": "Please enter the package quantity",
    "请输入物流单号": "Please enter the logistics order number",
    "厂商": "Manufacturer",
    "产品名": "Product name",
    "规格/材质": "Specifications/Material",
    "备货产品": "Stock products",
    "付款情况": "Payment situation",
    "库存情况": "Inventory situation",
    "您确定要对当前数据付款？操作后状态将会流转": "Are you sure you want to pay for the current data? The status will change after the operation",
    "您确定要对当前数据所有包裹确认收货？": "Are you sure you want to confirm receipt of all packages for the current data?",
    "您确定要对当前数据发往香港仓？": "Are you sure you want to send the current data to the Hong Kong warehouse?",
    "确认所有包裹已上车？": "Confirm all packages are on the vehicle?",
    "包件编码": "Package code",
    "包件数量": "Package quantity",
    "物流单号": "Logistics order number",
    "包裹状态": "Package status",
    "最近下单时间": "Recent order time",
    "包含数量": "Included quantity",
    "请选择地址": "Please select an address",
    "适用范围": "Applicable range",
    "派送费用": "Delivery cost",
    "基础运价": "Basic freight",
    "体积比": "Volume ratio",
    "家庭地址费": "Home address fee",
    "目的地若为家庭地址，则收取该费用": "If the destination is a home address, this fee will be charged",
    "偏远地区费": "Remote area fee",
    "目的地邮编若在Fedex偏远地区清单中，则收取该费用": "If the destination postcode is in the Fedex remote area list, this fee will be charged",
    "特殊处理费": "Special handling fee",
    "产品实重": "Actual weight of the product",
    "磅": "Pound",
    "包装": "Packaging",
    "满足以下任一条件，则收取该费用": "If any of the following conditions are met, this fee will be charged",
    "产品没有完全由外包装箱包装起来": "The product is not completely packaged in an outer box",
    "外包装由缠绕膜外包": "The outer packaging is wrapped in film",
    "未使用纸箱包装": "Did not use cardboard box packaging",
    "包装箱外形不是方形的": "The shape of the packaging box is not square",
    "尺寸": "Size",
    "最长边": "Longest side",
    "英寸": "Inch",
    "次长边": "Second longest side",
    "最短边）": "Shortest side)",
    "英寸且": "Inch and",
    "超标费": "Excess charge",
    "产生超标费后，基础运价按实际重量，体积重和": "After the excess charge is generated, the basic freight is based on the actual weight, volumetric weight and",
    "磅三者中最大者计费": "The largest of the three pounds is charged",
    "用Fedex": "Use Fedex",
    "Ground发货": "Ground delivery",
    "最短边）大于": "Shortest side) greater than",
    "英寸且小于等于": "Inch and less than or equal to",
    "最长边大于": "The longest side is greater than",
    "Delivery发货": "Delivery delivery",
    "未授权发货费": "Unauthorized delivery fee",
    "计价重大于": "Pricing weight is greater than",
    "签收费": "Receipt fee",
    "地址更正费": "Address correction fee",
    "由于地址不正确，Fedex会设法寻找正确的地址以完成送货": "Due to the incorrect address, Fedex will try to find the correct address to complete the delivery",
    "应发货方要求，更改送货地址": "Change the delivery address at the request of the shipper",
    "地址更改服务费": "Address change service fee",
    "应发货方要求，更改地址或者召回货物服务费": "Service fee for changing the address or recalling the goods at the request of the shipper",
    "燃油附加费": "Fuel surcharge",
    "根据官方燃油附加费率，按百分比收取": "Charged according to the official fuel surcharge rate, by percentage",
    "适用于超大或超重件运输": "Applicable to oversized or overweight item transportation",
    "每次发货前，可免费询价": "Free inquiry before each shipment",
    "体积重": "Volumetric weight",
    "高/体积比": "Height/volume ratio",
    "计价重量为体积重和实际重量中数值较大者": "The pricing weight is the larger of the volumetric weight and the actual weight",
    "特殊处理费，超标费及未授权发货费三项，不重复收费，只收取最贵的一项": "Special handling fee, excess charge and unauthorized delivery fee are not charged repeatedly, only the most expensive one is charged",
    "所有尺寸，重量均按英制计算": "All sizes and weights are calculated in imperial units",
    "千克": "Kilogram",
    "所有尺寸，重量均向上取整": "All sizes and weights are rounded up",
    "所有货币单位均为美元": "All currency units are in US dollars",
    "如Fedex价格变动，报价将进行相应变动，我司将另行通知": "If the Fedex price changes, the quotation will change accordingly, and our company will notify separately",
    "此报价不含旺季附加费，旺季附加费按官网价收取": "This quotation does not include peak season surcharges, peak season surcharges are charged at the official website price",
    "旺季附加费收费时间段以Fedex官网收费时间段为准": "The charging period for peak season surcharges is based on the charging period on the Fedex official website",
    "收费重量尺寸以Fedex实际账单为准": "The charging weight and size are based on the actual Fedex bill",
    "计价重量计算方式": "Pricing weight calculation method",
    "见拣货费价格": "See picking fee price",
    "今日计划详情": "Today's plan details",
    "快递详情": "Express details",
    "物流信息": "Logistics information",
    "查看运费": "View freight",
    "快递服务商": "Express service provider",
    "个工作日": "Working days",
    "含": "Including",
    "免费追踪": "Free tracking",
    "选择快递": "Choose express",
    "请完善包裹信息": "Please complete the package information",
    "您确定选择当前快递服务商并支付费用?": "Are you sure you want to choose the current express service provider and pay the fee?",
    "运费预估": "Freight estimate",
    "关联发货计划": "Associated shipping plan",
    "物品体积": "Item volume",
    "物品总重量": "Total weight of items",
    "您确定要对当前数据进行寄件": "Are you sure you want to send the current data",
    "您确定要对当前数据进行取消寄件": "Are you sure you want to cancel the mailing of the current data",
    "选择面单": "Choose face sheet",
    "选择打印机": "Choose printer",
    "费用预估为": "The cost estimate is",
    "费用预估": "Cost estimate",
    "您确定要对当前产品发往香港仓？": "Are you sure you want to send the current product to the Hong Kong warehouse?",
    "发货单详情": "Shipping order details",
    "文本记录": "Text record",
    "规格/尺寸": "Specification/Size",
    "发货仓": "Shipping warehouse",
    "包件备注": "Package remarks",
    "行程安排": "Itinerary arrangement",
    "送货员": "Delivery person",
    "确认取消包裹派送？": "Confirm to cancel the package delivery?",
    "确认包裹客户已接收？": "Confirm the package has been received by the customer?",
    "确认包裹要回仓？": "Confirm the package needs to be returned to the warehouse?",
    "未安排": "Not arranged",
    "库存状况": "Inventory status",
    "采购情况": "Purchasing situation",
    "确认收货": "Confirm receipt",
    "发往香港仓": "Send to Hong Kong warehouse",
    "确认上车": "Confirm boarding",
    "您确定对所选数据进行全部": "Are you sure you want to perform all on the selected data",
    "操作吗？": "operation?",
    "采购中": "In procurement",
    "已售待送": "Sold and waiting for delivery",
    "收货单详情": "Receipt details",
    "您确定要签收入库？": "Are you sure you want to sign for receipt into the warehouse?",
    "供货商": "Supplier",
    "到达时间": "Arrival time",
    "出入库单号": "In and out warehouse order number",
    "仓位": "Warehouse position",
    "总体积(m³)": "Total volume (m³)",
    "添加行": "Add row",
    "添加列": "Add column",
    "拆分为表格": "Split into table",
    "当前共有": "Currently there are",
    "条数据，请输入要拆分的行数": "data entries, please enter the number of rows to split",
    "拆分的行数": "Number of rows to split",
    "请输入要拆分的行数": "Please enter the number of rows to split",
    "商品快递索赔详情": "Product express claim details",
    "请选择商品代发单": "Please select the product consignment order",
    "商品退货详情": "Product return details",
    "商品代发详情": "Product consignment details",
    "您确定要通知仓库发货？": "Are you sure you want to notify the warehouse to ship?",
    "请完善产品仓库信息": "Please complete the product warehouse information",
    "入库预报详情": "Inbound forecast details",
    "您确定要发货？": "Are you sure you want to ship?",
    "索赔原因": "Claim reason",
    "您确定要对当前数据进行退货处理?": "Are you sure you want to process the return for the current data?",
    "发货模版": "Shipping template",
    "智能识别": "Intelligent recognition",
    "您确定要对当前订单进行作废": "Are you sure you want to void the current order?",
    "部分产品报价低于最低售价确定要审核通过？": "Some product prices are lower than the minimum selling price, are you sure you want to approve?",
    "预设报价": "Pre-set price",
    "是否发货": "Whether to ship",
    "您确定要取消当前订单寄件？": "Are you sure you want to cancel the mailing of the current order?",
    "您确定要清除当前订单的关联信息？清除后可以重新添加产品": "Are you sure you want to clear the associated information of the current order? After clearing, you can re-add products",
    "VIP类型": "VIP type",
    "商店域名": "Store domain name",
    "请输入商店域名": "Please enter the store domain name",
    "请选择VIP类型": "Please select VIP type",
    "选项值": "Option value",
    "面单标题": "Waybill title",
    "网点": "Branch",
    "快递员": "Courier",
    "面单编码": "Waybill code",
    "面单模版": "Waybill template",
    "绑定打印机": "Bind printer",
    "秘钥": "Secret key",
    "校验码": "Verification code",
    "打印机类型": "Printer type",
    "在线状态": "Online status",
    "模版图": "Template picture",
    "模版标题": "Template title",
    "打印纸尺寸(mm)": "Printing paper size (mm)",
    "快递面单模版": "Express waybill template",
    "排序字段": "Sorting field",
    "您确定要对当前数据进行同步翻译？": "Are you sure you want to synchronize the translation of the current data?",
    "请输入code编码": "Please enter the code",
    "请输入汇率": "Please enter the exchange rate",
    "销售单号规则": "Sales order number rule",
    "采购单号规则": "Purchase order number rule",
    "使用币种": "Currency used",
    "默认汇率": "Default exchange rate",
    "开启供应商仓储报表": "Open supplier warehousing report",
    "默认币种": "Default currency",
    "费用征收比例": "Fee collection ratio",
    "请输入merchant": "Please enter merchant",
    "秘钥Key": "Secret Key",
    "请输入秘钥Key": "Please enter the secret key",
    "不允许使用特殊符号": "Special symbols are not allowed",
    "公钥Key": "Public Key",
    "请输入公钥Key": "Please enter the public key",
    "字段控制": "Field control",
    "stripe支付配置": "Stripe payment configuration",
    "乐刷支付配置": "LeShua payment configuration",
    "使用次数": "Number of uses",
    "已授权": "Authorized",
    "未授权": "Unauthorized",
    "扫码授权": "QR code authorization",
    "授权状态": "Authorization status",
    "token过期时间": "Token expiration time",
    "昨日销量": "Yesterday's sales",
    "店铺扫码授权": "Store QR code authorization",
    "三方渠道": "Third-party channel",
    "您确定生成全部任务？": "Are you sure to generate all tasks?"
  },
}
