<!--
 * @Author: WGL
 * @Date: 2024-05-13 15:16:37
 * @LastEditors: WGL
 * @LastEditTime: 2024-05-16 09:53:39
 * @Description: 
-->
<template>
  <div class="stash-cascader-panel-item-label stash-cascader-panel-item-grid">
    <el-text class="fx w" :type="textType">
      <slot :index="node.index" :level="level" name="nodeItem" :node="node">
        {{ node[prop.label] }}
      </slot>
    </el-text>
  </div>
  <el-text
    v-show="node?.[prop.children]"
    class="stash-cascader-panel-item-arrow"
    :type="textType"
  >
    <el-icon><ArrowRight /></el-icon>
  </el-text>
</template>

<script setup>
  import { defineProps, inject, computed } from 'vue'
  import { ArrowRight } from '@element-plus/icons-vue'
  const props = defineProps({
    nodesPos: { type: Object, default: () => {} },
    prop: { type: Object, default: () => {} },
    level: { type: Number, default: 1 },
    x: { type: Number, default: 0 },
    y: { type: Number, default: 0 },
    pid: { type: [Number, String], default: 0 },
  })
  const node = computed(() => {
    const { x, y, nodesPos, pid } = props
    const pos = `${y}-${x}`
    return nodesPos[pos] || { x, y, pid }
  })
  const panel = inject('stashCascaderPanel')
  const textType = computed(() => {
    return panel.checkedNodesIds.includes(node.value?.[props.prop.value])
      ? 'primary'
      : ''
  })
</script>
