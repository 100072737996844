<!--
 * @Author: WGL
 * @Date: 2023-06-19 09:06:21
 * @LastEditors: WGL
 * @LastEditTime: 2024-02-18 11:54:20
 * @Description: 
-->
<template>
  <jwt-detail-drawer
    ref="pageDetailRef"
    :config="config"
    :current-page-drawer-config="currentPageDrawerConfig"
    :current-page-path="currentPagePath"
    :fill-url="fillUrl"
    :open-page-drawer-config-list="openPageDrawerConfigList"
    :request="request"
    :translate-title="translateTitle"
    :upload-rich-text-url="uploadRichTextUrl"
    :upload-url="uploadUrl"
    :user-menus-auth="userMenusAuthObj"
    @add-success="handleAddSuccess"
    @back-drawer="handleBackDrawer"
    @change="handleEditRowProp"
    @click-cell="handleClickRow"
    @close-drawer="handleCloseDrawer"
    @des-edit-success="handleDesEditSuccess"
    @drawer-add-success="handleDrawerAddSuccess"
    @edit-success="handleEditSuccess"
    @footer-action="handleFooterAction"
    @header-action="handleHeaderAction"
    @push-drawer="handlePushDrawerList"
    @refresh-table="handleRefreshTable"
  >
    <template
      v-for="k in Object.keys(slots)"
      #[k]="{ scope, model, row }"
      :key="k"
    >
      <slot :model="model" :name="k" :row="row" :scope="scope"></slot>
    </template>
  </jwt-detail-drawer>
</template>

<script>
  import {
    defineComponent,
    onBeforeMount,
    computed,
    useSlots,
    toRaw,
    ref,
  } from 'vue'
  import request from '@/utils/request'
  import { fillUrl } from '@/common/js/utils'
  import { useStore } from 'vuex'
  import { uploadUrl, uploadRichTextUrl } from '@/config/index'
  import { useRouter, useRoute } from 'vue-router'
  import { translateTitle } from '@/utils/i18n'

  export default defineComponent({
    name: 'PageDetailDrawer',
    components: {
      // AppForm,
    },
    props: {
      config: { require: true, type: Object, default: () => {} }, // 页面结构配置
      refComponmentUrl: { type: String, default: '' },
      currentPageDrawerConfig: { type: Object, default: () => {} }, // 当前页面draw的打开配置
    },
    emits: [
      'change',
      'click-cell',
      'header-action',
      'footer-action',
      'des-edit-success',
      'drawer-add-success',
    ],
    setup(props, { emit }) {
      const pageDetailRef = ref(null)
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const slots = useSlots()
      const userMenusAuthObj = computed(() => store.getters['routes/menusObj'])

      // 当前页面路径
      const currentPagePath = computed(() => {
        const { path: currentPath } = route
        let reult = props?.refComponmentUrl || currentPath || ''
        return reult || ''
      })
      // 动态组件的实例
      const currentTabsInstance = computed(() => {
        return pageDetailRef.value.dynamicsTabsInstance || null
      })
      // 打开的弹窗数据list
      const openPageDrawerConfigList = computed(
        () => store.getters['sys/openPageDrawerConfigList']
      )

      const handleEditRowProp = (row, item) => {
        emit('change', row, item, 'drawDes')
      }

      const handleClickRow = (row, item) => {
        emit('click-cell', row, item)
      }

      const handleHeaderAction = (action) => {
        emit('header-action', action)
      }

      const handleFooterAction = (action, model) => {
        emit('footer-action', action, model)
      }

      const handleDesEditSuccess = (item) => {
        emit('des-edit-success', item)
      }

      /**
       * 弹窗添加成功
       * @param {*} data
       */
      const handleDrawerAddSuccess = (data) => {
        emit('drawer-add-success', data)
      }
      /**
       * 关闭弹窗
       */
      const handleCloseDrawer = () => {
        store.dispatch('sys/handleCloseDrawer')
      }

      /**
       * 弹窗返回上一级
       */
      const handleBackDrawer = () => {
        store.dispatch('sys/handleBackDrawer')
      }

      /**
       * 弹窗列表添加一个配置
       * @param {*} data
       */
      const handlePushDrawerList = (data) => {
        store.commit('sys/setOpenPageDrawerConfig', {
          row: toRaw(data.row),
          item: toRaw(data.item),
        })
      }

      /**
       * 刷新列表
       */
      const handleRefreshTable = () => {
        store.commit('sys/setRefreshTable', true)
      }

      /**
       * 刷新详情
       */
      const handleRefreshDrawerDetail = () => {
        pageDetailRef?.value?.handleRefreshDrawerDetail()
      }

      /**
       * 关左侧产品添加弹窗
       */
      const handleCloseAddProductDrawer = () => {
        store.dispatch('sys/handleCloseAddProductDrawer')
      }

      /**
       * 表单添加成功
       */
      const handleAddSuccess = () => {
        handleCloseAddProductDrawer()
      }
      /**
       * 表单编辑成功
       */
      const handleEditSuccess = () => {
        handleCloseAddProductDrawer()
      }

      // 改变tabs
      const handleChangeTab = (name) => {
        pageDetailRef.value?.handleChangeTab(name)
      }

      onBeforeMount(() => {})

      return {
        pageDetailRef,
        request,
        uploadUrl,
        uploadRichTextUrl,
        slots,
        route,
        router,
        userMenusAuthObj,
        currentPagePath,
        currentTabsInstance,
        openPageDrawerConfigList,
        fillUrl,
        handleHeaderAction,
        translateTitle,
        handleEditRowProp,
        handleClickRow,
        handleDesEditSuccess,
        handleCloseDrawer,
        handleBackDrawer,
        handleRefreshTable,
        handlePushDrawerList,
        handleAddSuccess,
        handleEditSuccess,
        handleFooterAction,
        handleRefreshDrawerDetail,
        handleDrawerAddSuccess,
        handleChangeTab,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
