<template>
  <el-dialog
    v-model="visible"
    append-to-body
    center
    destroy-on-close
    :title="t('模板选择')"
    width="500px"
  >
    <div class="system-user-container">
      <base-table :config="tableConfig" table-height="40vh">
        <template #header-button-after="{}">
          <div>
            <el-button
              class="ml15"
              type="primary"
              @click="jump('/setting/templateSetting')"
            >
              {{ t('去添加') }}
            </el-button>
          </div>
        </template>
        <!-- <template #table-before v-if="action === 'print'">
          <div class="mt10">
            <div class="fxmj ptb4">
              <div class="pr5">{{ t('纸张大小') }}:</div>
              <div class="ex">
                <el-select
                  v-model="__paper_size"
                  class="w"
                  filterable
                  placeholder="请选择纸张大小"
                >
                  <el-option
                    v-for="(val, key) in PAPER_SIZE"
                    :key="key"
                    :label="val"
                    :value="+key"
                  />
                </el-select>
              </div>
            </div>
            <div class="fxmj ptb4">
              <div class="pr5">{{ t('纸张方向') }}:</div>
              <div class="ex">
                <el-select
                  v-model="__orientation"
                  class="w"
                  filterable
                  placeholder="请选择纸张大小"
                >
                  <el-option
                    v-for="(val, key) in PAPER_ORIENTATION"
                    :key="key"
                    :label="val"
                    :value="key"
                  />
                </el-select>
              </div>
            </div>
          </div>
        </template> -->
        <template #radio="{ scope }">
          <el-radio v-model="selectRadio" :label="scope.id">{{ '' }}</el-radio>
        </template>
        <template #file="{ scope }">
          <div class="cp cb tdu" @click="showPreview(scope.file_info)">
            {{ scope.title }}
          </div>
        </template>
      </base-table>
    </div>
    <Previewer ref="previewerRef" />
    <template #footer>
      <span>
        <el-button @click="visible = false">{{ t('取消') }}</el-button>
        <el-button
          v-if="action === 'export'"
          type="success"
          @click="handleExport(false)"
        >
          {{ t('无模板导出') }}
        </el-button>
        <el-button
          v-if="action === 'export'"
          type="primary"
          @click="handleExport"
        >
          {{ t('模板导出') }}
        </el-button>
        <el-button
          v-if="action === 'print'"
          type="success"
          @click="handlePrint(false)"
        >
          {{ t('无模板打印') }}
        </el-button>
        <el-button
          v-if="action === 'print'"
          type="primary"
          @click="handlePrint"
        >
          {{ t('模板打印') }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
  import { computed, defineComponent, reactive, ref, toRefs } from 'vue'
  import { ElLoading, ElMessage } from 'element-plus'
  import request from '@/utils/request'
  import { t } from '@/utils/i18n'
  import { merge } from 'lodash-es'
  import { useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import { handleAsynExportFileReq } from '@/utils/business'
  import { PAPER_ORIENTATION, PAPER_SIZE } from '@/config/variable.config'
  const MODULE = 'excel-template' // 模块

  // 表格数据
  const tableColumns = [
    { prop: 'radio', label: '单选', width: 80 },
    {
      label: '名称',
      prop: 'file',
    },
  ]

  export default defineComponent({
    name: 'ExcelTemplateBox',
    components: {},
    props: {
      api: {
        type: String,
        default: '',
      },
      printApi: {
        type: String,
        default: '',
      },
      params: {
        type: Object,
        default: () => {},
      },
    },
    emits: ['export-success'],
    setup(props, { emit }) {
      const router = useRouter()
      const store = useStore()
      const state = reactive({
        __paper_size: 9,
        __orientation: 'portrait',
      })
      const exportParams = computed(() => {
        return props.params
      })
      const selectRadio = ref(null)
      const visible = ref(false)
      const action = ref('export')
      const previewerRef = ref(null)
      let tableConfig = reactive({
        headerActions: {
          list: [''],
        },
        useField: false,
        tableColumn: tableColumns,
        tableListApi: `/${MODULE}/index`,
        tableListParams: {
          sort: 'id',
          order: 'desc',
        },
        tableAttrs: {
          'highlight-current-row': true,
        },
        tableSearch: {
          formAttrs: {
            inline: true,
          },
          formList: [
            {
              type: 'input',
              key: 'search',
              value: '',
              formAttrs: {
                type: 'text',
                clearable: true,
                disabled: false,
                placeholder: '搜索',
              },
            },
            {
              type: 'switch',
              key: '__only_me',
              labelAttrs: {
                label: '只看我的',
              },
            },
            {
              type: 'submit',
              submit: {
                title: '搜索',
                attrs: {
                  type: 'primary',
                },
              },
            },
          ],
        },
        tableDeleteParams: {},
        tableButtonNum: 3,
      })

      const showPreview = (row) => {
        previewerRef.value.show(row.file_path, row.extension, row.title)
      }

      const jump = (path) => {
        store.commit('sys/setOpenDrawer', false)
        router.push({
          path: path,
        })
      }

      const handleExport = (needTemplate = true) => {
        if (!props.api) {
          ElMessage({
            showClose: true,
            message: t('请配置导出接口'),
            type: 'warning',
          })
          return
        }
        if (!selectRadio.value && needTemplate) {
          ElMessage({
            showClose: true,
            message: t('请选择模板'),
            type: 'warning',
          })
          return
        }
        let params = null
        if (needTemplate) {
          params = merge(exportParams.value, {
            __template_id: selectRadio.value,
          })
        } else {
          params = exportParams.value
        }

        handleAsynExportFileReq(props.api, params, '', (data) => {
          emit('export-success', data)
          visible.value = false
        })
      }

      const handlePrint = (needTemplate = true) => {
        if (!props.printApi) {
          ElMessage({
            showClose: true,
            message: t('请配置打印接口'),
            type: 'warning',
          })
          return
        }
        if (!selectRadio.value && needTemplate) {
          ElMessage({
            showClose: true,
            message: t('请选择模板'),
            type: 'warning',
          })
          return
        }
        let params = null
        if (needTemplate) {
          params = merge(exportParams.value, {
            __template_id: selectRadio.value,
          })
        } else {
          params = exportParams.value
        }
        Object.assign(params, {
          __paper_size: state.__paper_size,
          __orientation: state.__orientation,
        })
        const loading = ElLoading.service({
          lock: true,
          fullscreen: true,
          text: t('正在打印中...'),
        })

        request({
          url: props.printApi,
          method: 'get',
          params,
          timeout: 120 * 1000,
        })
          .then((data) => {
            let url = data.http_path
            window.open(
              url,
              'newWindow',
              'width=1024, height=1000, top=0, right=0, titlebar=no, menubar=no, scrollbars=yes, resizable=yes, status=yes, , toolbar=no, location=yes'
            )
            loading.close()
            visible.value = false
            emit('export-success', data)
          })
          .catch((data) => {
            console.log('catch', data)
            loading.close()
          })
      }
      return {
        ...toRefs(state),
        visible,
        action,
        tableConfig,
        previewerRef,
        PAPER_ORIENTATION,
        PAPER_SIZE,
        showPreview,
        t,
        selectRadio,
        handleExport,
        handlePrint,
        jump,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
