<!--
 * @Author: WGL
 * @Date: 2023-06-21 09:23:33
 * @LastEditors: WGL
 * @LastEditTime: 2024-08-06 11:20:44
 * @Description: 
-->
<template>
  {{ stockText }}
</template>

<script>
  // 基础产品库存数量组件
  import { computed, defineComponent } from 'vue'

  export default defineComponent({
    name: 'BaseStock',
    components: {},
    props: {
      info: { type: Object, default: () => {} },
    },
    setup(props) {
      const stockText = computed(() => {
        const stockNum = +props?.info?.stock || 0
        const freezeNum = props?.info?.dynamic_info?.freeze_num || 0
        const unitTetx = props?.info?.unit || '-'
        return `${stockNum}-${freezeNum}=${stockNum - freezeNum} (${unitTetx})`
      })
      return {
        stockText,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
