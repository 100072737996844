/*
 * @Author: WGL
 * @Date: 2023-12-18 16:33:20
 * @LastEditors: WGL
 * @LastEditTime: 2024-04-23 16:19:16
 * @Description: 
 */
import { getRequest, postRequest } from './api'

const module = 'express-task'
export function getExpressDeliveryList(params = {}) {
  const url = `${module}/index`
  return getRequest(url, params)
}

export function addExpressDelivery(params = {}) {
  const url = `${module}/add`
  return postRequest(url, params)
}

export function editExpressDelivery(params = {}) {
  const url = `${module}/edit`
  return postRequest(url, params)
}

export function getExpressDeliveryDetail(params = {}) {
  const url = `${module}/detail`
  return getRequest(url, params)
}

export function deleteExpressDelivery(params = {}) {
  const url = `${module}/delete`
  return postRequest(url, params)
}


/**
 * 获取快递公司列表
 * @param {*} params 
 * @returns 
 */
export function getSupportExpress(params = {}) {
  const url = `${module}/enumExpress`
  return getRequest(url, params)
}

/**
 * 获取快递预估价格
 * @param {*} params 
 * @returns 
 */
export function getExpressPrice(params = {}) {
  const url = `${module}/csentPrice`
  return getRequest(url, params)
}


/**
 * 获取快递信息
 * @param {*} params 
 * @returns 
 */
export function getExpressSynquery(params = {}) {
  const url = `${module}/synquery`
  return getRequest(url, params)
}
/**
 * 获取各个快递费用
 * @param {*} params 
 * @returns 
 */
export function getImputedFreight(params = {}) {
  const url = `${module}/imputedFreight`
  return postRequest(url, params)
}
/**
 * 支付快递费用
 * @param {*} params 
 * @returns 
 */
export function payExpress(params = {}) {
  const url = `${module}/purchase`
  return postRequest(url, params)
}
export function getParseAddress(address, model) {
  const url = `${module}/parseAddress`
  postRequest(url, { address }).then((res) => {
    model.customer_telephone = res.mobile
    model.customer_title = res.name
    model.customer_address = `${res.province_name} ${res.city_name} ${res.county_name}${res.town_name}${res.detail}`
    model.customer_parse_address = res
  })
}