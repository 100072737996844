<!--
 * @Author: WGL
 * @Date: 2023-06-21 09:23:33
 * @LastEditors: WGL
 * @LastEditTime: 2023-12-27 16:51:58
 * @Description: 
-->
<template>
  <div v-if="info?.length" class="w ex">
    <el-popover
      placement="right"
      trigger="hover"
      :width="600"
      @show="handleShowPopover"
    >
      <template #reference>
        <div>
          <div class="lh">
            <span>{{ t('总计') }}:</span>
            <span class="cd b ml4">{{ num }}</span>
          </div>
          <template v-for="(item, index) in info" :key="index">
            <div v-if="index < 3" class="cp w fx lh">
              <div class="ex omit">{{ item.title }}:</div>
              <span class="cd b ml4">{{ item.count }}</span>
            </div>
          </template>
        </div>
      </template>
      <el-table
        v-if="showContent"
        border
        :data="tableData"
        max-height="500"
        :span-method="objectSpanMethod"
      >
        <el-table-column :label="t('类型')" prop="typeTitle" width="100" />
        <el-table-column :label="t('数量')" prop="count" width="80" />
        <el-table-column :label="t('渠道')" prop="title" width="120" />
        <el-table-column :label="t('链接')" min-width="240" prop="url">
          <template #default="{ row }">
            <a v-if="row.url" class="ex omit" :href="row.url" target="_blank">
              {{ row.url }}
            </a>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>
    </el-popover>
    <!-- </template> -->
  </div>
  <span v-else>-</span>
</template>

<script>
  // 基础产品渠道组件
  import { defineComponent, reactive, toRefs, computed } from 'vue'
  import { t } from '@/utils/i18n'

  export default defineComponent({
    name: 'BaseProductChannel',
    components: {},
    props: {
      info: { type: Array, default: () => [] },
      num: { type: [String, Number], default: 0 },
    },
    setup(props) {
      const state = reactive({
        showContent: false,
      })
      const tableData = computed(() => {
        let result = []
        props.info?.map((item) => {
          if (item.children) {
            item.children.map((v) => {
              result.push({
                id: item.id,
                typeTitle: item.title,
                title: v.title,
                count: item.count,
                url: v.url,
              })
            })
          }
        })
        return result
      })
      // console.log('tableData', tableData)
      const objectSpanMethod = ({ row, rowIndex, columnIndex }) => {
        if (row.count === 1) {
          return { rowspan: 1, colspan: 1 }
        }
        if (columnIndex === 0 || columnIndex === 1) {
          // 获取当前单元格的值
          const currentValue = row.id
          // 获取上一行相同列的值
          const preRow = tableData.value[rowIndex - 1]
          const preValue = preRow ? preRow.id : null
          // 如果当前值和上一行的值相同，则将当前单元格隐藏
          if (currentValue == preValue) {
            return { rowspan: 0, colspan: 0 }
          } else {
            // 否则计算当前单元格应该跨越多少行
            let rowspan = row.count
            console.log('rowspan', rowspan)
            return { rowspan, colspan: 1 }
          }
        }
      }

      const handleShowPopover = () => {
        if (state.showContent) return
        state.showContent = true
      }

      return {
        ...toRefs(state),
        tableData,
        t,
        objectSpanMethod,
        handleShowPopover,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
