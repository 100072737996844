import { getRequest, postRequest } from './api'

const module = 'supplier'
const module_supplier = 'supplier-type'
export function getSupplierList(params = {}) {
  const url = `${module}/index`
  return getRequest(url, params)
}

export function addSupplier(params = {}) {
  const url = `${module}/add`
  return postRequest(url, params)
}

export function editSupplier(params = {}) {
  const url = `${module}/edit`
  return postRequest(url, params)
}

export function getSupplierDetail(params = {}) {
  const url = `${module}/detail`
  return getRequest(url, params)
}

export function deleteSupplier(params = {}) {
  const url = `${module}/delete`
  return postRequest(url, params)
}

export function checkSuppilerInfo(params = {}) {
  const url = `${module}/check`
  return getRequest(url, params)
}
// 用户分类
export async function supplierCategoryList(params = {}) {
  const url = `${module_supplier}/index`
  return getRequest(url, params)
}
