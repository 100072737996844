import request from '@/utils/request'
import { encryptedData } from '@/utils/encrypt'
import { loginRSA } from '@/config'
import { getRequest } from './api'

export async function getUserApps(params = { page: 1, per_page: 100 }) {
  const url = 'user/myapps'
  return getRequest(url, params)
}

export async function login(data) {
  if (loginRSA) {
    data = await encryptedData(data)
  }
  return request({
    url: '/admin/login',
    method: 'post',
    data,
  })
}

export async function socialLogin(data) {
  if (loginRSA) {
    data = await encryptedData(data)
  }
  return request({
    url: '/socialLogin',
    method: 'post',
    data,
  })
}

export function getUserInfo() {
  return request({
    url: '/admin/profile',
    method: 'get',
  })
}

export function logout() {
  return request({
    url: '/admin/logout',
    method: 'post',
  })
}

// 快捷登录
export function quickLogin(data) {
  return request({
    url: '/admin/quickLogin',
    method: 'post',
    data,
  })
}

// 注册
export function register(data) {
  return request({
    url: '/admin/register',
    method: 'post',
    data,
  })
}

// 手机验证码
export function getCodePhone(data) {
  const { host: domain } = window.location
  return request({
    url: '/sms/send',
    method: 'post',
    data: { ...data, domain },
  })
}

// 切换团队
export function switchTeam(data) {
  return request({
    url: '/admin/switchTeam',
    method: 'post',
    data,
  })
}

// 获取用户拥有的企业
export function getUserPrincipals(params) {
  return request({
    url: '/admin/principals',
    method: 'get',
    params,
  })
}

// 切换公司主体
export function switchCompany(data) {
  return request({
    url: '/admin/switchPrincipal',
    method: 'post',
    data,
  })
}


// 获取用户列表字段
export async function getUserFieldData(data) {
  return request({
    url: '/admin/fields',
    method: 'get',
    data,
  })
}


// 获取公司信息
export async function getCompanyInfo(data = {}) {
  return request({
    url: '/company/detail',
    method: 'get',
    data,
  })
}