/*
 * @Author: WGL
 * @Date: 2022-02-15 18:40:13
 * @LastEditors: WGL
 * @LastEditTime: 2023-05-22 17:51:04
 * @Description: 
 */
export function setupComponents(app) {
  // 加载组件
  const Components = require.context('.', true, /\.vue$/)
  Components.keys()
    .map(Components)
    .forEach((item) => {
      // console.log(`item`, item)
      if (item.default.name) app.component(item.default.name, item.default)
    })
}
