import { getRequest } from './api'
// 地点管理
const module = 'place'
export function getPlaceList(params = {}) {
  const url = `${module}/list`
  return getRequest(url, params)
}

export function getAllPlaceList(params = {}) {
  const url = `${module}/index`
  return new Promise((resolve, reject) => {
    getRequest(url, { page: 1, per_page: 1000, ...params })
      .then((res) => {
        const result =
          res.data.map((item) => {
            item.label = item.title
            item.value = item.id
            return item
          }) || []
        resolve(result)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
