<template>
  <div v-if="dialogVisible" class="viewItemFile">
    <!-- t('{{ t('预览文件') }}') -->
    <el-dialog
      v-model="dialogVisible"
      class="viewItemFileDialog"
      destroy-on-close
      :fullscreen="fullscreen"
      :width="width"
    >
      <template #header="{ titleId, titleClass }">
        <div class="my-header">
          <h4 :id="titleId" :class="titleClass">{{ title }}</h4>
          <el-button type="primary" @click="down">{{ t('下载') }}</el-button>
        </div>
      </template>
      <div
        v-if="type == 'jpeg' || type == 'png' || type == 'jpg' || type == 'gif'"
        class="image"
      >
        <div>
          <el-image
            fit="contain"
            :preview-src-list="[imgUrl]"
            :src="imgUrl"
            style="display: block; max-width: 100%; margin: 0 auto"
          />
        </div>
      </div>
      <div v-if="type == 'docx'" class="docWrap">
        <!-- 预览文件的地方（用于渲染） -->
        <div ref="docxFile"></div>
      </div>
      <!-- <div v-if="type == 'xlsx' || type == 'xls' || type == 'csv'">
        <div class="tab">
          <el-radio-group
            v-model="excel.sheetNameActive"
            size="small"
            @change="getSheetNameTable"
          >
            <el-radio-button
              v-for="(item, index) in excel.sheetNames"
              :key="index"
              :label="item"
            />
          </el-radio-group>
        </div>
        <div style="margin-top: 5px; border: 1px solid #a0a0a0; overflow: auto">
          <div style="padding: 10px 15px" v-html="excel.SheetActiveTable"></div>
        </div>
      </div> -->
      <div v-if="type == 'xlsx' || type == 'xls' || type == 'csv'">
        <iframe
          height="1000px"
          :src="'https://view.officeapps.live.com/op/view.aspx?src=' + fileUrl"
          width="100%"
        />
      </div>
      <div v-if="type == 'pdf'">
        <iframe
          height="1000px"
          :src="pdfurl"
          type="application/x-google-chrome-pdf"
          width="100%"
        />
      </div>
      <div
        v-if="
          type != 'pdf' &&
          type != 'xlsx' &&
          type != 'xls' &&
          type != 'csv' &&
          type != 'docx' &&
          type != 'jpeg' &&
          type != 'png' &&
          type != 'jpg' &&
          type != 'gif'
        "
      >
        {{ t('该附件格式不支持预览，请下载后查看') }}
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    ref,
    getCurrentInstance,
    reactive,
    toRefs,
    defineComponent,
  } from 'vue'
  import * as docx from 'docx-preview'
  import axios from 'axios'
  import { fillUrl } from '@/common/js/utils'
  import { t } from '@/utils/i18n'
  export default defineComponent({
    name: 'Previewer',
    setup() {
      const { proxy } = getCurrentInstance()
      const loading = ref(false)
      const data = reactive({
        excel: {
          // 数据
          workbook: {},
          // 表名称集合
          sheetNames: [],
          // 激活项
          sheetNameActive: '',
          // 当前激活表格
          SheetActiveTable: '',
        },
        imgUrl: '',
        pdfurl: '',
        fileUrl: '',
        dialogVisible: false,
        type: '',
        title: '',
        width: '65%',
        fullscreen: false,
      })

      const show = (url, type, title) => {
        data.fileUrl = fillUrl(url)
        data.type = type
        data.title = title
        data.dialogVisible = true
        init(data.type)
      }
      const down = () => {
        window.open(data.fileUrl)
        // download(data.fileUrl, data.title, 'text/plain')
      }
      function init(type) {
        data.width = '65%'
        data.fullscreen = false
        if (type == 'jpg' || type == 'jpeg' || type == 'png' || type == 'gif') {
          data.imgUrl = data.fileUrl
          data.width = '45%'
        } else if (type == 'pdf') {
          data.fullscreen = true
          data.pdfurl = data.fileUrl
        } else if (type == 'xlsx' || type == 'xls' || type == 'csv') {
          data.fullscreen = true
        } else if (type == 'docx') {
          data.fullscreen = true
          axios
            .request({
              method: 'GET',
              url: data.fileUrl,
              responseType: 'blob',
            })
            .then((res) => {
              if (res) {
                if (res.data.size > 0)
                  docx.renderAsync(res.data, proxy.$refs.docxFile)
              } else {
                loading.value = false
              }
            })
        } else if (
          type != 'pdf' &&
          type != 'xlsx' &&
          type != 'xls' &&
          type != 'csv' &&
          type != 'docx' &&
          type != 'jpeg' &&
          type != 'png' &&
          type != 'jpg' &&
          type != 'gif'
        ) {
          data.width = '35%'
        }
      }

      return {
        ...toRefs(data),
        down,
        show,
        t,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .my-header {
    width: 95%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .viewItemFile {
    .image {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        height: 600px;
        width: 600px;
      }
    }
    .divContent {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    v-deep .el-dialog {
      margin: 0 !important;
      height: 100vh !important;
      .el-dialog__footer {
        margin-bottom: 30px;
        padding: 0px;
      }
    }
    v-deep .el-dialog__body {
      height: 96%;
      width: 100%;
      padding: 0;
      overflow: auto;
    }
  }
  .viewItemFile {
    .file-pre {
      height: calc(100vh - 40px);
      padding: 20px;

      .table-html-wrap v-deep table {
        border-right: 1px solid #e8eaec;
        border-bottom: 1px solid #e8eaec;
        border-collapse: collapse;
        margin: auto;
      }

      .table-html-wrap v-deep table td {
        border-left: 1px solid #e8eaec;
        border-top: 1px solid #e8eaec;
        white-space: wrap;
        text-align: left;
        min-width: 100px;
        padding: 4px;
      }

      table {
        border-top: 1px solid #ebeef5;
        border-left: 1px solid #ebeef5;
        width: 100%;
        overflow: auto;

        tr {
          height: 44px;
        }

        td {
          min-width: 200px;
          max-width: 400px;
          padding: 4px 8px;
          border-right: 1px solid #ebeef5;
          border-bottom: 1px solid #ebeef5;
        }
      }

      .el-tabs--border-card {
        // height: calc(100vh - 40px);;
      }

      .el-tabs--border-card > .el-tabs__content {
        overflow: auto;
        height: calc(100vh - 110px);
      }
    }
    v-deep table {
      width: 100% !important;
      border-collapse: collapse !important;
      border-spacing: 0 !important;
      text-align: center !important;
      border: 0px !important;
      overflow-x: auto !important;
    }

    v-deep table tr td {
      /* border: 1px solid gray !important; */
      border-right: 1px solid gray !important;
      border-bottom: 1px solid gray !important;
      width: 300px !important;
      height: 33px !important;
    }
    /**整体样式 */
    v-deep .excel-view-container {
      background-color: #ffffff;
    }
    /**标题样式 */
    v-deep .class4Title {
      font-size: 22px !important;
      font-weight: bold !important;
      padding: 10px !important;
    }
    /**表格表头样式 */
    v-deep.class4TableTh {
      /* font-size: 14px !important; */
      font-weight: bold !important;
      padding: 2px !important;
      background-color: #ccc !important;
    }
  }

  html body {
    width: 100%;
    height: 100vh;
    margin: 0;
  }
</style>
