<!--
 * @Author: WGL
 * @Date: 2023-05-30 13:36:31
 * @LastEditors: WGL
 * @LastEditTime: 2024-05-14 14:32:32
 * @Description: 

-->
<template>
  <jwt-header-button
    :config="config"
    :current-path="currentPagePath"
    :menus-obj="userMenusAuthObj"
    :row="detailInfo"
    :show-icon="showIcon"
    :show-text="showText"
    @header-action="handleHeaderAction"
  />
</template>

<script>
  import { defineComponent, onBeforeMount, computed, useSlots } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter, useRoute } from 'vue-router'
  // import AppForm from '@/components/AppForm'

  export default defineComponent({
    name: 'BaseHeaderBtn',
    components: {
      // AppForm,
    },
    props: {
      config: { require: true, type: Object, default: () => {} },
      detailInfo: { require: true, type: Object, default: () => {} },
      urlPath: { type: String, default: '' },
      showIcon: { type: Boolean, default: true },
      showText: { type: Boolean, default: true },
    },
    emits: ['header-action', 'des-edit-success'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const slots = useSlots()
      const userMenusAuthObj = computed(() => store.getters['routes/menusObj'])
      const currentPagePath = computed(() => {
        const { path } = route
        return props.urlPath || path || ''
      })

      const handleHeaderAction = (action) => {
        emit('header-action', action)
      }

      onBeforeMount(() => {})

      return {
        slots,
        route,
        router,
        userMenusAuthObj,
        currentPagePath,
        handleHeaderAction,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
