import { imgBaseUrl, domain, imgMaterialUrl } from '@/config'
/**
 * 解析URL参数
 * @example ?id=123456&a=b
 * @return Object {id:123456,a:b}
 */
export function urlParse() {
  let url = window.location.search
  let obj = {}
  let reg = /[?&][^?&]+=[^?&]+/g
  let arr = url.match(reg)
  // ['?id=12345', '&a=b']
  if (arr) {
    arr.forEach((item) => {
      let tempArr = item.substring(1).split('=')
      let key = decodeURIComponent(tempArr[0])
      let val = decodeURIComponent(tempArr[1])
      obj[key] = val
    })
  }
  return obj
}

export function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      )
    }
  }
  return fmt
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length)
}

/**
 * 深拷贝
 * @param {*} source 拷贝对象
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'shallowClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  for (const keys in source) {
    // eslint-disable-next-line no-prototype-builtins
    if (source.hasOwnProperty(keys)) {
      if (source[keys] && typeof source[keys] === 'object') {
        targetObj[keys] = source[keys].constructor === Array ? [] : {}
        targetObj[keys] = deepClone(source[keys])
      } else {
        targetObj[keys] = source[keys]
      }
    }
  }
  return targetObj
}

export function setCookie(name, value, days, domain) {
  var d = new Date()
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days)
  window.document.cookie =
    name +
    '=' +
    value +
    ';path=/;' +
    'domain=' +
    domain +
    ';expires=' +
    d.toGMTString()
}
// 对输入的内容进行防sql注入
export function changeStr(string) {
  let str = string
  str = str.toString()
  str = str.replace(str.match(/</g), '&lt;')
  str = str.replace(str.match(/>/g), '&gt;')
  // 去掉所有的换行符
  str = str.replace(/\r\n/g, '')
  str = str.replace(/\n/g, '')

  // 去掉所有的空格（中文空格、英文空格都会被替换）
  str = str.replace(/\s/g, ' ')
  return str
}

export function getCookie(name) {
  var v = window.document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
  return v ? v[2] : null
}

export function deleteCookie(name) {
  this.setCookie(name, '', -1)
}
// 判断是否为空
export function isNull(str) {
  if (str === '') return true
  if (str === null) return true
  var regu = '^[ ]+$'
  var re = new RegExp(regu)
  return re.test(str)
}
export function checkUrl(url) {
  if (url.indexOf('http') < 0) {
    return `${imgBaseUrl}${url}`
  } else {
    return url
  }
}

export function fillSrc(url) {
  if (!url) {
    return ''
  }

  if (url.indexOf('/') === 0) {
    url = url.substr(1, url.length)
  }

  if (url.indexOf('http') < 0) {
    return `${domain}/${url}`
  } else {
    return url
  }
}
export function fillUrl(url, w, h, quality) {
  if (!url) {
    return ''
  }
  if (url?.indexOf('/') === 0) {
    url = url.substr(1, url.length)
  }

  if (!url?.includes('http')) {
    url = `${imgBaseUrl}${url}`
  } else if (url?.includes('//erpcdn.3927qp.com/')) {
    // console.log('fillUrl url', url)
    // erpcdn.3927qp.com/ => https://cdn.jinweitec.com/erpsys/

    return url.replace(/http(s)?:\/\/erpcdn.3927qp.com\//g, 'https://cdn.jinweitec.com/erpsys/')
  }
  if (url?.includes(imgBaseUrl) || url?.includes(imgMaterialUrl)) {
    if (w && h && quality) {
      return `${url}?imageMogr2/thumbnail/${w}x${h}!|imageMogr2/gravity/center/crop/${w}x${h}/format/webp/interlace/0/quality/${quality}`
    }
    if (w && h) {
      return `${url}?imageMogr2/crop/${w}x${h}`
    }
    if (w) {
      return `${url}?imageMogr2/thumbnail/${w}x|imageMogr2/gravity/northwest/crop/${w}x${w}/rotate/0/strip/interlace/0`
    }
    if (quality) {
      return `${url}?imageMogr2/format/webp/interlace/0/quality/${quality}`
    } else {
      return url
    }
  } else {
    return url
  }
}

export function toArr(obj) {
  let arr = []
  for (let [key, value] of Object.entries(obj)) {
    let obj1 = {
      label: value,
      value: parseInt(key),
    }
    arr.push(obj1)
  }
  return arr
}

export function keyToValue(obj = {}) {
  console.log(obj)
  let obj2 = {}
  for (const [key, value] of Object.entries(obj)) {
    obj2[value] = key
  }
  return obj2
}
export function deleteKey(target = {}, params = []) {
  params.map((key) => {
    delete target[key]
  })
}
let checkType = (function () {
  let rules = {
    email(str) {
      return /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(str)
    },
    mobile(str) {
      return /^1[3|4|5|7|8][0-9]{9}$/.test(str)
    },
    tel(str) {
      return /^(0\d{2,3}-\d{7,8})(-\d{1,4})?$/.test(str)
    },
    number(str) {
      return /^[0-9]$/.test(str)
    },
    english(str) {
      return /^[a-zA-Z]+$/.test(str)
    },
    text(str) {
      return /^\w+$/.test(str)
    },
    chinese(str) {
      return /^[\u4E00-\u9FA5]+$/.test(str)
    },
    lower(str) {
      return /^[a-z]+$/.test(str)
    },
    upper(str) {
      return /^[A-Z]+$/.test(str)
    },
  }
  // 暴露接口
  return {
    // 校验
    check(str, type) {
      return rules[type] ? rules[type](str) : false
    },
    // 添加规则
    addRule(type, fn) {
      rules[type] = fn
    },
  }
})()

export function download(url, name, target = '_blank') {
  if (!url) {
    return
  }
  let link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.download = name || url
  link.target = target
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link);
}

export function downloadIamge(imgsrc, name) {
  //下载图片地址和图片名
  var image = new Image()
  // 解决跨域 Canvas 污染问题
  image.setAttribute('crossOrigin', 'anonymous')
  image.onload = function () {
    var canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height
    var context = canvas.getContext('2d')
    context.drawImage(image, 0, 0, image.width, image.height)
    var url = canvas.toDataURL('image/png') //得到图片的base64编码数据
    download(url, name)
  }
  image.src = imgsrc

}
export { checkType }
