/*
 * @Author: WGL
 * @Date: 2023-05-15 17:09:46
 * @LastEditors: WGL
 * @LastEditTime: 2023-08-02 11:20:08
 * @Description: 
 */
import { getRequest, postRequest } from './api'

const module = 'repository'
const module_product = 'repository-product'

export function getRepositoryList(params = {}) {
  const url = `${module}/index`
  return getRequest(url, params)
}

export function getAllProductList(params = {}) {
  const url = `${module_product}/fetch`
  return getRequest(url, params)
}

export function addRepository(params = {}) {
  const url = `${module}/add`
  return postRequest(url, params)
}

export function editRepository(params = {}) {
  const url = `${module}/edit`
  return postRequest(url, params)
}

export function deleteRepository(params = {}) {
  const url = `${module}/delete`
  return postRequest(url, params)
}
export function getRepositoryTree(params = {}) {
  const url = `${module}/tree`
  return getRequest(url, params)
}

