/*
 * @Author: WGL
 * @Date: 2023-11-01 15:34:13
 * @LastEditors: WGL
 * @LastEditTime: 2023-11-01 15:35:39
 * @Description: 
 */
import { getRequest, postRequest } from './api'
// 海关数据
const module = 'customs'
export function getCustomsList(params = {}) {
  const url = `${module}/index`
  return getRequest(url, params)
}

export function addCustoms(params = {}) {
  const url = `${module}/add`
  return postRequest(url, params)
}

export function editCustoms(params = {}) {
  const url = `${module}/edit`
  return postRequest(url, params)
}

export function getCustomsDetail(params = {}) {
  const url = `${module}/detail`
  return getRequest(url, params)
}

export function deleteCustoms(params = {}) {
  const url = `${module}/delete`
  return postRequest(url, params)
}

/**
 * 获取海关国家
 */
export function getCustomsCountry(params = {}) {
  const url = `${module}/country`
  return getRequest(url, params)
}