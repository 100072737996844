/**
 * @description 路由拦截状态管理，目前两种模式：all模式与intelligence模式，其中partialRoutes是菜单暂未使用
 */
import { asyncRoutes, constantRoutes, resetRouter } from '@/router'
// import { windowReload } from '@/utils/business'
// import { getList } from '@/api/menuManagement'
import { getRoleOperateTree } from '@/api/roleManagement'
import { getUserMenuList } from '@/api/menuManagement'
import {
  // convertRouter,
  filterRoutes,
  getTreeData,
  generateUserMenuObj2,
  generateUserMenu,
  sortUserMenu,
  getTreeUnOperateData,
  generateAsyncRoutes,
} from '@/utils/routes'
// import { getTreeData } from '@/utils/routes'
import { authentication, rolesControl, title } from '@/config'
import { isArray } from '@/utils/validate'
import { SHOP_PATH } from '@/config/business.config'

import { gp } from '@vab'

const state = () => ({
  routes: [],
  menus: [],
  allMenus: [],
  activeName: '',
})
const getters = {
  routes: (state) => state.routes,
  menus: (state) => getTreeData(state.menus),
  menusObj: (state) => generateUserMenuObj2(state.menus),
  menusIdObj: (state, getters) => {
    const obj = {}
    for (const v of Object.values(getters.menusObj)) {
      obj[v.id] = v
    }
    return obj
  },
  menuAllList: (state) => {
    return [
      {
        id: 0,
        title: title,
        children: getTreeData(state.allMenus),
      },
    ]
  },
  menuOwnList: (state) => {
    return [
      {
        id: 0,
        title: title,
        children: getTreeData(state.menus),
      },
    ]
  },
  unOperateMenuIds: (state) => getTreeUnOperateData(state.allMenus),
  activeName: (state) => state.activeName,
  // 是否有电商
  hasShopAuth: (state) => {
    let result = false
    state.menus.forEach(item => {
      if (item.url === SHOP_PATH) {
        result = true
      }
    });
    return result
  },
  firstMenuUrl: (state, getters) => {
    return getters.menus[0]?.children[0]?.url || '/work'
  },
}
const mutations = {
  /**
   * @description 多模式设置用户路由
   * @param {*} state
   * @param {*} routes
   */
  setMenus(state, menus) {
    state.menus = menus
  },

  /**
   * @description 多模式设置所有路由
   * @param {*} state
   * @param {*} routes
   */
  setAllMenus(state, allMenus) {
    state.allMenus = allMenus
  },

  /**
   * @description 多模式设置路由
   * @param {*} state
   * @param {*} routes
   */
  setRoutes(state, routes) {
    // console.log('routes :>> ', routes)
    state.routes = routes
  },
  /**
   * @description 修改Meta
   * @param {*} state
   * @param options
   */
  changeMenuMeta(state, options) {
    function handleRoutes(routes) {
      return routes.map((route) => {
        if (route.name === options.name) Object.assign(route.meta, options.meta)
        if (route.children && route.children.length)
          route.children = handleRoutes(route.children)
        return route
      })
    }

    state.routes = handleRoutes(state.routes)
  },
  /**
   * @description 修改 activeName
   * @param {*} state
   * @param activeName 当前激活菜单
   */
  changeActiveName(state, activeName) {
    state.activeName = activeName
  },
}
const actions = {
  /**
   * 获取菜单
   * @param {*} param0
   */
  async getUserMenuList({ commit }) {
    const { lists } = await getUserMenuList()
    // console.log('getUserMenuList :>> ', lists);
    if (lists && lists.length) {
      commit('setMenus', lists)
    } else {
      return gp.$baseMessage('没有菜单权限！', 'error', 'vab-hey-message-error')
    }
  },

  /**
   * 获取菜单
   * @param {*} param0
   */
  async getMenuList({ commit }) {
    const data = await getRoleOperateTree()
    commit('setAllMenus', data)
  },

  /**
   * @description 多模式设置路由
   * @param {*} { commit }
   * @param mode
   * @returns
   */
  async setRoutes({ commit, getters }, mode = 'none') {
    // 默认前端路由
    // let routes = [...asyncRoutes]
    const asyncRoutesObj = generateAsyncRoutes(asyncRoutes)
    // console.log('asyncRoutesObj :>> ', asyncRoutesObj)
    let routes = F.copy(asyncRoutes)
    // console.log('routes :>> ', routes)
    // 设置游客路由关闭路由拦截(不需要可以删除)
    const control = mode === 'visit' ? false : rolesControl
    // 设置后端路由(不需要可以删除)
    if (authentication === 'all') {
      const list = generateUserMenu(routes, getters.menusObj, asyncRoutesObj)
      const sortMenu = sortUserMenu(list)
      if (!isArray(sortMenu))
        gp.$baseMessage('路由格式返回有误！', 'error', 'vab-hey-message-error')
      if (sortMenu[list.length - 1].path !== '*')
        sortMenu.push({
          path: '/:pathMatch(.*)*',
          redirect: '/404',
          name: 'NotFound',
          meta: { hidden: true },
        })
      // routes = convertRouter(sortMenu)
      console.log('sortMenu', sortMenu)
      routes = sortMenu
    }
    // 根据权限和rolesControl过滤路由
    const accessRoutes = filterRoutes([...constantRoutes, ...routes], control)
    // 设置菜单所需路由
    commit('setRoutes', JSON.parse(JSON.stringify(accessRoutes)))
    // 根据可访问路由重置Vue Router
    await resetRouter(accessRoutes)
  },

  /**
   * @description 修改Route Meta
   * @param {*} { commit }
   * @param options
   */
  changeMenuMeta({ commit }, options = {}) {
    commit('changeMenuMeta', options)
  },
  /**
   * @description 修改 activeName
   * @param {*} { commit }
   * @param activeName 当前激活菜单
   */
  changeActiveName({ commit }, activeName) {
    commit('changeActiveName', activeName)
  },
  /**
   * @description 更改团队或者产品
   * @param {*} { commit }
   */
  async changeProductTeam({ dispatch }, appid) {
    console.log('changeProductTeam', appid)
    // if (appid) {
    //   await dispatch('user/setAppId', appid, { root: true })
    // }
    await dispatch('user/getUserInfo', null, { root: true })
    await dispatch('getUserMenuList')
    await dispatch('setRoutes', authentication)
    await dispatch('tabs/delAllVisitedRoutes', null, { root: true })
    // windowReload()
    // router.push('/')
  },
}
export default { state, getters, mutations, actions }
