<template>
  <el-image class="code" fit="cover" :src="codeUrl" @click="changeCode" />
</template>

<script>
  import { defineComponent, reactive, toRefs } from 'vue'
  import { baseURL } from '@/config'
  const codeUrl = baseURL + '/index/captcha'

  export default defineComponent({
    name: 'Captcha',
    setup() {
      const state = reactive({
        codeUrl: codeUrl + `?timestamp=${new Date().getTime()}`,
      })
      const changeCode = () => {
        state.codeUrl = `${codeUrl}?timestamp=${new Date().getTime()}`
      }
      return {
        ...toRefs(state),
        changeCode,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
