import { getRequest, postRequest } from './api'

const module = 'repository'
export function getStoreList(params = {}) {
  console.log(params)
  // const url = `${module}/index`
  // return getRequest(url, params)
}

export function addStore(params = {}) {
  const url = `${module}/add`
  return postRequest(url, params)
}

export function editStore(params = {}) {
  const url = `${module}/edit`
  return postRequest(url, params)
}

export function getStoreDetail(params = {}) {
  const url = `${module}/detail`
  return getRequest(url, params)
}

export function deleteStore(params = {}) {
  const url = `${module}/delete`
  return postRequest(url, params)
}

// 产品分类
export async function storeCategoryList(params = {}) {
  const url = `${module}/list`
  return getRequest(url, params)
}

// 产品具体分类
export function getStoreCategory(params = {}) {
  const url = `${module}/index`
  return getRequest(url, params)
}
