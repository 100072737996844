<!--
 * @Author: WGL
 * @Date: 2024-05-13 13:41:50
 * @LastEditors: WGL
 * @LastEditTime: 2024-05-16 09:55:11
 * @Description: 
-->
<template>
  <div class="stash-cascader-panel-item-label">
    <el-text class="fx w" :type="textType">
      <slot :index="index" :level="level" name="nodeItem" :node="node">
        {{ node[prop.label] }}
      </slot>
    </el-text>
  </div>
  <el-text
    v-show="node[prop.children]"
    class="stash-cascader-panel-item-arrow"
    :type="textType"
  >
    <el-icon><ArrowRight /></el-icon>
  </el-text>
</template>

<script setup>
  import { defineProps, inject, computed } from 'vue'
  import { ArrowRight } from '@element-plus/icons-vue'
  const props = defineProps({
    node: { type: Object, default: () => {} },
    prop: { type: Object, default: () => {} },
    index: { type: Number, default: 0 },
    level: { type: Number, default: 1 },
  })
  const panel = inject('stashCascaderPanel')
  const textType = computed(() => {
    return panel.checkedNodesIds.includes(props.node[props.prop.value])
      ? 'primary'
      : ''
  })
</script>
