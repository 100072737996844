import { getRequest, postRequest } from './api'

const module = 'target-group'
export function getTargetList(params = {}) {
  console.log(params)
  // const url = `${module}/index`
  // return getRequest(url, params)
}

export function addTarget(params = {}) {
  const url = `${module}/add`
  return postRequest(url, params)
}

export function editTarget(params = {}) {
  const url = `${module}/edit`
  return postRequest(url, params)
}

export function getTargetDetail(params = {}) {
  const url = `${module}/detail`
  return getRequest(url, params)
}

export function deleteTarget(params = {}) {
  const url = `${module}/delete`
  return postRequest(url, params)
}

// 用户分类
export async function targetCategoryList(params = {}) {
  const url = `${module}/index`
  return getRequest(url, params)
}
