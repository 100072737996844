<template>
  <el-config-provider :locale="messages[locale]">
    <router-view />
  </el-config-provider>
</template>

<script>
  import { defineComponent, toRef, onBeforeMount, provide, computed } from 'vue'
  import i18n from '@/i18n'
  import store from '@/store'

  export default defineComponent({
    name: 'VabApp',
    setup() {
      const locale = toRef(i18n.global, 'locale')
      const messages = toRef(i18n.global, 'messages')
      console.log('object :>> currentCompanyId', store.getters)
      const currentCompanyId = computed(() => store.getters['sys/companyId'])

      onBeforeMount(() => {
        store.dispatch('sys/getWebsiteData')
        setTimeout(() => {
          console.log('object :>> currentCompanyId', currentCompanyId.value)
          provide('companyId', currentCompanyId.value) // provide的第一个为名称，第二个值为所需要传的参数
        }, 2000)
      })
      return {
        locale,
        messages,
      }
    },
  })
</script>
