<template>
  <!--分栏布局 -->
  <div
    class="vab-layout-column"
    :class="{
      fixed: fixedHeader,
      'no-tabs-bar': !showTabs,
    }"
  >
    <!-- <column-bar /> -->
    <vab-column-bar />
    <div
      class="vab-main"
      :class="{
        ['vab-main-' + theme.columnStyle]: true,
        'is-collapse-main': collapse,
      }"
    >
      <div
        class="vab-layout-header"
        :class="{
          'fixed-header': fixedHeader,
        }"
      >
        <!-- <header-nav /> -->
        <vab-nav />
        <!-- <vab-tags /> -->
        <vab-tabs v-show="showTabs" />
      </div>
      <vab-app-main />
    </div>
  </div>
</template>

<script>
  // import columnBar from '@/views/ceshi/components/columnBar.vue'
  // import headerNav from '@/views/ceshi/components/headerNav.vue'
  // import vabTags from '@/views/ceshi/components/vabTags.vue'
  import { computed, defineComponent } from 'vue'
  import { useStore } from 'vuex'

  export default defineComponent({
    name: 'VabLayoutColumn',
    components: {},
    props: {
      collapse: {
        type: Boolean,
        default() {
          return false
        },
      },
      fixedHeader: {
        type: Boolean,
        default() {
          return true
        },
      },
      showTabs: {
        type: Boolean,
        default() {
          return true
        },
      },
    },
    setup() {
      const store = useStore()

      return {
        theme: computed(() => store.getters['settings/theme']),
      }
    },
  })
</script>

<style lang="scss" scoped>
  .vab-layout-column {
    .vab-main {
      .fixed-header {
        left: $base-left-menu-width;
        width: $base-right-content-width;
      }

      &.is-collapse-main {
        &.vab-main-horizontal {
          margin-left: $base-left-menu-width-min * 1.3;

          :deep() {
            .fixed-header {
              left: $base-left-menu-width-min * 1.3;
              width: calc(100% - #{$base-left-menu-width-min} * 1.3);
            }
          }
        }
      }
    }
  }
</style>
