/*
 * @Author: WGL
 * @Date: 2023-05-04 15:38:39
 * @LastEditors: WGL
 * @LastEditTime: 2023-09-20 11:38:53
 * @Description:
 */
/**
 * @description 导出vue/cli配置，以下所有配置修改需要重启项目
 */
module.exports = {
  // 开发以及部署时的URL
  // hash模式时在不确定二级目录名称的情况下建议使用""代表相对路径或者"/二级目录/"
  // history模式默认使用"/"或者"/二级目录/"，记住只有hash时publicPath可以为空！！！
  publicPath: process.env.NODE_ENV === 'development'
    ? ''
    : '//static.erp.jinweinet.com',
  // 生产环境构建文件的目录名
  // outputDir: 'dist',
  outputDir:
    process.env.NODE_ENV === 'development'
      ? '../front-static/dev/smart-trade-admin'
      : 'dist',
  // : '../front-static/release/smart-trade-admin',
  // 放置生成的静态资源 (js、css、img、fonts) 的 (相对于 outputDir 的) 目录。
  assetsDir: 'static',
  // 开发环境每次保存时是否输出为eslint编译警告
  lintOnSave: true,
  // 进行编译的依赖
  transpileDependencies: ['resize-detector'],
  // 开发环境端口号
  devPort: 8083,
  // 需要自动注入并加载的模块
  providePlugin: {},
  // npm run build时是否自动生成7z压缩包
  build7z: false,
  // npm run build时是否生成gzip
  buildGzip: true,
  // npm run build时是否开启图片压缩，由于国内网路原因image-webpack-loader必须使用cnpm安装，如无法使用cnpm，请配置false
  imageCompression: true,
  // pwa
  pwa: true,
}
