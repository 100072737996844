/*
 * @Author: WGL
 * @Date: 2024-01-29 10:26:56
 * @LastEditors: WGL
 * @LastEditTime: 2024-08-05 16:00:47
 * @Description: 
 */
import { getRequest, postRequest } from './api'

const module = 'customer'
const module_customer = 'customer-type'
const module_customer_product = 'customer-product'
export function getCustomerList(params = {}) {
  const url = `${module}/index`
  return getRequest(url, params)
}

export function addCustomer(params = {}) {
  const url = `${module}/add`
  return postRequest(url, params)
}

export function editCustomer(params = {}) {
  const url = `${module}/edit`
  return postRequest(url, params)
}

export function getCustomerDetail(params = {}) {
  const url = `${module}/detail`
  return getRequest(url, params)
}

export function deleteCustomer(params = {}) {
  const url = `${module}/delete`
  return postRequest(url, params)
}

export function getCustomerCode(params = {}) {
  const url = `${module}/code`
  return postRequest(url, params)
}
export function checkCustomerInfo(params = {}, MODULE) {
  const url = `${MODULE}/check`
  return getRequest(url, params)
}

export function checkCustomerPhone(telephone) {
  const url = `${module}/index`
  return getRequest(url, { telephone })
}
// 用户分类
export async function customerCategoryList(params = {}) {
  const url = `${module_customer}/index`
  return getRequest(url, params)
}
export async function customerProductList(params = {}) {
  const url = `${module_customer_product}/index`
  return getRequest(url, params)
}