<!--
 * @Author: WGL
 * @Date: 2024-01-23 14:54:12
 * @LastEditors: WGL
 * @LastEditTime: 2024-01-26 15:44:23
 * @Description:
-->
<template>
  <erp-poster-design
    v-model="posterDesignConfig.data"
    v-bind="posterDesignConfig"
    @save="save"
  />
</template>

<script>
  import { defineComponent, reactive, computed } from 'vue'
  import { baseURL } from '@/config'
  // import { getRequest } from '@/api/api'
  import { download } from '@/common/js/utils'
  import { useStore } from 'vuex'

  export default defineComponent({
    name: 'PosterDesign',
    emits: ['saveImg'],
    setup(props, { emit }) {
      const store = useStore()
      const userInfo = computed(() => store.getters['user/userInfo'])
      const posterDesignConfig = reactive({
        data: '',
        companyId: userInfo.value.company_id,
        config: {
          API_URL: baseURL || '/admin',
        },
      })

      const showPosterDesign = (data) => {
        // getRequest('/product/detail?id=19246').then((r) => {
        //   posterDesignConfig.data = r
        // })
        posterDesignConfig.data = data
      }

      const closePosterDesign = () => {
        showPosterDesign(null)
      }
      const save = (data) => {
        emit('saveImg', data)
        download(data, '1')
      }

      return {
        posterDesignConfig,
        showPosterDesign,
        save,
        closePosterDesign,
      }
    },
  })
</script>

<style scoped></style>
