<template>
  <el-breadcrumb class="vab-breadcrumb" separator=">">
    <el-breadcrumb-item
      v-for="(item, index) in breadcrumbList"
      :key="index"
      :to="{ path: item.redirect }"
    >
      <vab-icon v-if="item.meta && item.meta.icon" :icon="item.meta.icon" />
      <span>{{ translateTitle(item.meta.title) }}</span>
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
  import { computed, defineComponent } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import { translateTitle } from '@/utils/i18n'
  import { handleMatched } from '@/utils/routes'

  export default defineComponent({
    name: 'VabBreadcrumb',
    setup() {
      const store = useStore()
      const route = useRoute()

      const routes = computed(() => store.getters['routes/routes'])
      const breadcrumbList = computed(() =>
        handleMatched(routes.value, route.name).filter(
          (item) => item.meta.breadcrumbHidden !== true
        )
      )
      console.log('breadcrumbList :>> ', breadcrumbList.value)

      return {
        breadcrumbList,
        translateTitle,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .vab-breadcrumb {
    height: $base-nav-height;
    font-size: $base-font-size-default;
    line-height: $base-nav-height;

    :deep() {
      .el-breadcrumb__item {
        .el-breadcrumb__inner,
        .el-breadcrumb__separator {
          font-weight: normal;
          color: #fff7e9;
        }

        &:last-child {
          .el-breadcrumb__inner {
            a {
              color: #fff7e9;
            }
          }
        }
      }
    }
  }
</style>
