/*
 * @Author: WGL
 * @Date: 2023-03-16 12:05:36
 * @LastEditors: WGL
 * @LastEditTime: 2024-08-13 18:06:09
 * @Description:
 */
/**
 * @description 路由守卫，目前两种模式：all模式与intelligence模式
 */
import store from '@/store'
import router from '@/router'
import VabProgress from 'nprogress'
import 'nprogress/nprogress.css'
import getPageTitle from '@/utils/pageTitle'
import { toLoginRoute } from '@/utils/routes'
import { getMemoryUsage } from '@/utils/business'
import {
  authentication,
  loginInterception,
  routesWhiteList,
  supportVisit,
} from '@/config'

export function setup() {
  VabProgress.configure({
    easing: 'ease',
    speed: 500,
    trickleSpeed: 200,
    showSpinner: false,
  })
  router.beforeEach(async (to, from, next) => {
    const { showProgressBar } = store.getters['settings/theme']
    if (showProgressBar) VabProgress.start()
    let hasToken = store.getters['user/token']
    if (!loginInterception) hasToken = true
    if (hasToken) {
      if (store.getters['routes/routes'].length) {
        console.log('beforeEach >> from ', from)
        console.log('beforeEach >> to', to)
        // console.log('routes/routes', store.getters['routes/routes'])
        // 禁止已登录用户返回登录页
        if (to.path === '/login') {
          next({ path: '/' })
          if (showProgressBar) VabProgress.done()
        } else if (from.fullPath === '/' && to.fullPath === '/404') {
          // debugger
          // const menusObj = store.getters['routes/menusObj']
          // if (menusObj['/']) {
          //   next()
          // } else {
          // }
          const firstMenuUrl = store.getters['routes/firstMenuUrl']
          next({ path: firstMenuUrl })
        } else {
          next()
        }
      } else {
        try {
          if (loginInterception) await store.dispatch('user/getUserInfo')
          // config/setting.config.js loginInterception为false(关闭登录拦截时)时，创建虚拟角色
          else await store.dispatch('user/setVirtualRoles')
          // 获取通用字典配置
          await store.dispatch('sys/getLocalDict')

          // await store.dispatch('acl/getRoleList')
          // 用户菜单路由
          await store.dispatch('routes/getUserMenuList')
          // 根据路由模式获取路由并根据权限过滤
          await store.dispatch('routes/setRoutes', authentication)
          // 获取用户列表字段配置
          await store.dispatch('sys/getUserFieldList')
          // 获取系统配置信息
          await store.dispatch('sys/handleGetSysConfigSetting')
          // 获取地区数据
          await store.dispatch('sys/getAreaTreeData')
          // 获取当前用户拥有的公司主体
          // await store.dispatch('user/getCompanyList')
          // const menusObj = store.getters['routes/menusObj']
          // console.log(`menusObj`, menusObj)
          // 获取所有产品分类
          // store.dispatch('sys/getProductCategoryList')
          // 获取客户分类
          // store.dispatch('sys/getCustomerCategoryList')
          // 获取供应商分类
          // store.dispatch('sys/getSupplierCategoryList')
          // 获取仓库分类
          // store.dispatch('sys/getStoreCategoryList')
          // 获取目标分组
          // store.dispatch('sys/getTargetCategoryList')
          // 获取消息类型
          // store.dispatch('sys/getTypeNoticeList')
          // 获取所有菜单
          store.dispatch('routes/getMenuList')
          // 获取仓库数据
          store.dispatch('sys/getAllRepositoryList')
          // 获取海关国家数据
          store.dispatch('sys/getCustomsCountryList')
          // 获取1688商品分类
          store.dispatch('sys/handleGetAlbbProductCategoryTree')
          // 获取快递公司
          store.dispatch('sys/handleGetSupportExpress')
          store.dispatch('sys/handleGetSupportExpressKb')
          // 轮询请求
          store.dispatch('sys/handleSysPolling')
          next({ ...to, replace: true })
        } catch (err) {
          console.error('vue-admin-beautiful错误拦截:', err)
          await store.dispatch('user/resetAll')
          next(toLoginRoute(to.path))
        }
      }
    } else {
      if (routesWhiteList.includes(to.path)) {
        // 设置游客路由(不需要可以删除)
        if (supportVisit && !store.getters['routes/routes'].length) {
          await store.dispatch('routes/setRoutes', 'visit')
          next({ path: to.path, replace: true })
        } else next()
      } else next(toLoginRoute(to.path))
    }
  })
  router.afterEach((to) => {
    getMemoryUsage()
    // 记录最近访问
    try {
      let recentRoutes = JSON.parse(localStorage.getItem('recent-routes')) || []
      // 先unshift再判断是否超过10，超过则删掉
      if (recentRoutes.filter((_) => _.title === to.meta.title).length > 0) {
        recentRoutes = recentRoutes.filter((_) => _.title !== to.meta.title)
      }
      if (recentRoutes.length > 10) {
        recentRoutes.shift()
      }
      recentRoutes.unshift({ ...to.meta, path: to.path })
      localStorage.setItem('recent-routes', JSON.stringify(recentRoutes))
    } catch (error) {
      console.log(error)
    }
    document.title = getPageTitle(to.meta.title)
    if (VabProgress.status) VabProgress.done()
  })
}
