/*
 * @Author: WGL
 * @Date: 2022-09-06 09:57:18
 * @LastEditors: WGL
 * @LastEditTime: 2024-01-22 16:27:53
 * @Description:
 */
// =====================================================  全局 js
import '@/utils/common'
// =====================================================  模块
import App from './App.vue'
import { createApp } from 'vue'
import { setupVab } from '@/vab'
import { setupStore } from '@/store'
import { setupRouter } from '@/router'
import { setupComponents } from '@/components'
import { setupComponentBase } from '@/componentBase'
import 'element-plus/theme-chalk/display.css'
import 'erp-poster-design/style.css'
// import { setupJwt } from './packages'
import { setupJwt } from 'jwt-ui'
import 'jwt-ui/lib/jwt-ui.css'
import './utils/day'
// 全局变量样式
import '@/styles/index.scss'
// 字体图标
import '@/iconfont/iconfont.css'
// import './registerServiceWorker'
const app = createApp(App)
// window.__1688_OPEN_SDK_CONFIG = {
//   appKey: '4465554',
//   appName: '鲸苇ERP',
//   spaType: 'hash',
// }
// import { pwa } from './config'

// 富文本
// import { Boot } from '@wangeditor/editor'
// import attachmentModule from '@wangeditor/plugin-upload-attachment'
// // 注册。要在创建编辑器之前注册，且只能注册一次，不可重复注册。
// Boot.registerModule(attachmentModule)

/**
 * @description 正式环境默认使用mock，正式项目记得注释后再打包
 */
// import { baseURL, pwa } from './config'
// import { isExternal } from '@/utils/validate'

// if (process.env.NODE_ENV === 'production' && !isExternal(baseURL)) {
//   const { mockXHR } = require('@/utils/static')
//   mockXHR()
// }

// if (pwa) require('./registerServiceWorker')

/**
 * @description 生产环境启用组件初始化，编译，渲染和补丁性能跟踪。仅在开发模式和支持 Performance.mark API的浏览器中工作。
 */
if (process.env.NODE_ENV === 'development') {
  app.config.performance = true
}
setupVab(app)
setupJwt(app)
setupComponents(app)
setupComponentBase(app)
setupStore(app)
setupRouter(app)
  .isReady()
  .then(() => app.mount('#app'))
