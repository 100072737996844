<!--
 * @Author: WGL
 * @Date: 2023-12-11 11:53:34
 * @LastEditors: WGL
 * @LastEditTime: 2023-12-18 11:20:43
 * @Description: 
-->
<template>
  <el-dialog
    v-model="dialogVisible"
    append-to-body
    center
    :title="dialogTitle"
    top="3vh"
    width="80%"
    @close="handleCloseDialog"
  >
    <div>
      <el-steps :active="currentStep" align-center>
        <el-step
          v-for="(item, index) in stepList"
          :key="index"
          :title="item.title"
        />
      </el-steps>

      <div class="mt10 oy">
        <el-form
          ref="formRef"
          :inline="false"
          label-width="80px"
          :model="formModel"
        >
          <!-- 选择类目 -->
          <div v-show="currentStep === 1">
            <el-form-item :label="t('上架店铺')">
              <jwt-select-server
                :ref="setItemRef"
                v-model="formModel.shop_id"
                :request="request"
                v-bind="{
                  clearable: true,
                  filterable: true,
                  class: 'wi',
                  labelKey: 'company_name',
                  listApi: `/albb/shopList`,
                  searchKey: 'title',
                  placeholder: t('请选择店铺'),
                }"
                @change="
                  handleVehicleMakeChange(
                    $event,
                    item,
                    index,
                    'edition_id',
                    model
                  )
                "
              />
            </el-form-item>
            <el-form-item :label="t('商品类目')">
              <el-cascader
                v-model="formModel.category_id"
                class="w"
                clearable
                filterable
                :options="albbCategoryTree"
                :props="{ label: 'name', value: 'category_id' }"
                @change="handleCategoryChange"
              />
            </el-form-item>
            <div class="fx">
              <template
                v-for="(val, key, index) in searchAlbbCategoryList"
                :key="key"
              >
                <div
                  v-if="index < 5"
                  class="bg p5 r4px mr10 cp"
                  @click="handleSelectSearchCategory(key)"
                >
                  {{ val }}
                </div>
              </template>
            </div>
          </div>
          <!-- 产品信息 -->
          <div v-show="currentStep === 2">
            <el-tabs
              v-model="albbProductAttrs.activeName"
              @tab-click="handleTabClick"
            >
              <el-tab-pane
                v-for="tab in albbProductAttrs.navBarList"
                :key="tab.id"
                :label="tab.label"
                :name="tab.value"
                style="max-height: 65vh"
              >
                <!-- <div class="fxmj pb10 bbor mb10">
                  <div class="fs18 b">{{ tab?.label }}</div>
                  <div
                    v-if="tab?.promote?.right"
                    v-html="tab.promote.right"
                  ></div>
                </div>
                <template v-for="item in tab.children" :key="item?.id">
                  <ProductPriceRange
                    v-if="item.labelKey === 'priceRange'"
                    :form-model="formModel"
                    :item="item"
                  />
                  <ProductPriceSku
                    v-else-if="item.labelKey === 'skuTable'"
                    :form-model="formModel"
                    :item="item"
                    :sale-prop-info="salePropInfo"
                  />
                  <el-form-item
                    v-else-if="item.uiType != 'form'"
                    :label="item.label"
                    v-bind="item.formAttrs"
                  >
                    <AlbbFormItem
                      :form-model="formModel"
                      :item="item"
                      @change="handleFormItemChange($event, item)"
                    />
                  </el-form-item>
                  <div v-else class="fx mb10">
                    <div class="el-form-item__label w80">{{ item.label }}</div>
                    <div class="el-form-item__content fv">
                      <div class="fxmj ex w">
                        <el-text
                          v-if="item?.promote?.top"
                          type="info"
                          v-html="item?.promote?.top"
                        />
                      </div>
                      <el-row class="bg p10 r4px w">
                        <el-col
                          v-for="child in item.children"
                          :key="child?.id"
                          :span="12"
                        >
                          <el-form-item
                            :label="child.label"
                            v-bind="child.formAttrs"
                          >
                            <AlbbFormItem
                              :form-model="formModel"
                              :item="child"
                            />
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                </template> -->
              </el-tab-pane>
            </el-tabs>
            <div class="oy plr10" style="max-height: 60vh">
              <div
                v-for="(tab, index) in albbProductAttrs.navBarList"
                :id="tab.value"
                :key="index"
              >
                <div class="fxmj pb10 bbor mb10">
                  <div class="fs18 b">{{ tab?.label }}</div>
                  <div
                    v-if="tab?.promote?.right"
                    v-html="tab.promote.right"
                  ></div>
                </div>
                <template v-for="item in tab.children" :key="item?.id">
                  <ProductPriceRange
                    v-if="item.labelKey === 'priceRange'"
                    :form-model="formModel"
                    :item="item"
                  />
                  <ProductPriceSku
                    v-else-if="item.labelKey === 'skuTable'"
                    :form-model="formModel"
                    :item="item"
                    :sale-prop-info="salePropInfo"
                  />
                  <el-form-item
                    v-else-if="item.uiType != 'form'"
                    :label="item.label"
                    v-bind="item.formAttrs"
                  >
                    <AlbbFormItem
                      :form-model="formModel"
                      :global-data="globalData"
                      :item="item"
                      @change="handleFormItemChange($event, item)"
                    />
                  </el-form-item>
                  <div v-else class="fx mb10">
                    <div class="el-form-item__label w80">{{ item.label }}</div>
                    <div class="el-form-item__content fv">
                      <div class="fxmj ex w">
                        <el-text
                          v-if="item?.promote?.top"
                          type="info"
                          v-html="item?.promote?.top"
                        />
                      </div>
                      <el-row class="bg p10 r4px w">
                        <el-col
                          v-for="child in item.children"
                          :key="child?.id"
                          :span="12"
                        >
                          <el-form-item
                            :label="child.label"
                            v-bind="child.formAttrs"
                          >
                            <AlbbFormItem
                              :form-model="formModel"
                              :global-data="globalData"
                              :item="child"
                            />
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <template #footer>
      <span>
        <el-button v-if="currentStep !== 1" @click="handleBack">
          {{ t('上一步') }}
        </el-button>
        <el-button
          :disabled="nextStepDisabled"
          type="primary"
          @click="handleNext"
        >
          {{ currentStep === stepList.length ? t('提交') : t('下一步') }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
  import { defineProps, ref, computed, nextTick, defineExpose } from 'vue'
  import request from '@/utils/request'
  import { t } from '@/utils/i18n'
  import { getAlbbProductRule, albbPushProduct } from '@/api/third'
  // import { mockAlbbData } from './mock'
  import { useStore } from 'vuex'
  const store = useStore()
  const albbCategoryTree = computed(() => store.getters['sys/albbCategoryTree'])
  const stepList = [
    { title: '选择类目', content: '基本信息' },
    { title: '产品信息', content: '产品信息' },
    // { title: '发布产品', content: '发布产品' },
  ]

  const localAlbbCategoryList = LS.get('albbCategoryList')
  const searchAlbbCategoryList = ref({})
  searchAlbbCategoryList.value = localAlbbCategoryList || []

  const props = defineProps({
    isAdd: { type: Boolean, default: false },
    info: { type: Object, default: () => {} },
  })

  const dialogVisible = ref(true)
  const formRef = ref(null)
  const currentStep = ref(1)
  const salePropInfo = ref({}) // 产品规格属性
  // const categoryId = ref([])
  const formModel = ref({
    shop_id: '1', // 店铺id
    category_id: '1041507', // 类目id
    catProp: {},
  })

  // 商品属性数据
  const albbProductAttrs = ref({})
  const dialogTitle = computed(() => {
    return props.isAdd ? t('新增产品') : t('修改产品')
  })

  const globalData = computed(() => {
    return albbProductAttrs.value.dataSource.global
  })
  // 下一步按钮是否禁用
  const nextStepDisabled = computed(() => {
    if (currentStep.value === 1) {
      return !formModel.value.category_id || !formModel.value.shop_id
    }
    return currentStep.value === 1 && !formModel.value.shop_id
  })

  /**
   * 记录搜索历史
   * @param {*} value
   */
  function handleRecodeSearchCategory(
    value,
    index = 0,
    result = [],
    dataSource = []
  ) {
    dataSource.map((item) => {
      if (item.category_id === value[index]) {
        console.log('item :>> ', item)
        result.push(item)
        if (item?.children?.length > 0) {
          handleRecodeSearchCategory(value, index + 1, result, item.children)
        }
      }
    })
  }

  /**
   * 处理分类改变
   * @param {*} value
   */
  function handleCategoryChange(value) {
    if (value) {
      formModel.value.category_id = value[value.length - 1] || ''
      const result = []
      handleRecodeSearchCategory(value, 0, result, albbCategoryTree.value)
      const key = `${result[0].category_id}-${result[1].category_id}-${result[2].category_id}`
      const obj = {
        [key]: `${result[0].name} > ${result[1].name} > ${result[2].name}`,
      }
      searchAlbbCategoryList.value = {
        ...searchAlbbCategoryList.value,
        ...obj,
      }
      nextTick(() => {
        LS.set('albbCategoryList', searchAlbbCategoryList.value)
      })
      console.log('result', obj)
    }
  }

  /**
   * 搜索分类
   * @param {*} key
   */
  function handleSelectSearchCategory(key) {
    const result = key.split('-').map((item) => Number(item))
    formModel.value.category_id = result
    nextTick(() => {
      formModel.value.category_id = result[2]
    })
  }

  /**
   * 获取阿里巴巴类目下的产品规则数据
   */
  function getAlbbProductRuleData() {
    const params = {
      shop_id: formModel.value.shop_id,
      category_id: formModel.value.category_id,
    }
    getAlbbProductRule(params).then((res) => {
      console.log('res :>> getAlbbProductRule', res)
      handleAlbbProductRuleResult(res)
    })
  }

  function scrollToSection(sectionId) {
    const element = document.getElementById(sectionId)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  /**
   * tab 点击事件
   * @param {*} tab
   */
  function handleTabClick(tab) {
    console.log('tab', tab)
    nextTick(() => {
      console.log('handleTabClick :>> ', albbProductAttrs.value.activeName)
      scrollToSection(albbProductAttrs.value.activeName)
    })
  }

  /**
   * 下一步
   */
  function handleNext() {
    console.log('formModel', formModel.value)
    if (currentStep.value === stepList.length) {
      handleFormResult()
      return
    }
    currentStep.value += 1
    nextTick(() => {
      if (currentStep.value === 2) {
        getAlbbProductRuleData()
        // handleAlbbProductRuleResult(mockAlbbData)
      }
    })
  }

  /**
   * 上一步
   */
  function handleBack() {
    currentStep.value -= 1
  }

  /**
   * 关闭弹窗
   */
  const handleCloseDialog = () => {
    dialogVisible.value = false
  }

  /**
   * 打开弹窗
   */
  function handleShowDialog() {
    dialogVisible.value = true
  }

  /**
   * 获取数据源文本
   * @param {string} val 文本
   * @param {string} dataSource 数据源
   */
  function handleGetDataSourceText(val, dataSource) {
    let result = ''
    dataSource.map((item) => {
      // console.log('item handleGetDataSourceText', item, val)
      if (item.value === val) {
        result = item.text
      }
    })
    console.log('result', result)
    return result
  }

  /**
   * 获取表单类型
   * @param {string} type 类型
   * @param {string} labelKey 文本labelKey
   */
  function handleGetUiTypeVal(type, labelKey, dataSource) {
    let result = null
    const value = formModel.value[labelKey] || ''
    // console.log('labelKey', type, labelKey, value)
    switch (type) {
      case 'input':
      case 'inputTag':
      case 'number':
        result = value
        break
      case 'unit':
        result = {
          unit: value,
        }
        break
      case 'table':
        result = value
        break
      case 'checkbox':
        result = value?.map((v) => {
          return {
            value: v,
            text: v?.toString(),
          }
        })
        break
      case 'select':
      case 'radio':
      case 'shelftime':
        result = {
          value: value,
          text: handleGetDataSourceText(value, dataSource),
        }
        break
    }
    return result
  }
  // 产品上传
  function handleSubmitalbbPushProduct(jsonData) {
    const params = {
      // product_id: props?.info?.id,
      shop_id: formModel.value.shop_id,
      category_id: formModel.value.category_id,
      jsonData: jsonData,
    }

    albbPushProduct(params).then(() => {})
  }

  /**
   * 表单提交
   */
  async function handleFormResult() {
    console.log('formRef :>> ', formRef.value)
    const validForm = await formRef.value.validate((valid) => {
      return valid
    })
    console.log('validForm :>> ', validForm)
    // if (!validForm) return
    const obj = {}
    albbProductAttrs.value?.navBarList?.forEach((item) => {
      if (
        item.children
        // item.value === 'blockLogistics'
        // (item.value === 'blockTradeInfo' || item.value === 'blockProps')
      ) {
        console.log('handleFormResult', item)
        item.children.forEach((filed) => {
          const { labelKey, uiType, dataSource } = filed
          if (filed.uiType === 'form') {
            obj[labelKey] = {}
            filed?.children?.map((v) => {
              const {
                labelKey: labelKey2,
                uiType: uiType2,
                dataSource: dataSource2,
              } = v
              if (labelKey2) {
                obj[labelKey][labelKey2] = handleGetUiTypeVal(
                  uiType2,
                  labelKey2,
                  dataSource2
                )
              }
            })
          } else {
            obj[labelKey] = handleGetUiTypeVal(uiType, labelKey, dataSource)
          }
        })
      }
    })
    console.log('obj', obj)
    console.log('dataSource', albbProductAttrs.value.dataSource)
    const jsonData = {
      formValues: { ...obj, global: globalData.value },
    }
    console.log('jsonData', jsonData)
    handleSubmitalbbPushProduct(jsonData)
  }

  /**
   * 获取ui类型
   * @param {*} filed
   */
  function getAlbbProductRuleUiType(filed, hasChild = true) {
    if (!filed.uiType) return 'input'
    if (filed.uiType.includes('selecttree')) {
      return 'tree'
    } else if (
      filed.uiType.includes('select') ||
      filed.uiType.includes('send_address')
    ) {
      return 'select'
    } else if (filed.uiType.includes('freight')) {
      return 'freightSelect'
    } else if (filed.uiType === 'input' && filed.customizable) {
      return 'inputTag'
    } else if (filed.uiType.includes('title') || filed.uiType === 'input') {
      return 'input'
    } else if (filed.uiType.includes('radio')) {
      return 'radio'
    } else if (filed.uiType.includes('unit')) {
      return 'unit'
    } else if (filed.uiType.includes('checkbox')) {
      return 'checkbox'
    } else if (filed.uiType.includes('mode')) {
      return 'mode'
    } else if (filed.uiType.includes('table')) {
      return 'table'
    } else if (filed.uiType.includes('number')) {
      return 'number'
    } else if (filed.uiType.includes('volume')) {
      return 'volume'
    } else if (filed.uiType.includes('shelftime')) {
      // 特殊处理定时开售
      return 'shelftime'
    } else if (filed.uiType.includes('description')) {
      // 特殊处理定时开售
      return 'richText'
    } else if (filed.dataSource && hasChild) {
      return 'form'
    }
    return filed.uiType || 'input'
  }

  /**
   * 设置默认值
   * @param {*} fields
   * @param {*} labelKey
   * @param {*} uiType
   */
  function handleSetDefaultValue(fields, labelKey, uiType) {
    const options = fields.dataSource || fields.unitOptions || []
    // console.log('labelKey :>> handleSetDefaultValue', labelKey)
    // console.log('fields :>> handleSetDefaultValue', fields)
    switch (uiType) {
      case 'radio':
      case 'shelftime':
        formModel.value[labelKey] = options[0].value
        break
      case 'select':
        formModel.value[labelKey] = options[options.length - 1].value
        break
      case 'unit':
        formModel.value[labelKey] = options[options.length - 1]
        break
      case 'inputTag':
      case 'checkbox':
        formModel.value[labelKey] = []
        break
      case 'volume':
        formModel.value[labelKey] = {
          height: null,
          width: null,
          length: null,
        }
        break
      case 'table':
        if (labelKey === 'priceRange') {
          // 价格区间
          formModel.value[labelKey] = [
            {
              pricerange_beginAmount: null,
              pricerange_price: null,
            },
          ]
        } else if (labelKey === 'skuTable') {
          // 规格报价
          formModel.value[labelKey] = [
            {
              sku_props: [{}],
              sku_price: 0,
              sku_amountOnSale: 1000,
              sku_amountOrder: 0,
              sku_status: 1,
            },
          ]
        } else {
          formModel.value[labelKey] = []
        }
        break
      default:
        break
    }
  }

  /**
   * 处理关联表单
   * @param {*} fields
   * @param {*} labelKey
   */
  function handleFormRelevance(fields, labelKey) {
    // console.log('labelKey :>> handleFormRelevance', labelKey)
    let obj = {}
    if (labelKey === 'totalSales') {
      // 可售总量
      obj.refUintLabelKey = 'cbuUnit'
    }
    return obj
  }

  /**
   * 获取form表单的属性
   * @param {*} fields
   * @param {*} labelKey
   */
  function handleAFormRuleFiledAttrs(fields, labelKey) {
    console.log('fields', fields)
    console.log('labelKey :>> ', labelKey)
    if (labelKey === 'saleProp') {
      salePropInfo.value = fields
    }
    const uiType = getAlbbProductRuleUiType(fields, false)
    handleSetDefaultValue(fields, labelKey, uiType)
    const attrsData = {
      formAttrs: {
        rules: [
          {
            required: fields.required,
            message: `'${fields.label}' 不能为空`,
            trigger: uiType === 'input' ? 'blur' : 'change',
          },
        ],
        prop: labelKey,
        labelWidth: fields.propType === 'item' ? '180px' : '110px',
      },
      attrs: {
        placeholder:
          fields.placeholder ||
          (uiType === 'select' ? '请选择' : '请输入') + fields.label,
        'show-word-limit': true,
        maxlength: fields.maxLength,
      },
    }
    const extraData = {}
    // 特殊处理
    if (fields.uiType === 'cbu_freight') {
      // 运费模板
      const freightObj = {
        T: '使用运费模版',
        C: '运费指导模版',
        F: '卖家承担运费',
      }
      const list = fields.dataSource.map((item) => {
        return {
          value: item,
          text: freightObj[item],
          asyncQueryType: item === 'T' ? 'fullFreightTemplateAsyncQuery' : '',
        }
      })
      extraData.dataSource = list
    }
    return { ...attrsData, ...extraData }
  }

  /**
   * 处理字段规则
   * @param {*} filedKey 当前字段key
   * @param {*} allFields 所有字段
   */
  function handleAlbbProductRuleFiled(filedKey, allFields) {
    const fields = allFields[filedKey]?.fields
    if (!fields) return null
    const labelKey = allFields[filedKey].id
    return {
      ...fields,
      labelKey,
      ...handleAFormRuleFiledAttrs(fields, labelKey),
      ...handleFormRelevance(fields, labelKey),
      uiType: getAlbbProductRuleUiType(fields),
      children:
        fields.dataSource && fields.dataSource[0]?.uiType
          ? fields.dataSource.map((item) => {
              return {
                ...item,
                labelKey: item.name,
                label: item.label,
                uiType: getAlbbProductRuleUiType(item, false),
                ...handleAFormRuleFiledAttrs(item, item.name),
              }
            })
          : null,
    }
  }

  /**
   * 获取产品规则数据并组装数据
   */
  function handleAlbbProductRuleResult(albbData) {
    const { hierarchy, data: allFields } = albbData
    console.log('allFields', allFields)
    console.log('hierarchy', hierarchy)
    const { root, structure } = hierarchy
    // 隐藏的属性
    const hideAttrs = [
      'importProp', // 进口属性
      'bPrice', // L会员价设置
      'beginAmount', // 最小起订量
      'standardPrice', // 价格
      'processServiceTrade', // 定制项
      'relationOffer', //关联定制产品
      'anXinGouService', // 安心购·定制版
      'buyerProtection', // 买家保障
      'customExtraService', // 特色服务
      'officialLogistics', // 按规格设置重量
      'detailVideo', // 详情视频
    ]
    const navBarList = []
    structure[root].forEach((item) => {
      if (item.includes('block') && item !== 'blockPrimary') {
        navBarList.push({
          ...allFields[item].fields,
          label: allFields[item].fields.label,
          value: allFields[item].id,
          children: structure[item]
            .map((filedKey) => {
              return handleAlbbProductRuleFiled(filedKey, allFields)
            })
            .filter((item) => {
              console.log('item filter', item)
              return item && !hideAttrs.includes(item.labelKey)
            }),
        })
      }
    })
    albbProductAttrs.value = {
      navBarList: navBarList,
      activeName: navBarList[0].value,
      dataSource: albbData,
    }
    console.log('navBarList', navBarList)
  }

  /**
   * 值改变
   * @param {*} val
   * @param {*} item
   */
  function handleFormItemChange(val, item) {
    console.log('val :>> ', val)
    console.log('item :>> ', item)
  }

  defineExpose({
    handleShowDialog,
    handleCloseDialog,
  })
</script>
<script>
  export default {
    name: 'AlbbPublishProduct',
  }
</script>
<style scoped></style>
