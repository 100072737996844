<!--
 * @Author: WGL
 * @Date: 2023-06-19 09:06:21
 * @LastEditors: WGL
 * @LastEditTime: 2023-07-03 11:51:15
 * @Description: 
-->
<template>
  <el-popover placement="bottom" trigger="hover" :width="200">
    <div>
      <p class="tc fs12">扫描二维码联系工作人员</p>
      <img alt="" :src="customerServiceImg" style="width: 180px" />
    </div>
    <template #reference>
      <vab-icon icon="customer-service-2-line" />
    </template>
  </el-popover>
</template>

<script>
  import { computed, defineComponent } from 'vue'
  import { useStore } from 'vuex'
  import customerServiceImg from '@/assets/sys/customer-service.png'

  export default defineComponent({
    name: 'VabCustomerService',
    setup() {
      const store = useStore()

      return {
        customerServiceImg,
        theme: computed(() => store.getters['settings/theme']),
      }
    },
  })
</script>
