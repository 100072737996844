<!--
 * @Author: WGL
 * @Date: 2023-06-21 09:23:33
 * @LastEditors: WGL
 * @LastEditTime: 2024-03-11 17:47:26
 * @Description: 
-->
<template>
  <div v-if="skuTags.length">
    <el-tag v-for="(item, index) in skuTags" :key="index" class="mb4">
      {{ item }}
    </el-tag>
  </div>
  <span v-else>-</span>
</template>

<script>
  // 基础产品Sku组件
  import { computed, defineComponent } from 'vue'

  export default defineComponent({
    name: 'BaseSku',
    components: {},
    props: {
      info: { type: Object, default: () => {} },
    },
    setup(props) {
      const skuTags = computed(() => {
        if (props?.info?.sku) {
          return props.info.sku.split(',')
        }
        return []
      })

      return {
        skuTags,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
