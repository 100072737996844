<!--
 * @Author: WGL
 * @Date: 2023-06-21 09:23:33
 * @LastEditors: WGL
 * @LastEditTime: 2024-05-24 17:00:47
 * @Description: 
-->
<template>
  <div v-if="info?.supplier_list?.length" class="tl omit3 w cp">
    <template v-if="tag">
      <el-tag v-for="(item, index) in showListData" :key="index" class="mb2">
        {{ item.title }}
      </el-tag>
    </template>
    <template v-else>
      <el-popover
        placement="right"
        trigger="hover"
        :width="500"
        @show="handleShowPopover"
      >
        <template #reference>
          <div class="w omit3">
            <template v-for="(item, index) in info.supplier_list" :key="index">
              <div v-if="index < 3" class="fx w">
                <div class="ex omit" :title="item.title">{{ item.title }}</div>
                /
                <span v-if="item.exw_price" class="">
                  {{ CURRENCY_UNIT[item.currency] }}{{ item.exw_price }}
                </span>
                /
                <span v-if="item.fob_price" class="">
                  {{ CURRENCY_UNIT[item.fob_currency] }}{{ item.fob_price }}
                </span>
                <span v-else>-</span>
                /
                <div class="w80 omit" :title="item.sp_code">
                  {{ item.sp_code || '-' }}
                </div>
              </div>
            </template>
          </div>
        </template>
        <el-table
          v-if="showContent"
          :data="info.supplier_list"
          :max-height="500"
        >
          <el-table-column :label="t('供应商')" prop="title" width="200" />
          <!-- <el-table-column
            :label="t('最近报价')"
            prop="recently_price"
            width="100"
          >
            <template #default="{ row }">
              <span v-if="row.recently_price" class="cd b">
                {{ CURRENCY_UNIT[row.currency] }}{{ row.recently_price }}
              </span>
              <span v-else>-</span>
            </template>
          </el-table-column> -->
          <el-table-column :label="t('EXW价格')" prop="exw_price" width="100">
            <template #default="{ row }">
              <span v-if="row.exw_price" class="cd b">
                {{ CURRENCY_UNIT[row.currency] }}{{ row.exw_price }}
              </span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column :label="t('FOB价格')" prop="fob_price" width="100">
            <template #default="{ row }">
              <span v-if="row.fob_price" class="cd b">
                {{ CURRENCY_UNIT[row.fob_currency] }}{{ row.fob_price }}
              </span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column :label="t('编码')" prop="sp_code" width="190">
            <template #default="{ row }">
              {{ row.sp_code || '-' }}
            </template>
          </el-table-column>
        </el-table>
      </el-popover>
    </template>
    <!-- </template> -->
  </div>
  <span v-else>-</span>
</template>

<script>
  // 基础产品供应商组件
  import { defineComponent, reactive, toRefs, computed } from 'vue'
  import { CURRENCY_UNIT } from '@/config/variable.config'
  import { t } from '@/utils/i18n'

  export default defineComponent({
    name: 'BaseSuppliers',
    components: {},
    props: {
      info: { type: Object, default: () => {} },
      tag: { type: Boolean, default: false }, // 是否是标签显示
      showNum: { type: Number, default: 3 }, // 显示数量
    },
    setup(props) {
      const state = reactive({
        showContent: false,
      })

      // 显示列表数据
      const showListData = computed(() => {
        if (props.info?.supplier_list?.length) {
          return props.info?.supplier_list.slice(0, props.showNum)
        }
        return []
      })
      const handleShowPopover = () => {
        if (state.showContent) return
        state.showContent = true
      }

      return {
        ...toRefs(state),
        t,
        CURRENCY_UNIT,
        showListData,
        handleShowPopover,
      }
    },
  })
</script>

<style lang="scss" infod></style>
