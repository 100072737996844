/*
 * @Author: WGL
 * @Date: 2023-11-27 14:45:42
 * @LastEditors: WGL
 * @LastEditTime: 2024-01-24 14:16:20
 * @Description: 
 */
import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
// import 'element-plus/theme-chalk/display.css'

export function setup(app) {
  app.use(ElementPlus, {
    //
    // size: 'large'
  })
}
