<!--
 * @Author: WGL
 * @Date: 2023-06-15 13:42:15
 * @LastEditors: WGL
 * @LastEditTime: 2024-01-30 14:02:42
 * @Description: 
-->
<template>
  <div class="system-user-container">
    <base-table
      ref="tableRef"
      :config="tableConfig"
      :ref-menu-id="refMenuId"
      table-height="auto"
      @header-action="handleHeaderAction"
    >
      <template #search-before v-if="params?.from">
        <div>
          <span class="b">{{ t('价格策略') }}</span>
          <span class="ml15 g6 fs14">
            {{ t('最近销售时间') }}:
            <span class="cd">{{ info?.recently_sale_time || '-' }}</span>
          </span>
        </div>
      </template>
      <template #detailList="{ model }">
        <div>
          <div class="fxm mb10">
            <div class="w110 tr pr10">{{ t('策略条件') }}</div>
            <el-button type="primary" @click="handleAddPricePolicy(model)">
              {{ t('添加') }}
            </el-button>
          </div>
          <div v-for="(item, index) in model?.list" :key="index" class="fx">
            <!-- 数量大于等于 -->
            <el-form-item
              :label="t('数量大于等于')"
              label-width="110"
              :prop="'list.' + index + '.over_num'"
              :rules="[
                { required: true, message: t('请填写'), trigger: 'blur' },
              ]"
            >
              <el-input-number
                v-model="item.over_num"
                :controls="true"
                controls-position="right"
                label=""
                :max="999999"
                :min="1"
                :step="1"
              />
            </el-form-item>
            <el-form-item
              :label="t('单价为')"
              label-width="110"
              :prop="'list.' + index + '.price'"
              :rules="[
                { required: true, message: t('请填写'), trigger: 'blur' },
              ]"
            >
              <el-input-number
                v-model="item.price"
                :controls="true"
                controls-position="right"
                label=""
                :max="999999"
                :min="1"
                :step="1"
              />
            </el-form-item>
            <!-- 删除 -->
            <el-form-item label="" label-width="10">
              <el-button
                link
                type="danger"
                @click="handleDeletePricePolicy(model, index)"
              >
                <jwt-icon icon="delete-bin-7-line" size="18" />
              </el-button>
            </el-form-item>
          </div>
        </div>
      </template>
      <template #product_list="{ scope }">
        <div>
          <div v-for="(item, index) in scope.list" :key="index">
            {{ t('数量大于等于') }}
            <span class="b cd">{{ item.over_num }}</span>
            {{ t('价格为') }}
            <span class="b cd">
              {{ CURRENCY_UNIT[scope.currency] }}{{ item.price }}
            </span>
          </div>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
  import { defineComponent, reactive, onBeforeMount, ref } from 'vue'
  import {
    NORMAL_STATUS,
    CURRENCY_UNIT,
    PRODUCT_UNIT,
    PRICE_POLICY_TYPE,
  } from '@/config/variable.config'
  import { t } from '@/utils/i18n'
  import { productPricePolicyAddDialog } from '@/utils/dialogBoxConfig'
  import { objToArr } from '@/utils/index'
  import request from '@/utils/request'
  import { handleSupportCurrency } from '@/utils/business'
  const { supportCurrency } = handleSupportCurrency()

  const MODULE = 'product-price-policy' // 模块

  export default defineComponent({
    name: 'ProductPriceStrategyList',
    props: {
      params: { type: Object, default: () => {} },
      info: { type: Object, default: () => {} },
      refMenuId: { type: [Number, String], default: '' }, // 关联的菜单ID
      supplierId: { type: [Number, String], default: '' }, // 供应商
    },
    emits: [],
    setup(props) {
      console.log('props :>> ProductPackageList', props)
      const tableRef = ref(null)

      const handleDeletePricePolicyReq = (data) => {
        request({
          url: `/${MODULE}/delete`,
          method: 'post',
          data: {
            product_id: props?.info?.id,
            type: data.type,
            mode: data.mode,
            currency: data.currency,
          },
        }).then(() => {
          tableRef?.value?.initSearch()
        })
      }
      // 表格数据
      const tableColumns = [
        {
          hide: props.params?.from || '',
          type: 'index',
          label: '序号',
          width: 60,
          fixed: 'left',
        },
        {
          hide: props.params?.from || '',
          label: '类型',
          prop: 'title',
          width: 150,
          valFun: (row) => {
            return PRICE_POLICY_TYPE[row.type]
          },
        },
        {
          label: '币种',
          prop: 'currency',
          width: 100,
          valFun: (row) => {
            return supportCurrency[row.currency]
          },
        },
        { label: '策略方式', prop: 'product_list', minWidth: 130 },
        { label: '创建人', prop: 'creator.name', width: 100 },
        { label: '创建时间', prop: 'create_time', width: 110 },
        {
          width: 100,
          label: '操作',
          isOperate: true,
          fixed: 'right',
          action: {
            editProductPricePolicy: {
              type: 'editBox',
              editBoxName: 'editProductPricePolicy',
            },
            deleteProductPricePolicy: {
              event: 'deleteProductPricePolicy',
              deleteFun: (row) => {
                console.log('row :>> ', row)
                handleDeletePricePolicyReq(row)
              },
            },
            list: ['editProductPricePolicy', 'deleteProductPricePolicy'],
          },
        },
      ]
      let tableConfig = reactive({
        searchBtnInline: true,
        headerActions: {
          addProductPricePolicy: {
            type: 'editBox',
          },
          list: ['addProductPricePolicy'],
        },
        useField: false,
        tableColumn: tableColumns,
        tableListApi: `/${MODULE}/get`,
        hasPagination: false,
        showPagination: false,
        tableListParams: {
          sort: 'id',
          order: 'desc',
          type: props?.params?.typeVal || '',
          supplier_id: props.supplierId || '',
          [props?.params?.searchKey || 'product_id']: props?.info?.id || '',
        },
        tableUpdateApi: `/${MODULE}/edit`,
        tableDeleteApi: `/${MODULE}/delete`,
        tableExportApi: `/${MODULE}/export`,
        tableFileImport: {
          refreshTable: true,
          action: `/${MODULE}/import`,
        },
        tableExportPathKey: 'http_path',
        tableDeleteParams: {},
        tableButtonNum: 3,
        tableSearch:
          props.supplierId || props?.params?.from
            ? {}
            : {
                formAttrs: {
                  inline: true,
                },
                formList: [
                  {
                    type: 'select',
                    key: 'type',
                    value: '',
                    labelAttrs: {
                      label: '策略类型',
                    },
                    formAttrs: {
                      placeholder: '请选择策略类型',
                      clearable: true,
                    },
                    options: objToArr(PRICE_POLICY_TYPE),
                  },
                  {
                    type: 'submit',
                    submit: {
                      title: '搜索',
                      attrs: {
                        type: 'primary',
                      },
                    },
                    reset: {
                      title: '重置',
                    },
                  },
                ],
              },
        editBox: productPricePolicyAddDialog(
          MODULE,
          'add',
          props.info,
          props.params,
          props.supplierId ? 'supplier' : ''
        ),
        editProductPricePolicy: productPricePolicyAddDialog(
          MODULE,
          'edit',
          props.info,
          props.params,
          props.supplierId ? 'supplier' : ''
        ),
      })

      const handleHeaderAction = (action) => {
        console.log('action', action)
        // setTimeout(() => {
        //   store.commit('sys/setOpenPageDrawerName', 'productDetail')
        // }, 1000)
      }

      // 添加条件
      const handleAddPricePolicy = (model) => {
        model.list.push({
          price: 1,
          over_num: 1,
        })
      }

      // 删除条件
      const handleDeletePricePolicy = (model, index) => {
        model.list.splice(index, 1)
      }

      onBeforeMount(async () => {})
      return {
        tableConfig,
        NORMAL_STATUS,
        tableRef,
        PRODUCT_UNIT,
        MODULE,
        CURRENCY_UNIT,
        PRICE_POLICY_TYPE,
        request,
        handleHeaderAction,
        handleAddPricePolicy,
        handleDeletePricePolicy,
        t,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .system-user-container {
    height: 100%;
    :deep(.input-number) {
      .el-input__inner {
        padding-right: 0;
      }
    }
  }
</style>
