<template>
  <el-dropdown v-if="theme.showLanguage" @command="handleCommand">
    <vab-icon icon="translate" />
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item
          v-for="(lang, index) in languageList"
          :key="index"
          :class="[currentLang === lang.type ? 'is-active-menu' : '']"
          :command="lang.value"
        >
          {{ lang.label }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
  import {
    computed,
    defineComponent,
    getCurrentInstance,
    reactive,
    toRefs,
  } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import getPageTitle from '@/utils/pageTitle'
  import i18n from '@/i18n'
  import { LANGUAGE_LIST } from '@/config/variable.config'
  import { objToArr } from '@/utils/index'

  export default defineComponent({
    name: 'VabLanguage',
    setup() {
      const store = useStore()
      const route = useRoute()
      const state = reactive({
        languageList: objToArr(LANGUAGE_LIST, false),
      })

      const currentLang = computed(() => {
        return localStorage.getItem('language') || 'zh_CN'
      })

      console.log('currentLang', currentLang.value)

      const changeLanguage = (language) =>
        store.dispatch('settings/language', language)
      const handleCommand = (language) => {
        changeLanguage(language)
        console.log('language', language)
        i18n.global.locale = language
        SS.set('localDict', {})
        localStorage.setItem('language', language)
        document.title = getPageTitle(route.meta.title)
        location.reload()
        //refreshRoute()
      }

      const { proxy } = getCurrentInstance()

      const refreshRoute = () => {
        proxy.$pub('reload-router-view')
      }

      return {
        ...toRefs(state),
        currentLang,
        handleCommand,
        refreshRoute,
        theme: computed(() => store.getters['settings/theme']),
      }
    },
  })
</script>
<style lang="scss" scoped>
  :deep() {
    .is-active-menu {
      background-color: var(--el-dropdown-menuItem-hover-fill);
      color: var(--el-dropdown-menuItem-hover-color);
    }
  }
</style>
