<!--
 * @Author: WGL
 * @Date: 2022-09-06 09:57:18
 * @LastEditors: WGL
 * @LastEditTime: 2024-03-01 09:20:10
 * @Description: 
-->
<template>
  <span v-if="!edit" class="cd price-text">{{ inputModel }}</span>
  <el-input-number
    v-else-if="type === 'number'"
    v-model="inputModel"
    controls-position="right"
    placeholder=""
    @change="handleChange"
  />
  <el-input v-else v-model="inputModel" @change="handleChange" />
</template>

<script>
  import { defineComponent, computed } from 'vue'

  export default defineComponent({
    name: 'InputText',
    props: {
      modelValue: { type: [String, Number], default: null },
      type: { type: String, default: 'number' },
      edit: { type: Boolean, default: false },
    },
    emits: ['update:modelValue', 'change'],
    setup(props, { emit }) {
      const inputModel = computed({
        get: () => props.modelValue || '',
        set: (value) => {
          emit('update:modelValue', value)
        },
      })

      /**
       * @description: 事件回调
       * @param {*} val
       */
      const handleChange = (val) => {
        emit('change', val)
      }
      return {
        inputModel,
        handleChange,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
