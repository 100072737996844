<!--
 * @Author: WGL
 * @Date: 2023-11-20 14:58:07
 * @LastEditors: WGL
 * @LastEditTime: 2024-05-27 16:34:53
 * @Description: 
-->
<template>
  <el-drawer
    v-model="visible"
    append-to-body
    class="add-strategy-modal-class"
    destroy-on-close
    direction="ltr"
    :modal="false"
    :show-close="true"
    size="100%"
    :with-header="false"
  >
    <div class="pt15">
      <ProductPriceStrategyList
        :info="info"
        :params="params"
        :supplier-id="supplierId"
      />
      <el-button
        class="add-suppiler-close-btn"
        :icon="Close"
        link
        @click.stop="handleCloseDrawer"
      />
    </div>
  </el-drawer>
</template>

<script>
  import { defineComponent, reactive, ref, toRefs } from 'vue'
  import ProductPriceStrategyList from './productPriceStrategyList.vue' // 价格策略

  import { Close } from '@element-plus/icons-vue'

  export default defineComponent({
    name: 'ProductPriceStrategyDrawer',
    components: {
      ProductPriceStrategyList,
    },
    props: {
      params: { type: Object, default: () => {} },
      info: { type: Object, default: () => {} },
      refMenuId: { type: [Number, String], default: '' }, // 关联的菜单ID
      supplierId: { type: [Number, String], default: '' }, // 供应商
    },
    emits: ['add-success', 'close'],
    setup(props, { emit }) {
      const state = reactive({
        selectList: [],
      })

      const visible = ref(false)

      const handleCloseDrawer = () => {
        visible.value = false
        emit('close')
      }
      const handleOpenDrawer = () => {
        visible.value = true
      }
      return {
        ...toRefs(state),
        Close,
        visible,
        handleCloseDrawer,
        handleOpenDrawer,
      }
    },
  })
</script>

<style lang="scss">
  .add-suppiler-close-btn {
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 10;
    font-size: 20px !important;
    font-weight: lighter;
  }
  .add-strategy-modal-class {
    top: 0;
    left: 0;
    bottom: 0;
    width: 38%;
  }
</style>
