<!--
 * @Author: WGL
 * @Date: 2023-06-21 09:23:33
 * @LastEditors: WGL
 * @LastEditTime: 2024-07-24 09:33:50
 * @Description: 
-->
<template>
  <div v-if="showList?.length" class="w ex">
    <el-tag
      v-for="(item, index) in showList"
      :key="index"
      class="mb4"
      :type="tagType"
    >
      {{ item.showTitle }}
      <span v-if="nature">| {{ PRODUCT_NATURE[item.nature] }}</span>
    </el-tag>
  </div>
  <span v-else>-</span>
</template>

<script>
  // 基础产品渠道组件
  import { defineComponent, computed } from 'vue'
  import { PRODUCT_NATURE } from '@/config/variable.config'
  import { getDeepVal } from '@/utils/index'
  export default defineComponent({
    name: 'BaseTitleTags',
    components: {},
    props: {
      list: { type: Array, default: () => [] },
      tagType: { type: String, default: 'info' },
      valKey: { type: String, default: 'title' },
      nature: { type: Boolean, default: false },
      limit: { type: [Number, String], default: 0 },
    },
    setup(props) {
      const showList = computed(() => {
        let result = props.list || []
        if (props.limit) {
          result = props.list.slice(0, +props.limit)
        }
        return result.map((item) => {
          item.showTitle = getDeepVal(props.valKey, item)
          return item
        })
      })
      return {
        showList,
        PRODUCT_NATURE,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
