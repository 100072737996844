/*
 * @Author: WGL
 * @Date: 2022-04-16 11:34:59
 * @LastEditors: WGL
 * @LastEditTime: 2024-09-04 10:00:34
 * @Description:
 */
/**
 * @description 导出网络配置
 **/
const SERVER_API = {
  // development: 'http://192.168.110.245:9501',
  development: 'https://api.erp.dev.jinweitec.com',
  // development: 'https://api.erp.jinweinet.com',
  // development: '',
  // development: 'http://localhost',
  // development: 'https://api.erp.jinweinet.com',
  // production: 'https://api.erp.jinweinet.com',
  production: '',
}
module.exports = {
  // 默认的接口地址，开发环境和生产环境都会走/vab-mock-server
  // 正式项目可以选择自己配置成需要的接口地址，如"https://api.xxx.com"
  // 问号后边代表开发环境，冒号后边代表生产环境
  version: 'Ver 1.2.3.5',
  domain: `${SERVER_API[process.env.NODE_ENV]}`,
  baseURL: `${SERVER_API[process.env.NODE_ENV]}/admin`,
  imgBaseUrl: 'https://cdn.erp.jinweitec.com/',
  imgMaterialUrl: 'https://cdn.material.jinweitec.com/', // 素材图片
  // imgBaseUrl: 'https://cdn.jinweitec.com/',
  uploadMediaUrl: `${SERVER_API[process.env.NODE_ENV]}/admin/media/upload`, //素材图片上传
  uploadUrl: `${SERVER_API[process.env.NODE_ENV]}/admin/common/upload`, // 常规图片上传
  mattingUrl: `${SERVER_API[process.env.NODE_ENV]}/admin/common/matting`, // 抠图图片
  uploadFileUrl: `${SERVER_API[process.env.NODE_ENV]}/admin/file/uploadFile`, // 常规文件上传
  uploadBase64: `${SERVER_API[process.env.NODE_ENV]
    }/admin/common/uploadByBase64`, // 常规图片上传
  uploadRichTextUrl: `${SERVER_API[process.env.NODE_ENV]
    }/admin/common/uploadEditor`, // 富文本图片、视频上传地址
  // 配后端数据的接收方式application/json;charset=UTF-8 或 application/x-www-form-urlencoded;charset=UTF-8
  contentType: 'application/x-www-form-urlencoded;charset=UTF-8',
  // contentType: 'application/json;charset=UTF-8',
  // 最长请求时间
  requestTimeout: 60 * 1000,
  // 操作正常code，支持String、Array、int多种类型
  successCode: [200, 0, '200', '0'],
  // 数据状态的字段名称
  statusName: 'code',
  // 状态信息的字段名称
  messageName: 'msg',
}
