<!--
 * @Author: WGL
 * @Date: 2023-12-12 10:41:37
 * @LastEditors: WGL
 * @LastEditTime: 2024-04-28 10:22:19
 * @Description: 
-->
<template>
  <div v-if="item?.promote?.top" class="g9" v-html="item.promote.top"></div>
  <slot :name="item.labelKey">
    <el-input
      v-if="item.uiType === 'input'"
      v-model="appModel[item.labelKey]"
      v-bind="item.attrs"
      type="text"
    />
    <div v-else-if="item.uiType === 'inputTag'">
      <div class="fx w fxwp">
        <div
          v-for="(input, index) in appModel[item.labelKey]"
          :key="index"
          class="fx p5"
        >
          <el-input
            v-model="input.text"
            class="w200"
            v-bind="item.attrs"
            :show-word-limit="false"
            @blur="handleInputTagBlur($event, item, 'exisit')"
          />
          <el-button
            link
            type="primary"
            @click="handleRemoveInputTag(index, item)"
          >
            删除
          </el-button>
        </div>
        <el-input
          v-model="inputTagVal"
          class="w200 p5"
          v-bind="item.attrs"
          :show-word-limit="false"
          @blur="handleInputTagBlur($event, item)"
        />
      </div>
      <div v-if="inputTagValTips" class="cd fs13">{{ inputTagValTips }}</div>
    </div>
    <el-select
      v-else-if="item.uiType === 'select'"
      v-model="appModel[item.labelKey]"
      v-bind="item.attrs"
      class="w"
    >
      <el-option
        v-for="(option, i) in item.dataSource"
        :key="i"
        :label="option.text"
        :value="option.value"
      />
    </el-select>
    <div v-else-if="item.uiType === 'freightSelect'">
      <el-select
        v-model="appModel[item.labelKey]"
        v-bind="item.attrs"
        class="w"
        @change="handleSelectChange($event, item)"
      >
        <el-option
          v-for="(option, i) in item.dataSource"
          :key="i"
          :label="option.text"
          :value="option.value"
        />
      </el-select>
    </div>
    <el-radio-group
      v-else-if="item.uiType === 'radio'"
      v-model="appModel[item.labelKey]"
      @change="handleRadioChange"
    >
      <el-radio v-for="(rad, i) in item.dataSource" :key="i" :label="rad.value">
        {{ rad.text }}
        <el-popover v-if="rad.help" placement="right" trigger="hover" width="">
          <div class="popover-content-wrapper" v-html="rad.help"></div>
          <template #reference>
            <jwt-icon class="gd fs16" icon="question-fill" />
          </template>
        </el-popover>

        <!-- <el-tooltip
          v-if="rad.help"
          :content="rad.help"
          effect="light"
          placement="right"
        /> -->
      </el-radio>
    </el-radio-group>
    <!-- 特殊处理定时开售 -->
    <el-radio-group
      v-else-if="item.uiType === 'shelftime'"
      v-model="appModel[item.labelKey]"
      @change="handleRadioChange"
    >
      <el-radio v-for="(rad, i) in item.dataSource" :key="i" :label="rad.value">
        {{ rad.text }}
        <el-popover v-if="rad.help" placement="right" trigger="hover" width="">
          <div class="popover-content-wrapper" v-html="rad.help"></div>
          <template #reference>
            <jwt-icon class="gd fs16" icon="question-fill" />
          </template>
        </el-popover>
      </el-radio>
    </el-radio-group>
    <el-select
      v-else-if="item.uiType === 'unit'"
      v-model="appModel[item.labelKey]"
      v-bind="item.attrs"
    >
      <el-option
        v-for="(option, i) in item.unitOptions"
        :key="i"
        :label="option"
        :value="option"
      />
    </el-select>
    <el-checkbox-group
      v-else-if="item.uiType === 'checkbox'"
      v-model="appModel[item.labelKey]"
      v-bind="item.attrs"
    >
      <el-checkbox
        v-for="ckb in item.dataSource"
        :key="ckb.value"
        :label="ckb.value"
      >
        {{ ckb.text }}
      </el-checkbox>
    </el-checkbox-group>
    <el-checkbox-group
      v-else-if="item.uiType === 'mode'"
      v-model="appModel[item.labelKey]"
      v-bind="item.attrs"
    >
      <el-checkbox
        v-for="ckb in item.processTemplateList"
        :key="ckb.id"
        :label="ckb.id"
      >
        {{ ckb.name }}
        <el-popover
          v-if="ckb.help"
          placement="right"
          trigger="hover"
          width="450"
        >
          <div class="popover-content-wrapper" v-html="ckb.help"></div>
          <template #reference>
            <jwt-icon class="gd fs16" icon="question-fill" />
          </template>
        </el-popover>
      </el-checkbox>
    </el-checkbox-group>
    <template v-else-if="item.uiType === 'table'">
      <el-table border :data="appModel[item.labelKey]" stripe>
        <el-table-column
          v-for="(column, index) in item.column"
          :key="index"
          :min-width="50"
          v-bind="column"
        />
      </el-table>
    </template>
    <el-input
      v-else-if="item.uiType === 'number'"
      v-model.number="appModel[item.labelKey]"
      v-bind="item.attrs"
      class="w200"
      type="number"
    >
      <template #append v-if="item.refUintLabelKey">
        {{ appModel[item.refUintLabelKey] }}
      </template>
    </el-input>
    <template v-else-if="item.uiType === 'volume'">
      <div>
        <el-input
          v-model.number="appModel[item.labelKey].length"
          v-bind="item.attrs"
          class="w200"
          placeholder="长"
          type="number"
        >
          <template #append>厘米</template>
        </el-input>
        <span class="plr8">X</span>
        <el-input
          v-model.number="appModel[item.labelKey].width"
          v-bind="item.attrs"
          class="w200"
          placeholder="宽"
          type="number"
        >
          <template #append>厘米</template>
        </el-input>
        <span class="plr8">X</span>
        <el-input
          v-model.number="appModel[item.labelKey].height"
          v-bind="item.attrs"
          class="w200"
          placeholder="高"
          type="number"
        >
          <template #append>厘米</template>
        </el-input>
      </div>
    </template>
    <el-cascader
      v-else-if="item.uiType === 'tree'"
      v-model="appModel[item.labelKey]"
      clearable
      :options="item.dataSource"
      :props="treeProps"
    />
    <jwt-editor
      v-else-if="item.uiType === 'richText'"
      v-model="appModel[item.labelKey]"
      class="ex mh0"
      :exclude-keys="excludeKeys"
      height="400"
      :toolbar-keys="toolbarKeys"
      :upload-url="uploadRichTextUrl"
    />
    <el-input
      v-else
      v-model="appModel[item.labelKey]"
      v-bind="item.attrs"
      type="text"
    />
  </slot>
  <div
    v-if="item?.promote?.right"
    class="ml20 g9 ex lh"
    v-html="item.promote.right"
  ></div>
  <div
    v-if="item?.promote?.bottom"
    class="w bg r4px plr10 mt5"
    v-html="item.promote.bottom"
  ></div>
</template>

<script>
  import { defineComponent, computed, ref, nextTick } from 'vue'
  import { uploadRichTextUrl } from '@/config/index'
  import { getAlbbApiGeneral } from '@/api/third'
  export default defineComponent({
    name: 'AlbbFormItem',
    components: {},
    props: {
      item: { type: Object, default: () => {} },
      formModel: { type: Object, default: () => {} },
      globalData: { type: Object, default: () => {} },
    },
    emits: ['update:modelValue', 'change'],
    setup(props, { emit }) {
      // console.log('props :>> ', props.item)
      const inputTagVal = ref(null)
      const inputTagValTips = ref(null)
      const treeProps = ref({
        value: 'value',
        label: 'text',
        children: 'children',
        multiple: true,
        checkStrictly: true,
      })
      const toolbarKeys = []
      const excludeKeys = [
        'blockquote',
        'group-more-style',
        'fontFamily',
        'lineHeight',
        'todo',
        'emotion',
        'uploadAttachment',
        'group-video',
        'insertTable',
        'codeBlock',
        'divider',
        'undo',
        'redo',
      ]
      const appModel = computed({
        get: () => {
          return props.formModel || {}
        },
        set: (value) => {
          emit('update:modelValue', value)
        },
      })

      const handleRadioChange = (val) => {
        emit('change', val)
      }

      const handleCheckInputTag = (item) => {
        const hasEmpty = appModel.value[item.labelKey].some((v) => !v.value)
        console.log('hasEmpty :>> ', hasEmpty)
        inputTagValTips.value = hasEmpty ? item.label + '不能为空' : ''
      }

      /**
       * 失去焦点
       * @param {*} e
       * @param {*} item
       */
      const handleInputTagBlur = (e, item, type = '') => {
        console.log('val :>> ', e)
        const value = e?.target?.value
        if (type === 'exisit') {
          handleCheckInputTag(item)
          return
        }
        if (value != '') {
          appModel.value[item.labelKey].push({
            value: '',
            text: value,
            custom: true,
          })
          inputTagVal.value = ''
          emit('change', appModel.value[item.labelKey])
        }
        // const value=e.tar
      }

      const handleRemoveInputTag = (index, item) => {
        appModel.value[item.labelKey].splice(index, 1)
        emit('change', appModel.value[item.labelKey])
        nextTick(() => {
          handleCheckInputTag(item)
        })
      }

      const handleGetAlbbAsyncData = (optType = '') => {
        const params = {
          shop_id: props.formModel.shop_id,
          api: 'com.alibaba.product:product.asyncQueryInfo.getInfo-1',
          data: {
            optType,
            dataJson: {
              global: props.globalData,
              data: {
                value: '',
                pageIndex: 1,
                pageSize: 20,
              },
            },
          },
        }
        console.log('params', params)
        // debugger
        getAlbbApiGeneral(params)
      }

      const handleSelectChange = (val, item) => {
        console.log('val :>> ', val)
        const { labelKey, dataSource } = item
        if (labelKey === 'freight') {
          const hasAsyncQueryType = dataSource.filter(
            (item) => item.asyncQueryType && item.value === val
          )
          // 有异步请求的数据
          if (hasAsyncQueryType[0]?.asyncQueryType) {
            handleGetAlbbAsyncData(hasAsyncQueryType[0]?.asyncQueryType)
          }
        }
      }

      return {
        appModel,
        treeProps,
        toolbarKeys,
        excludeKeys,
        inputTagVal,
        inputTagValTips,
        uploadRichTextUrl,
        handleRadioChange,
        handleSelectChange,
        handleInputTagBlur,
        handleRemoveInputTag,
      }
    },
  })
</script>

<style lang="scss">
  .popover-content-wrapper {
    img {
      display: block;
      max-width: 450px;
    }
  }
</style>
