export function handleStoreCategory(list) {
  console.log('list :>> ', list)
  Array.isArray(list) &&
    list.forEach((item) => {
      if (item.children && item.children.length === 0) {
        item.children = null
      } else {
        handleStoreCategory(item.children)
      }
    })
}
