<template>
  <jwt-table-only
    ref="jwtTableRef"
    :config="appConfig"
    :fill-url="fillUrl"
    :request="request"
    :table-height="tableHeight"
    :upload-url="uploadUrl"
    @list-change="handleListChange"
    @selection-change="handleSelectionChange"
    @table-current-change="handleTableCurrentChange"
    @tablelist-change="handleTablelistChange"
  >
    <template v-for="k in Object.keys(slots)" #[k]="{ scope }" :key="k">
      <slot :name="k" :scope="scope"></slot>
    </template>
  </jwt-table-only>
</template>

<script>
  import { defineComponent, ref, onBeforeMount, useSlots, computed } from 'vue'
  import request from '@/utils/request'
  import { fillUrl } from '@/common/js/utils'
  import { uploadUrl } from '@/config/index'

  // import AppForm from '@/components/AppForm'
  import dayjs from 'dayjs'

  export default defineComponent({
    name: 'BaseOnlyTable',
    components: {
      // AppForm,
    },
    props: {
      config: {
        require: true,
        type: Object,
        default: () => {},
      },
      tableHeight: {
        type: [String, Number],
        default: null,
      },
    },
    emits: [
      'selection-change',
      'list-change',
      'header-action',
      'table-action',
      'add-handle',
      'edit-handle',
      'add-success',
      'edit-success',
      'delete-success',
      'table-current-change',
      'import-goods',
      'tablelist-change',
      'click-func',
      'get-detail-success',
    ],
    setup(props, { emit }) {
      const jwtTableRef = ref(null)
      const slots = useSlots()

      let appConfig = computed(() => props.config)

      /**
       * 勾选列表回调
       * @param val
       */
      const handleSelectionChange = (val) => {
        emit('selection-change', val)
      }

      // 列表数据改变
      const handleListChange = () => {
        emit('list-change')
      }

      /**
       * 表格选择单行数据时
       * @param val
       */
      const handleTableCurrentChange = (currentRow) => {
        // console.log('currentRow', currentRow)
        emit('table-current-change', currentRow)
      }

      const handleTablelistChange = () => {
        emit('tablelist-change')
      }

      // 表格数据
      const tableData = computed(() => jwtTableRef.value.tableData)

      const initSearch = () => {
        jwtTableRef.value.initSearch()
      }
      onBeforeMount(() => {})

      return {
        request,
        uploadUrl,
        appConfig,
        jwtTableRef,
        dayjs,
        slots,
        tableData,
        fillUrl,
        handleSelectionChange,
        handleListChange,
        handleTableCurrentChange,
        handleTablelistChange,
        initSearch,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
